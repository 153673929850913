<template>
  <div class="o-layout">
    <div class="o-layout__item u-4of12">
      <router-link to="/investors/drafts" class="c-btn c-btn--block c-btn--medium u-mrgn-bottom--1" tag="button">
        Draft
      </router-link>
    </div>

    <div class="o-layout__item u-4of12">
      <div v-if="isAllowed('investor', 'download_sinvest')">
        <bl-button-av
          block
          class="investor-index-action-buttons__download-sid u-mrgn-bottom--1"
          @click="$refs.DownloadInvestorFileSheet.openDialog('sid')"
        >
          Download SID
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__download-ifua u-mrgn-bottom--1"
          @click="$refs.DownloadInvestorFileSheet.openDialog('ifua')"
        >
          Download IFUA
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__download-bank u-mrgn-bottom--1"
          @click="$refs.DownloadInvestorFileSheet.openDialog('bank')"
        >
          Download Bank
        </bl-button-av>
      </div>
      <div v-if="isAllowed('investor', 'upload_sinvest')">
        <bl-button-av
          block
          class="investor-index-action-buttons__upload-sid u-mrgn-bottom--1"
          @click="$refs.UploadInvestorFileSheet.openDialog('SID')"
        >
          Upload SID
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__upload-ifua u-mrgn-bottom--1"
          @click="$refs.UploadInvestorFileSheet.openDialog('IFUA')"
        >
          Upload IFUA
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__upload-bank u-mrgn-bottom--1"
          @click="$refs.UploadInvestorFileSheet.openDialog('Bank')"
        >
          Upload Bank
        </bl-button-av>
      </div>
    </div>
    <div class="o-layout__item u-4of12">
      <div v-if="isAllowed('investor_revise', 'download_sinvest')">
        <bl-button-av
          block
          class="investor-index-action-buttons__download-revise-data u-mrgn-bottom--1"
          @click="$refs.DownloadInvestorFileSheet.openDialog('revise_data')"
        >
          Download Update Investor
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__download-revise-bank u-mrgn-bottom--1"
          @click="$refs.DownloadInvestorFileSheet.openDialog('revise_bank')"
        >
          Download Update Bank
        </bl-button-av>
      </div>

      <div v-if="isAllowed('investor_revise', 'upload_sinvest')">
        <bl-button-av
          block
          class="investor-index-action-buttons__upload-revise-data u-mrgn-bottom--1"
          @click="$refs.UploadInvestorFileSheet.openDialog('revise_data')"
        >
          Upload Amandement Investor
        </bl-button-av>
        <bl-button-av
          block
          class="investor-index-action-buttons__upload-revise-bank u-mrgn-bottom--1"
          @click="$refs.UploadInvestorFileSheet.openDialog('revise_bank')"
        >
          Upload Amandement Bank
        </bl-button-av>
      </div>
    </div>

    <upload-investor-file-sheet ref="UploadInvestorFileSheet" />
    <download-investor-file-sheet ref="DownloadInvestorFileSheet" :agent-options="agentOptions" />
  </div>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import UploadInvestorFileSheet from './UploadInvestorFileSheet';
import DownloadInvestorFileSheet from './DownloadInvestorFileSheet';

export default {
  name: 'InvestorIndexActionButtons',
  components: {
    BlButtonAv,
    UploadInvestorFileSheet,
    DownloadInvestorFileSheet,
  },
  mixins: [policyMixin],
  props: {
    state: {
      type: String,
      default: '',
    },
    agentOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>
