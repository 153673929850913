<template>
  <div>
    <bl-button-av class="u-mrgn-bottom--1" @click="openUploadDialog">
      Update Mass NAV
    </bl-button-av>
    <bl-dialog-mv :active.sync="uploadDialog" size="medium" title="Update Mass Nav">
      <bl-field-mv>
        <bl-field-label-av>Mass nav File</bl-field-label-av>
        <bl-field-row-mv>
          <bl-upload-field-av ref="input" v-model="navFileUpload" @input="onFileChange" />
        </bl-field-row-mv>
      </bl-field-mv>
      <div v-if="csvData.length" class="u-mrgn-top--4">
        <Table
          :is-draft="false"
          :table-header="['Tanggal', 'Product', 'NAV']"
          :table-data="csvData"
          :load-state="'success'"
        />
      </div>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av style="width: 50%; display: inline-block;" @click="uploadDialog = false">
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%; display: inline-block;"
          color="red"
          has-spinner
          :disabled="isUploading"
          :activated="isUploading"
          @click="uploadMassNav()"
        >
          Upload
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
  </div>
</template>
<script>
import { money } from 'partner-admin/shared/helpers/numberHelper';
import Table from 'partner-admin/components/Table';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlUploadFieldAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlUploadFieldAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';

export default {
  name: 'MassNavUpload',
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlUploadFieldAv,
    BlButtonGroupMv,
    BlButtonAv,
    BlDialogMv,
    BlFieldLabelAv,
  },
  data() {
    return {
      uploadDialog: false,
      navFileUpload: null,
      csvData: [],
      isUploading: false,
    };
  },
  methods: {
    onFileChange(files) {
      if (files[0]) {
        const dataReader = new FileReader();
        dataReader.onload = file => {
          this.navFileUpload = {
            file_name: files[0].name,
            file: file.target.result,
          };
        };
        dataReader.readAsDataURL(files[0]);

        const textReader = new FileReader();
        textReader.onload = file => {
          this.parseCSV(file.target.result);
        };
        textReader.readAsText(files[0]);
      }
    },
    parseCSV(text) {
      const rows = text
        .split('\n')
        .map(item => item.split('|'))
        .filter(item => item[0] && item.length > 1);

      const headerRow = rows.shift();
      const columnNames = ['NAV Date', 'Fund Name', 'Nav per Unit'];
      const columnIndex = columnNames.map(col => headerRow.findIndex(item => item === col));

      const tableRow = rows.map((row, rowIdx) => {
        const data = [rowIdx];
        columnNames.forEach((name, idx) => {
          let value = row[columnIndex[idx]];
          if (columnNames[idx] === 'Nav per Unit') {
            const decimal = value.split('.')[1];
            const precision = decimal ? decimal.length : 2;
            value = money(value, precision);
          }
          data.push(value);
        });
        return data;
      });

      this.csvData = tableRow;
    },
    openUploadDialog() {
      this.csvData = [];
      this.navFileUpload = null;
      this.uploadDialog = true;
    },
    handleFinishRequest(success) {
      this.isUploading = false;
      if (success) {
        this.uploadDialog = false;
      }
    },

    uploadMassNav() {
      this.isUploading = true;
      this.$emit('upload', this.navFileUpload);
    },
  },
};
</script>
