<template>
  <Table
    :load-state="loadState"
    :rows="sktDocuments"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <upload-skt-table-row
      v-for="(sktdDocument, index) in sktDocuments"
      :key="index"
      :skt-document="sktdDocument"
      @refresh="refresh"
      @showError="showError"
    />
    <bl-dialog-mv :active.sync="showErrorDialog" title="Error Note">
      <div style="margin-bottom: 18px">
        {{ errorNote }}
      </div>

      <bl-button-av block color="red" @click="showErrorDialog = false">
        Tutup
      </bl-button-av>
    </bl-dialog-mv>
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import UploadSktTableRow from './UploadSktTableRow';

export default {
  name: 'UploadSktTable',
  components: {
    UploadSktTableRow,
    BlButtonAv,
    BlDialogMv,
  },
  mixins: [tableWrapperMixin],
  headers: ['Upload Date', 'Produk', 'SKT Date', 'Status', 'Upload By'],
  props: {
    sktDocuments: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showErrorDialog: false,
      errorNote: '',
    };
  },
  methods: {
    showError(errorNote) {
      this.errorNote = errorNote;
      this.showErrorDialog = true;
    },
  },
};
</script>
