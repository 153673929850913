<template>
  <div>
    <basic-field label="Nomor Identitas">
      <bl-text-field-mv
        class="old_identity_number"
        :value="newData.identity_number || oldData.identity_number"
        readonly
      />
    </basic-field>
    <basic-field label="NPWP">
      <bl-text-field-mv class="old_npwp_number" :value="newData.npwp_number || oldData.npwp_number" readonly />
    </basic-field>
    <basic-field label="IFUA (Investor Fund Unit Account)">
      <bl-text-field-mv class="old_ifua" :value="newData.ifua || oldData.ifua" readonly />
    </basic-field>
    <basic-field label="SID (Single Investor Identification)">
      <bl-text-field-mv class="old_sid" :value="newData.sid || oldData.sid" readonly />
    </basic-field>
    <basic-field label="Investor Type">
      <bl-text-field-mv class="old_investor_type" :value="newData.investor_type || oldData.investor_type" readonly />
    </basic-field>
    <basic-field label="Agent">
      <bl-text-field-mv class="old_agent" :value="agent" readonly />
    </basic-field>
    <basic-field label="Nama">
      <bl-text-field-mv class="old_name" :value="newData.name || oldData.name" readonly />
    </basic-field>
    <basic-field label="Tempat Lahir">
      <bl-text-field-mv class="old_birth_place" :value="newData.birth_place || oldData.birth_place" readonly />
    </basic-field>
    <basic-field label="Tanggal Lahir">
      <bl-text-field-mv class="old_birth_date" :value="newData.birth_date || oldData.birth_date" readonly />
    </basic-field>
    <basic-field label="Jenis Kelamin">
      <bl-text-field-mv class="old_gender" :value="newData.gender || oldData.gender" readonly />
    </basic-field>
    <basic-field label="Provinsi">
      <bl-text-field-mv class="old_province" :value="newData.province || oldData.province" readonly />
    </basic-field>
    <basic-field label="Kota/Kabupaten">
      <bl-text-field-mv class="old_city" :value="newData.city || oldData.city" readonly />
    </basic-field>
    <basic-field label="Kecamatan">
      <bl-text-field-mv class="old_sub_district" :value="sub_district || old_sub_district" readonly />
    </basic-field>
    <basic-field label="Kel Desa">
      <bl-text-field-mv class="old_village" :value="village || old_village" readonly />
    </basic-field>
    <basic-field label="RT/RW">
      <bl-text-field-mv class="old_neighborhood" :value="neighborhood || old_neighborhood" readonly />
    </basic-field>
    <basic-field label="Alamat">
      <bl-text-field-mv class="old_address" :value="address || old_address" readonly />
    </basic-field>
    <basic-field label="Kode Pos">
      <bl-text-field-mv class="old_postal_code" :value="newData.postal_code || oldData.postal_code" readonly />
    </basic-field>
    <basic-field label="Domisili Provinsi">
      <bl-text-field-mv
        class="old_domicile_province"
        :value="newData.domicile_province || oldData.domicile_province"
        readonly
      />
    </basic-field>
    <basic-field label="Domisili Kota/Kabupaten">
      <bl-text-field-mv class="old_domicile_city" :value="newData.domicile_city || oldData.domicile_city" readonly />
    </basic-field>
    <basic-field label="Domisili Kecamatan">
      <bl-text-field-mv
        class="old_domicile_sub_district"
        :value="domicile_sub_district || old_domicile_sub_district"
        readonly
      />
    </basic-field>
    <basic-field label="Domisili Kel Desa">
      <bl-text-field-mv class="old_domicile_village" :value="domicile_village || old_domicile_village" readonly />
    </basic-field>
    <basic-field label="Domisili RT/RW">
      <bl-text-field-mv
        class="old_domicile_neighborhood"
        :value="domicile_neighborhood || old_domicile_neighborhood"
        readonly
      />
    </basic-field>
    <basic-field label="Domisili Alamat">
      <bl-text-field-mv class="old_domicile_address" :value="domicile_address || old_domicile_address" readonly />
    </basic-field>
    <basic-field label="Domisili Kode Pos">
      <bl-text-field-mv
        class="old_domicile_postal_code"
        :value="newData.domicile_postal_code || oldData.domicile_postal_code"
        readonly
      />
    </basic-field>
    <basic-field label="Agama">
      <bl-text-field-mv class="old_religion" :value="newData.religion || oldData.religion" readonly />
    </basic-field>
    <basic-field label="Status Perkawinan">
      <bl-text-field-mv class="old_marital_status" :value="newData.marital_status || oldData.marital_status" readonly />
    </basic-field>
    <basic-field label="Pekerjaan">
      <bl-text-field-mv class="old_occupation" :value="newData.occupation || oldData.occupation" readonly />
    </basic-field>
    <basic-field label="Education">
      <bl-text-field-mv class="old_education" :value="newData.education || oldData.education" readonly />
    </basic-field>
    <basic-field label="Income Level">
      <bl-text-field-mv class="old_income_level" :value="newData.income_level || oldData.income_level" readonly />
    </basic-field>
    <basic-field label="Risk Profile">
      <bl-text-field-mv class="old_risk_profile" :value="newData.risk_profile || oldData.risk_profile" readonly />
    </basic-field>
    <basic-field label="Investment Objective">
      <bl-text-field-mv
        class="old_investment_objective"
        :value="newData.investment_objective || oldData.investment_objective"
        readonly
      />
    </basic-field>
    <basic-field label="Source of Fund">
      <bl-text-field-mv class="old_source_of_fund" :value="newData.source_of_fund || oldData.source_of_fund" readonly />
    </basic-field>
    <basic-field label="E-mail">
      <bl-text-field-mv class="old_email" :value="newData.email || oldData.email" readonly />
    </basic-field>
    <basic-field label="Beneficiary Owner">
      <bl-text-field-mv
        class="old_bo"
        :value="getBooleanDropdownValue(newData.bo) || getBooleanDropdownValue(oldData.bo)"
        readonly
      />
    </basic-field>
    <basic-field label="Politically Exposed Persons">
      <bl-text-field-mv
        class="old_pep"
        :value="getBooleanDropdownValue(newData.pep) || getBooleanDropdownValue(oldData.pep)"
        readonly
      />
    </basic-field>
    <basic-field label="phone">
      <bl-text-field-mv class="old_phone" :value="newData.phone || oldData.phone" readonly />
    </basic-field>
  </div>
</template>
<script>
import InvestorDraftViewMixin from '../../mixins/InvestorDraftViewMixin';

export default {
  name: 'InvestorDraftViewData',
  mixins: [InvestorDraftViewMixin],
  data() {
    return {
      address: null,
      neighborhood: null,
      village: null,
      sub_district: null,
      old_address: null,
      old_neighborhood: null,
      old_village: null,
      old_sub_district: null,
      domicile_address: null,
      domicile_neighborhood: null,
      domicile_village: null,
      domicile_sub_district: null,
      old_domicile_address: null,
      old_domicile_neighborhood: null,
      old_domicile_village: null,
      old_domicile_sub_district: null,
    };
  },
  computed: {
    agent() {
      if (this.newData.agent) return this.newData.agent.name;
      if (this.oldData.agent) return this.oldData.agent.name;
      return '';
    },
  },
  created() {
    this.populateData();
  },
  methods: {
    populateData() {
      if (this.newData.address) {
        const [address, neighborhood, village, subDistrict] = this.newData.address.split(',').map(val => val.trim());
        this.address = address;
        this.neighborhood = neighborhood || null;
        this.village = village || null;
        this.sub_district = subDistrict || null;
      }

      if (this.newData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.newData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.domicile_address = address;
        this.domicile_neighborhood = neighborhood || null;
        this.domicile_village = village || null;
        this.domicile_sub_district = subDistrict || null;
      }

      if (this.oldData.address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.address.split(',').map(val => val.trim());
        this.old_address = address;
        this.old_neighborhood = neighborhood || null;
        this.old_village = village || null;
        this.old_sub_district = subDistrict || null;
      }

      if (this.oldData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.old_domicile_address = address;
        this.old_domicile_neighborhood = neighborhood || null;
        this.old_domicile_village = village || null;
        this.old_domicile_sub_district = subDistrict || null;
      }
    },
  },
};
</script>
