<template>
  <ul class="c-tab__nav">
    <tab-nav-item v-if="isAllowed('transaction', 'view_list')" to="/transactions">
      Transactions
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('product', 'view_list')" to="/products">
      Products
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('investor', 'view_list')" to="/investors">
      Investors
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('nav', 'view_list')" to="/navs">
      NAV
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('portfolio', 'view_list')" to="/portofolios">
      Portofolios
    </tab-nav-item>
    <tab-nav-item v-if="fileManagementPolicy" to="/file-management">
      File Management
    </tab-nav-item>
    <tab-nav-item
      v-if="isAllowed('document', 'transaction_confirmation') || isAllowed('document', 'account_statement')"
      :to="uploadSktRedirect"
    >
      Upload SKT
    </tab-nav-item>
    <tab-nav-item v-if="canAccessReport" to="/reports">
      ARIA Report
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('aml_report', 'download')" to="/aml-cft">
      AML & CFT Report
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('agent', 'view_list')" to="/agents">
      Agents
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('exchange_rate', 'view_list')" to="/exchange-rate">
      Exchange Rate
    </tab-nav-item>
    <tab-nav-item v-if="isSuperStaff" to="/roles">
      Staff
    </tab-nav-item>
    <tab-nav-item v-if="isAllowed('investor', 'user_management')" to="/users">
      Users
    </tab-nav-item>
  </ul>
</template>

<script>
/* istanbul ignore file */
import policyMixin from 'partner-admin/mixins/policyMixin';
import TabNavItem from './TabNavItem';

export default {
  components: {
    TabNavItem,
  },
  mixins: [policyMixin],
  computed: {
    uploadSktRedirect() {
      if (this.isAllowed('document', 'account_statement') && !this.isAllowed('document', 'transaction_confirmation')) {
        return '/upload-skt/account-statement';
      }
      return '/upload-skt';
    },
    fileManagementPolicy() {
      return (
        this.isAllowed('transaction', 'upload') ||
        this.isAllowed('transaction', 'upload_sinvest') ||
        this.isAllowed('nav', 'upload_sinvest') ||
        this.isAllowed('investor', 'upload_sinvest') ||
        this.isAllowed('investor_revise', 'upload_sinvest')
      );
    },
  },
};
</script>
