<template>
  <div
    v-if="draftRejectionReason"
    class="draft-rejection-reason"
  >
    <basic-field :label="draftRejectionReason.title">
      <bl-text-field-mv
        v-model="draftRejectionReason.reason"
        multiline
        readonly
      />
    </basic-field>
  </div>
</template>

<script>
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from 'partner-admin/components/BasicField';
import dayjs from 'dayjs';

export default {
  name: 'DraftRejectionReason',
  components: {
    BlTextFieldMv,
    BasicField,
  },
  props: {
    draft: {
      type: Object,
      required: true,
    },
    form: {
      type: String,
      required: true,
      validator(value) {
        return ['view', 'review', 'kyc'].includes(value);
      },
    },
  },
  computed: {
    draftRejectionReason() {
      const title = 'First / Second Rejection Reason (date)';
      if (this.form === 'view' && this.draft.rejection_reason) {
        return {
          reason: this.draft.rejection_reason,
          title: title.replace('date', dayjs(this.draft.rejected_at).format('DD MMMM YYYY')),
        };
      }

      if (this.form === 'kyc' && this.draft.last_draft && this.draft.last_draft.rejection_reason) {
        return {
          reason: this.draft.last_draft.rejection_reason,
          title: title.replace('date', dayjs(this.draft.last_draft.rejected_at).format('DD MMMM YYYY')),
        };
      }

      return null;
    },
  },
};
</script>
