import api from 'partner-admin/api';

export default {
  getLoginState({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.loadState !== 'loading') {
        commit('setLoadState', 'loading');
        api
          .fetchToken()
          .then(token => {
            commit('setIsLoggedIn', !!token);
            commit('setToken', token);
            commit('setLoadState', 'success');
            resolve();
          })
          .catch(error => {
            commit('setLoadState', 'error');
            // disable error refresh token when trying to get session after expire
            if (error.errors && error.errors[0] && error.errors[0].field === 'refresh_token') {
              commit('setIsLoggedIn', false);
              resolve();
            } else {
              reject(error);
            }
          });
      } else {
        resolve();
      }
    });
  },
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .login(payload)
        .then(token => {
          commit('setToken', token);
          commit('setIsLoggedIn', true);
          resolve();
        })
        .catch(e => {
          if (e.message === 'Oauth::MustChangePassword') {
            commit('setIsLoggedIn', true);
          }
          reject(e);
        });
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      api
        .logout()
        .catch(() => {})
        .finally(() => {
          commit('setIsLoggedIn', false);
          commit('setToken', null);
          commit('setLoadState', 'idle');
          resolve();
        });
    });
  },
};
