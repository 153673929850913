import Vue from 'partner-admin/lib/vue';
import Vuex from 'partner-admin/lib/vuex';
import products from './products';
import investors from './investors';
import drafts from './drafts';
import histories from './histories';
import policies from './policies';
import transactions from './transactions';
import portfolios from './portfolios';
import navs from './navs';
import session from './session';
import user from './user';
import filters from './filters';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  modules: {
    products,
    investors,
    drafts,
    histories,
    policies,
    transactions,
    portfolios,
    navs,
    session,
    user,
    filters,
  },
});
