<template>
  <bl-field-mv split>
    <bl-field-col-mv>
      <bl-field-label-av v-if="label">
        {{ label }}
      </bl-field-label-av>
    </bl-field-col-mv>
    <bl-field-col-mv> <slot /> </bl-field-col-mv>
  </bl-field-mv>
</template>
<script>
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldColMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldColMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';

export default {
  name: 'InlineField',
  components: {
    BlFieldMv,
    BlFieldColMv,
    BlFieldLabelAv,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
