import api from 'partner-admin/api';
import populateDraft from './formatter/populateDraft';
import constructDraft from './formatter/constructDraft';

const states = {
  drafts: [],
  draftsLoadState: 'idle',
  draftsTotal: 0,
  draftsFilter: {},
};

const getters = {
  getDrafts: state => state.drafts,
  getDraftsLoadState: state => state.draftsLoadState,
  getDraftsTotal: state => state.draftsTotal,
  getDraftsFilter: state => state.draftsFilter,
};

const mutations = {
  setDrafts: (state, val) => {
    state.drafts = val;
  },
  setDraftsLoadState: (state, val) => {
    state.draftsLoadState = val;
  },
  setDraftsTotal: (state, val) => {
    state.draftsTotal = val;
  },
  setDraftsFilter: (state, val) => {
    state.draftsFilter = { ...state.draftsFilter, ...val };
  },
  updateDraft: (state, val) => {
    const draftIdx = state.drafts.findIndex(draft => draft.id === val.id);
    if (draftIdx > -1) {
      state.drafts.splice(draftIdx, 1, val);
    } else {
      state.drafts.unshift(val);
    }
  },
};

// Helpers Start
function getAllPopulateDraftPromises({ dispatch }, drafts) {
  const promises = [];
  drafts.forEach(draft => {
    const recordId = draft.record_id;
    if (recordId) {
      if (draft.record_type === 'Bib::Product') {
        dispatch('getProductById', recordId).then(product => {
          promises.push(dispatch('populateDraftWithOriginalData', { draft, originalData: product }));
        });
      }
      if (draft.record_type === 'Bib::Investor') {
        dispatch('getInvestorById', recordId).then(investor => {
          promises.push(dispatch('populateDraftWithOriginalData', { draft, originalData: investor }));
        });
      }
    }
  });
  return promises;
}

function massPopulateDraftsWithId({ state, commit, dispatch, resolve }, drafts) {
  const populateActions = getAllPopulateDraftPromises({ dispatch }, drafts);
  if (populateActions.length) {
    Promise.all(populateActions).then(() => {
      commit('setDraftsLoadState', 'success');
      resolve(state.drafts);
    });
  } else {
    commit('setDraftsLoadState', 'success');
    resolve(state.drafts);
  }
}
// Helpers End

const actions = {
  retrieveDrafts({ state, commit, dispatch }, filterPayload) {
    return new Promise((resolve, reject) => {
      if (state.draftsLoadState !== 'loading') {
        commit('setDraftsLoadState', 'loading');
        commit('setDraftsFilter', filterPayload);
        api
          .getDrafts(filterPayload)
          .then(response => {
            const drafts = response.data.map(constructDraft);
            commit('setDrafts', drafts);
            commit('setDraftsTotal', response.meta.total);
            massPopulateDraftsWithId({ state, commit, dispatch, resolve }, drafts);
          })
          .catch(response => {
            commit('setDraftsLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.products);
      }
    });
  },
  getDraftById({ state }, draftId) {
    return new Promise((resolve, reject) => {
      const storeDraft = state.drafts.find(draft => draft.id === Number(draftId));
      if (storeDraft) {
        const formattedDraft = constructDraft({ ...storeDraft });
        resolve(formattedDraft);
        return;
      }
      api
        .getDraft(draftId)
        .then(response => {
          const formattedDraft = constructDraft({ ...response.data });
          resolve(formattedDraft);
        })
        .catch(reject);
    });
  },
  populateDraftWithOriginalData({ commit }, { draft, originalData }) {
    return new Promise(resolve => {
      const draftData = draft.data;
      const newDraft = populateDraft(draftData, originalData);
      const updatedDraft = { ...draft, data: newDraft };
      commit('updateDraft', updatedDraft);
      resolve(updatedDraft);
    });
  },
  populateNewDraft({ dispatch }, newDraft) {
    return new Promise(resolve => {
      const recordId = newDraft.record_id;
      if (newDraft.record_type === 'Bib::Product') {
        dispatch('getProductById', recordId).then(product => {
          dispatch('populateDraftWithOriginalData', { draft: newDraft, originalData: product });
        });
      }
      if (newDraft.record_type === 'Bib::Investor') {
        dispatch('getInvestorById', recordId).then(investor => {
          dispatch('populateDraftWithOriginalData', { draft: newDraft, originalData: investor });
        });
      }
      resolve();
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
