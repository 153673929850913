<template>
  <div>
    <div class="c-tab__content__head">History Update {{ this.$route.query.name }}</div>

    <div class="c-tab__content__body">
      <investor-history-update-table
        :load-state="loadState"
        :investor-revises="investorRevises"
        :total="total"
        :limit="limit"
        :offset="offset"
        @reload="retrieveInvestorRevisions"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import InvestorHistoryUpdateTable from '../components/InvestorHistoryUpdateTable';

export default {
  name: 'InvestorHistoryUpdatePage',
  components: {
    InvestorHistoryUpdateTable,
  },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      loadState: 'idle',
      investorRevises: [],
      total: 0,
      offset: 0,
      limit: 10,
    };
  },
  computed: {
    defaultPayload() {
      return { 'investor[id]': this.$route.params.id, offset: this.offset, limit: this.limit };
    },
  },
  created() {
    this.retrieveInvestorRevisions();
  },
  methods: {
    retrieveInvestorRevisions() {
      this.loadState = 'loading';
      this.$api
        .getInvestorRevises(this.defaultPayload)
        .then(({ data, meta }) => {
          this.investorRevises = data;
          this.total = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.showErrorNotif(error);
          this.loadState = 'error';
        });
    },
    setOffset(query) {
      this.offset = query.offset;
      this.retrieveInvestorRevisions();
    },
  },
};
</script>
