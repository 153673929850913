import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from 'partner-admin/components/BasicField';

export default {
  components: { BlTextFieldMv, BasicField },
  props: {
    investor: {
      type: Object,
      default: () => ({}),
    },
    oldData: {
      type: Object,
      default: () => ({}),
    },
    newData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    getBooleanDropdownValue(value) {
      if (value === null || value === undefined) {
        return '';
      }
      return value ? 'Yes' : 'No';
    },
  },
};
