import mutations from './mutations';
import actions from './actions';

const states = {
  token: null,
  isLoggedIn: false,
  loadState: 'idle',
};

export default {
  namespaced: true,
  state: { ...states },
  mutations,
  actions,
};
