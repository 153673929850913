<template>
  <div>
    <div class="c-tab__content__body">
      <ul class="c-tab__nav">
        <li class="c-tab__list">
          <a
            v-if="isAllowed('document', 'transaction_confirmation')"
            class="c-tab__link"
            @click.prevent="gotoUploadSktPage"
          >
            <span> Upload SKT </span>
          </a>
        </li>
        <li class="c-tab__list is-active">
          <a class="c-tab__link" @click.prevent="">
            <span> Account Statement </span>
          </a>
        </li>
      </ul>
      <div class="c-tab__content is-active">
        <div class="c-tab__content__head">
          <div class="o-layout o-layout--responsive">
            <div class="o-layout__item u-8of12">
              <div class="o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-mv class="o-layout__item u-4of12" split>
                    <bl-field-col-mv>
                      <bl-field-label-av>Pilih Bulan</bl-field-label-av>
                    </bl-field-col-mv>
                    <bl-field-col-mv>
                      <month-picker-input :no-default="true" class="date-picker" lang="id" @input="showDate" />
                    </bl-field-col-mv>
                  </bl-field-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-5of12 u-mrgn-left--4">
                  <bl-field-row-mv>
                    <bl-dropdown-av
                      v-model="choosenProduct"
                      placeholder="Pilih Produk"
                      track-by="id"
                      label="fund_name"
                      :options="products"
                      searchable
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-button-av
                  class="o-layout__item u-2of12 u-mrgn-left--4"
                  color="red"
                  @click="searchAccountStatement()"
                >
                  Search
                </bl-button-av>
              </div>
            </div>

            <div class="o-layout__item u-4of12 u-align-right">
              <bl-button-av @click="openPopupAccountStatement()">
                Upload Account Statement
              </bl-button-av>
              <account-statement-dialog ref="AccountStatementDialog" @refresh="refresh" />
            </div>
          </div>
        </div>

        <div class="c-tab__content__body">
          <account-statement-table
            :load-state="loadState"
            :account-statements="accountStatementList"
            :total="total"
            :limit="limit"
            :offset="offset"
            @reload="retrieveInitialData"
            @refresh="retrieveInitialData"
            @changePage="setOffset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker';
import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldColMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldColMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import dayjs from 'dayjs';
import AccountStatementTable from '../components/AccountStatementTable';
import AccountStatementDialog from '../components/AccountStatementDialog';

export default {
  name: 'AccountStatementIndexPage',
  components: {
    AccountStatementTable,
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlButtonAv,
    AccountStatementDialog,
    MonthPickerInput,
    BlFieldColMv,
    BlFieldLabelAv,
  },
  mixins: [PolicyPageMixin, ErrorMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      accountStatementList: [],
      loadState: 'idle',
      total: 0,
      choosenProduct: undefined,
      chosenDate: {},
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
    }),
    defaultPayload() {
      return { offset: this.offset, limit: this.limit, action: 'account_statement' };
    },
    searchPayload() {
      const date = Object.keys(this.chosenDate).length ? this.formattedDate : undefined;
      const product = this.choosenProduct ? this.choosenProduct.id : undefined;
      return { date, 'product[id]': product };
    },
    formattedDate() {
      return dayjs(`${this.chosenDate.year}-${this.chosenDate.monthIndex}-1`).format('YYYY-MM-DD');
    },
  },
  created() {
    this.retrieveProducts({ state: 'approved', limit: 100, offset: 0 });
  },
  methods: {
    ...mapActions(['retrieveProducts']),
    showDate(date) {
      this.chosenDate = date;
    },
    gotoUploadSktPage() {
      this.$router.push({
        name: 'uploadSktIndex',
      });
    },
    openPopupAccountStatement() {
      this.$refs.AccountStatementDialog.openUploadAccountStatementDialog();
    },
    retrieveAccountStatements(payload) {
      this.loadState = 'loading';
      this.$api
        .getBundleDocuments(payload)
        .then(({ data, meta }) => {
          this.accountStatementList = data;
          this.total = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.handleError(error);
          this.loadState = 'error';
        });
    },
    searchAccountStatement() {
      this.offset = 0;
      this.getAccountStatements();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getAccountStatements();
    },
    getAccountStatements() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveAccountStatements(payload);
    },
    retrieveInitialData() {
      this.retrieveAccountStatements(this.defaultPayload);
    },
    refresh() {
      this.retrieveInitialData();
      this.offset = 0;
      this.chosenDate = null;
      this.choosenProduct = undefined;
    },
  },
};
</script>

<style scoped>
.date-picker /deep/ .month-picker__container {
  background-color: white;
}
.date-picker /deep/ .month-picker-input {
  padding: 0px 1em;
}
</style>
