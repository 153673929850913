/**
 * Description:
 * Populate draft with new item per missing investor data and fill it with oldValue
 * Draft need to be populated because it only return changed field, and our form need all field to show properly
 *
 * @param {Array}   draft     Array of draft data (field changed in creation of draft)
 * @param {Object}  original  Object original data that will populate draft
 */

const populateDraft = (draft, original) => {
  const keys = Object.keys(original);
  const newDraft = keys.map(key => {
    // handle file url attribute to form friendly attribute
    const fieldName = key.replace('_url', '');

    const exist = draft.find(item => item.field === fieldName);
    if (exist) {
      return exist;
    }
    return {
      field: fieldName,
      old_value: original[key] !== null ? original[key] : '',
      new_value: null,
    };
  });
  return newDraft;
};

export default populateDraft;
