<template>
  <div class="o-flag__head">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFlagHeadAv',
  status: 'ready',
  release: '0.1.1',
};
</script>
