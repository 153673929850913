export default {
  setToken: (state, val) => {
    state.token = val;
  },
  setLoadState: (state, val) => {
    state.loadState = val;
  },
  setIsLoggedIn: (state, val) => {
    state.isLoggedIn = val;
  },
};
