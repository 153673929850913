<template>
  <div>
    <span class="u-fg--ash-dark datepicker__date-space">
      {{ weekend[0] }}
    </span>
    <span
      v-for="day in workdays"
      :key="day.id"
      class="u-fg--ash-dark datepicker__date-space"
    >
      {{ day }}
    </span>
    <span class="u-fg--ash-dark datepicker__date-space">
      {{ weekend[1] }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BlCalendarDayTitleAv',
  props: {
    weekend: {
      type: Array,
      required: true,
    },
    workdays: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style src="./BlCalendarDayTitleAv.style.scss" lang="scss"></style>
