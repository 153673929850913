<template>
  <input
    type="file"
    refs="file"
    :class="getClasses"
    :disabled="isDisabled"
    :placeholder="placeholder"
    :required="required"
    v-bind="$attrs"
    @change.prevent="upload"
  >
</template>

<script>
import BaseInput from '../../../mixins/BaseInput';

export default {
  name: 'BlUploadFieldAv',
  status: 'ready',
  release: '0.2.0',
  mixins: [BaseInput],
  props: {
    value: {
      type: null,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      innerValue: null,
    };
  },
  methods: {
    upload(e) {
      this.innerValue = e.target.files;
      this.$emit('input', e.target.files);
    },
  },
};
</script>

<style src="./BlUploadFieldAv.style.scss" lang="scss"></style>
