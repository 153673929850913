<template>
  <div :class="flagClasses">
    <slot />
  </div>
</template>

<script>
import valigns from './data/valigns.json';
import types from './data/types.json';

export default {
  name: 'BlFlagMv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * Whether the flag is reverse
     * `true , false`
     */
    reverse: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * The align of the flag.
     * `default, top, button`
     */
    valign: {
      type: String,
      default: 'default',
      required: false,
      validator: (value, getValue) => {
        if (getValue) {
          return valigns;
        }

        return valigns.indexOf(value) !== -1;
      },
    },
    /**
     * The type of the flag.
     * `default, bleed, micro, tiny, small, medium, large, huge`
     */
    type: {
      type: String,
      default: 'default',
      required: false,
      validator: (value, getValue) => {
        if (getValue) {
          return types;
        }
        return types.indexOf(value) !== -1;
      },
    },
  },
  computed: {
    flagClasses() {
      const mainClass = 'o-flag';
      const classes = ['o-flag'];

      Object.keys(this.$props).forEach(item => {
        const propValue = this.$props[item];
        if (propValue && propValue !== 'default') {
          classes.push(item === 'reverse' ? `${mainClass}--rev` : `${mainClass}--${propValue}`);
        }
      });

      return classes;
    },
  },
};
</script>
