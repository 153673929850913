<template>
  <div>
    <bl-dialog-mv
      :active.sync="accountStatementConfirmationDialog"
      class="custom-dialog"
      title="Upload Account Statement"
    >
      <div style="margin-bottom: 18px">
        Apakah anda yakin akan melakukan upload Account Statement?
        <div class="account-statement-confirmation__product-name">Produk: {{ product.fund_name }}</div>
        <div class="account-statement-confirmation__date">Account Statement Bulan: {{ date | humanizeDate }}</div>
      </div>

      <div style="text-align: right">
        <bl-button-av @click="accountStatementConfirmationDialog = false">
          Tidak
        </bl-button-av>
        <bl-button-av color="red" @click="submit">
          Upload
        </bl-button-av>
      </div>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import dayjs from 'dayjs';

export default {
  name: 'AccountStatementConfirmationDialog',
  filters: {
    humanizeDate(value) {
      if (value) return dayjs(value).format('MMMM YYYY');
      return '-';
    },
  },
  components: {
    BlDialogMv,
    BlButtonAv,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      accountStatementConfirmationDialog: false,
    };
  },
  methods: {
    openAccountStatementConfirmationDialog() {
      this.accountStatementConfirmationDialog = true;
    },
    submit() {
      this.accountStatementConfirmationDialog = false;
      this.$emit('submit');
    },
  },
};
</script>
