import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from 'partner-admin/components/BasicField';
import InvestorViewBooleanDropdown from './InvestorViewBooleanDropdown';

export default {
  components: { BlTextFieldMv, BasicField },
  mixins: [InvestorViewBooleanDropdown],
  props: {
    investorBankData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    investorBankDraftData() {
      const newData = {};
      const oldData = {};
      const base = this.investorBankData.data || {};

      base.forEach(data => {
        newData[data.field] = data.new_value;
        oldData[data.field] = data.old_value;
      });
      return { id: this.investorBankData.record_id, newData, oldData };
    },
    oldData() {
      return this.investorBankDraftData.oldData || {};
    },
    newData() {
      return this.investorBankDraftData.newData || {};
    },
  },
};
