import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BasicField from 'partner-admin/components/BasicField';
import RULES from '../components/InvestorRevisesEditForm/rules';

export default {
  components: { BlTextFieldMv, BasicField, BlDropdownAv, BlFieldErrorAv },
  RULES,
  BooleanDropdownOptions: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  props: {
    investor: {
      type: Object,
      default: () => ({}),
    },
    oldData: {
      type: Object,
      default: () => ({}),
    },
    newData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
    };
  },
  computed: {
    isFormEditable() {
      return Object.keys(this.newData).length;
    },
    requiredFields() {
      return Object.keys(this.newData);
    },
    fieldRules() {
      const finalRules = {};

      // Make user updated data required
      this.requiredFields.forEach(field => {
        finalRules[field] = {
          ...finalRules[field],
          ...this.$options.RULES[field],
          required: true,
        };
      });

      // Validate admin edited field
      const remainingFields = this.$options.editableFields.filter(field => !this.requiredFields.includes(field));
      remainingFields.forEach(field => {
        if (this[field]) {
          finalRules[field] = {
            ...finalRules[field],
            ...this.$options.RULES[field],
          };
        }
      });

      if (finalRules.postal_code) delete finalRules.postal_code.required;

      return finalRules;
    },
  },
  methods: {
    isInputStyleError(field) {
      return Boolean(this.newData[field] || this[field]);
    },
    isEditFieldShown(field) {
      return this.isFormEditable && (this.$options.editableFields.includes(field) || this.newData[field]);
    },
    fieldErrorMessage(field, label = '') {
      const errorMessage = this.errors.first(field) || '';
      return errorMessage.replace(field, label);
    },
  },
};
