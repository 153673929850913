<template>
  <dt class="c-deflist__label">
    <slot />
  </dt>
</template>
<script>
export default {
  name: 'BlListDefinitionLabelAv',
  status: 'ready',
  release: '0.1.1',
};
</script>
