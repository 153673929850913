<template>
  <div>
    <bl-field-mv v-for="(info, index) in infoData" :key="index">
      <bl-field-row-mv>
        <bl-field-label-av class="c-deflist__label" inline>
          {{ info.label }}
        </bl-field-label-av>
        <bl-field-label-av class="u-mrgn-left--1" inline>
          {{ info.value }}
        </bl-field-label-av>
      </bl-field-row-mv>
    </bl-field-mv>
  </div>
</template>

<script>
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';

export default {
  name: 'FieldDetailInfo',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlFieldLabelAv,
  },
  props: {
    infoData: {
      type: Array,
      required: true,
    },
  },
};
</script>
