<template>
  <div :class="getClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFieldGroupMv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * Whether the field group is full
     * `true , false`
     */
    full: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the field group is left
     * `true , false`
     */
    left: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the field group is right
     * `true , false`
     */
    right: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      const baseClass = 'c-fld__grp';
      return [
        baseClass,
        this.full ? `${baseClass}--full` : '',
        this.left ? `${baseClass}--left` : '',
        this.right ? `${baseClass}--right` : '',
      ];
    },
  },
};
</script>

<style src="./BlFieldGroupMv.style.scss" lang="scss"></style>
