<template>
  <div>
    <basic-field label="Nomor Identitas">
      <bl-text-field-mv :value="oldData.identity_number" class="old_identity_number" readonly />
    </basic-field>
    <basic-field label="NPWP">
      <bl-text-field-mv :value="oldData.npwp_number" class="old_npwp_number" readonly />
    </basic-field>
    <basic-field label="IFUA (Investor Fund Unit Account)">
      <bl-text-field-mv class="old_ifua" :value="oldData.ifua" readonly />
    </basic-field>
    <basic-field label="SID (Single Investor Identification)">
      <bl-text-field-mv class="old_sid" :value="oldData.sid" readonly />
    </basic-field>
    <basic-field label="Investor Type">
      <bl-text-field-mv class="old_investor_type" :value="oldData.investor_type" readonly />
    </basic-field>
    <basic-field label="Agent">
      <bl-text-field-mv class="old_agent" :value="oldData.agent && oldData.agent.name" readonly />
    </basic-field>
    <basic-field label="Nama">
      <bl-text-field-mv class="old_name" :value="oldData.name" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('name')"
        v-model="name"
        v-validate="fieldRules.name"
        :is-error="isInputStyleError('name')"
        class="new_name"
        name="name"
      />
      <bl-field-error-av v-if="fieldErrorMessage('name')">
        {{ fieldErrorMessage('name', 'Nama') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Tempat Lahir">
      <bl-text-field-mv class="old_birth_place" :value="oldData.birth_place" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('birth_place')"
        v-model="birth_place"
        v-validate="fieldRules.birth_place"
        :is-error="isInputStyleError('birth_place')"
        class="new_birth_place"
        name="birth_place"
      />
      <bl-field-error-av v-if="fieldErrorMessage('birth_place')">
        {{ fieldErrorMessage('birth_place', 'Tempat Lahir') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Tanggal Lahir">
      <bl-text-field-mv class="old_birth_date" :value="oldData.birth_date" readonly />
      <vue-datepicker
        v-if="isEditFieldShown('birth_date')"
        v-model="birth_date"
        v-validate="fieldRules.birth_date"
        :language="$options.datepickerLocale"
        :input-class="isInputStyleError('birth_date') ? 'c-inp--error' : ''"
        format="yyyy-MM-dd"
        class="new_birth_date"
        name="birth_date"
      />
      <bl-field-error-av v-if="fieldErrorMessage('birth_date')">
        {{ fieldErrorMessage('birth_date', 'Tanggal Lahir') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Jenis Kelamin">
      <bl-text-field-mv class="old_gender" :value="oldData.gender" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('gender')"
        v-model="gender"
        v-validate="fieldRules.gender"
        :options="registrationAttributes.genders"
        :is-error="isInputStyleError('gender')"
        class="new_gender"
        name="gender"
      />
      <bl-field-error-av v-if="fieldErrorMessage('gender')">
        {{ fieldErrorMessage('gender', 'Jenis Kelamin') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Provinsi">
      <bl-text-field-mv class="old_province" :value="oldData.province" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('province')"
        v-model="province"
        v-validate="fieldRules.province"
        :options="registrationAttributes.provinces"
        :is-error="isInputStyleError('province')"
        track-by="label"
        label="label"
        searchable
        class="new_province"
        name="province"
      />
      <bl-field-error-av v-if="fieldErrorMessage('province')">
        {{ fieldErrorMessage('province', 'Provinsi') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kota/Kabupaten">
      <bl-text-field-mv class="old_city" :value="oldData.city" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('city')"
        v-model="city"
        v-validate="fieldRules.city"
        :options="cityOptions"
        :is-error="isInputStyleError('city')"
        track-by="label"
        label="label"
        searchable
        class="new_city"
        name="city"
      />
      <bl-field-error-av v-if="fieldErrorMessage('city')">
        {{ fieldErrorMessage('city', 'Kota/Kabupaten') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kecamatan">
      <bl-text-field-mv class="old_sub_district" :value="old_sub_district" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('sub_district')"
        v-model="sub_district"
        v-validate="fieldRules.sub_district"
        :is-error="isInputStyleError('sub_district')"
        class="new_sub_district"
        name="sub_district"
      />
      <bl-field-error-av v-if="fieldErrorMessage('sub_district')">
        {{ fieldErrorMessage('sub_district', 'Kecamatan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kel Desa">
      <bl-text-field-mv class="old_village" :value="old_village" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('village')"
        v-model="village"
        v-validate="fieldRules.village"
        :is-error="isInputStyleError('village')"
        class="new_village"
        name="village"
      />
      <bl-field-error-av v-if="fieldErrorMessage('village')">
        {{ fieldErrorMessage('village', 'Kel Desa') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="RT/RW">
      <bl-text-field-mv class="old_neighborhood" :value="old_neighborhood" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('neighborhood')"
        v-model="neighborhood"
        v-validate="fieldRules.neighborhood"
        :is-error="isInputStyleError('neighborhood')"
        class="new_neighborhood"
        name="neighborhood"
      />
      <bl-field-error-av v-if="fieldErrorMessage('neighborhood')">
        {{ fieldErrorMessage('neighborhood', 'RT/RW') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Alamat">
      <bl-text-field-mv class="old_address" :value="old_address" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('address')"
        v-model="address"
        v-validate="fieldRules.address"
        :is-error="isInputStyleError('address')"
        class="new_address"
        name="address"
      />
      <bl-field-error-av v-if="fieldErrorMessage('address')">
        {{ fieldErrorMessage('address', 'Alamat') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kode Pos">
      <bl-text-field-mv class="old_postal_code" :value="oldData.postal_code" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('postal_code')"
        v-model="postal_code"
        v-validate="fieldRules.postal_code"
        :is-error="isInputStyleError('postal_code')"
        class="new_postal_code"
        name="postal_code"
      />
      <bl-field-error-av v-if="fieldErrorMessage('postal_code')">
        {{ fieldErrorMessage('postal_code', 'Kode Pos') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Provinsi">
      <bl-text-field-mv class="old_domicile_province" :value="oldData.domicile_province" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('domicile_province')"
        v-model="domicile_province"
        v-validate="fieldRules.domicile_province"
        :options="registrationAttributes.provinces"
        :is-error="isInputStyleError('domicile_province')"
        track-by="label"
        label="label"
        searchable
        class="new_domicile_province"
        name="domicile_province"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_province')">
        {{ fieldErrorMessage('domicile_province', 'Domisili Provinsi') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kota/Kabupaten">
      <bl-text-field-mv class="old_domicile_city" :value="oldData.domicile_city" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('domicile_city')"
        v-model="domicile_city"
        v-validate="fieldRules.domicile_city"
        :options="domicileCityOptions"
        :is-error="isInputStyleError('domicile_city')"
        track-by="label"
        label="label"
        searchable
        class="new_domicile_city"
        name="domicile_city"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_city')">
        {{ fieldErrorMessage('domicile_city', 'Domisili Kota/Kabupaten') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kecamatan">
      <bl-text-field-mv class="old_domicile_sub_district" :value="old_domicile_sub_district" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('domicile_sub_district')"
        v-model="domicile_sub_district"
        v-validate="fieldRules.domicile_sub_district"
        :is-error="isInputStyleError('domicile_sub_district')"
        class="new_domicile_sub_district"
        name="domicile_sub_district"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_sub_district')">
        {{ fieldErrorMessage('domicile_sub_district', 'Domisili Kecamatan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kel Desa">
      <bl-text-field-mv class="old_domicile_village" :value="old_domicile_village" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('domicile_village')"
        v-model="domicile_village"
        v-validate="fieldRules.domicile_village"
        :is-error="isInputStyleError('domicile_village')"
        class="new_domicile_village"
        name="domicile_village"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_village')">
        {{ fieldErrorMessage('domicile_village', 'Domisili Kel Desa') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili RT/RW">
      <bl-text-field-mv class="old_domicile_neighborhood" :value="old_domicile_neighborhood" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('domicile_neighborhood')"
        v-model="domicile_neighborhood"
        v-validate="fieldRules.domicile_neighborhood"
        :is-error="isInputStyleError('domicile_neighborhood')"
        class="new_domicile_neighborhood"
        name="domicile_neighborhood"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_neighborhood')">
        {{ fieldErrorMessage('domicile_neighborhood', 'Domisili RT/RW') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Alamat">
      <bl-text-field-mv class="old_domicile_address" :value="old_domicile_address" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('domicile_address')"
        v-model="domicile_address"
        v-validate="fieldRules.domicile_address"
        :is-error="isInputStyleError('domicile_address')"
        class="new_domicile_address"
        name="domicile_address"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_address')">
        {{ fieldErrorMessage('domicile_address', 'Domisili Alamat') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kode Pos">
      <bl-text-field-mv class="old_domicile_postal_code" :value="oldData.domicile_postal_code" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('domicile_postal_code')"
        v-model="domicile_postal_code"
        v-validate="fieldRules.domicile_postal_code"
        :is-error="isInputStyleError('domicile_postal_code')"
        class="new_domicile_postal_code"
        name="domicile_postal_code"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_postal_code')">
        {{ fieldErrorMessage('domicile_postal_code', 'Domisili Kode Pos') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Agama">
      <bl-text-field-mv class="old_religion" :value="oldData.religion" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('religion')"
        v-model="religion"
        v-validate="fieldRules.religion"
        :options="registrationAttributes.religions"
        :is-error="isInputStyleError('religion')"
        class="new_religion"
        name="religion"
      />
      <bl-field-error-av v-if="fieldErrorMessage('religion')">
        {{ fieldErrorMessage('religion', 'Agama') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Status Perkawinan">
      <bl-text-field-mv class="old_marital_status" :value="oldData.marital_status" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('marital_status')"
        v-model="marital_status"
        v-validate="fieldRules.marital_status"
        :options="registrationAttributes.martial_statuses"
        :is-error="isInputStyleError('marital_status')"
        class="new_marital_status"
        name="marital_status"
      />
      <bl-field-error-av v-if="fieldErrorMessage('marital_status')">
        {{ fieldErrorMessage('marital_status', 'Status Perkawinan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Pekerjaan">
      <bl-text-field-mv class="old_occupation" :value="oldData.occupation" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('occupation')"
        v-model="occupation"
        v-validate="fieldRules.occupation"
        :options="registrationAttributes.occupations"
        :is-error="isInputStyleError('occupation')"
        class="new_occupation"
        name="occupation"
      />
      <bl-field-error-av v-if="fieldErrorMessage('occupation')">
        {{ fieldErrorMessage('occupation', 'Pekerjaan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Education">
      <bl-text-field-mv class="old_education" :value="oldData.education" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('education')"
        v-model="education"
        v-validate="fieldRules.education"
        :options="registrationAttributes.educations"
        :is-error="isInputStyleError('education')"
        class="new_education"
        name="education"
      />
      <bl-field-error-av v-if="fieldErrorMessage('education')">
        {{ fieldErrorMessage('education', 'Education') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Income Level">
      <bl-text-field-mv class="old_income_level" :value="oldData.income_level" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('income_level')"
        v-model="income_level"
        v-validate="fieldRules.income_level"
        :options="registrationAttributes.income_levels"
        :is-error="isInputStyleError('income_level')"
        class="new_income_level"
        name="income_level"
      />
      <bl-field-error-av v-if="fieldErrorMessage('income_level')">
        {{ fieldErrorMessage('income_level', 'Income Level') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Risk Profile">
      <bl-text-field-mv class="old_risk_profile" :value="oldData.risk_profile" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('risk_profile')"
        v-model="risk_profile"
        v-validate="fieldRules.risk_profile"
        :options="registrationAttributes.risk_profiles"
        :is-error="isInputStyleError('risk_profile')"
        class="new_risk_profile"
        name="risk_profile"
      />
      <bl-field-error-av v-if="fieldErrorMessage('risk_profile')">
        {{ fieldErrorMessage('risk_profile', 'Risk Profile') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Investment Objective">
      <bl-text-field-mv class="old_investment_objective" :value="oldData.investment_objective" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('investment_objective')"
        v-model="investment_objective"
        v-validate="fieldRules.investment_objective"
        :options="['Gain from  price margin', 'Investment', 'Speculation', 'Obtain the revenue or income', 'Others']"
        :is-error="isInputStyleError('investment_objective')"
        class="new_investment_objective"
        name="investment_objective"
      />
      <bl-field-error-av v-if="fieldErrorMessage('investment_objective')">
        {{ fieldErrorMessage('investment_objective', 'Investment Objective') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Source of Fund">
      <bl-text-field-mv class="old_source_of_fund" :value="oldData.source_of_fund" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('source_of_fund')"
        v-model="source_of_fund"
        v-validate="fieldRules.source_of_fund"
        :options="registrationAttributes.source_of_funds"
        :is-error="isInputStyleError('source_of_fund')"
        class="new_source_of_fund"
        name="source_of_fund"
      />
      <bl-field-error-av v-if="fieldErrorMessage('source_of_fund')">
        {{ fieldErrorMessage('source_of_fund', 'Source of Fund') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="E-mail">
      <bl-text-field-mv class="old_email" :value="oldData.email" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('email')"
        v-model="email"
        v-validate="fieldRules.email"
        :is-error="isInputStyleError('email')"
        class="new_email"
        name="email"
      />
      <bl-field-error-av v-if="fieldErrorMessage('email')">
        {{ fieldErrorMessage('email', 'E-mail') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Beneficiary Owner">
      <bl-text-field-mv class="old_bo" :value="oldData.bo ? 'Yes' : 'No'" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('bo')"
        v-model="bo"
        v-validate="fieldRules.bo"
        :options="$options.BooleanDropdownOptions"
        :is-error="isInputStyleError('bo')"
        track-by="value"
        label="label"
        class="new_bo"
        name="bo"
      />
      <bl-field-error-av v-if="fieldErrorMessage('bo')">
        {{ fieldErrorMessage('bo', 'Beneficiary Owner') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Politically Exposed Persons">
      <bl-text-field-mv class="old_pep" :value="oldData.pep ? 'Yes' : 'No'" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('pep')"
        v-model="pep"
        v-validate="fieldRules.pep"
        :options="$options.BooleanDropdownOptions"
        :is-error="isInputStyleError('pep')"
        track-by="value"
        label="label"
        class="new_pep"
        name="pep"
      />
      <bl-field-error-av v-if="fieldErrorMessage('pep')">
        {{ fieldErrorMessage('pep', 'Politically Exposed Persons') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Phone">
      <bl-text-field-mv class="old_phone" :value="oldData.phone" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('phone')"
        v-model="phone"
        v-validate="fieldRules.phone"
        :is-error="isInputStyleError('phone')"
        class="new_phone"
        name="phone"
      />
    </basic-field>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import vueDatepicker from 'vuejs-datepicker';
import { id } from 'vuejs-datepicker/dist/locale';
import InvestorRevisesEditMixin from '../../mixins/InvestorRevisesEditMixin';

export default {
  name: 'InvestorRevisesEditData',
  datepickerLocale: id,
  editableFields: [
    'name',
    'birth_place',
    'birth_date',
    'gender',
    'province',
    'city',
    'sub_district',
    'village',
    'neighborhood',
    'address',
    'postal_code',
    'domicile_province',
    'domicile_city',
    'domicile_sub_district',
    'domicile_village',
    'domicile_neighborhood',
    'domicile_address',
    'domicile_postal_code',
    'religion',
    'marital_status',
    'occupation',
    'education',
    'income_level',
    'risk_profile',
    'investment_objective',
    'source_of_fund',
    'email',
    'bo',
    'pep',
    'phone',
  ],
  components: { vueDatepicker },
  mixins: [InvestorRevisesEditMixin],
  props: {
    registrationAttributes: {
      type: Object,
      required: true,
    },
  },
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
      old_address: null,
      old_neighborhood: null,
      old_village: null,
      old_sub_district: null,
    };
  },
  computed: {
    cityOptions() {
      if (this.province) {
        return this.registrationAttributes.cities.filter(city => city.province_id === this.province.id);
      }
      return [];
    },
    domicileCityOptions() {
      if (this.domicile_province) {
        return this.registrationAttributes.cities.filter(city => city.province_id === this.domicile_province.id);
      }
      return [];
    },
  },
  watch: {
    province() {
      this.$nextTick(() => {
        const newCity = this.cityOptions.find(city => this.city && city.label === this.city.label);
        if (!newCity) {
          this.city = null;
        }
      });
    },
    domicile_province() {
      this.$nextTick(() => {
        if (this.domicile_city) {
          const newCity = this.domicileCityOptions.find(city => city.label === this.domicile_city.label);
          if (!newCity) {
            this.domicile_city = null;
          }
        }
      });
    },
  },
  created() {
    this.populateData();
  },
  methods: {
    populateData() {
      this.$options.editableFields.forEach(field => {
        if (!['province', 'city', 'domicile_province', 'domicile_city', 'pep', 'bo'].includes(field)) {
          this[field] = this.newData[field];
        }
      });

      const newProvince = this.registrationAttributes.provinces.find(
        province => province.label === this.newData.province,
      );
      if (newProvince) {
        this.province = newProvince;
      }

      const newCity = this.registrationAttributes.cities.find(city => city.label === this.newData.city);
      if (newCity) {
        this.city = newCity;
      }

      if (this.newData.address) {
        const [address, neighborhood, village, subDistrict] = this.newData.address.split(',').map(val => val.trim());
        this.address = address;
        this.neighborhood = neighborhood || null;
        this.village = village || null;
        this.sub_district = subDistrict || null;
      }

      if (this.oldData.address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.address.split(',').map(val => val.trim());
        this.old_address = address;
        this.old_neighborhood = neighborhood || null;
        this.old_village = village || null;
        this.old_sub_district = subDistrict || null;
      }

      const newDomicileProvince = this.registrationAttributes.provinces.find(
        province => province.label === this.newData.domicile_province,
      );
      if (newDomicileProvince) {
        this.domicile_province = newDomicileProvince;
      }

      const newDomicileCity = this.registrationAttributes.cities.find(
        city => city.label === this.newData.domicile_city,
      );
      if (newDomicileCity) {
        this.domicile_city = newCity;
      }

      if (this.newData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.newData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.domicile_address = address;
        this.domicile_neighborhood = neighborhood || null;
        this.domicile_village = village || null;
        this.domicile_sub_district = subDistrict || null;
      }

      if (this.oldData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.old_domicile_address = address;
        this.old_ndomicile_eighborhood = neighborhood || null;
        this.old_domicile_village = village || null;
        this.old_domicile_sub_district = subDistrict || null;
      }

      ['pep', 'bo'].forEach(field => {
        const newField = this.$options.BooleanDropdownOptions.find(opt => opt.value === this.newData[field]);
        if (this.newData[field] !== undefined && newField) {
          this[field] = newField;
        }
      });
    },
    getPayload() {
      if (!this.isFormEditable) {
        return {};
      }

      const newAddress = this.address
        ? `${this.address}, ${this.neighborhood || ''}, ${this.village || ''}, ${this.sub_district || ''}`
        : undefined;

      const newDomicileAddress = `${this.domicile_address}, ${this.domicile_neighborhood || ''}, ${this
        .domicile_village || ''}, ${this.domicile_sub_district || ''}`;

      return {
        name: this.name || undefined,
        birth_place: this.birth_place || undefined,
        birth_date: this.birth_date ? dayjs(this.birth_date).format('YYYY-MM-DD') : undefined,
        gender: this.gender || undefined,
        province: this.province ? this.province.label : undefined,
        city: this.city ? this.city.label : undefined,
        address: newAddress,
        postal_code: this.postal_code || undefined,
        domicile_province: this.domicile_province ? this.domicile_province.label : undefined,
        domicile_city: this.domicile_city ? this.domicile_city.label : undefined,
        domicile_address: newDomicileAddress,
        domicile_postal_code: this.domicile_postal_code || undefined,
        religion: this.religion || undefined,
        marital_status: this.marital_status || undefined,
        occupation: this.occupation || undefined,
        education: this.education || undefined,
        income_level: this.income_level || undefined,
        risk_profile: this.risk_profile || undefined,
        investment_objective: this.investment_objective || undefined,
        source_of_fund: this.source_of_fund || undefined,
        email: this.email || undefined,
        bo: this.bo ? this.bo.value : undefined,
        pep: this.pep ? this.pep.value : undefined,
        phone: this.phone || undefined,
      };
    },
  },
};
</script>
