<template>
  <tr>
    <td class="portfolio-table-row__name">
      {{ (portfolio.investor && portfolio.investor.name) || '-' }}
    </td>
    <td class="portfolio-table-row__email">
      {{ (portfolio.investor && portfolio.investor.email) || '-' }}
    </td>
    <td class="portfolio-table-row__product">
      {{ (portfolio.product && portfolio.product.fund_name) || '-' }}
    </td>
    <td class="portfolio-table-row__ccy">
      {{ (portfolio.product && portfolio.product.fund_ccy) || '-' }}
    </td>
    <td class="portfolio-table-row__unit">
      {{ portfolio.unit | delimiter }}
    </td>
    <td class="portfolio-table-row__estimated-unit">
      {{ getEstimatedUnit | delimiter }}
    </td>
    <td class="portfolio-table-row__current">
      {{ portfolio.current | money }}
    </td>
    <td class="portfolio-table-row__growth">
      {{ portfolio.growth | money }} ({{ portfolio.growth_percentage || '0' }}%)
    </td>
    <td class="portfolio-table-row__agent">
      {{ portfolio.agent && portfolio.agent.name }}
    </td>
    <td class="portfolio-table-row__updated-at">
      {{ portfolio.updated_at | formatDateTime }}
    </td>
    <td>
      <bl-button-av
        class="portfolio-table-row__view-product"
        :href="viewProductUrl"
        @click.prevent.native="gotoProductView"
      >
        Lihat
      </bl-button-av>
      <bl-button-av
        class="portfolio-table-row__view-investor"
        :href="viewInvestorUrl"
        @click.prevent.native="gotoInvestorView"
      >
        Investor Detail
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import { delimiter } from 'partner-admin/shared/helpers/stringHelper';
import dayjs from 'dayjs';

export default {
  name: 'PortfolioTableRow',
  components: { BlButtonAv },
  filters: {
    money(val) {
      return val ? money(val, 2, '') : '-';
    },
    delimiter(val) {
      return val ? delimiter(val) : '-';
    },
    formatDateTime(val) {
      return val ? dayjs(val).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
  mixins: [policyMixin],
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },
  computed: {
    viewProductUrl() {
      return `${window.location.origin}/portofolios/${this.portfolio.id}/product/${this.portfolio.product.id}`;
    },
    viewInvestorUrl() {
      return `${window.location.origin}/portofolios/${this.portfolio.id}/investor/${this.portfolio.investor.id}`;
    },
    getEstimatedUnit() {
      if (this.portfolio) {
        const { unit, future_unit: futureUnit, outstanding_unit: outstandingUnit } = this.portfolio;
        return (unit - outstandingUnit + futureUnit).toFixed(4);
      }
      return null;
    },
  },
  methods: {
    gotoProductView() {
      this.$router.push({
        name: 'portofoliosProductView',
        params: {
          id: this.portfolio.id,
          product_id: this.portfolio.product.id,
        },
      });
    },
    gotoInvestorView() {
      this.$router.push({
        name: 'portofoliosInvestorView',
        params: {
          id: this.portfolio.id,
          investor_id: this.portfolio.investor.id,
        },
      });
    },
  },
};
</script>
