import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import ProductForm from '../components/ProductForm';

export default {
  components: { ProductForm },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      draft: null,
      loadState: 'idle',
    };
  },
  computed: {
    ...mapGetters({
      drafts: 'getDrafts',
    }),
  },
  methods: {
    ...mapActions(['populateDraftWithOriginalData', 'getProductById']),
    retrieveInitialData() {
      this.loadState = 'loading';
      this.getDraft()
        .then(this.validatePageAccess)
        .then(this.getProduct)
        .then(this.populateDraftWithProduct)
        .then(this.renderForm)
        .catch(e => {
          this.loadState = 'error';
          if (e.message !== 'Policy::InvalidPageAccess') {
            this.showErrorNotif(e);
          }
        });
    },
    getDraft() {
      return new Promise((resolve, reject) => {
        const draftId = this.$route.params.id;
        this.$api
          .getDraft(draftId)
          .then(({ data }) => {
            this.draft = data;
            resolve();
          })
          .catch(reject);
      });
    },
    getProduct() {
      return new Promise((resolve, reject) => {
        const productId = this.draft.record_id;
        if (productId) {
          this.getProductById(productId)
            .then(resolve)
            .catch(reject);
        } else {
          resolve();
        }
      });
    },
    populateDraftWithProduct(product) {
      return new Promise((resolve, reject) => {
        if (this.draft.record_id) {
          this.populateDraftWithOriginalData({
            draft: this.draft,
            originalData: product,
          })
            .then(populatedDraft => {
              this.draft = populatedDraft;
              resolve();
            })
            .catch(reject);
        } else {
          resolve();
        }
      });
    },
    validatePageAccess() {
      return new Promise((resolve, reject) => {
        let allowedPage = 'productIndex';
        if (this.isAllowed('product', 'view_list')) {
          allowedPage = 'productDraftView';
        }
        if (this.draft && this.draft.state === 'submitted' && this.isAllowed('product', 'approve_reject')) {
          allowedPage = 'productDraftReview';
        }
        if (allowedPage && this.$route.name !== allowedPage) {
          this.$router.replace({ name: allowedPage });
          reject(new Error('Policy::InvalidPageAccess'));
        }
        resolve();
      });
    },
    renderForm() {
      this.loadState = 'success';
    },
  },
};
