export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function explode(str) {
  return str
    .split(/[\n\s]/g)
    .map(k => k.trim())
    .filter(k => k);
}

export function delimiter(nStr) {
  let text = nStr;
  text += '';
  const x = text.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `,${x[1]}` : ',00';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1.$2');
  }
  return x1 + x2;
}

export function truncate(string, n) {
  if (string.length <= n) {
    return string;
  }
  const subString = string.substr(0, n - 1);
  return `${subString}...`;
}

export default {
  capitalizeFirstLetter,
  explode,
  delimiter,
  truncate,
};
