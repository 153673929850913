import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  mixins: [NotifMixin],
  data() {
    return {
      agentOptions: [],
      agentOptionsLoadState: 'idle',
    };
  },
  methods: {
    retrieveAgentOptions() {
      this.agentOptionsLoadState = 'loading';
      this.$api
        .getAgents({ offset: 0, limit: 99 })
        .then(({ data }) => {
          this.agentOptions = data;
          this.agentOptionsLoadState = 'success';
        })
        .catch(e => {
          this.agentOptionsLoadState = 'error';
          this.showErrorNotif(e);
        });
    },
  },
};
