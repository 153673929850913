<template>
  <div>
    <div
      v-if="loadState === 'loading'"
      class="loader-loading u-align-center"
      :class="loadingClass"
      :style="loadingStyle"
    >
      <bl-spinner-av variant="red" size="large" />
    </div>
    <div v-if="loadState === 'error'" class="loader-error" :class="errorClass" :style="errorStyle">
      Gagal memuat data <a @click.prevent="reload">muat ulang</a>
    </div>
    <div v-if="loadState === 'success'" class="loader-success">
      <slot />
    </div>
  </div>
</template>
<script>
import BlSpinnerAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlSpinnerAv';

export default {
  name: 'Loader',
  components: { BlSpinnerAv },
  props: {
    loadState: {
      type: String,
      required: true,
      validator(value) {
        return ['idle', 'loading', 'error', 'success'].includes(value);
      },
    },
    loadingClass: {
      type: [Object, Array],
      default: () => [],
    },
    loadingStyle: {
      type: [Object, Array],
      default: () => ({}),
    },
    errorClass: {
      type: [Object, Array],
      default: () => [],
    },
    errorStyle: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
  },
};
</script>
