<template>
  <tr>
    <td class="account-statement-row__upload-date">
      {{ accountStatement.created_at | humanizeDate }}
    </td>
    <td class="account-statement-row__product">
      {{ accountStatement.product && accountStatement.product.fund_name }}
    </td>
    <td class="account-statement-row__date">
      {{ accountStatement.date | humanizeDate('month') }}
    </td>
    <td>
      <a
        v-if="accountStatement.state === 'uncompleted'"
        class="account-statement-row__state"
        @click.prevent="showError"
        >{{ accountStatement.state }}</a
      >
      <span v-else class="account-statement-row__state">{{ accountStatement.state }}</span>
    </td>
    <td class="account-statement-row__upload-by">
      {{ accountStatement.maker_username }}
    </td>
    <td>
      <bl-button-av class="account-statement-row__view" size="tiny" @click.native="download">
        Lihat
      </bl-button-av>
      <file-downloader ref="FileDownloader" />
    </td>
  </tr>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';
import queryString from 'query-string';
import FileDownloader from 'partner-admin/components/FileDownloader';

const endpoint = `${process.env.PARTNER_API_URL}/_exclusive/bundle-documents`;

export default {
  name: 'AccountStatementTableRow',
  filters: {
    humanizeDate(value, type = 'date') {
      if (value) {
        if (type === 'month') {
          return dayjs(value).format('MMMM YYYY');
        }
        return dayjs(value).format('DD MMMM YYYY');
      }
      return '-';
    },
  },
  components: { BlButtonAv, FileDownloader },
  props: {
    accountStatement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
  },
  methods: {
    download() {
      const token = this.tokenObj.token;

      const payload = {
        access_token: token,
      };

      const url = new URL(`${endpoint}/${this.accountStatement.id}/download?${queryString.stringify(payload)}`);

      this.$refs.FileDownloader.download(`${url}`);
    },
    showError() {
      this.$emit('showError', this.accountStatement.error_notes);
    },
  },
};
</script>
