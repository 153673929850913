import api from 'partner-admin/api';

const states = {
  products: [],
  productsLoadState: 'idle',
  productsTotal: 0,
};

const getters = {
  getProducts: state => state.products,
  getProductsLoadState: state => state.productsLoadState,
  getProductsTotal: state => state.productsTotal,
};

const mutations = {
  setProducts: (state, val) => {
    state.products = val;
  },
  setProductsLoadState: (state, val) => {
    state.productsLoadState = val;
  },
  setProductsTotal: (state, val) => {
    state.productsTotal = val;
  },
};

const actions = {
  retrieveProducts({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.productsLoadState !== 'loading') {
        commit('setProductsLoadState', 'loading');
        api
          .getProducts(payload)
          .then(response => {
            commit('setProductsLoadState', 'success');
            commit('setProducts', response.data);
            commit('setProductsTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setProductsLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.products);
      }
    });
  },
  getProductById({ state }, productId) {
    return new Promise((resolve, reject) => {
      const storeProduct = state.products.find(product => product.id === Number(productId));
      if (storeProduct) {
        resolve(storeProduct);
        return;
      }
      api
        .getProduct(productId)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
