<template>
  <div>
    <div class="c-tab__content__body">
      <ul class="c-tab__nav">
        <li class="c-tab__list  is-active">
          <a class="c-tab__link" @click.prevent="">
            <span> Upload SKT </span>
          </a>
        </li>
        <li class="c-tab__list">
          <a v-if="isAllowed('document', 'account_statement')" class="c-tab__link" @click="gotoAccountStatementPage">
            <span> Account Statement </span>
          </a>
        </li>
      </ul>
      <div class="c-tab__content is-active">
        <div class="c-tab__content__head">
          <div class="o-layout o-layout--responsive">
            <div class="o-layout__item u-8of12">
              <div class="o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-date-picker-mv v-model="chosenDate" :begin-date="chosenDate || $options.currentDate" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-5of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av
                      v-model="choosenProduct"
                      placeholder="Pilih Produk"
                      track-by="id"
                      label="fund_name"
                      :options="products"
                      searchable
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-button-av class="o-layout__item u-2of12 u-mrgn-left--4" color="red" @click="searchSkt()">
                  Search
                </bl-button-av>
              </div>
            </div>

            <div class="o-layout__item u-4of12 u-align-right">
              <bl-button-av @click="openPopupSkt()">
                Upload Skt
              </bl-button-av>
              <upload-skt-dialog ref="UploadSktDialog" @refresh="refresh" />
            </div>
          </div>
        </div>

        <div class="c-tab__content__body">
          <upload-skt-table
            :load-state="loadState"
            :skt-documents="sktList"
            :total="sktTotal"
            :limit="limit"
            :offset="offset"
            @reload="retrieveInitialData"
            @refresh="retrieveInitialData"
            @changePage="setOffset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import dayjs from 'dayjs';
import UploadSktTable from '../components/UploadSktTable';
import UploadSktDialog from '../components/UploadSktDialog';

export default {
  name: 'UploadSktIndexPage',
  currentDate: new Date().setHours(0, 0, 0, 0),
  components: {
    UploadSktTable,
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlButtonAv,
    BlDatePickerMv,
    UploadSktDialog,
  },
  mixins: [PolicyPageMixin, ErrorMixin, PolicyMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      sktList: [],
      loadState: 'idle',
      sktTotal: 0,
      choosenProduct: undefined,
      chosenDate: null,
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
    }),
    defaultPayload() {
      return { offset: this.offset, limit: this.limit, action: 'transaction_confirmation' };
    },
    searchPayload() {
      const date = this.chosenDate ? dayjs(this.chosenDate).format('YYYY-MM-DD') : undefined;
      const product = this.choosenProduct ? this.choosenProduct.id : undefined;
      return { date, 'product[id]': product };
    },
  },
  created() {
    this.retrieveProducts({ state: 'approved', limit: 100, offset: 0 });
  },
  methods: {
    ...mapActions(['retrieveProducts']),
    gotoAccountStatementPage() {
      this.$router.push({
        name: 'AccountStatementIndex',
      });
    },
    openPopupSkt() {
      this.$refs.UploadSktDialog.openUploadSktDialog();
    },
    retrieveSktDocuments(payload) {
      this.loadState = 'loading';
      this.$api
        .getBundleDocuments(payload)
        .then(({ data, meta }) => {
          this.sktList = data;
          this.sktTotal = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.handleError(error);
          this.loadState = 'error';
        });
    },
    searchSkt() {
      this.offset = 0;
      this.getSktDocuments();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getSktDocuments();
    },
    getSktDocuments() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveSktDocuments(payload);
    },
    retrieveInitialData() {
      this.retrieveSktDocuments(this.defaultPayload);
    },
    refresh() {
      this.retrieveInitialData();
      this.offset = 0;
      this.chosenDate = null;
      this.choosenProduct = undefined;
    },
  },
};
</script>
