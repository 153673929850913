<template>
  <component
    :is="layoutType"
    :label="field.label"
  >
    <bl-text-field-mv
      v-if="['text', 'date', 'number', 'select', 'radio'].includes(field.type)"
      ref="input"
      :value="value"
      :name="field.name"
      :multiline="field.multiline === true"
      readonly
    />
    <div v-if="field.type === 'file'">
      <bl-button-av
        v-if="field.format === 'pdf'"
        :href="value"
        color="red"
      >
        {{ field.label }}
      </bl-button-av>
      <img
        v-if="field.format === 'image'"
        :src="value"
        width="200px"
        height="auto"
      >
      <div
        v-if="field.format === 'identity-image' && value"
        v-viewer
        style="min-height: 350px;"
      >
        <img
          :src="value"
          class="u-hidden"
        >
      </div>
    </div>
  </component>
</template>
<script>
import dayjs from 'dayjs';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from '../BasicField';
import InlineField from '../InlineField';

export default {
  name: 'ReadonlyField',
  components: { BasicField, InlineField, BlTextFieldMv, BlButtonAv },
  props: {
    field: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'basic',
      validator(value) {
        return ['basic', 'inline'].includes(value);
      },
    },
  },
  computed: {
    value() {
      return this.field.value !== null ? this.sanitizeValue(this.field.value) : '';
    },
    layoutType() {
      return {
        basic: 'basic-field',
        inline: 'inline-field',
      }[this.layout];
    },
  },
  watch: {
    field: {
      handler: () => {},
      deep: true,
    },
  },
  methods: {
    sanitizeValue(value) {
      let inputValue = value;
      if (this.field.type === 'date') {
        inputValue = dayjs(inputValue).format('DD MMMM YYYY');
      }
      if (this.field.type === 'number') {
        if (this.field.format === 'money') {
          inputValue = money(inputValue);
        }
        if (this.field.format === 'percentage') {
          const formatted = `${inputValue}`.replace('.', ',');
          inputValue = `${formatted}%`;
        }
        if (this.field.format === 'decimal') {
          inputValue = `${inputValue}`.replace('.', ',');
        }
      }
      if (this.field.type === 'radio') {
        const option = this.field.options.find(opt => opt.value === inputValue);
        inputValue = option ? option.label : '';
      }
      if (this.field.type === 'select' && inputValue) {
        // please use blselect format { value, label }
        const option = this.field.options.find(opt => opt.value === inputValue.value);
        inputValue = option ? option.label : '';
      }
      return inputValue;
    },
  },
};
</script>
