<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-10of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-date-picker-mv
                  v-model="chosenDate"
                  class="u-width-1"
                  is-has-multiple-calendar
                  is-date-range-enabled
                  :begin-date="chosenDate.beginDate || currentDate"
                  :end-date="chosenDate.endDate"
                  multiple-calendar
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-group-mv>
                <bl-field-mv inline>
                  <timeselector
                    v-model="timeBegin"
                    :disabled="!chosenDate.beginDate"
                    return-format="HH:mm"
                    pad-time
                    :interval="{ h: 1, m: 1 }"
                  />
                </bl-field-mv>
                <bl-field-mv inline>
                  <bl-field-label-av inline>
                    -
                  </bl-field-label-av>
                </bl-field-mv>
                <bl-field-mv inline>
                  <timeselector
                    v-model="timeEnd"
                    :disabled="!chosenDate.beginDate"
                    return-format="HH:mm"
                    pad-time
                    :interval="{ h: 1, m: 1 }"
                  />
                </bl-field-mv>
              </bl-field-group-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="type"
                  placeholder="Type"
                  :options="$options.typeOptions"
                  track-by="code"
                  label="label"
                />
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-6of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="orderId" multiline placeholder="Order Id / Reference No" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-6of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="ifuaOrSid" multiline placeholder="IFUA/SID" />
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-6of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="product"
                  placeholder="Product"
                  track-by="id"
                  label="fund_name"
                  :options="products"
                  multiple
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="agentIds"
                  placeholder="Agent"
                  track-by="id"
                  label="name"
                  :options="agentOptions"
                  multiple
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item">
              <bl-field-row-mv>
                <bl-button-group-mv>
                  <bl-button-av
                    class="transaction-index-page__search"
                    color="red"
                    style="margin-right: 12px"
                    @click="searchTransactions"
                  >
                    Search
                  </bl-button-av>
                  <bl-button-av class="transaction-index-page__reset" color="red" @click="resetFilter">
                    Reset Filter
                  </bl-button-av>
                </bl-button-group-mv>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>

        <div class="o-layout__item u-2of12 u-align-right">
          <download-transactions-action />
          <upload-transactions-action />
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <transaction-table
        :transactions="transactions"
        :total="transactionsTotal"
        :limit="limit"
        :offset="offset"
        :load-state="transactionsLoadState"
        @reload="getTransactions"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import Timeselector from 'vue-timeselector';
import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import AgentOptionsMixin from 'partner-admin/mixins/agentOptionsMixin';

import dayjs from 'dayjs';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import BlFieldGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldGroupMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import { explode } from 'partner-admin/shared/helpers/stringHelper';
import { transactionTypeOptions } from 'partner-admin/constant/transactionType';
import DownloadTransactionsAction from '../components/DownloadTransactionsAction';
import TransactionTable from '../components/TransactionTable';
import UploadTransactionsAction from '../components/UploadTransactionsAction';

export default {
  name: 'TransactionsIndexPage',
  components: {
    Timeselector,
    DownloadTransactionsAction,
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlTextFieldMv,
    BlButtonAv,
    BlDatePickerMv,
    BlButtonGroupMv,
    TransactionTable,
    BlFieldLabelAv,
    BlFieldGroupMv,
    UploadTransactionsAction,
  },
  typeOptions: [{ code: '', label: 'All Type' }, ...transactionTypeOptions],
  stateOptions: [
    '',
    'created',
    'waited',
    'reconciled',
    'cancelled',
    'sinvest_approved',
    'sinvest_rejected',
    'sinvest_unitized',
    'rejected',
    'approved',
    'mismatched',
    'client_created',
    'proof_uploaded',
    'proof_rejected',
    'expired',
  ],
  mixins: [PolicyPageMixin, NotifMixin, AgentOptionsMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      currentDate,
      chosenDate: {},
      type: '',
      state: '',
      timeBegin: null,
      timeEnd: null,
      product: '',
      ifuaOrSid: '',
      orderId: '',
      referenceNo: '',
      offset: 0,
      limit: 10,
      agentIds: '',
    };
  },
  computed: {
    ...mapGetters({
      transactions: 'getTransactions',
      transactionsLoadState: 'getTransactionsLoadState',
      transactionsTotal: 'getTransactionsTotal',
      products: 'getProducts',
    }),
    defaultPayload() {
      return { offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return {
        'keywords[]': explode(this.orderId),
        'investor[keywords][]': this.ifuaOrSid ? explode(this.ifuaOrSid) : undefined,
        'product[keywords][]': this.product.length ? this.product.map(product => product.id) : undefined,
        start_time: this.buildDatePayload(this.chosenDate.beginDate, this.timeBegin),
        end_time: this.buildDatePayload(this.chosenDate.endDate, this.timeEnd),
        state: this.state,
        type: this.type && this.type.code,
        'agent_id[]': this.agentIds ? this.agentIds.map(agent => agent.id) : undefined,
      };
    },
  },
  methods: {
    ...mapActions(['retrieveTransactions', 'retrieveProducts']),
    retrieveInitialData() {
      this.retrieveTransactions(this.defaultPayload).catch(this.showErrorNotif);
      this.retrieveProducts({ state: 'approved', limit: 100, offset: 0 });
      this.retrieveAgentOptions();
    },
    searchTransactions() {
      this.offset = 0;
      this.getTransactions();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getTransactions();
    },
    getTransactions() {
      const payload = {
        ...this.defaultPayload,
        ...this.searchPayload,
      };
      this.retrieveTransactions(payload);
    },
    buildDatePayload(date, time) {
      const payload = date ? new Date(date) : '';
      if (payload) {
        if (time) {
          const timePayload = dayjs(time);
          payload.setHours(timePayload.hour());
          payload.setMinutes(timePayload.minute());
        }
      }
      return payload ? dayjs(payload).format('YYYY-MM-DDTHH:mm:ss+07:00') : undefined;
    },
    resetFilter() {
      this.chosenDate = {};
      this.type = '';
      this.state = '';
      this.timeBegin = null;
      this.timeEnd = null;
      this.product = '';
      this.ifuaOrSid = '';
      this.orderId = '';
      this.referenceNo = '';
      this.agentIds = '';
      this.offset = 0;
      this.limit = 10;
      this.getTransactions();
    },
  },
};
</script>
