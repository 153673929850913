import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      policies: 'getPolicies',
      isSuperStaff: 'getIsSuperStaff',
      canAccessReport: 'getCanAccessReport',
    }),
  },
  methods: {
    isAllowed(resource, action) {
      return this.policies && this.policies[resource] && this.policies[resource][action];
    },
  },
};
