<template>
  <div>
    <readonly-field v-for="field in upperFields" :key="field.name" :ref="field.name" :field="field" layout="inline" />
    <div>
      <review-field
        v-for="field in nominalField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in grossNominalField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in netNominalField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in unitField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in navValueField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in navDateField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
      <review-field
        v-for="field in feeField"
        :key="field.name"
        :ref="field.name"
        :field="field"
        layout="inline"
        show-hint
      />
    </div>
    <readonly-field v-for="field in lowerFields" :key="field.name" :ref="field.name" :field="field" layout="inline" />

    <div v-if="transaction.state === 'mismatched'" class="u-mrgn-top--6">
      <bl-button-av class="transaction-form__approve-ashmore" color="red" @click="approveTransaction(false)">
        Approve Ashmore
      </bl-button-av>

      <bl-button-av class="transaction-form__approve-sinvest" color="red" @click="approveTransaction(true)">
        Approve S-Invest
      </bl-button-av>
    </div>

    <div v-if="isShowProofActions" class="u-mrgn-top--6">
      <bl-button-av class="transaction-form__reject-proof-file u-mrgn-right--2" @click="approveProofFile(false)">
        Reject Payment Proof
      </bl-button-av>

      <bl-button-av class="transaction-form__approve-proof-file" color="red" @click="approveProofFile(true)">
        Approve Payment Proof
      </bl-button-av>
    </div>

    <div v-if="showReject" class="u-mrgn-top--6">
      <input-field v-for="field in rejectionReasonField" :key="field.name" :ref="field.name" :field="field" />

      <bl-button-av v-if="canReject" class="transaction-form__reject" color="red" @click="rejectTransaction">
        Reject
      </bl-button-av>
    </div>
    <slot />
  </div>
</template>
<script>
import ReadonlyField from 'partner-admin/components/ReadonlyField';
import ReviewField from 'partner-admin/components/ReviewField';
import InputField from 'partner-admin/components/InputField';
import FormMixin from 'partner-admin/mixins/formMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import { transactionTypeMap } from 'partner-admin/constant/transactionType';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import { TransactionFields } from './config';

export default {
  name: 'TransactionForm',
  components: { ReadonlyField, ReviewField, InputField, BlButtonAv },
  mixins: [FormMixin, PolicyMixin],
  props: {
    transaction: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputFields: [],
    };
  },
  computed: {
    upperFields() {
      const fields = ['created_at', 'type', 'reference_id', 'ifua', 'fund_code', 'fund_currency', 'agent'];
      return this.inputFields.filter(field => fields.includes(field.name));
    },
    lowerFields() {
      const fields = [
        'all_unit',
        'bi_code',
        'payment_no',
        'payment_date',
        'transfer_type',
        'sa_reference',
        'payment_proof',
      ];
      return this.inputFields.filter(field => fields.includes(field.name));
    },
    nominalField() {
      return this.inputFields.filter(field => field.name === 'nominal');
    },
    grossNominalField() {
      return this.inputFields.filter(field => field.name === 'gross_nominal');
    },
    netNominalField() {
      return this.inputFields.filter(field => field.name === 'net_nominal');
    },
    unitField() {
      return this.inputFields.filter(field => field.name === 'unit');
    },
    navValueField() {
      return this.inputFields.filter(field => field.name === 'nav_value');
    },
    navDateField() {
      return this.inputFields.filter(field => field.name === 'nav_date');
    },
    feeField() {
      return this.inputFields.filter(field => field.name === 'fee');
    },
    rejectionReasonField() {
      return this.inputFields.filter(field => field.name === 'rejection_reason');
    },
    showReject() {
      return ['cancelled', 'sinvest_rejected'].includes(this.transaction.state) || this.transaction.rejection_reason;
    },
    canReject() {
      return !this.transaction.rejection_reason;
    },
    isShowProofActions() {
      return this.transaction.state === 'proof_uploaded' && this.isAllowed('transaction', 'check_proof');
    },
  },
  created() {
    this.setupForm();
  },
  methods: {
    setupForm() {
      this.inputFields = TransactionFields.map(field => {
        let value = this.getInputValue(field, this.transaction);
        let hint = field.hint;
        if (['nominal', 'unit', 'nav_value', 'nav_date', 'fee', 'gross_nominal', 'net_nominal'].includes(field.name)) {
          let oldValue = this.transaction[field.name];
          let newValue = this.transaction[`sinvest_${field.name}`];
          let isDifference = ![undefined, null].includes(newValue) && oldValue !== newValue;

          if (isDifference) {
            if (['gross_nominal', 'net_nominal', 'nominal', 'fee'].includes(field.name)) {
              isDifference = Math.abs(newValue - oldValue) > 1;
              oldValue = isDifference ? oldValue : newValue; // use sinvest value if not difference
            } else if (['unit'].includes(field.name)) {
              isDifference = Math.abs(newValue - oldValue) > 0.0001;
              oldValue = isDifference ? oldValue : newValue; // use sinvest value if not difference
            }
          }

          value = {
            oldValue,
            newValue: isDifference ? newValue : null,
          };

          if (isDifference) {
            if (field.format === 'moneyWithoutCurrency') {
              oldValue = money(oldValue, undefined, '');
              newValue = money(newValue, undefined, '');
            }
            if (field.format === 'decimal') {
              oldValue = `${oldValue}`.replace('.', ',');
              newValue = `${newValue}`.replace('.', ',');
            }
            const name = `${field.name.charAt(0).toUpperCase()}${field.name.slice(1)}`;
            hint = `${name} Ashmore ${oldValue}, ${name} S-Invest ${newValue}`;
          }
        }
        if (field.name === 'agent') {
          value = this.transaction.agent && this.transaction.agent.name;
        }
        if (field.name === 'fund_currency') {
          value = this.transaction.product && this.transaction.product.fund_ccy;
        }
        if (field.name === 'type') {
          value = transactionTypeMap[this.transaction.type] || this.transaction.type;
        }
        return {
          ...field,
          value,
          hint,
        };
      });
    },
    rejectTransaction() {
      const rejectionReasonInput = this.$refs.rejection_reason[0];
      rejectionReasonInput.validate();
      this.$nextTick(() => {
        if (!this.errors.items.length) {
          this.constructRejectionPayload();
        } else {
          this.scrollToError(this.errors.items[0]);
        }
      });
    },
    constructRejectionPayload() {
      const rejectionReasonInput = this.$refs.rejection_reason[0];
      const payload = {
        id: this.transaction.id,
        rejection_reason: this.getInputPayload(rejectionReasonInput),
      };
      this.$emit('reject', payload);
    },
    approveTransaction(useSInvest) {
      this.$emit('approve', {
        id: this.transaction.id,
        use_sinvest: useSInvest,
      });
    },
    approveProofFile(isApprove) {
      this.$emit('approveProofFile', {
        id: this.transaction.id,
        state: isApprove ? 'proof_approve' : 'proof_reject',
      });
    },
  },
};
</script>
