<template>
  <div>
    <bl-dialog-mv :active.sync="isShowDialog" class="custom-dialog" title="Reset password">
      <div style="margin-bottom: 18px">
        Apakah anda yakin akan mengirim email reset password
        <b class="reset-password-confirmation__email"> {{ user.email }} </b>
      </div>

      <div style="text-align: right">
        <bl-button-av class="reset-password-confirmation__cancel" @click="isShowDialog = false">
          Batal
        </bl-button-av>
        <bl-button-av class="reset-password-confirmation__confirm" color="red" @click="submit">
          Kirim email
        </bl-button-av>
      </div>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';

export default {
  name: 'UserIndexResetPasswordConfirmation',
  components: {
    BlDialogMv,
    BlButtonAv,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowDialog: false,
    };
  },
  watch: {
    value: {
      handler() {
        this.isShowDialog = this.value;
      },
      immediate: true,
    },
    isShowDialog: {
      handler() {
        this.$emit('input', this.isShowDialog);
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.user.id);
      this.isShowDialog = false;
    },
  },
};
</script>
