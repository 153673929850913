<template>
  <bl-dialog-mv :active.sync="showConfirmationDialog" title="Confirmation Dialog">
    <div>
      Are you sure to save this exchange rate setup?
      <div class="u-mrgn-top--2"><strong>Update Date:</strong> {{ $options.currentDate | humanizeDate }}</div>
      <div class="u-mrgn-top--2 exchange-rate-confirmation-dialog__usd-rate">
        <strong>USD Rate:</strong> {{ newData.usdRate | delimiter }}
      </div>
      <div class="u-mrgn-top--2 exchange-rate-confirmation-dialog__eur-rate">
        <strong>EUR Rate:</strong> {{ newData.eurRate | delimiter }}
      </div>
    </div>
    <div class="u-mrgn-top--4">
      <bl-button-av @click="showConfirmationDialog = false">
        Cancel
      </bl-button-av>
      <bl-button-av color="red" @click="save">
        Save
      </bl-button-av>
    </div>
  </bl-dialog-mv>
</template>

<script>
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';
import { delimiter } from 'partner-admin/shared/helpers/numberHelper';

export default {
  name: 'ExchangeRateConfirmationDialog',
  currentDate: new Date().setHours(0, 0, 0, 0),
  filters: {
    humanizeDate(value) {
      return dayjs(value).format('DD MMMM YYYY');
    },
    delimiter,
  },
  components: {
    BlDialogMv,
    BlButtonAv,
  },
  props: {
    newData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showConfirmationDialog: false,
    };
  },
  methods: {
    openConfirmationDialog() {
      this.showConfirmationDialog = true;
    },
    save() {
      this.$emit('save');
      this.showConfirmationDialog = false;
    },
  },
};
</script>
