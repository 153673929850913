<template>
  <div>
    <basic-field label="Nomor Identitas">
      <div class="o-layout">
        <bl-text-field-mv
          v-model="identity_number"
          v-validate="fieldRules.identity_number"
          class="o-layout__item u-8of12 identity_number"
          :is-error="!!fieldErrorMessage('identity_number')"
          name="identity_number"
        />
        <bl-button-av class="o-layout__item u-4of12" @click="checkIdentityNumber">
          Cek
        </bl-button-av>
        <bl-field-error-av v-if="fieldErrorMessage('identity_number')" class="o-layout__item u-12of12">
          {{ fieldErrorMessage('identity_number', 'Nomor Identitas') }}
        </bl-field-error-av>
      </div>
    </basic-field>
    <basic-field label="NPWP">
      <bl-text-field-mv
        v-model="npwp_number"
        v-validate="fieldRules.npwp_number"
        :is-error="!!fieldErrorMessage('npwp_number')"
        class="npwp_number"
        name="npwp_number"
      />
      <bl-field-error-av v-if="fieldErrorMessage('npwp_number')">
        {{ fieldErrorMessage('npwp_number', 'NPWP') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Nama">
      <bl-text-field-mv
        v-model="name"
        v-validate="fieldRules.name"
        :is-error="!!fieldErrorMessage('name')"
        class="name"
        name="name"
      />
      <bl-field-error-av v-if="fieldErrorMessage('name')">
        {{ fieldErrorMessage('name', 'Nama') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Tempat Lahir">
      <bl-text-field-mv
        v-model="birth_place"
        v-validate="fieldRules.birth_place"
        :is-error="!!fieldErrorMessage('birth_place')"
        class="birth_place"
        name="birth_place"
      />
      <bl-field-error-av v-if="fieldErrorMessage('birth_place')">
        {{ fieldErrorMessage('birth_place', 'Tempat Lahir') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Tanggal Lahir">
      <vue-datepicker
        v-model="birth_date"
        v-validate="fieldRules.birth_date"
        :language="$options.datepickerLocale"
        :input-class="!!fieldErrorMessage('birth_date') ? 'c-inp--error' : ''"
        format="yyyy-MM-dd"
        class="birth_date"
        name="birth_date"
      />
      <bl-field-error-av v-if="fieldErrorMessage('birth_date')">
        {{ fieldErrorMessage('birth_date', 'Tanggal Lahir') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Jenis Kelamin">
      <bl-dropdown-av
        v-model="gender"
        v-validate="fieldRules.gender"
        :options="registrationAttributes.genders"
        :is-error="!!fieldErrorMessage('gender')"
        class="gender"
        name="gender"
      />
      <bl-field-error-av v-if="fieldErrorMessage('gender')">
        {{ fieldErrorMessage('gender', 'Jenis Kelamin') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Provinsi">
      <bl-dropdown-av
        v-model="province"
        v-validate="fieldRules.province"
        :options="registrationAttributes.provinces"
        :is-error="!!fieldErrorMessage('province')"
        track-by="label"
        label="label"
        searchable
        class="province"
        name="province"
      />
      <bl-field-error-av v-if="fieldErrorMessage('province')">
        {{ fieldErrorMessage('province', 'Provinsi') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kota/Kabupaten">
      <bl-dropdown-av
        v-model="city"
        v-validate="fieldRules.city"
        :options="cityOptions"
        :is-error="!!fieldErrorMessage('city')"
        track-by="label"
        label="label"
        searchable
        class="city"
        name="city"
      />
      <bl-field-error-av v-if="fieldErrorMessage('city')">
        {{ fieldErrorMessage('city', 'Kota/Kabupaten') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kecamatan">
      <bl-text-field-mv
        v-model="sub_district"
        v-validate="fieldRules.sub_district"
        :is-error="!!fieldErrorMessage('sub_district')"
        class="sub_district"
        name="sub_district"
      />
      <bl-field-error-av v-if="fieldErrorMessage('sub_district')">
        {{ fieldErrorMessage('sub_district', 'Kecamatan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kel Desa">
      <bl-text-field-mv
        v-model="village"
        v-validate="fieldRules.village"
        :is-error="!!fieldErrorMessage('village')"
        class="village"
        name="village"
      />
      <bl-field-error-av v-if="fieldErrorMessage('village')">
        {{ fieldErrorMessage('village', 'Kel Desa') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="RT/RW">
      <bl-text-field-mv
        v-model="neighborhood"
        v-validate="fieldRules.neighborhood"
        :is-error="!!fieldErrorMessage('neighborhood')"
        class="neighborhood"
        name="neighborhood"
      />
      <bl-field-error-av v-if="fieldErrorMessage('neighborhood')">
        {{ fieldErrorMessage('neighborhood', 'RT/RW') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Alamat">
      <bl-text-field-mv
        v-model="address"
        v-validate="fieldRules.address"
        :is-error="!!fieldErrorMessage('address')"
        class="address"
        name="address"
      />
      <bl-field-error-av v-if="fieldErrorMessage('address')">
        {{ fieldErrorMessage('address', 'Alamat') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Kode Pos">
      <bl-text-field-mv
        v-model="postal_code"
        v-validate="fieldRules.postal_code"
        :is-error="!!fieldErrorMessage('postal_code')"
        class="postal_code"
        name="postal_code"
      />
      <bl-field-error-av v-if="fieldErrorMessage('postal_code')">
        {{ fieldErrorMessage('postal_code', 'Kode Pos') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Provinsi">
      <bl-dropdown-av
        v-model="domicile_province"
        v-validate="fieldRules.domicile_province"
        :options="registrationAttributes.provinces"
        :is-error="!!fieldErrorMessage('domicile_province')"
        track-by="label"
        label="label"
        searchable
        class="domicile_province"
        name="domicile_province"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_province')">
        {{ fieldErrorMessage('domicile_province', 'Domisili Provinsi') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kota/Kabupaten">
      <bl-dropdown-av
        v-model="domicile_city"
        v-validate="fieldRules.domicile_city"
        :options="domicileCityOptions"
        :is-error="!!fieldErrorMessage('domicile_city')"
        track-by="label"
        label="label"
        searchable
        class="domicile_city"
        name="domicile_city"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_city')">
        {{ fieldErrorMessage('domicile_city', 'Domisili Kota/Kabupaten') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kecamatan">
      <bl-text-field-mv
        v-model="domicile_sub_district"
        v-validate="fieldRules.domicile_sub_district"
        :is-error="!!fieldErrorMessage('domicile_sub_district')"
        class="domicile_sub_district"
        name="domicile_sub_district"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_sub_district')">
        {{ fieldErrorMessage('domicile_sub_district', 'Domisili Kecamatan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kel Desa">
      <bl-text-field-mv
        v-model="domicile_village"
        v-validate="fieldRules.domicile_village"
        :is-error="!!fieldErrorMessage('domicile_village')"
        class="domicile_village"
        name="domicile_village"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_village')">
        {{ fieldErrorMessage('domicile_village', 'Domisili Kel Desa') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili RT/RW">
      <bl-text-field-mv
        v-model="domicile_neighborhood"
        v-validate="fieldRules.domicile_neighborhood"
        :is-error="!!fieldErrorMessage('domicile_neighborhood')"
        class="domicile_neighborhood"
        name="domicile_neighborhood"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_neighborhood')">
        {{ fieldErrorMessage('domicile_neighborhood', 'Domisili RT/RW') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Alamat">
      <bl-text-field-mv
        v-model="domicile_address"
        v-validate="fieldRules.domicile_address"
        :is-error="!!fieldErrorMessage('domicile_address')"
        class="domicile_address"
        name="domicile_address"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_address')">
        {{ fieldErrorMessage('domicile_address', 'Domisili Alamat') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Domisili Kode Pos">
      <bl-text-field-mv
        v-model="domicile_postal_code"
        v-validate="fieldRules.domicile_postal_code"
        :is-error="!!fieldErrorMessage('domicile_postal_code')"
        class="domicile_postal_code"
        name="domicile_postal_code"
      />
      <bl-field-error-av v-if="fieldErrorMessage('domicile_postal_code')">
        {{ fieldErrorMessage('domicile_postal_code', 'Domisili Kode Pos') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Agama">
      <bl-dropdown-av
        v-model="religion"
        v-validate="fieldRules.religion"
        :options="registrationAttributes.religions"
        :is-error="!!fieldErrorMessage('religion')"
        class="religion"
        name="religion"
      />
      <bl-field-error-av v-if="fieldErrorMessage('religion')">
        {{ fieldErrorMessage('religion', 'Agama') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Status Perkawinan">
      <bl-dropdown-av
        v-model="marital_status"
        v-validate="fieldRules.marital_status"
        :options="registrationAttributes.marital_statuses"
        :is-error="!!fieldErrorMessage('marital_status')"
        class="marital_status"
        name="marital_status"
      />
      <bl-field-error-av v-if="fieldErrorMessage('marital_status')">
        {{ fieldErrorMessage('marital_status', 'Status Perkawinan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Pekerjaan">
      <bl-dropdown-av
        v-model="occupation"
        v-validate="fieldRules.occupation"
        :options="registrationAttributes.occupations"
        :is-error="!!fieldErrorMessage('occupation')"
        class="occupation"
        name="occupation"
      />
      <bl-field-error-av v-if="fieldErrorMessage('occupation')">
        {{ fieldErrorMessage('occupation', 'Pekerjaan') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Education">
      <bl-dropdown-av
        v-model="education"
        v-validate="fieldRules.education"
        :options="registrationAttributes.educations"
        :is-error="!!fieldErrorMessage('education')"
        class="education"
        name="education"
      />
      <bl-field-error-av v-if="fieldErrorMessage('education')">
        {{ fieldErrorMessage('education', 'Education') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Income Level">
      <bl-dropdown-av
        v-model="income_level"
        v-validate="fieldRules.income_level"
        :options="registrationAttributes.income_levels"
        :is-error="!!fieldErrorMessage('income_level')"
        class="income_level"
        name="income_level"
      />
      <bl-field-error-av v-if="fieldErrorMessage('income_level')">
        {{ fieldErrorMessage('income_level', 'Income Level') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Risk Profile">
      <bl-dropdown-av
        v-model="risk_profile"
        v-validate="fieldRules.risk_profile"
        :options="registrationAttributes.risk_profiles"
        :is-error="!!fieldErrorMessage('risk_profile')"
        class="risk_profile"
        name="risk_profile"
      />
      <bl-field-error-av v-if="fieldErrorMessage('risk_profile')">
        {{ fieldErrorMessage('risk_profile', 'Risk Profile') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Investment Objective">
      <bl-dropdown-av
        v-model="investment_objective"
        v-validate="fieldRules.investment_objective"
        :options="['Gain from  price margin', 'Investment', 'Speculation', 'Obtain the revenue or income', 'Others']"
        :is-error="!!fieldErrorMessage('investment_objective')"
        class="investment_objective"
        name="investment_objective"
      />
      <bl-field-error-av v-if="fieldErrorMessage('investment_objective')">
        {{ fieldErrorMessage('investment_objective', 'Investment Objective') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Source of Fund">
      <bl-dropdown-av
        v-model="source_of_fund"
        v-validate="fieldRules.source_of_fund"
        :options="registrationAttributes.source_of_funds"
        :is-error="!!fieldErrorMessage('source_of_fund')"
        class="source_of_fund"
        name="source_of_fund"
      />
      <bl-field-error-av v-if="fieldErrorMessage('source_of_fund')">
        {{ fieldErrorMessage('source_of_fund', 'Source of Fund') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="E-mail">
      <bl-text-field-mv
        v-model="email"
        v-validate="fieldRules.email"
        :is-error="!!fieldErrorMessage('email')"
        class="email"
        name="email"
      />
      <bl-field-error-av v-if="fieldErrorMessage('email')">
        {{ fieldErrorMessage('email', 'E-mail') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Beneficiary Owner">
      <bl-dropdown-av
        v-model="bo"
        v-validate="fieldRules.bo"
        :options="$options.BooleanDropdownOptions"
        :is-error="!!fieldErrorMessage('bo')"
        track-by="value"
        label="label"
        class="bo"
        name="bo"
      />
      <bl-field-error-av v-if="fieldErrorMessage('bo')">
        {{ fieldErrorMessage('bo', 'Beneficiary Owner') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Politically Exposed Persons">
      <bl-dropdown-av
        v-model="pep"
        v-validate="fieldRules.pep"
        :options="$options.BooleanDropdownOptions"
        :is-error="!!fieldErrorMessage('pep')"
        track-by="value"
        label="label"
        class="pep"
        name="pep"
      />
      <bl-field-error-av v-if="fieldErrorMessage('pep')">
        {{ fieldErrorMessage('pep', 'Politically Exposed Persons') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Phone">
      <bl-text-field-mv v-model="phone" class="phone" name="phone" readonly />
    </basic-field>
  </div>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';
import vueDatepicker from 'vuejs-datepicker';
import { id } from 'vuejs-datepicker/dist/locale';
import InvestorDraftEditMixin from '../../mixins/InvestorDraftEditMixin';

export default {
  name: 'InvestorDraftEditData',
  datepickerLocale: id,
  editableFields: [
    'identity_number',
    'npwp_number',
    'name',
    'birth_place',
    'birth_date',
    'gender',
    'province',
    'city',
    'sub_district',
    'village',
    'neighborhood',
    'address',
    'postal_code',
    'domicile_province',
    'domicile_city',
    'domicile_sub_district',
    'domicile_village',
    'domicile_neighborhood',
    'domicile_address',
    'domicile_postal_code',
    'religion',
    'marital_status',
    'occupation',
    'education',
    'income_level',
    'risk_profile',
    'investment_objective',
    'source_of_fund',
    'email',
    'bo',
    'pep',
    'phone',
  ],
  components: { vueDatepicker, BlButtonAv },
  mixins: [InvestorDraftEditMixin],
  props: {
    registrationAttributes: {
      type: Object,
      required: true,
    },
  },
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
    };
  },
  computed: {
    cityOptions() {
      if (this.province) {
        return this.registrationAttributes.cities.filter(city => city.province_id === this.province.id);
      }
      return [];
    },
    domicileCityOptions() {
      if (this.domicile_province) {
        return this.registrationAttributes.cities.filter(city => city.province_id === this.domicile_province.id);
      }
      return [];
    },
  },
  watch: {
    province() {
      this.$nextTick(() => {
        if (this.city) {
          const newCity = this.cityOptions.find(city => city.label === this.city.label);
          if (!newCity) {
            this.city = null;
          }
        }
      });
    },
    domicile_province() {
      this.$nextTick(() => {
        if (this.domicile_city) {
          const newCity = this.domicileCityOptions.find(city => city.label === this.domicile_city.label);
          if (!newCity) {
            this.domicile_city = null;
          }
        }
      });
    },
  },
  created() {
    this.populateData();
  },
  methods: {
    checkIdentityNumber() {
      this.$api
        .getIdentityData({ nik: this.identity_number })
        .then(this.populateInvestorByIdentity)
        .catch(this.handleError);
    },
    populateInvestorByIdentity({ data }) {
      if (data.birth_date) {
        this.birth_date = dayjs(data.birth_date).valueOf();
      }
      if (data.district) {
        this.sub_district = data.district;
      }
      if (data.gender) {
        const genderOpt = this.registrationAttributes.genders.find(opt => opt === data.gender) || {};
        this.gender = genderOpt;
      }
      if (data.province_id || data.province) {
        const provinceOpt = this.registrationAttributes.provinces.find(
          opt => opt.id === data.province_id || opt.label === data.province,
        );
        this.province = provinceOpt;
      }

      if (data.city) {
        const cityOpt = this.registrationAttributes.cities.find(
          opt => opt.id === data.city_id || opt.label === data.city,
        );
        if (cityOpt) {
          this.city = cityOpt;
        }
      }
    },
    populateData() {
      this.$options.editableFields.forEach(field => {
        if (!['province', 'city', 'pep', 'bo'].includes(field)) {
          this[field] = this.newData[field] !== null ? this.newData[field] : this.oldData[field];
        }
      });

      const provinceValue = this.newData.province !== null ? this.newData.province : this.oldData.province;
      const cityValue = this.newData.city !== null ? this.newData.city : this.oldData.city;
      const addressValue = this.newData.address !== null ? this.newData.address : this.oldData.address;

      const newProvince = this.registrationAttributes.provinces.find(province => province.label === provinceValue);
      if (newProvince) {
        this.province = newProvince;
      }

      const newCity = this.registrationAttributes.cities.find(city => city.label === cityValue);
      if (newCity) {
        this.city = newCity;
      }

      if (addressValue) {
        const [address, neighborhood, village, subDistrict] = addressValue.split(',').map(val => val.trim());
        this.address = address;
        this.neighborhood = neighborhood || null;
        this.village = village || null;
        this.sub_district = subDistrict || null;
      }

      const domicileProvinceValue =
        this.newData.domicile_province !== null ? this.newData.domicile_province : this.oldData.domicile_province;
      const domicileCityValue =
        this.newData.domicile_city !== null ? this.newData.domicile_city : this.oldData.domicile_city;
      const domicileAddressValue =
        this.newData.domicile_address !== null ? this.newData.domicile_address : this.oldData.domicile_address;

      const newDomicileProvince = this.registrationAttributes.provinces.find(
        province => province.label === domicileProvinceValue,
      );
      if (newDomicileProvince) {
        this.domicile_province = newDomicileProvince;
      }

      const newDomicileCity = this.registrationAttributes.cities.find(city => city.label === domicileCityValue);
      if (newDomicileCity) {
        this.domicile_city = newDomicileCity;
      }

      if (domicileAddressValue) {
        const [address, neighborhood, village, subDistrict] = domicileAddressValue.split(',').map(val => val.trim());
        this.domicile_address = address;
        this.domicile_neighborhood = neighborhood || null;
        this.domicile_village = village || null;
        this.domicile_sub_district = subDistrict || null;
      }

      ['pep', 'bo'].forEach(field => {
        const fieldValue = this.newData[field] !== null ? this.newData[field] : this.oldData[field];
        const newField = this.$options.BooleanDropdownOptions.find(opt => opt.value === fieldValue);
        if (fieldValue !== undefined && newField) {
          this[field] = newField;
        }
      });
    },
    getPayload() {
      if (!this.isFormEditable) {
        return {};
      }

      const newAddress = this.address
        ? `${this.address}, ${this.neighborhood || ''}, ${this.village || ''}, ${this.sub_district || ''}`
        : undefined;

      const newDomicileAddress = `${this.domicile_address}, ${this.domicile_neighborhood || ''}, ${this
        .domicile_village || ''}, ${this.domicile_sub_district || ''}`;

      return {
        identity_number: this.identity_number || undefined,
        npwp_number: this.npwp_number || undefined,
        name: this.name || undefined,
        birth_place: this.birth_place || undefined,
        birth_date: this.birth_date ? dayjs(this.birth_date).format('YYYY-MM-DD') : undefined,
        gender: this.gender || undefined,
        province: this.province ? this.province.label : undefined,
        city: this.city ? this.city.label : undefined,
        address: newAddress,
        postal_code: this.postal_code || undefined,
        domicile_province: this.domicile_province ? this.domicile_province.label : undefined,
        domicile_city: this.domicile_city ? this.domicile_city.label : undefined,
        domicile_address: newDomicileAddress,
        domicile_postal_code: this.domicile_postal_code || undefined,
        religion: this.religion || undefined,
        marital_status: this.marital_status || undefined,
        occupation: this.occupation || undefined,
        education: this.education || undefined,
        income_level: this.income_level || undefined,
        risk_profile: this.risk_profile || undefined,
        investment_objective: this.investment_objective || undefined,
        source_of_fund: this.source_of_fund || undefined,
        email: this.email || undefined,
        bo: this.bo ? this.bo.value : undefined,
        pep: this.pep ? this.pep.value : undefined,
        phone: this.phone.toString() || undefined,
      };
    },
  },
};
</script>
