<template>
  <header class="datepicker__header u-align-center u-pad--3 u-border-bottom--1--ash-light">
    <bl-icon-av
      v-if="!hideLeftIcon"
      class="u-float-left u-pointer"
      variant="chevron-left"
      size="medium"
      @click="$emit('goToPreviousMonth')"
    />
    <span class="datepicker__title u-txt--bold u-txt--fair">
      {{ monthYearTitle }}
    </span>
    <bl-icon-av
      v-if="!hideRightIcon"
      class="u-float-right u-pointer"
      variant="chevron-right"
      size="medium"
      @click="$emit('goToNextMonth')"
    />
  </header>
</template>

<script>
import BlIconAv from '../../../atoms/BlIconAv';

export default {
  name: 'BlCalendarHeaderMv',
  components: {
    BlIconAv,
  },
  props: {
    monthYearTitle: {
      type: String,
      required: true,
    },
    isHasMultipleCalendar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    hideLeftIcon() {
      return this.type === 'right' && this.isHasMultipleCalendar;
    },
    hideRightIcon() {
      return this.type === 'left' && this.isHasMultipleCalendar;
    },
  },
};
</script>
