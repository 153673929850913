import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from 'partner-admin/components/BasicField';
import InvestorViewBooleanDropdown from './InvestorViewBooleanDropdown';

export default {
  components: { BlTextFieldMv, BasicField },
  mixins: [InvestorViewBooleanDropdown],
  props: {
    investor: {
      type: Object,
      default: () => ({}),
    },
    oldData: {
      type: Object,
      default: () => ({}),
    },
    newData: {
      type: Object,
      default: () => ({}),
    },
  },
};
