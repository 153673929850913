<template>
  <div :class="getClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFieldMv',
  status: 'ready',
  release: '0.4.0',
  props: {
    /**
     * Whether the field is split
     * `true , false`
     */
    split: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the field is inline
     * `true , false`
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the field is disabled
     * `true , false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      const baseClass = 'c-fld';
      return [
        baseClass,
        this.split ? `${baseClass}--split` : '',
        this.inline ? `${baseClass}--inline` : '',
        this.disabled ? `${baseClass}--disabled` : '',
      ];
    },
  },
};
</script>

<style src="./BlFieldMv.style.scss" lang="scss"></style>
