<template>
  <div>
    <div v-if="isAllowed('investor', 'view_list')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Lihat Investor
      </h1>
    </div>

    <div v-if="investor" class="c-tab__content__body">
      <investor-view-form :investor="investor" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'partner-admin/lib/vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import InvestorViewForm from '../components/InvestorViewForm';

export default {
  name: 'InvestorViewPage',
  components: { InvestorViewForm },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      investor: null,
    };
  },
  methods: {
    ...mapActions(['getInvestorById']),
    retrieveInitialData() {
      const investorId = this.$route.params.id;
      if (investorId) {
        this.getInvestorById(investorId)
          .then(response => {
            this.investor = response;
          })
          .catch(this.showErrorNotif);
      } else {
        this.showErrorNotif('Investor tidak ditemukan');
      }
    },
  },
};
</script>
