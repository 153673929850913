export function getAddressInheritValue(addressValue) {
  if (addressValue) {
    const values = addressValue;
    return values.split(', ').map(value => value.trim());
  }
  return Array(4);
}

export function processAddressInheritValue(draftData) {
  let inherited = [];
  const result = draftData.map(draft => {
    if (draft.field === 'address') {
      const oldValueCombination = getAddressInheritValue(draft.old_value);
      const newValueCombination = getAddressInheritValue(draft.new_value);
      inherited = ['address', 'neighborhood', 'village', 'sub_district'].map((fieldName, idx) => ({
        field: fieldName,
        old_value: oldValueCombination[idx],
        new_value: newValueCombination[idx],
      }));
      return inherited.shift(); // remove address field
    }
    return draft;
  });
  return [...result, ...inherited];
}

export function processBankInheritValue(draftData) {
  let inherited = [];
  draftData.forEach(draft => {
    if (draft.field === 'bank_data') {
      const oldValue = (draft.old_value && draft.old_value[0]) || {};
      const newValue = (draft.new_value && draft.new_value[0]) || {};
      const keys = ['account_name', 'account_no', 'bank_name', 'bank_branch', 'account_valid'];
      inherited = keys.map(key => ({
        field: key,
        old_value: oldValue[key],
        new_value: newValue[key],
      }));
    }
    return draft;
  });
  return [...draftData, ...inherited];
}

/**
 * Description:
 * Populate draft with inherited value if exist on draft
 *
 * @param {Object}   draft    draft data as per API response
 */

const constructDraft = draft => {
  let newDraftData = draft.data;
  if (draft.record_type === 'Bib::Investor') {
    newDraftData = processAddressInheritValue(newDraftData);
    newDraftData = processBankInheritValue(newDraftData);
  }
  return { ...draft, data: newDraftData };
};

export default constructDraft;
