<template>
  <Table
    :load-state="loadState"
    :rows="portfolios"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <portfolio-table-row v-for="portfolio in portfolios" :key="portfolio.id" :portfolio="portfolio" />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import PortfolioTableRow from './PortfolioTableRow';

export default {
  name: 'PortfolioTable',
  components: {
    PortfolioTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: [
    'Nama',
    'Email',
    'Produk',
    'CCY',
    'Unit',
    'Estimated Unit',
    'Total Amount',
    'Keuntungan',
    'Agent',
    'Last Update',
  ],
  props: {
    portfolios: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
