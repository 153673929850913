<template>
  <bl-popover-av
    ref="popover"
    :disabled="isDisabled"
    :class="popoverClasses"
    size="auto"
    keep-popover
  >
    <bl-input-group-mv
      v-if="!isTriggerByButtonIcon"
      postfix-type="icon"
      postfix-icon="calendar"
    >
      <bl-text-field-mv
        slot="input"
        ref="popoverInput"
        :value="formattedDate"
        readonly
        placeholder="Pilih Tanggal"
        @click="showCalendar = !showCalendar"
      />
    </bl-input-group-mv>
    <bl-button-av
      v-if="isTriggerByButtonIcon"
      :class="buttonClasses"
      @click="showCalendar = !showCalendar"
    >
      <i class="c-btn__icon c-icon c-icon--calendar" />
    </bl-button-av>
    <div
      slot="content"
      :class="datePickerContentClasses"
    >
      <div class="o-layout u-pad-left--4 u-display-flex">
        <div
          :class="{
            [sidebarMenuEnabledCalendarClass]: isSideMenuEnabled,
            ['u-6of12']: isHasMultipleCalendar,
          }"
          class="o-layout__item u-pad--0 u-border-right--1--ash-light"
        >
          <BlDatePickerCalendarMv
            :limited-date="limitedDate"
            :outdated-date="outdatedDate"
            :begin-date="calendarBeginDate"
            :end-date="calendarEndDate"
            :start-date="startDate[LEFT_CALENDAR]"
            :is-has-multiple-calendar="isHasMultipleCalendar"
            :is-limited-date-enabled="isLimitedDateEnabled"
            :is-outdated-date-enabled="isOutdatedDateEnabled"
            :is-date-range-enabled="isDateRangeEnabled"
            :is-side-menu-enabled="isSideMenuEnabled"
            :is-holiday-shown="isHolidayShown"
            :is-trigger-by-button-icon="isTriggerByButtonIcon"
            :is-button-size-small="isButtonSizeSmall"
            :is-button-size-large="isButtonSizeLarge"
            type="left"
            @input="chooseDate"
            @goToNextMonth="goToNextMonth"
            @goToPreviousMonth="goToPreviousMonth"
          />
        </div>
        <div
          v-if="isHasMultipleCalendar"
          class="o-layout__item u-pad--0"
          :class="isSideMenuEnabled ? 'u-width-2of5' : 'u-6of12'"
        >
          <BlDatePickerCalendarMv
            :limited-date="limitedDate"
            :outdated-date="outdatedDate"
            :begin-date="calendarBeginDate"
            :end-date="calendarEndDate"
            :start-date="startDate[RIGHT_CALENDAR]"
            :is-has-multiple-calendar="isHasMultipleCalendar"
            :is-limited-date-enabled="isLimitedDateEnabled"
            :is-outdated-date-enabled="isOutdatedDateEnabled"
            :is-date-range-enabled="isDateRangeEnabled"
            :is-side-menu-enabled="isSideMenuEnabled"
            :is-holiday-shown="isHolidayShown"
            :is-trigger-by-button-icon="isTriggerByButtonIcon"
            :is-button-size-small="isButtonSizeSmall"
            :is-button-size-large="isButtonSizeLarge"
            type="right"
            @input="chooseDate"
            @goToNextMonth="goToNextMonth"
            @goToPreviousMonth="goToPreviousMonth"
          />
        </div>
        <div
          v-if="$slots.sideMenu || isSideMenuEnabled"
          class="o-layout__item u-width--200 u-pad-v--4 u-pad-h--3 u-border-left--1--ash-light"
        >
          <slot name="sideMenu" />
          <template v-if="!$slots.sideMenu">
            <bl-button-av
              v-for="item in sideMenuItems"
              :key="item.value"
              block
              class="u-mrgn-bottom--2"
              @click="chooseSideMenuItem(item.value)"
            >
              {{ item.text }}
            </bl-button-av>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="$slots.footer || isDateRangeEnabled"
      slot="footer"
      class="u-clearfix"
      :class="isSideMenuEnabled ? 'u-align-right' : 'u-align-left'"
    >
      <bl-button-av
        v-if="isSideMenuEnabled"
        class="u-mrgn-right--2"
        @click="cancelSelection"
      >
        Batal
      </bl-button-av>
      <bl-button-av
        color="red"
        @click="applyRange"
      >
        Terapkan
      </bl-button-av>
    </div>
  </bl-popover-av>
</template>

<script>
import { addMonths, subMonths, subDays } from 'date-fns';
import { dateFormat } from '../../../utils/date-helper';

import BlInputGroupMv from '../BlInputGroupMv';
import BlTextFieldMv from '../BlTextFieldMv';
import BlButtonAv from '../../atoms/BlButtonAv';
import BlPopoverAv from '../../atoms/BlPopoverAv';
import BlDatePickerCalendarMv from './components/BlDatePickerCalendarMv.vue';

const LEFT_CALENDAR = 0;
const RIGHT_CALENDAR = 1;

export default {
  name: 'BlDatePickerMv',
  status: 'ready',
  release: '0.4.0',
  components: {
    BlPopoverAv,
    BlTextFieldMv,
    BlInputGroupMv,
    BlButtonAv,
    BlDatePickerCalendarMv,
  },
  props: {
    /**
     * Begin Date Picker
     */
    beginDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    /**
     * End Date Picker
     */
    endDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    /**
     * Limited Date Picker
     */
    limitedDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    /**
     * Outdated Date Picker
     */
    outdatedDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    /**
     * Whether the datepicker has multiple calendar
     * `true , false`
     */
    isHasMultipleCalendar: {
      type: Boolean,
      default: false,
    },
    /**
     * Output Date Format Date Picker
     */
    outputDateFormat: {
      type: String,
      default: 'DD-MM-YY',
    },
    /**
     * Whether the datepicker is limited date enabled
     * `true , false`
     */
    isLimitedDateEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is data range enable
     * `true , false`
     */
    isDateRangeEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the date picker is side menu enabled shown
     * `true , false`
     */
    isSideMenuEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the date picker is holiday shown
     * `true , false`
     */
    isHolidayShown: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is outdated date enable
     * `true , false`
     */
    isOutdatedDateEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is disabled
     * `true , false`
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is trigger by button icon
     * `true , false`
     */
    isTriggerByButtonIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is button size small
     * `true , false`
     */
    isButtonSizeSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the datepicker is button size large
     * `true , false`
     */
    isButtonSizeLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Value datepicker
     */
    value: {
      validator: prop => {
        return typeof prop === 'object' || typeof prop === 'number' || prop === null;
      },
      required: true,
    },
    /**
     * Side menu item datepicker
     * `7 Hari Terakhir, 30 Hari Terakhir`
     */
    sideMenuItems: {
      type: Array,
      default: () => [
        {
          value: 7,
          text: '7 Hari Terakhir',
        },
        {
          value: 30,
          text: '30 Hari Terakhir',
        },
      ],
    },
    /**
     * Positions date picker
     * `left, right, ''`
     */
    position: {
      type: String,
      default: 'left',
      validator: value => {
        const positions = ['left', 'right', ''];
        return positions.indexOf(value) !== -1;
      },
    },
    /**
     * Button trigger size
     * `tiny, small, default, large`
     */
    size: {
      type: String,
      default: 'default',
      validator: value => {
        const positions = ['tiny', 'small', 'default', 'large'];
        return positions.indexOf(value) !== -1;
      },
    },
  },
  data() {
    const nowDate = new Date();
    const firstMonth = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1).getTime();
    const nextMonth = new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, 1).getTime();

    return {
      startDate: [firstMonth, nextMonth],
      chosenDates: [this.beginDate],
      showCalendar: false,
      dateRangeState: 'end',
      LEFT_CALENDAR,
      RIGHT_CALENDAR,
    };
  },
  computed: {
    datePickerContentClasses() {
      const type = this.isHasMultipleCalendar ? '--multiple-calendar' : '--single-calendar';
      const sideMenuClass = this.isHasMultipleCalendar
        ? '--side-menu-multiple-calendar'
        : '--side-menu-single-calendar';
      return this.isSideMenuEnabled ? `datepicker__content${sideMenuClass}` : `datepicker__content${type}`;
    },
    formattedDate() {
      if (this.isDateRangeEnabled) {
        if (Object.keys(this.value).length) {
          const beginDate = this.value.beginDate;
          const endDate = this.value.endDate;
          const formattedBeginDate = dateFormat(beginDate, this.outputDateFormat);
          const formattedEndDate = dateFormat(endDate, this.outputDateFormat);
          return `${formattedBeginDate} - ${formattedEndDate}`;
        }
        return null;
      }
      return this.value ? dateFormat(this.value, this.outputDateFormat) : this.value;
    },
    sidebarMenuEnabledCalendarClass() {
      return this.isHasMultipleCalendar ? 'u-width-2of5' : 'u-width-4of5';
    },
    popoverClasses() {
      const array = ['c-popover'];
      array.push(`c-popover--${this.position}`);

      return array;
    },
    buttonClasses() {
      const array = ['c-btn'];
      array.push(`c-btn--${this.size}`);

      return array;
    },
    calendarBeginDate() {
      return this.isDateRangeEnabled ? this.chosenDates[0] : this.beginDate;
    },
    calendarEndDate() {
      return this.isDateRangeEnabled ? this.chosenDates[1] || this.chosenDates[0] : this.endDate;
    },
  },
  watch: {
    showCalendar() {
      this.resetStartDate(this.beginDate);
      this.chosenDates = [this.beginDate, this.endDate].sort();
    },
  },
  created() {
    if (this.isDateRangeEnabled && this.value && this.value.endDate) {
      this.dateRangeState = 'begin';
    }
  },
  methods: {
    goToPreviousMonth() {
      const firstStartDate = new Date(this.startDate[LEFT_CALENDAR]);
      const secondStartDate = new Date(this.startDate[RIGHT_CALENDAR]);
      this.startDate = [subMonths(firstStartDate, 1).getTime(), subMonths(secondStartDate, 1).getTime()];
    },
    goToNextMonth() {
      const firstStartDate = new Date(this.startDate[LEFT_CALENDAR]);
      const secondStartDate = new Date(this.startDate[RIGHT_CALENDAR]);
      this.startDate = [addMonths(firstStartDate, 1).getTime(), addMonths(secondStartDate, 1).getTime()];
    },
    buildDateRange() {
      return {
        beginDate: this.chosenDates[0],
        endDate: this.chosenDates[1] || this.chosenDates[0],
      };
    },
    handleDateRange(timestamp) {
      const chosenDate = new Date(timestamp).setHours(0, 0, 0, 0);
      if (this.chosenDates.length >= 2) {
        this.chosenDates = [chosenDate];
      } else {
        this.chosenDates.push(chosenDate);
        this.chosenDates.sort();
      }

      return this.buildDateRange();
    },
    chooseDate({ timestamp }) {
      if (!this.isDateRangeEnabled) {
        this.$emit('input', timestamp);
        this.setClosedCalendar();
      } else {
        this.$emit('chooseDate', this.handleDateRange(timestamp));
      }
    },
    chooseSideMenuItem(count = 0) {
      const today = new Date().setHours(0, 0, 0, 0);
      const targetDate = subDays(today, count).setHours(0, 0, 0, 0);

      if (this.isDateRangeEnabled) {
        this.chosenDates = [today, targetDate].sort();
      } else {
        this.chooseDate({ timestamp: targetDate });
      }
    },
    cancelSelection() {
      this.$emit('cancel');
      this.setClosedCalendar();
    },
    applyRange() {
      this.$emit('input', this.buildDateRange());
      this.setClosedCalendar();
    },
    resetStartDate(timestamp) {
      const date = new Date(timestamp);
      this.startDate = [
        new Date(date.getFullYear(), date.getMonth(), 1).getTime(),
        new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime(),
      ];
    },
    setClosedCalendar() {
      this.showCalendar = false;
      this.$refs.popover.closePopover();
    },
  },
};
</script>
<style src="./BlDatePickerMv.style.scss" lang="scss"></style>
