<template>
  <div class="c-tab__content is-active">
    <div class="c-tab__content__body">
      <loader
        :load-state="loadState"
        :loading-class="['u-mrgn-v--6']"
        :error-class="['u-mrgn-v--6', 'u-align-center']"
        @reload="retrieveInitialData"
      >
        <bl-list-definition-mv>
          <bl-list-definition-label-av>
            Update Date
          </bl-list-definition-label-av>
          <bl-list-definition-value-av class="exchange-rate-page__update-date">
            {{ currentExchangeRate.updated_at | humanizeDate }}
          </bl-list-definition-value-av>
          <bl-list-definition-label-av>
            USD rate
          </bl-list-definition-label-av>
          <bl-list-definition-value-av class="exchange-rate-page__usd-rate">
            {{ currentRate.usd | delimiter }}
          </bl-list-definition-value-av>
          <bl-list-definition-label-av>
            EUR rate
          </bl-list-definition-label-av>
          <bl-list-definition-value-av class="exchange-rate-page__eur-rate">
            {{ currentRate.eur | delimiter }}
          </bl-list-definition-value-av>
          <bl-list-definition-label-av>
            Last edit
          </bl-list-definition-label-av>
          <bl-list-definition-value-av class="exchange-rate-page__last-edit">
            {{ currentExchangeRate.updated_by }} - {{ currentExchangeRate.updated_at | fullDate }}
          </bl-list-definition-value-av>
        </bl-list-definition-mv>

        <bl-button-av
          v-if="isAllowed('exchange_rate', 'edit')"
          class="u-mrgn-top--4 exchange-rate-page__edit"
          @click="openEditForm"
        >
          Update Exchange Rate
        </bl-button-av>
      </loader>
    </div>

    <exchange-rate-edit-form ref="ExchangeRateEditForm" @proceed="proceed" />

    <exchange-rate-confirmation-dialog ref="ExchangeRateConfirmationDialog" :new-data="newData" @save="save" />
  </div>
</template>

<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlListDefinitionMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlListDefinitionMv';
import BlListDefinitionLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlListDefinitionMv/components/BlListDefinitionLabelAv';
import BlListDefinitionValueAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlListDefinitionMv/components/BlListDefinitionValueAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import Loader from 'partner-admin/components/Loader';
import dayjs from 'dayjs';
import { delimiter } from 'partner-admin/shared/helpers/numberHelper';
import ExchangeRateEditForm from './components/ExchangeRateEditForm';
import ExchangeRateConfirmationDialog from './components/ExchangeRateConfirmationDialog';

export default {
  name: 'ExchangeRatePage',
  filters: {
    humanizeDate(value) {
      return dayjs(value).format('DD MMMM YYYY');
    },
    fullDate(value) {
      return dayjs(value).format('DD MMMM YYYY (HH:mm:ss [WIB])');
    },
    delimiter,
  },
  components: {
    BlListDefinitionMv,
    BlListDefinitionLabelAv,
    BlListDefinitionValueAv,
    BlButtonAv,
    ExchangeRateEditForm,
    ExchangeRateConfirmationDialog,
    Loader,
  },
  mixins: [PolicyPageMixin, ErrorMixin],
  data() {
    return {
      currentExchangeRate: {},
      loadState: 'loading',
      newData: {},
    };
  },
  computed: {
    currentRate() {
      return (this.currentExchangeRate && this.currentExchangeRate.rates) || {};
    },
  },
  methods: {
    retrieveInitialData() {
      this.loadState = 'loading';
      this.$api
        .getExchangeRates()
        .then(({ data }) => {
          this.currentExchangeRate = data;
          this.loadState = 'success';
        })
        .catch(error => {
          this.handleError(error);
          this.loadState = 'error';
        });
    },
    openEditForm() {
      this.$refs.ExchangeRateEditForm.openEditForm();
    },
    proceed(newData = {}) {
      this.newData = newData;
      this.$refs.ExchangeRateEditForm.closeEditForm();
      this.$refs.ExchangeRateConfirmationDialog.openConfirmationDialog();
    },
    save() {
      this.loadState = 'loading';
      this.$api
        .patchExchangeRates({ usd: this.newData.usdRate, eur: this.newData.eurRate })
        .then(({ data }) => {
          this.currentExchangeRate = data;
        })
        .catch(this.handleError)
        .finally(() => {
          this.loadState = 'success';
          this.newData = {};
          this.$refs.ExchangeRateEditForm.resetForm();
        });
    },
  },
};
</script>
