<template>
  <div>
    <div v-if="loadState === 'success'" class="o-layout">
      <div class="o-layout__item u-5of12">
        <div class="o-layout u-pad-left--4">
          <investor-draft-view-data
            :investor="investor"
            :old-data="oldInvestorDraftData"
            :new-data="newInvestorDraftData"
          />
        </div>
      </div>
      <div class="o-layout__item u-1of12">
        &nbsp;
      </div>
      <div class="o-layout__item u-6of12">
        <basic-field label="Identity File">
          <div v-if="oldInvestorDraftData.identity_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.identity_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="NPWP File">
          <div v-if="oldInvestorDraftData.npwp_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.npwp_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Selfie File">
          <div v-if="oldInvestorDraftData.selfie_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.selfie_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Signature File">
          <div>
            <img
              v-if="oldInvestorDraftData.signature_url"
              :src="oldInvestorDraftData.signature_url"
              width="200px"
              height="auto"
            />
          </div>
        </basic-field>
        <investor-draft-view-bank
          v-for="(investorDraftBank, index) in investorDraftBanks"
          :key="index"
          :investor-bank-data="investorDraftBank"
          :title="`Bank ${index + 1}`"
        />
      </div>
      <draft-rejection-reason v-if="draft" class="o-layout__item" :draft="draft" form="view" />
      <div v-if="rejectionReasons.length && adminRejectionReason" class="o-layout__item u-pad-top--4">
        <investor-rejection-reasons-field
          ref="InvestorRejectionReasonsField"
          :field="{ name: 'admin_rejection_reason', label: 'Rejection Reasons' }"
          :rejection-reasons="rejectionReasons"
          :admin-rejection-reason="adminRejectionReason"
          readonly
        />
      </div>
      <template v-if="customRejectionReason">
        <basic-field label="Custom Rejection Reason">
          <bl-text-field-mv
            :value="customRejectionReason"
            class="investor-view-form__custom-rejection-reason"
            readonly
          />
        </basic-field>
      </template>
    </div>
  </div>
</template>
<script>
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BasicField from 'partner-admin/components/BasicField';
import InvestorRejectionReasonsField from 'partner-admin/components/RejectionReasonsField';
import DraftRejectionReason from 'partner-admin/components/DraftRejectionReason';
import InvestorDraftFormMixin from '../../mixins/InvestorDraftFormMixin';
import InvestorDraftViewData from './InvestorDraftViewData';
import InvestorDraftViewBank from './InvestorDraftViewBank';

export default {
  name: 'InvestorDraftViewForm',
  components: {
    BasicField,
    InvestorRejectionReasonsField,
    InvestorDraftViewData,
    InvestorDraftViewBank,
    DraftRejectionReason,
    BlTextFieldMv,
  },
  mixins: [InvestorDraftFormMixin],
  computed: {
    adminRejectionReason() {
      return this.newInvestorDraftData.admin_rejection_reason || this.oldInvestorDraftData.admin_rejection_reason || 0;
    },
    customRejectionReason() {
      return this.newInvestorDraftData.custom_rejection_field || this.oldInvestorDraftData.custom_rejection_field || '';
    },
  },
  created() {
    this.setupFormData();
  },
  methods: {
    setupFormData() {
      return new Promise((resolve, reject) => {
        this.loadState = 'loading';
        this.$api
          .getRejectionsReasons()
          .then(({ data }) => {
            this.rejectionReasons = this.processRejectionReason(data);
            this.loadState = 'success';
            resolve();
          })
          .catch(e => {
            this.loadState = 'error';
            this.showErrorNotif(e);
            reject(e);
          });
      });
    },
    processRejectionReason(rejectionReasons) {
      return rejectionReasons.filter(reason => reason.key !== 'ktp_duplicate');
    },
  },
};
</script>
