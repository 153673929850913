<template>
  <dl :class="defListClasses">
    <slot />
  </dl>
</template>

<script>
export default {
  name: 'BlListDefinitionMv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The sizes of the list definition.
     * `small, ''`
     */
    size: {
      type: String,
      default: '',
      validator: value => {
        const validValue = ['', 'small'];

        return validValue.includes(value);
      },
    },
  },

  computed: {
    defListClasses() {
      return ['c-deflist', this.size ? `c-deflist--${this.size}` : ''];
    },
  },
};
</script>

<style src="./BlListDefinitionMv.style.scss" lang="scss"></style>
