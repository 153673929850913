<template>
  <div class="c-tab__content is-active">
    <div class="c-tab__content__body">
      <ul class="c-tab__nav">
        <li
          v-for="tab in filteredTab"
          :key="tab.code"
          class="c-tab__list"
          :class="type === tab.code ? 'is-active' : ''"
        >
          <a class="c-tab__link" @click.prevent="changeType(tab.code)">
            <span> {{ tab.label }} </span>
          </a>
        </li>
      </ul>
      <div class="c-tab__content is-active">
        <div class="c-tab__content__head">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-mv class="o-layout__item u-4of12" split>
                <bl-field-col-mv>
                  <bl-field-label-av>Pilih Bulan</bl-field-label-av>
                </bl-field-col-mv>
                <bl-field-col-mv>
                  <!-- attr key is needed for rerendering purpose to clear the input -->
                  <month-picker-input :key="type" :no-default="true" class="date-picker" lang="id" @input="showDate" />
                </bl-field-col-mv>
              </bl-field-mv>
            </bl-field-mv>
            <bl-button-av class="o-layout__item u-2of12 u-mrgn-left--4" color="red" @click="searchReport()">
              Search
            </bl-button-av>
          </div>
        </div>

        <div class="c-tab__content__body">
          <report-table
            :load-state="loadState"
            :reports="reportList"
            :total="total"
            :limit="limit"
            :offset="offset"
            @reload="retrieveInitialData"
            @changePage="setOffset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotifMixin from 'partner-admin/mixins/notifMixin';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldColMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldColMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import dayjs from 'dayjs';
import { MonthPickerInput } from 'vue-month-picker';
import ReportTable from './components/ReportTable';

export default {
  name: 'ReportPage',
  components: { BlFieldMv, BlButtonAv, MonthPickerInput, BlFieldColMv, BlFieldLabelAv, ReportTable },
  tabOptions: [
    { code: 'investor_individuals', label: 'Individual Investor', policy: 'investor_individual' },
    { code: 'asset_under_management', label: 'AUM', policy: 'aum' },
  ],
  mixins: [NotifMixin, PolicyMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      reportList: [],
      loadState: 'idle',
      total: 0,
      chosenDate: {},
      type: this.$options.tabOptions[0].code,
    };
  },
  computed: {
    filteredTab() {
      return this.$options.tabOptions.filter(tab => this.isAllowed('aria_report', tab.policy));
    },
    defaultPayload() {
      return { offset: this.offset, limit: this.limit, type: this.type };
    },
    searchPayload() {
      const date = Object.keys(this.chosenDate).length ? this.formattedDate : undefined;
      return { date };
    },
    formattedDate() {
      return dayjs(`${this.chosenDate.year}-${this.chosenDate.monthIndex}-1`).format('YYYY-MM-DD');
    },
  },
  created() {
    this.retrieveInitialData();
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.offset = 0;
      this.chosenDate = {};
      this.retrieveInitialData();
    },
    showDate(date) {
      this.chosenDate = date;
    },
    retrieveReports(payload) {
      this.loadState = 'loading';
      this.$api
        .getReportList(payload)
        .then(({ data, meta }) => {
          this.reportList = data;
          this.total = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.showErrorNotif(error);
          this.loadState = 'error';
        });
    },
    searchReport() {
      this.offset = 0;
      this.getReports();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getReports();
    },
    getReports() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveReports(payload);
    },
    retrieveInitialData() {
      this.retrieveReports(this.defaultPayload);
    },
  },
};
</script>

<style scoped>
.date-picker /deep/ .month-picker__container {
  background-color: white;
}
.date-picker /deep/ .month-picker-input {
  padding: 0px 1em;
}
</style>
