<template>
  <div>
    <div class="o-layout u-pad--4">
      <div class="o-layout__item u-4of12">
        <span class="u-mrgn-right--2"> Choose report date </span>
        <month-picker-input
          :no-default="true"
          class="date-picker"
          lang="id"
          style="display: inline-block"
          @input="setDate"
        />
      </div>
      <div class="o-layout__item u-2of12">
        <bl-button-av
          class="aml-cft-index-page__download"
          color="red"
          block
          has-spinner
          :disabled="!date.month || !date.year"
          @click="download"
        >
          Download
        </bl-button-av>
      </div>
      <div v-if="isAllowed('aml_report', 'config')" class="o-layout__item u-2of12">
        <bl-button-av class="aml-cft-index-page__configuration" color="red" block @click="gotoConfigurationPage">
          Configuration
        </bl-button-av>
      </div>
    </div>
    <file-downloader ref="FileDownloader" />
  </div>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import { MonthPickerInput } from 'vue-month-picker';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import queryString from 'query-string';
import FileDownloader from 'partner-admin/components/FileDownloader';

const downloadUrl = `${process.env.PARTNER_API_URL}/_exclusive/aml-reports/download`;

export default {
  name: 'AmlCftIndexPage',
  components: { MonthPickerInput, BlButtonAv, FileDownloader },
  mixins: [PolicyPageMixin],
  data() {
    return {
      date: {},
    };
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    configurationUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/aml-cft/configuration`;
    },
  },
  methods: {
    setDate(date) {
      this.date = date;
    },
    gotoConfigurationPage() {
      this.$router.push({
        name: 'AmlCftConfiguration',
      });
    },
    download() {
      const token = this.tokenObj.token;

      const payload = {
        access_token: token,
        month: this.date.monthIndex,
        year: this.date.year,
      };

      const url = new URL(`${downloadUrl}?${queryString.stringify(payload)}`);

      this.$refs.FileDownloader.download(`${url}`);
    },
  },
};
</script>
<style scoped>
.date-picker /deep/ .month-picker__container {
  background-color: white;
}
.date-picker /deep/ .month-picker-input {
  padding: 0px 1em;
}
</style>
