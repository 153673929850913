<template>
  <div class="c-fld__row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFieldRowMv',
  status: 'ready',
  release: '0.4.0',
};
</script>

<style src="./BlFieldRowMv.style.scss" lang="scss"></style>
