export function getAddressInheritValue(addressValue, isDomicile = false) {
  let base = {
    address: '',
    neighborhood: '',
    village: '',
    sub_district: '',
  };

  if (isDomicile) {
    base = {
      domicile_address: '',
      domicile_neighborhood: '',
      domicile_village: '',
      domicile_sub_district: '',
    };
  }

  if (addressValue) {
    const values = addressValue.split(', ').map(value => value.trim());
    Object.keys(base).forEach((key, idx) => {
      base[key] = values[idx];
    });
  }
  return base;
}

export function getBankInheritValue(bankValue) {
  const base = {
    account_name: '',
    account_no: '',
    bank_name: '',
    bank_branch: '',
    account_valid: '',
  };
  if (bankValue) {
    Object.keys(base).forEach(key => {
      base[key] = bankValue[key];
    });
  }
  return base;
}

export function constructInvestor(investor) {
  const inheritedAddress = getAddressInheritValue(investor.address);
  const inheritedDomicileAddress = getAddressInheritValue(investor.domicile_address, true);
  const inheritedBank = getBankInheritValue(investor.bank_data && investor.bank_data[0]);
  return { ...investor, ...inheritedAddress, ...inheritedDomicileAddress, ...inheritedBank };
}

export default {};
