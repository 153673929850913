<template>
  <div>
    <bl-dialog-mv :active.sync="accountStatementDialog" class="custom-dialog" title="Upload Account Statement">
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-dropdown-av
            v-model="choosenProduct"
            placeholder="Pilih Produk"
            track-by="id"
            label="fund_name"
            :options="products"
            searchable
          />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-field-mv>
        <bl-field-row-mv>
          <month-picker
            lang="id"
            :default-year="$options.currentDate.getFullYear()"
            :default-month="$options.currentDate.getMonth() + 1"
            @change="showDate"
          />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-field-mv>
        <bl-field-row-mv>
          <bl-upload-field-av ref="input" v-model="fileModel" @input="onFileChange" />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-button-av
        color="red"
        block
        has-spinner
        :disabled="isSubmitting"
        :activated="isSubmitting"
        @click="openConfirmation"
      >
        Upload
      </bl-button-av>

      <account-statement-confirmation-dialog
        v-if="!anyEmptyField"
        ref="AccountStatementConfirmationDialog"
        :product="choosenProduct"
        :date="formattedDate"
        @submit="submit"
      />
    </bl-dialog-mv>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'partner-admin/lib/vuex';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlUploadFieldAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlUploadFieldAv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import dayjs from 'dayjs';
import { MonthPicker } from 'vue-month-picker';
import AccountStatementConfirmationDialog from './AccountStatementConfirmationDialog';

export default {
  name: 'AccountStatementDialog',
  currentDate: new Date(),
  components: {
    BlDialogMv,
    BlButtonAv,
    BlDropdownAv,
    BlFieldMv,
    BlFieldRowMv,
    BlUploadFieldAv,
    AccountStatementConfirmationDialog,
    MonthPicker,
  },
  mixins: [ErrorMixin],
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    ...mapGetters({
      products: 'getProducts',
    }),
    anyEmptyField() {
      return !this.choosenProduct || !this.chosenDate || !this.fileModel;
    },
    formattedDate() {
      return dayjs(`${this.chosenDate.year}-${this.chosenDate.monthIndex}-1`).format('YYYY-MM-DD');
    },
  },
  data() {
    return {
      accountStatementDialog: false,
      choosenProduct: undefined,
      chosenDate: {},
      fileModel: '',
      isSubmitting: false,
    };
  },
  methods: {
    showDate(date) {
      this.chosenDate = date;
    },
    openUploadAccountStatementDialog() {
      this.fileModel = '';
      this.chosenDate = null;
      this.choosenProduct = undefined;
      this.accountStatementDialog = true;
    },
    openConfirmation() {
      if (this.anyEmptyField) {
        this.handleError('Ada field yang belum terisi. Silahkan cek kembali.');
        return;
      }

      this.$refs.AccountStatementConfirmationDialog.openAccountStatementConfirmationDialog();
    },
    onFileChange(files) {
      if (files[0]) {
        this.fileModel = files[0];
      }
    },
    submit() {
      this.isSubmitting = true;
      const token = this.tokenObj.token;

      const formData = new FormData();
      formData.append('action', 'account_statement');
      formData.append('date', this.formattedDate);
      formData.append('product_id', this.choosenProduct.id);
      formData.append('attachment', this.fileModel);

      fetch(`${process.env.PARTNER_API_URL}/_exclusive/bundle-documents`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(r => r.json())
        .then(response => {
          if (response.meta.http_status === 201) {
            this.$root.$emit('showFlashNotif', {
              text: 'Berhasil upload file',
              type: 'success',
            });
            this.accountStatementDialog = false;
            this.$emit('refresh');
            return;
          }
          throw response;
        })
        .catch(this.handleError)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style scoped>
.custom-dialog /deep/ .c-dialog__content {
  max-width: 440px !important;
}
</style>
