<template>
  <div style="display: flex; width: 100%" class="u-pad--4 u-border--bottom">
    <div style="flex-grow: 1">
      <router-link to="/" style="text-decoration: none">
        <h1 class="u-txt--bold main-header__title u-fg--black">
          Ashmore PLT
        </h1>
      </router-link>
    </div>
    <div v-if="user" style="flex-grow: 0">
      <bl-popover-av position="right">
        <div class="main-header__username u-pointer">
          {{ user.username }}
          <i class="c-icon c-icon--account c-icon--large" />
        </div>
        <div slot="content" class="u-pad--2">
          <div class="main-header__change-password u-pointer u-mrgn-bottom--2" @click="gotoChangePasswordPage">
            Change Password
          </div>
          <div class="main-header__logout u-pointer" @click="onClickLogout">
            Logout
          </div>
        </div>
      </bl-popover-av>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'partner-admin/lib/vuex';
import BlPopoverAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlPopoverAv';

export default {
  name: 'MainHeader',
  components: { BlPopoverAv },
  computed: {
    ...mapState('user', {
      user: 'user',
    }),
  },
  methods: {
    ...mapMutations(['resetPolicies']),
    ...mapActions('session', ['logout']),
    onClickLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'LoginPage' });
        this.resetPolicies();
      });
    },
    gotoChangePasswordPage() {
      this.$router.push({
        name: 'changePasswordPage',
      });
    },
  },
};
</script>
