<template>
  <div>
    <div v-if="loadState === 'success'" class="o-layout">
      <div class="o-layout__item u-5of12">
        <div class="o-layout u-pad-left--4">
          <investor-revises-view-data
            :investor="investorRevise.investor"
            :old-data="oldInvestorData"
            :new-data="newInvestorData"
          />
        </div>
      </div>
      <div class="o-layout__item u-1of12">
        &nbsp;
      </div>
      <div class="o-layout__item u-6of12">
        <basic-field label="Identity File">
          <div v-if="oldInvestorData.identity_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.identity_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="NPWP File">
          <div v-if="oldInvestorData.npwp_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.npwp_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Selfie File">
          <div v-if="oldInvestorData.selfie_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.selfie_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Signature File">
          <img v-if="oldInvestorData.signature_url" :src="oldInvestorData.signature_url" width="200px" height="auto" />
        </basic-field>
        <investor-revises-view-bank
          v-for="(oldInvestorBank, idx) in oldInvestorBanks"
          :key="oldInvestorBank.investor_bank_id || oldInvestorBank.id"
          :investor="investorRevise.investor"
          :old-data="oldInvestorBank"
          :new-data="newInvestorBanks[idx]"
          :title="`Bank ${idx + 1}`"
        />
      </div>
      <draft-rejection-reason v-if="draft" class="o-layout__item" :draft="draft" form="view" />
      <div v-if="rejectionReasons.length && adminRejectionReason" class="o-layout__item u-pad-top--4">
        <investor-rejection-reasons-field
          ref="InvestorRejectionReasonsField"
          :field="{ name: 'admin_rejection_reason', label: 'Rejection Reasons' }"
          :rejection-reasons="rejectionReasons"
          :admin-rejection-reason="adminRejectionReason"
          readonly
        />
      </div>
    </div>
  </div>
</template>
<script>
import BasicField from 'partner-admin/components/BasicField';
import InvestorRejectionReasonsField from 'partner-admin/components/RejectionReasonsField';
import DraftRejectionReason from 'partner-admin/components/DraftRejectionReason';
import InvestorRevisesFormMixin from '../../mixins/InvestorRevisesFormMixin';
import InvestorRevisesViewData from './InvestorRevisesViewData';
import InvestorRevisesViewBank from './InvestorRevisesViewBank';

export default {
  name: 'InvestorRevisesViewForm',
  components: {
    BasicField,
    InvestorRejectionReasonsField,
    InvestorRevisesViewData,
    InvestorRevisesViewBank,
    DraftRejectionReason,
  },
  mixins: [InvestorRevisesFormMixin],
  computed: {
    adminRejectionReason() {
      const adminRejection =
        this.draft &&
        this.draft.data &&
        this.draft.data.length &&
        this.draft.data.find(draftData => draftData.field === 'admin_rejection_reason');
      if (adminRejection) {
        return adminRejection.new_value;
      }
      return this.investorRevise.admin_rejection_reason || 0;
    },
  },
  created() {
    this.setupFormData();
  },
  methods: {
    setupFormData() {
      return new Promise((resolve, reject) => {
        this.loadState = 'loading';
        this.$api
          .getRevisionRejectionsReasons()
          .then(({ data }) => {
            this.rejectionReasons = this.processRejectionReason(data);
            this.loadState = 'success';
            resolve();
          })
          .catch(e => {
            this.loadState = 'error';
            this.showErrorNotif(e);
            reject(e);
          });
      });
    },
    processRejectionReason(rejectionReasons) {
      return rejectionReasons.filter(reason => reason.key !== 'ktp_duplicate');
    },
  },
};
</script>
