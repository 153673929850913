import queryString from 'query-string';

function urlWithParams(url, params = {}) {
  const paramUrl = queryString.stringify(params);
  const newUrl = `${url}?${paramUrl}`;
  return newUrl;
}

function urlByMethod(method, url, data) {
  return method === 'get' ? urlWithParams(url, data) : url;
}

function request(ctx, method, endpointUrl, options = {}) {
  return new Promise((resolve, reject) => {
    ctx[method](endpointUrl, 'user', options.payload)
      .then(response => {
        return resolve(response);
      })
      .catch(reject);
  });
}

function generateRequestCustomSegment(method, url, options, data) {
  const segment = data.customSegments.reduce((a, s) => `${a}/${s}`);
  delete data.customSegments;
  const newUrl = `${url}/${segment}`;
  return urlByMethod(method, newUrl, data);
}

function generateRequestWithData(method, url, options, data) {
  return urlByMethod(method, url, data);
}

function generateRequestWithId(method, url, options, data) {
  let newUrl = `${url}/${data}`;
  if (options.appendUrl) {
    newUrl = `${newUrl}/${options.appendUrl}`;
  }
  return newUrl;
}

function generateRequestWithIdAndData(method, url, options, data) {
  let newUrl = `${url}/${data.id}`;
  if (options.appendUrl) {
    newUrl = `${newUrl}/${options.appendUrl}`;
  }
  return urlByMethod(method, newUrl, data);
}

function isCustomSegment(options) {
  return options.withData && options.customSegments;
}

function isWithIdAndData(options) {
  return options.withId && options.withData;
}

export function generateApiUrl(method, url, options, data) {
  // make sure the order is correct
  let newUrl = url;
  if (isCustomSegment(options)) {
    newUrl = generateRequestCustomSegment(method, url, options, data);
  } else if (isWithIdAndData(options)) {
    newUrl = generateRequestWithIdAndData(method, url, options, data);
  } else if (options.withData) {
    newUrl = generateRequestWithData(method, url, options, data);
  } else if (options.withId) {
    newUrl = generateRequestWithId(method, url, options, data);
  }
  return newUrl;
}

function generateApiInterface(method, url, requestOptions, options) {
  /* istanbul ignore next */
  return function apiInterface(data, opts = {}) {
    const newUrl = generateApiUrl(method, url, options, data);
    const { headers } = opts;

    requestOptions.payload = { ...requestOptions.payload, headers };

    if (options.withData) {
      requestOptions.payload = { ...requestOptions.payload, data };
    }

    if (options.withCredentials) {
      requestOptions.payload = { ...requestOptions.payload, withCredentials: true };
    }

    return request(this, method, newUrl, requestOptions);
  };
}

/* istanbul ignore next */
const createGenerator = (appName, endpoints) => {
  const logName = `frontend_dweb_${appName}`;
  const generateInterface = (method, endpointName, options = {}) => {
    const label = `bukareksa_${method}_${endpointName}_api`;
    const payload = { responseErrorJson: true };
    const url = endpoints[endpointName];
    const requestOptions = { payload, label, logName };

    return generateApiInterface(method, url, requestOptions, options);
  };

  return generateInterface;
};

export default {
  createGenerator,
};
