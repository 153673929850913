<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/investors">
        &lt; Kembali Ke Halaman Daftar Investor
      </router-link>

      <investor-checked-filter @search="searchInvestor" />
    </div>

    <div class="c-tab__content__body">
      <div class="o-layout o-layout--responsive">
        <div v-if="isAllowedToApproveOrReject" class="o-layout__item u-2of12">
          <bl-button-av block :color="isCheckedAll ? 'yellow' : 'default'" @click="checkAll">
            {{ isCheckedAll ? 'Deselect All' : 'Select All' }}
          </bl-button-av>
        </div>

        <div v-if="isAllowedToApproveOrReject" class="o-layout__item u-2of12">
          <bl-button-av class="investor-checked-filter__approve" color="green" block @click.native="openConfirmation">
            Approve
          </bl-button-av>
          <investor-checked-approve-confirmation
            ref="InvestorCheckedApproveConfirmation"
            :chosen-investors="chosenInvestors"
            @refresh="getInvestorChecked"
          />
        </div>

        <div class="o-layout__item u-1of12">
          <bl-dropdown-av v-model="limit" :options="[10, 50, 100]" />
        </div>
      </div>

      <investor-checked-table
        :record-type="recordType"
        :load-state="loadState"
        :investor-checked-data="investorCheckedDrafts"
        :total="totalData"
        :limit="limit"
        :offset="offset"
        :chosen-investors="chosenInvestors"
        @reload="getInvestorChecked"
        @refresh="getInvestorChecked"
        @changePage="setOffset"
        @chooseInvestor="chooseInvestor"
      />
    </div>
  </div>
</template>

<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import InvestorCheckedTable from '../components/InvestorCheckedTable';
import InvestorCheckedFilter from '../components/InvestorCheckedFilter';
import InvestorCheckedApproveConfirmation from '../components/InvestorCheckedApproveConfirmation';

export default {
  name: 'InvestorCheckedIndexPage',
  draftRecordType: {
    investorCheckedIndex: 'Bib::Investor',
    investorRevisesCheckedIndex: 'Bib::InvestorRevise',
  },
  api: {
    'Bib::Investor': 'getInvestor',
    'Bib::InvestorRevise': 'getInvestorRevise',
  },
  components: {
    InvestorCheckedTable,
    BlButtonAv,
    BlDropdownAv,
    InvestorCheckedFilter,
    InvestorCheckedApproveConfirmation,
  },
  mixins: [PolicyPageMixin, ErrorMixin],
  data() {
    return {
      investorCheckedDrafts: [],
      totalData: 0,
      limit: 10,
      offset: 0,
      loadState: 'idle',
      searchPayload: {},
      chosenInvestors: [],
    };
  },
  computed: {
    defaultPayload() {
      return {
        offset: this.offset,
        limit: this.limit,
        record_type: this.recordType,
        action: 'draft_create',
        state: 'approved',
      };
    },
    isCheckedAll() {
      return this.investorCheckedDrafts.length > 0 && this.chosenInvestors.length === this.investorCheckedDrafts.length;
    },
    recordType() {
      return this.$options.draftRecordType[this.$route.name];
    },
    isAllowedToApproveOrReject() {
      if (this.recordType === 'Bib::Investor') {
        return this.isAllowed('investor', 'final_approve_reject');
      }
      if (this.recordType === 'Bib::InvestorRevise') {
        return this.isAllowed('investor_revise', 'final_approve_reject');
      }
      return false;
    },
  },
  watch: {
    limit: {
      handler() {
        this.getInvestorChecked();
      },
    },
  },
  methods: {
    retrieveInvestorCheckedDraft(payload) {
      this.loadState = 'loading';
      this.$api
        .getDrafts(payload)
        .then(({ data, meta }) => {
          this.investorCheckedDrafts = data;
          this.totalData = meta.total;
        })
        .then(this.retrieveDraftRecords)
        .then(this.populateDraftRecords)
        .then(() => {
          this.loadState = 'success';
        })
        .catch(error => {
          this.loadState = 'error';
          this.handleError(error);
        });
    },
    retrieveDraftRecords() {
      return new Promise(resolve => {
        const recordIds = this.investorCheckedDrafts.map(draft => draft.record_id);
        const uniqueRecordIds = Array.from(new Set(recordIds));
        const apis = uniqueRecordIds.map(id => this.$api[this.$options.api[this.recordType]](id));
        Promise.all(apis).then(resolve);
      });
    },
    populateDraftRecords(investorRecords) {
      this.investorCheckedDrafts.forEach((draft, idx) => {
        const investorRecordIdx = investorRecords.findIndex(record => record.data.id === draft.record_id);
        if (investorRecordIdx !== -1) {
          const record = investorRecords[investorRecordIdx].data;
          this.$set(this.investorCheckedDrafts, idx, { ...this.investorCheckedDrafts[idx], record });
        }
      });
    },
    searchInvestor(searchPayload) {
      this.searchPayload = searchPayload;
      this.offset = 0;
      this.getInvestorChecked();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getInvestorChecked();
    },
    getInvestorChecked() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveInvestorCheckedDraft(payload);
    },
    retrieveInitialData() {
      this.retrieveInvestorCheckedDraft(this.defaultPayload);
    },
    checkAll() {
      if (this.isCheckedAll) {
        // All is checked, deselect all
        this.chosenInvestors = [];
      } else {
        this.chosenInvestors = this.investorCheckedDrafts.map(draft => draft.id);
      }
    },
    chooseInvestor({ id, checked }) {
      if (checked) {
        if (!this.chosenInvestors.includes(id)) {
          this.chosenInvestors.push(id);
        }
      } else {
        this.chosenInvestors = this.chosenInvestors.filter(investorId => investorId !== id);
      }
    },
    openConfirmation() {
      if (!this.chosenInvestors.length) {
        this.handleError('Tidak ada investor yang dipilih');
        return;
      }
      this.$refs.InvestorCheckedApproveConfirmation.openDialog();
    },
  },
};
</script>
