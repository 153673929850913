<template>
  <div>
    <BlCalendarHeaderMv
      :type="type"
      :is-has-multiple-calendar="isHasMultipleCalendar"
      :month-year-title="monthYearTitle"
      @goToPreviousMonth="goToPreviousMonth"
      @goToNextMonth="goToNextMonth"
    />
    <div class="datepicker__body u-pad--3">
      <div class="u-clearfix">
        <BlCalendarDayTitleAv
          :workdays="workdays"
          :weekend="weekend"
        />
      </div>
      <div class="u-clearfix">
        <span
          v-for="blank in amountOfBlankDays"
          :key="blank.id"
          class="datepicker__date-space u-display-inline-block"
        />
        <BlCalendarDateAv
          v-for="date in datesOfMonth"
          :key="date.timestamp"
          :title="holidayTooltip(date)"
          :date="date"
          @onClick="onClick(date, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDaysInMonth, isBefore, isWeekend, isEqual, isAfter } from 'date-fns';
import { workdays, weekend, dateFormat } from '../../../../utils/date-helper';
import BlCalendarHeaderMv from './BlCalendarHeaderMv.vue';
import BlCalendarDateAv from './BlCalendarDateAv.vue';
import BlCalendarDayTitleAv from './BlCalendarDayTitleAv.vue';
import HolidaysData from '../data/HolidaysData';

export default {
  name: 'BlDatePickerCalendarMv',
  components: {
    BlCalendarHeaderMv,
    BlCalendarDateAv,
    BlCalendarDayTitleAv,
  },
  props: {
    beginDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    endDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    limitedDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    outdatedDate: {
      type: Number,
      default: new Date().setHours(0, 0, 0, 0),
    },
    startDate: {
      type: Number,
      required: true,
    },
    isHasMultipleCalendar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    isLimitedDateEnabled: {
      type: Boolean,
      default: false,
    },
    isDateRangeEnabled: {
      type: Boolean,
      default: false,
    },
    isOutdatedDateEnabled: {
      type: Boolean,
      default: false,
    },
    isHolidayShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workdays,
      weekend,
    };
  },
  computed: {
    amountOfBlankDays() {
      const startDate = new Date(this.startDate);
      return startDate.getDay();
    },

    datesOfMonth() {
      const startDate = new Date(this.startDate);
      const daysInMonth = getDaysInMonth(this.startDate);
      const dates = [];
      for (let date = 1; date <= daysInMonth; date += 1) {
        const thisDate = new Date(startDate.getFullYear(), startDate.getMonth(), date);
        dates.push({
          value: date,
          timestamp: thisDate.getTime(),
          outdated: this.isOutdated(thisDate.getTime()),
          weekend: isWeekend(thisDate.getTime()),
          holiday: this.getHoliday(thisDate.getTime()),
          todayDate: this.isTodayDate(thisDate.getTime()),
          selectedDate: this.isSelectedDate(thisDate.getTime()),
          limited: this.isLimited(thisDate.getTime()),
          beginDateRange: this.isBeginDateRange(thisDate.getTime()),
          endDateRange: this.isEndDateRange(thisDate.getTime()),
          inRange: this.isInRange(thisDate.getTime()),
        });
      }
      return dates;
    },

    monthYearTitle() {
      return dateFormat(new Date(this.startDate), 'MMMM YYYY');
    },
  },
  methods: {
    goToPreviousMonth() {
      this.$emit('goToPreviousMonth');
    },
    goToNextMonth() {
      this.$emit('goToNextMonth');
    },
    isOutdated(timestamp) {
      if (!this.isOutdatedDateEnabled) {
        return false;
      }
      return isBefore(timestamp, this.outdatedDate);
    },
    getHoliday(timestamp) {
      if (!this.isHolidayShown) {
        return false;
      }
      return HolidaysData.find(value => {
        const holidayDate = value.date;
        return isEqual(timestamp, holidayDate);
      });
    },
    holidayTooltip(date) {
      if (!this.isHolidayShown) {
        return '';
      }
      const holiday = this.getHoliday(date.timestamp);
      if (holiday == null) {
        return '';
      }
      return holiday.name;
    },
    isSelectedDate(timestamp) {
      if (!this.isDateRangeEnabled) {
        return isEqual(timestamp, this.beginDate);
      }
      return false;
    },
    isEndDateRange(timestamp) {
      if (this.isDateRangeEnabled) {
        return isEqual(timestamp, this.endDate);
      }
      return false;
    },
    isBeginDateRange(timestamp) {
      if (this.isDateRangeEnabled) {
        return isEqual(timestamp, this.beginDate);
      }
      return false;
    },
    isInRange(timestamp) {
      if (!this.isDateRangeEnabled) {
        return false;
      }
      const date = timestamp;
      const beetweenDates = isBefore(this.beginDate, date) && isBefore(date, this.endDate);
      const dateRange = this.isBeginDateRange(timestamp) || this.isEndDateRange(timestamp);
      return beetweenDates || dateRange;
    },
    isTodayDate(timestamp) {
      const currentDate = new Date();
      const thisDate = new Date(timestamp);
      return thisDate.getDate() === currentDate.getDate() && thisDate.getMonth() === currentDate.getMonth();
    },
    isLimited(timestamp) {
      if (!this.isLimitedDateEnabled) {
        return false;
      }
      return isAfter(timestamp, this.limitedDate);
    },
    onClick(date, e) {
      this.$emit('input', { timestamp: date.timestamp, e });
    },
  },
};
</script>
<style src="./BlCalendarDayTitleAv.style.scss" lang="scss"></style>
