<template>
  <div class="c-tab__content is-active">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'RolesPage',
};
</script>
