<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-10of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="sid" placeholder="SID" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="ifua" placeholder="IFUA" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="nik" placeholder="NIK" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="email" placeholder="Email" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="product"
                  placeholder="Product"
                  :options="productOptions"
                  track-by="id"
                  label="fund_name"
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="agentIds"
                  placeholder="Agent"
                  track-by="id"
                  label="name"
                  :options="agentOptions"
                  multiple
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item">
              <bl-field-row-mv>
                <bl-button-av class="portfolio-index-page__search" color="red" @click="searchPortfolios">
                  Search
                </bl-button-av>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <div class="porfolio-index-page__total-result">Result: {{ portfoliosTotal }}</div>
      <portfolio-table
        :portfolios="portfolios"
        :total="portfoliosTotal"
        :limit="limit"
        :offset="offset"
        :load-state="portfoliosLoadState"
        @reload="getPortfolios"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'partner-admin/lib/vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import AgentOptionsMixin from 'partner-admin/mixins/agentOptionsMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import PortfolioTable from '../components/PortfolioTable';

export default {
  name: 'PortofoliosIndexPage',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlButtonAv,
    BlDropdownAv,
    PortfolioTable,
  },
  mixins: [PolicyPageMixin, NotifMixin, AgentOptionsMixin],
  data() {
    return {
      ifua: '',
      sid: '',
      nik: '',
      email: '',
      product: '',
      agentIds: '',
      offset: 0,
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      portfolios: 'getPortfolios',
      portfoliosLoadState: 'getPortfoliosLoadState',
      portfoliosTotal: 'getPortfoliosTotal',
      products: 'getProducts',
    }),
    productOptions() {
      return ['', ...this.products];
    },
    defaultPayload() {
      return { offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return {
        sid: this.sid,
        ifua: this.ifua,
        nik: this.nik,
        email: this.email,
        product_id: this.product.id,
        'agent_id[]': this.agentIds ? this.agentIds.map(agent => agent.id) : undefined,
      };
    },
  },
  methods: {
    ...mapActions(['retrievePortfolios', 'retrieveProducts']),
    retrieveInitialData() {
      this.retrievePortfolios(this.defaultPayload).catch(this.showErrorNotif);
      this.retrieveProducts({ state: 'approved', limit: 100, offset: 0 });
      this.retrieveAgentOptions();
    },
    searchPortfolios() {
      this.offset = 0;
      this.getPortfolios();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getPortfolios();
    },
    getPortfolios() {
      const payload = {
        ...this.defaultPayload,
        ...this.searchPayload,
      };
      this.retrievePortfolios(payload).catch(this.showErrorNotif);
    },
  },
};
</script>
