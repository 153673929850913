import api from 'partner-admin/api';

const states = {
  portfolios: [],
  portfoliosLoadState: 'idle',
  portfoliosTotal: 0,
  portfolioSummaries: {},
  investorPortfolios: [],
  investorPortfoliosLoadState: 'idle',
  investorPortfoliosTotal: 0,
  productPortfolios: [],
  productPortfoliosLoadState: 'idle',
  productPortfoliosTotal: 0,
};

const getters = {
  getPortfolios: state => state.portfolios,
  getPortfoliosLoadState: state => state.portfoliosLoadState,
  getPortfoliosTotal: state => state.portfoliosTotal,
  getPortfolioSummariesByInvestor: state => state.portfolioSummaries,
  getInvestorPortfolios: state => state.investorPortfolios,
  getInvestorPortfoliosLoadState: state => state.investorPortfoliosLoadState,
  getInvestorPortfoliosTotal: state => state.investorPortfoliosTotal,
  getProductPortfolios: state => state.productPortfolios[0],
  getProductPortfoliosLoadState: state => state.productPortfoliosLoadState,
  getProductPortfoliosTotal: state => state.productPortfoliosTotal,
};

const mutations = {
  setPortfolios: (state, val) => {
    state.portfolios = val;
  },
  setPortfoliosLoadState: (state, val) => {
    state.portfoliosLoadState = val;
  },
  setPortfoliosTotal: (state, val) => {
    state.portfoliosTotal = val;
  },
  setInvestorPortfolios: (state, val) => {
    state.investorPortfolios = val;
  },
  setInvestorPortfoliosLoadState: (state, val) => {
    state.investorPortfoliosLoadState = val;
  },
  setInvestorPortfoliosTotal: (state, val) => {
    state.investorPortfoliosTotal = val;
  },
  setProductPortfolios: (state, val) => {
    state.productPortfolios = val;
  },
  setProductPortfoliosLoadState: (state, val) => {
    state.productPortfoliosLoadState = val;
  },
  setProductPortfoliosTotal: (state, val) => {
    state.productPortfoliosTotal = val;
  },
  setPortfolioSummariesByInvestor: (state, val) => {
    state.portfolioSummaries = val;
  },
};

const actions = {
  retrievePortfolios({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.portfoliosLoadState !== 'loading') {
        commit('setPortfoliosLoadState', 'loading');
        api
          .getPortfolios(payload)
          .then(response => {
            commit('setPortfoliosLoadState', 'success');
            commit('setPortfolios', response.data);
            commit('setPortfoliosTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setPortfoliosLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.portfolios);
      }
    });
  },
  retrieveInvestorPortfolios({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.investorPortfoliosLoadState !== 'loading') {
        commit('setInvestorPortfoliosLoadState', 'loading');
        api
          .getPortfolios(payload)
          .then(response => {
            commit('setInvestorPortfoliosLoadState', 'success');
            commit('setInvestorPortfolios', response.data);
            commit('setInvestorPortfoliosTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setInvestorPortfoliosLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.investorPortfolios);
      }
    });
  },
  retrievePortfolio({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (state.productPortfoliosLoadState !== 'loading') {
        commit('setProductPortfoliosLoadState', 'loading');
        api
          .getPortfolio(payload)
          .then(response => {
            commit('setProductPortfoliosLoadState', 'success');
            commit('setProductPortfolios', [response.data]);
            commit('setProductPortfoliosTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setProductPortfoliosLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.productPortfolios);
      }
    });
  },
  retrievePortfolioSummaries({ state, commit }, investorId) {
    return new Promise((resolve, reject) => {
      api
        .getPortfolioSummariesByInvestor({ investor_id: investorId })
        .then(response => {
          commit('setPortfolioSummariesByInvestor', response.data);
          resolve(response);
        })
        .catch(response => {
          commit('setProductPortfoliosLoadState', 'error');
          reject(response);
        });
      resolve(state.portfolioSummaries);
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
