<template>
  <div>
    <inline-field label="Transaction Date">
      <bl-text-field-mv :value="formatDate(transaction.created_at)" readonly />
    </inline-field>
    <inline-field label="Transaction Type">
      <bl-text-field-mv :value="formatTransactionType(transaction.type)" readonly />
    </inline-field>
    <inline-field label="Reference No.">
      <bl-text-field-mv :value="transaction.reference_id || ''" readonly />
    </inline-field>
    <inline-field label="Investor Fund Unit A/C No.">
      <bl-text-field-mv :value="transaction.ifua" readonly />
    </inline-field>
    <inline-field label="Agent">
      <bl-text-field-mv :value="agent" readonly />
    </inline-field>
    <inline-field :label="`CCY`">
      <bl-text-field-mv :value="fundCurrency" readonly />
    </inline-field>
    <inline-field :label="`${typeLabel} Fund Code`">
      <bl-text-field-mv :value="fundCode" readonly />
    </inline-field>
    <inline-field :label="`${typeLabel} Amount (Nominal)`">
      <bl-text-field-mv :value="formatMoney(transaction.nominal)" readonly />
      <template v-if="isDifferent(transaction.nominal, transaction.sinvest_nominal, 1)">
        <bl-text-field-mv :value="formatMoney(transaction.sinvest_nominal)" is-error readonly />
        <bl-field-hint-av>
          Amount (Nominal) BIB {{ formatMoney(transaction.nominal) }}, Amount (Nominal) S-Invest
          {{ formatMoney(transaction.sinvest_nominal) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field label="Gross Amount (Nominal)">
      <bl-text-field-mv :value="formatMoney(transaction.gross_nominal)" readonly />
      <template v-if="isDifferent(transaction.gross_nominal, transaction.sinvest_gross_nominal, 1)">
        <bl-text-field-mv :value="formatMoney(transaction.sinvest_gross_nominal)" is-error readonly />
        <bl-field-hint-av>
          Gross Amount (Nominal) BIB {{ formatMoney(transaction.gross_nominal) }}, Gross Amount (Nominal) S-Invest
          {{ formatMoney(transaction.sinvest_gross_nominal) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field label="Nett Amount (Nominal)">
      <bl-text-field-mv :value="formatMoney(transaction.net_nominal)" readonly />
      <template v-if="isDifferent(transaction.net_nominal, transaction.sinvest_net_nominal, 1)">
        <bl-text-field-mv :value="formatMoney(transaction.sinvest_net_nominal)" is-error readonly />
        <bl-field-hint-av>
          Nett Amount (Nominal) BIB {{ formatMoney(transaction.net_nominal) }}, Nett Amount (Nominal) S-Invest
          {{ formatMoney(transaction.sinvest_net_nominal) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field :label="`${typeLabel} Amount (Unit)`">
      <bl-text-field-mv :value="formatDecimal(transaction.unit)" readonly />
      <template v-if="isDifferent(transaction.unit, transaction.sinvest_unit, 0.0001)">
        <bl-text-field-mv :value="formatDecimal(transaction.sinvest_unit)" is-error readonly />
        <bl-field-hint-av>
          Amount (Unit) BIB {{ formatDecimal(transaction.unit) }}, Amount (Unit) S-Invest
          {{ formatDecimal(transaction.sinvest_unit) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field label="NAV Value">
      <bl-text-field-mv :value="formatMoney(transaction.nav_value)" readonly />
      <template v-if="isDifferent(transaction.nav_value, transaction.sinvest_nav_value)">
        <bl-text-field-mv :value="formatMoney(transaction.sinvest_nav_value)" is-error readonly />
        <bl-field-hint-av>
          NAV Value BIB {{ formatMoney(transaction.nav_value) }}, NAV Value S-Invest
          {{ formatMoney(transaction.sinvest_nav_value) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field label="NAV Date">
      <bl-text-field-mv :value="formatDate(transaction.nav_date)" readonly />
      <template v-if="isDifferent(transaction.nav_date, transaction.sinvest_nav_date)">
        <bl-text-field-mv :value="formatDate(transaction.sinvest_nav_date)" is-error readonly />
        <bl-field-hint-av>
          NAV Date BIB {{ formatDate(transaction.nav_date) }}, NAV Date S-Invest
          {{ formatDate(transaction.sinvest_nav_date) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field label="Fee (Nominal)">
      <bl-text-field-mv :value="formatMoney(transaction.fee)" readonly />
      <template v-if="isDifferent(transaction.fee, transaction.sinvest_fee, 1)">
        <bl-text-field-mv :value="formatMoney(transaction.sinvest_fee)" is-error readonly />
        <bl-field-hint-av>
          Fee (Nominal) BIB {{ formatMoney(transaction.fee) }}, Fee (Nominal) S-Invest
          {{ formatMoney(transaction.sinvest_fee) }}
        </bl-field-hint-av>
      </template>
    </inline-field>
    <inline-field :label="`${typeLabel} Amount (All Units)`">
      <bl-text-field-mv :value="transaction.all_unit ? 'Ya' : 'Tidak'" readonly />
    </inline-field>
    <inline-field label="Switching Fee Charge Fund">
      <bl-text-field-mv :value="transaction.fee_charge_type" readonly />
    </inline-field>
    <inline-field :label="`${targetTypeLabel} Fund Code`">
      <bl-text-field-mv :value="targetFundCode" readonly />
    </inline-field>
    <inline-field label="Payment Date">
      <bl-text-field-mv :value="formatDate(transaction.payment_date)" readonly />
    </inline-field>
    <inline-field label="Transfer Type">
      <bl-text-field-mv :value="transaction.transfer_type" readonly />
    </inline-field>
    <inline-field label="SA Reference No.">
      <bl-text-field-mv :value="transaction.sa_reference" readonly />
    </inline-field>

    <div v-if="transaction.state === 'mismatched'" class="u-mrgn-top--6">
      <bl-button-av class="transaction-switching-form__approve-bib" color="red" @click="approveTransaction(false)">
        Approve BIB
      </bl-button-av>

      <bl-button-av class="transaction-switching-form__approve-sinvest" color="red" @click="approveTransaction(true)">
        Approve S-Invest
      </bl-button-av>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import InlineField from 'partner-admin/components/InlineField';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import { transactionTypeMap } from 'partner-admin/constant/transactionType';

export default {
  name: 'TransactionSwitchingFormBase',

  components: {
    InlineField,
    BlTextFieldMv,
    BlFieldHintAv,
    BlButtonAv,
  },

  props: {
    transaction: {
      type: Object,
      default: () => ({}),
    },
    targetTransaction: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    typeLabel() {
      return this.transaction.type === 'switching-in' ? 'Switch-In' : 'Switch-Out';
    },
    targetTypeLabel() {
      return this.transaction.type === 'switching-in' ? 'Switch-Out' : 'Switch-In';
    },

    agent() {
      return this.transaction.agent ? this.transaction.agent.name : '';
    },
    fundCode() {
      return this.transaction.product ? this.transaction.product.fund_code : '';
    },
    targetFundCode() {
      return this.targetTransaction.product ? this.targetTransaction.product.fund_code : '';
    },
    fundCurrency() {
      return this.transaction.product ? this.transaction.product.fund_ccy : '';
    },
  },

  methods: {
    formatMoney(amount) {
      return [null, undefined].includes(amount) ? '' : money(amount, undefined, '');
    },
    formatDecimal(amount) {
      return [null, undefined].includes(amount) ? '' : `${amount}`.replace('.', ',');
    },
    formatDate(date) {
      return date ? dayjs(date).format('DD MMMM YYYY') : '';
    },
    isDifferent(bibValue, sinvestValue, threshold = 0) {
      const isThresholdDifferent = threshold ? Number(Math.abs(sinvestValue - bibValue).toFixed(4)) > threshold : true;

      return ![undefined, null].includes(sinvestValue) && bibValue !== sinvestValue && isThresholdDifferent;
    },
    formatTransactionType(type) {
      return transactionTypeMap[type] || type;
    },

    approveTransaction(useSInvest) {
      this.$emit('approve', {
        id: this.transaction.id,
        use_sinvest: useSInvest,
      });
    },
  },
};
</script>
