<template>
  <div>
    <basic-field label="Nomor Identitas">
      <bl-text-field-mv class="old_identity_number" :value="oldData.identity_number" readonly />
      <bl-text-field-mv
        v-if="newData.identity_number"
        class="new_identity_number"
        :value="newData.identity_number"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="NPWP">
      <bl-text-field-mv class="old_npwp_number" :value="oldData.npwp_number" readonly />
      <bl-text-field-mv
        v-if="newData.npwp_number"
        class="new_npwp_number"
        :value="newData.npwp_number"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="IFUA (Investor Fund Unit Account)">
      <bl-text-field-mv class="old_ifua" :value="oldData.ifua" readonly />
      <bl-text-field-mv v-if="newData.ifua" class="new_ifua" :value="newData.ifua" readonly is-error />
    </basic-field>
    <basic-field label="SID (Single Investor Identification)">
      <bl-text-field-mv class="old_sid" :value="oldData.sid" readonly />
      <bl-text-field-mv v-if="newData.sid" class="new_sid" :value="newData.sid" readonly is-error />
    </basic-field>
    <basic-field label="Investor Type">
      <bl-text-field-mv class="old_investor_type" :value="oldData.investor_type" readonly />
      <bl-text-field-mv
        v-if="newData.investor_type"
        class="new_investor_type"
        :value="newData.investor_type"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Agent">
      <bl-text-field-mv class="old_agent" :value="oldData.agent && oldData.agent.name" readonly />
      <bl-text-field-mv
        v-if="newData.agent && newData.agent.name"
        class="new_agent"
        :value="newData.agent && newData.agent.name"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Nama">
      <bl-text-field-mv class="old_name" :value="oldData.name" readonly />
      <bl-text-field-mv v-if="newData.name" class="new_name" :value="newData.name" readonly is-error />
    </basic-field>
    <basic-field label="Tempat Lahir">
      <bl-text-field-mv class="old_birth_place" :value="oldData.birth_place" readonly />
      <bl-text-field-mv
        v-if="newData.birth_place"
        class="old_birth_place"
        :value="newData.birth_place"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Tanggal Lahir">
      <bl-text-field-mv class="old_birth_date" :value="oldData.birth_date" readonly />
      <bl-text-field-mv
        v-if="newData.birth_date"
        class="old_birth_date"
        :value="newData.birth_date"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Jenis Kelamin">
      <bl-text-field-mv class="old_gender" :value="oldData.gender" readonly />
      <bl-text-field-mv v-if="newData.gender" class="new_gender" :value="newData.gender" readonly is-error />
    </basic-field>
    <basic-field label="Provinsi">
      <bl-text-field-mv class="old_province" :value="oldData.province" readonly />
      <bl-text-field-mv v-if="newData.province" class="new_province" :value="newData.province" readonly is-error />
    </basic-field>
    <basic-field label="Kota/Kabupaten">
      <bl-text-field-mv class="old_city" :value="oldData.city" readonly />
      <bl-text-field-mv v-if="newData.city" class="new_city" :value="newData.city" readonly is-error />
    </basic-field>
    <basic-field label="Kecamatan">
      <bl-text-field-mv class="old_sub_district" :value="old_sub_district" readonly />
      <bl-text-field-mv v-if="sub_district" class="new_sub_district" :value="sub_district" readonly is-error />
    </basic-field>
    <basic-field label="Kel Desa">
      <bl-text-field-mv class="old_village" :value="old_village" readonly />
      <bl-text-field-mv v-if="village" class="new_village" :value="village" readonly is-error />
    </basic-field>
    <basic-field label="RT/RW">
      <bl-text-field-mv class="old_neighborhood" :value="old_neighborhood" readonly />
      <bl-text-field-mv v-if="neighborhood" class="new_neighborhood" :value="neighborhood" readonly is-error />
    </basic-field>
    <basic-field label="Alamat">
      <bl-text-field-mv class="old_address" :value="old_address" readonly />
      <bl-text-field-mv v-if="address" class="new_address" :value="address" readonly is-error />
    </basic-field>
    <basic-field label="Kode Pos">
      <bl-text-field-mv class="old_postal_code" :value="oldData.postal_code" readonly />
      <bl-text-field-mv
        v-if="newData.postal_code"
        class="new_postal_code"
        :value="newData.postal_code"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Provinsi">
      <bl-text-field-mv class="old_domicile_province" :value="oldData.domicile_province" readonly />
      <bl-text-field-mv
        v-if="newData.domicile_province"
        class="new_domicile_province"
        :value="newData.domicile_province"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Kota/Kabupaten">
      <bl-text-field-mv class="old_domicile_city" :value="oldData.domicile_city" readonly />
      <bl-text-field-mv
        v-if="newData.domicile_city"
        class="new_domicile_city"
        :value="newData.domicile_city"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Kecamatan">
      <bl-text-field-mv class="old_domicile_sub_district" :value="old_domicile_sub_district" readonly />
      <bl-text-field-mv
        v-if="domicile_sub_district"
        class="new_domicile_sub_district"
        :value="domicile_sub_district"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Kel Desa">
      <bl-text-field-mv class="old_domicile_village" :value="old_domicile_village" readonly />
      <bl-text-field-mv
        v-if="domicile_village"
        class="new_domicile_village"
        :value="domicile_village"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili RT/RW">
      <bl-text-field-mv class="old_domicile_neighborhood" :value="old_domicile_neighborhood" readonly />
      <bl-text-field-mv
        v-if="domicile_neighborhood"
        class="new_domicile_neighborhood"
        :value="domicile_neighborhood"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Alamat">
      <bl-text-field-mv class="old_domicile_address" :value="old_domicile_address" readonly />
      <bl-text-field-mv
        v-if="domicile_address"
        class="new_domicile_address"
        :value="domicile_address"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Domisili Kode Pos">
      <bl-text-field-mv class="old_domicile_postal_code" :value="oldData.domicile_postal_code" readonly />
      <bl-text-field-mv
        v-if="newData.domicile_postal_code"
        class="new_domicile_postal_code"
        :value="newData.domicile_postal_code"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Agama">
      <bl-text-field-mv class="old_religion" :value="oldData.religion" readonly />
      <bl-text-field-mv v-if="newData.religion" class="new_religion" :value="newData.religion" readonly is-error />
    </basic-field>
    <basic-field label="Status Perkawinan">
      <bl-text-field-mv class="old_marital_status" :value="oldData.marital_status" readonly />
      <bl-text-field-mv
        v-if="newData.marital_status"
        class="new_marital_status"
        :value="newData.marital_status"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Pekerjaan">
      <bl-text-field-mv class="old_occupation" :value="oldData.occupation" readonly />
      <bl-text-field-mv
        v-if="newData.occupation"
        class="new_occupation"
        :value="newData.occupation"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Education">
      <bl-text-field-mv class="old_education" :value="oldData.education" readonly />
      <bl-text-field-mv v-if="newData.education" class="new_education" :value="newData.education" readonly is-error />
    </basic-field>
    <basic-field label="Income Level">
      <bl-text-field-mv class="old_income_level" :value="oldData.income_level" readonly />
      <bl-text-field-mv
        v-if="newData.income_level"
        class="new_income_level"
        :value="newData.income_level"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Risk Profile">
      <bl-text-field-mv class="old_risk_profile" :value="oldData.risk_profile" readonly />
      <bl-text-field-mv
        v-if="newData.risk_profile"
        class="new_risk_profile"
        :value="newData.risk_profile"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Investment Objective">
      <bl-text-field-mv class="old_investment_objective" :value="oldData.investment_objective" readonly />
      <bl-text-field-mv
        v-if="newData.investment_objective"
        class="new_investment_objective"
        :value="newData.investment_objective"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Source of Fund">
      <bl-text-field-mv class="old_source_of_fund" :value="oldData.source_of_fund" readonly />
      <bl-text-field-mv
        v-if="newData.source_of_fund"
        class="new_source_of_fund"
        :value="newData.source_of_fund"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="E-mail">
      <bl-text-field-mv class="old_email" :value="oldData.email" readonly />
      <bl-text-field-mv v-if="newData.email" class="new_email" :value="newData.email" readonly is-error />
    </basic-field>
    <basic-field label="Beneficiary Owner">
      <bl-text-field-mv class="old_bo" :value="oldData.bo ? 'Yes' : 'No'" readonly />
      <bl-text-field-mv
        v-if="newData.bo !== undefined"
        class="new_bo"
        :value="newData.bo ? 'Yes' : 'No'"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Politically Exposed Persons">
      <bl-text-field-mv class="old_pep" :value="oldData.pep ? 'Yes' : 'No'" readonly />
      <bl-text-field-mv
        v-if="newData.pep !== undefined"
        class="new_pep"
        :value="newData.pep ? 'Yes' : 'No'"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Phone">
      <bl-text-field-mv class="old_phone" :value="oldData.phone" readonly />
      <bl-text-field-mv v-if="newData.phone" class="new_phone" :value="newData.phone" readonly is-error />
    </basic-field>
  </div>
</template>
<script>
import InvestorRevisesViewMixin from '../../mixins/InvestorRevisesViewMixin';

export default {
  name: 'InvestorRevisesViewData',
  mixins: [InvestorRevisesViewMixin],
  data() {
    return {
      address: null,
      neighborhood: null,
      village: null,
      sub_district: null,
      old_address: null,
      old_neighborhood: null,
      old_village: null,
      old_sub_district: null,
      domicile_address: null,
      domicile_neighborhood: null,
      domicile_village: null,
      domicile_sub_district: null,
      old_domicile_address: null,
      old_domicile_neighborhood: null,
      old_domicile_village: null,
      old_domicile_sub_district: null,
    };
  },
  created() {
    this.populateData();
  },
  methods: {
    populateData() {
      if (this.newData.address) {
        const [address, neighborhood, village, subDistrict] = this.newData.address.split(',').map(val => val.trim());
        this.address = address;
        this.neighborhood = neighborhood || null;
        this.village = village || null;
        this.sub_district = subDistrict || null;
      }

      if (this.newData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.newData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.domicile_address = address;
        this.domicile_neighborhood = neighborhood || null;
        this.domicile_village = village || null;
        this.domicile_sub_district = subDistrict || null;
      }

      if (this.oldData.address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.address.split(',').map(val => val.trim());
        this.old_address = address;
        this.old_neighborhood = neighborhood || null;
        this.old_village = village || null;
        this.old_sub_district = subDistrict || null;
      }

      if (this.oldData.domicile_address) {
        const [address, neighborhood, village, subDistrict] = this.oldData.domicile_address
          .split(',')
          .map(val => val.trim());
        this.old_domicile_address = address;
        this.old_domicile_neighborhood = neighborhood || null;
        this.old_domicile_village = village || null;
        this.old_domicile_sub_district = subDistrict || null;
      }
    },
  },
};
</script>
