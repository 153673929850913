<template>
  <div :class="getClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFieldErrorAv',
  status: 'ready',
  release: '0.4.0',
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      return ['c-fld__error', this.absolute ? 'c-fld__error--absolute' : ''];
    },
  },
};
</script>

<style src="./BlFieldErrorAv.style.scss" lang="scss"></style>
