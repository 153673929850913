<template>
  <div>
    <bl-table-av :zebra="zebra">
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="table-header"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loadState === 'loading'">
          <tr class="table-loading-row u-align-center">
            <td :colspan="headers.length + 1">
              <bl-spinner-av
                variant="red"
                size="large"
              />
            </td>
          </tr>
        </template>
        <template v-if="loadState === 'success'">
          <slot />
          <tr
            v-if="!rows.length"
            class="table-empty-row u-align-center"
          >
            <td :colspan="headers.length + 1">
              Tidak ada Data
            </td>
          </tr>
        </template>
        <template v-if="loadState === 'error'">
          <tr class="table-error-row u-align-center">
            <td :colspan="headers.length + 1">
              Gagal mendapatkan data, <a @click.prevent="reload">muat ulang</a>
            </td>
          </tr>
        </template>
      </tbody>
    </bl-table-av>

    <bl-pagination-mv
      v-if="totalRowData > limit"
      class="u-mrgn-top--4"
      :total="totalRowData"
      :limit="limit"
      :offset="offset"
      :max-links="10"
      @changePage="changePage"
    />
  </div>
</template>
<script>
import BlTableAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlTableAv';
import BlSpinnerAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlSpinnerAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';

export default {
  name: 'Table',
  components: { BlTableAv, BlPaginationMv, BlSpinnerAv },
  props: {
    loadState: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    totalRowData: {
      type: Number,
      default: 0,
    },
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 20,
    },
    zebra: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    changePage(payload) {
      this.$emit('changePage', payload);
    },
  },
};
</script>
