<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-8of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="productCode" placeholder="Kode Produk" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="productName" placeholder="Nama Produk" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-button-av class="o-layout__item u-2of12 u-mrgn-left--4" color="red" @click="searchProduct()">
              Search
            </bl-button-av>
          </div>
        </div>

        <div class="o-layout__item u-4of12 u-align-right">
          <router-link to="/products/drafts" class="c-btn c-btn--medium" tag="button">
            Task
          </router-link>
          <router-link
            v-if="isAllowed('product', 'create')"
            to="/products/drafts/add"
            class="c-btn c-btn--medium"
            tag="button"
          >
            Tambah Produk
          </router-link>
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="false"
        :table-header="['Nama Produk', 'Tipe Produk', 'Kode Produk', 'CCY', 'NAV', 'Perubahan Terakhir']"
        :table-data="tableData"
        :load-state="productsLoadState"
        :action-button="['view_list', 'edit', 'view_log']"
        :action-root-url="'/products'"
        @reload="retrieveProducts"
      />
      <bl-pagination-mv
        v-if="productsTotal > limit"
        class="u-mrgn-top--4"
        :total="productsTotal"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import Table from 'partner-admin/components/Table';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import dayjs from 'dayjs';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';

export default {
  name: 'ProductIndexPage',
  stateOptions: ['approved', 'rejected'],
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlTextFieldMv,
    BlButtonAv,
    BlPaginationMv,
  },
  mixins: [PolicyPageMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      productCode: '',
      productName: '',
      state: 'approved',
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      productsLoadState: 'getProductsLoadState',
      productsTotal: 'getProductsTotal',
    }),
    tableData() {
      return this.products.map(product => [
        product.id,
        product.fund_name,
        product.fund_type,
        product.fund_code,
        product.fund_ccy,
        money(product.initial_nav, undefined, ''),
        dayjs(product.updated_at).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB'),
      ]);
    },
    defaultPayload() {
      return { state: this.state, offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return { fund_code: this.productCode, fund_name: this.productName };
    },
  },
  methods: {
    ...mapActions(['retrieveProducts']),
    searchProduct() {
      this.offset = 0;
      this.getProducts();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getProducts();
    },
    getProducts() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveProducts(payload);
    },
    retrieveInitialData() {
      this.retrieveProducts(this.defaultPayload);
    },
  },
};
</script>
