import api from 'partner-admin/api';

export default {
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoadState', 'loading');
      api
        .getStaffsMe()
        .then(({ data }) => {
          commit('setUser', data);
          commit('setLoadState', 'success');
          resolve(data);
        })
        .catch(e => {
          commit('setLoadState', 'error');
          reject(e);
        });
    });
  },
  resetUser({ commit }) {
    commit('setUser', null);
    commit('setLoadState', 'idle');
  },
};
