<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-6of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-12of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="keywords" placeholder="NIK / SID / IFUA" multiline />
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-date-picker-mv
                  v-model="chosenDate"
                  class="u-width-1"
                  is-has-multiple-calendar
                  is-date-range-enabled
                  :begin-date="chosenDate.beginDate || currentDate"
                  :end-date="chosenDate.endDate"
                  multiple-calendar
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="waitingUpdate" placeholder="Waiting Update" :options="['', 'true', 'false']" />
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="identityNumber" placeholder="NIK" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="email" placeholder="Email" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="agentIds"
                  placeholder="Agent"
                  track-by="id"
                  label="name"
                  :options="agentOptions"
                  multiple
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="risk" :options="$options.riskOptions" placeholder="Risk" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-4of12">
              <bl-field-row-mv>
                <bl-button-av class="investor-index-page__search" color="red" @click="searchInvestor()">
                  Search
                </bl-button-av>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>
        <div class="o-layout__item u-6of12">
          <investor-index-action-buttons :state="state" :agent-options="agentOptions" />
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <investor-table
        :load-state="investorLoadState"
        :investors="investors"
        :total="investorsTotal"
        :limit="limit"
        :offset="offset"
        @reload="getInvestors"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'partner-admin/lib/vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import AgentOptionsMixin from 'partner-admin/mixins/agentOptionsMixin';
import dayjs from 'dayjs';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import { explode } from 'partner-admin/shared/helpers/stringHelper';
import InvestorIndexActionButtons from '../components/InvestorIndexActionButtons';
import InvestorTable from '../components/InvestorTable';

export default {
  name: 'InvestorIndexPage',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlTextFieldMv,
    BlButtonAv,
    BlDatePickerMv,
    InvestorIndexActionButtons,
    InvestorTable,
  },
  riskOptions: ['', 'high', 'low'],
  stateOptions: [
    '',
    'created',
    'applied',
    'processed',
    'approved',
    'sid_approved',
    'ifua_approved',
    'rejected',
    'sinvest_rejected',
    'admin_rejected',
  ],
  mixins: [PolicyPageMixin, AgentOptionsMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      chosenDate: {},
      currentDate,
      state: '',
      offset: 0,
      limit: 10,
      waitingUpdate: '',
      email: '',
      identityNumber: '',
      agentIds: '',
      risk: '',
      keywords: '', // SID, IFUA, NIK
    };
  },
  computed: {
    ...mapGetters({
      investors: 'getInvestors',
      investorLoadState: 'getInvestorsLoadState',
      investorsTotal: 'getInvestorsTotal',
    }),
    defaultPayload() {
      return { offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return {
        'keywords[]': explode(this.keywords),
        state: this.state,
        identity_number: this.identityNumber,
        email: this.email,
        waiting_update: this.waitingUpdate ? this.waitingUpdate === 'true' : '',
        start_date: this.chosenDate.beginDate ? dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD') : '',
        end_date: this.chosenDate.endDate ? dayjs(this.chosenDate.endDate).format('YYYY-MM-DD') : '',
        'agent_id[]': this.agentIds ? this.agentIds.map(agent => agent.id) : undefined,
        risk: this.risk,
      };
    },
  },
  methods: {
    ...mapActions(['retrieveInvestors']),
    searchInvestor() {
      this.offset = 0;
      this.getInvestors();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getInvestors();
    },
    getInvestors() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveInvestors(payload);
    },
    retrieveInitialData() {
      this.retrieveInvestors(this.defaultPayload);
      this.retrieveAgentOptions();
    },
  },
};
</script>
