<template>
  <tr>
    <td class="upload-file-management-table-row__date-upload">
      {{ humanizeDate(file.created_at) }}
    </td>
    <td class="upload-file-management-table-row__time-upload">
      {{ humanizeTime(file.created_at) }}
    </td>
    <td class="upload-file-management-table-row__type">
      {{ file.action }}
    </td>
    <td class="upload-file-management-table-row__filename">
      <a v-if="allowedByPolicy" @click.prevent="downloadFile"> {{ file.file_name }} </a>
      <span v-else> {{ file.file_name }} </span>
      <file-downloader ref="FileDownloader" />
    </td>
    <td class="upload-file-management-table-row__state">
      {{ file.state }}
    </td>
    <td>
      <button
        v-if="file.notes"
        class="c-btn c-btn--medium upload-file-management-table-row__notes-button"
        @click="openNotes"
      >
        Notes
      </button>
      <button
        v-if="allowedRetry"
        class="c-btn c-btn--medium upload-file-management-table-row__retry-button"
        @click="retry"
      >
        Retry
      </button>
      <button
        v-if="allowedByPolicy"
        class="c-btn c-btn--medium upload-file-management-table-row__history-button"
        @click="gotoHistory"
      >
        Riwayat
      </button>
    </td>
  </tr>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import dayjs from 'dayjs';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import queryString from 'query-string';
import FileDownloader from 'partner-admin/components/FileDownloader';

export default {
  name: 'UploadFileManagementTableRow',
  components: { FileDownloader },
  mixins: [PolicyMixin],
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    allowedByPolicy() {
      return (
        {
          investor_sinvest_sid: this.isAllowed('investor', 'upload_sinvest'),
          investor_sinvest_ifua: this.isAllowed('investor', 'upload_sinvest'),
          nav_sinvest: this.isAllowed('nav', 'upload_sinvest'),
          transaction_recon: this.isAllowed('transaction', 'upload'),
          transaction_sinvest_status: this.isAllowed('transaction', 'upload_sinvest'),
          transaction_sinvest_unit: this.isAllowed('transaction', 'upload_sinvest'),
          transaction_sinvest_status_switching: this.isAllowed('transaction', 'upload_sinvest'),
          investor_sinvest_revise_data: this.isAllowed('investor_revise', 'upload_sinvest'),
          investor_sinvest_revise_bank: this.isAllowed('investor_revise', 'upload_sinvest'),
        }[this.file.action] || false
      );
    },
    allowedRetry() {
      return this.file.state === 'uncompleted' && this.allowedByPolicy;
    },
  },
  methods: {
    humanizeDate(date) {
      return dayjs(date).format('DD MMMM YYYY');
    },
    humanizeTime(time) {
      return dayjs(time).format('HH:mm:ss');
    },
    retry() {
      this.$emit('retry', { id: this.file.id });
    },
    gotoHistory() {
      this.$router.push({
        name: 'fileManagementHistory',
        params: {
          id: this.file.id,
        },
        query: {
          type: 'upload_file',
        },
      });
    },
    downloadFile() {
      const token = this.tokenObj.token;
      const payload = {
        access_token: token,
      };
      const url = new URL(
        `${process.env.PARTNER_API_URL}/_exclusive/upload-files/${this.file.id}/download?${queryString.stringify(
          payload,
        )}`,
      );

      this.$refs.FileDownloader.download(`${url}`);
    },
    openNotes() {
      this.$emit('openNotes', this.file.notes);
    },
  },
};
</script>
