<template>
  <Table
    :load-state="loadState"
    :rows="reports"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <report-table-row v-for="(report, index) in reports" :key="index" :report="report" @refresh="refresh" />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import ReportTableRow from './ReportTableRow';

export default {
  name: 'ReportTable',
  components: {
    ReportTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Tanggal Report', 'Nama File', 'Action'],
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
