<template>
  <bl-dialog-mv :active.sync="confirmationDialog" title="Konfirmasi">
    <span v-show="!identityInconsistenciesText"> Apa kamu yakin akan approve investor ini? </span>
    <span v-show="identityInconsistenciesText">
      Ada ketidaksesuaian antara NIK dengan {{ identityInconsistenciesText }} user. Apakah data yang dimasukkan sudah
      benar?
    </span>
    <bl-button-group-mv is-equal class="u-mrgn-top--4">
      <bl-button-av color="red" style="display: inline-block;" @click.native="emitApprove">
        Setuju
      </bl-button-av>
      <bl-button-av style="display: inline-block;" @click.native="confirmationDialog = false">
        Tolak
      </bl-button-av>
    </bl-button-group-mv>
  </bl-dialog-mv>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import { checkBirthDate, checkGender, checkProvince } from './helper';

export default {
  name: 'NikCheckerDialog',
  components: { BlDialogMv, BlButtonGroupMv, BlButtonAv },
  data() {
    return {
      inconsistencies: [],
      confirmationDialog: false,
      payload: {},
    };
  },
  computed: {
    identityInconsistenciesText() {
      if (this.inconsistencies.length > 2) {
        const inconsistencies = this.inconsistencies;
        const last = inconsistencies.pop();
        return `${inconsistencies.join(', ')} dan ${last}`;
      }
      return this.inconsistencies.join(' dan ');
    },
  },
  methods: {
    emitApprove() {
      this.confirmationDialog = false;
      this.$emit('approve', this.payload);
    },
    checkIdentitiyCombination(payload) {
      return new Promise(resolve => {
        this.payload = payload;
        this.inconsistencies = [];
        const nik = payload.identity_number;

        if (!checkBirthDate(nik, payload.birth_date)) {
          this.inconsistencies.push('tanggal lahir');
        }

        if (!checkGender(nik, payload.gender)) {
          this.inconsistencies.push('gender');
        }

        if (!checkProvince(nik, payload.province)) {
          this.inconsistencies.push('provinsi tempat tinggal');
        }

        if (!this.inconsistencies.length) {
          resolve(payload);
        } else {
          this.confirmationDialog = true;
        }
      });
    },
  },
};
</script>
