<template>
  <bl-dialog-mv :active.sync="showEditForm" title="Edit">
    <div>
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-field-label-av> Update Date </bl-field-label-av>
          <div>{{ $options.currentDate | humanizeDate }}</div>
        </bl-field-row-mv>
        <bl-field-row-mv class="u-mrgn-top--2">
          <bl-field-label-av> USD Rate </bl-field-label-av>
          <bl-text-field-mv v-model="usdRate" type="number" />
        </bl-field-row-mv>
        <bl-field-row-mv class="u-mrgn-top--2">
          <bl-field-label-av> EUR Rate </bl-field-label-av>
          <bl-text-field-mv v-model="eurRate" type="number" />
        </bl-field-row-mv>
        <bl-field-hint-av>USD Rate and EUR Rate are required</bl-field-hint-av>
      </bl-field-mv>
    </div>
    <div>
      <bl-button-av class="exchange-rate-edit-form__proceed" color="red" @click="proceed">
        Save
      </bl-button-av>
    </div>
  </bl-dialog-mv>
</template>

<script>
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import dayjs from 'dayjs';

export default {
  name: 'ExchangeRateEditForm',
  currentDate: new Date().setHours(0, 0, 0, 0),
  filters: {
    humanizeDate(value) {
      return dayjs(value).format('DD MMMM YYYY');
    },
  },
  components: {
    BlDialogMv,
    BlButtonAv,
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlFieldLabelAv,
    BlFieldHintAv,
  },
  data() {
    return {
      showEditForm: false,
      usdRate: '',
      eurRate: '',
    };
  },
  methods: {
    openEditForm() {
      this.resetForm();
      this.showEditForm = true;
    },
    proceed() {
      if (!this.usdRate || !this.eurRate) {
        return;
      }

      this.$emit('proceed', {
        usdRate: Number(this.usdRate),
        eurRate: Number(this.eurRate),
      });
    },
    closeEditForm() {
      this.showEditForm = false;
    },
    resetForm() {
      this.usdRate = '';
      this.eurRate = '';
    },
  },
};
</script>
