<template>
  <div>
    <div class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Lihat Transaksi
      </h1>
    </div>

    <div v-if="transaction" class="c-tab__content__body">
      <transaction-switching-form-container
        v-if="isSwitching"
        :transaction="transaction"
        @reject="handleRejection"
        @approve="handleApproval"
      />
      <transaction-form
        v-else
        :transaction="transaction"
        @reject="handleRejection"
        @approve="handleApproval"
        @approveProofFile="handleApproveProofFile"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import TransactionForm from '../components/TransactionForm';
import TransactionSwitchingFormContainer from '../components/TransactionSwitchingFormContainer';

export default {
  name: 'TransactionsViewPage',
  components: { TransactionForm, TransactionSwitchingFormContainer },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      transactionId: Number(this.$route.params.id),
      transaction: null,
    };
  },
  computed: {
    isSwitching() {
      if (this.transaction) {
        return ['switching-in', 'switching-out'].includes(this.transaction.type);
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['retrieveTransaction']),
    retrieveInitialData() {
      this.retrieveTransaction(this.transactionId)
        .then(trx => {
          this.transaction = trx;
        })
        .catch(this.showErrorNotif);
    },
    handleRejection(payload) {
      this.$api
        .patchTransaction({
          id: payload.id,
          state: 'rejected',
          rejection_reason: payload.rejection_reason,
        })
        .then(() => {
          this.showSuccessNotif('Berhasil reject transaction');
          this.$router.push({
            name: 'transactionsIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
    handleApproval(payload) {
      this.$api
        .patchTransaction({
          id: payload.id,
          state: 'approved',
          use_sinvest: payload.use_sinvest,
        })
        .then(() => {
          this.showSuccessNotif('Berhasil approve transaction');
          this.$router.push({
            name: 'transactionsIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
    handleApproveProofFile(payload) {
      const successText = {
        proof_approve: 'approve',
        proof_reject: 'reject',
      }[payload.state];

      this.$api
        .patchTransaction({
          id: payload.id,
          state: payload.state,
        })
        .then(() => {
          this.showSuccessNotif(`Berhasil ${successText} file bukti transaksi`);
          this.$router.push({
            name: 'transactionsIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
