// import { createKeyValueCombination as createOptions } from 'shared/helpers/arrayHelper';

export const TransactionFields = [
  {
    name: 'created_at',
    label: 'Transaction Date',
    type: 'date',
  },
  {
    name: 'type',
    label: 'Transaction Type',
    type: 'text',
  },
  {
    name: 'reference_id',
    label: 'Reference No',
    type: 'text',
  },
  {
    name: 'ifua',
    label: 'Investor Fund Unit A/C No.',
    type: 'text',
  },
  {
    name: 'agent',
    label: 'Agent',
    type: 'text',
  },
  {
    name: 'fund_currency',
    label: 'CCY',
    type: 'text',
  },
  {
    name: 'fund_code',
    label: 'Fund Code',
    type: 'text',
  },
  {
    name: 'gross_nominal',
    label: 'Gross Amount (Nominal)',
    type: 'number',
    format: 'moneyWithoutCurrency',
  },
  {
    name: 'net_nominal',
    label: 'Net Amount (Nominal)',
    type: 'number',
    format: 'moneyWithoutCurrency',
  },
  {
    name: 'nominal',
    label: 'Amount (Nominal)',
    type: 'number',
    format: 'moneyWithoutCurrency',
  },
  {
    name: 'unit',
    label: 'Amount (Unit)',
    type: 'number',
    format: 'decimal',
  },
  {
    name: 'nav_value',
    label: 'NAV Value',
    type: 'number',
    format: 'decimal',
  },
  {
    name: 'nav_date',
    label: 'NAV Date',
    type: 'date',
  },
  {
    name: 'fee',
    label: 'Fee (Nominal)',
    type: 'number',
    format: 'moneyWithoutCurrency',
  },
  {
    name: 'all_unit',
    label: 'Amount (All Units)',
    type: 'select',
    options: [
      { value: true, label: 'Ya' },
      { value: false, label: 'Tidak' },
    ],
  },
  {
    name: 'bi_code',
    label: 'REDM Payment Bank BI Member Code',
    type: 'text',
  },
  {
    name: 'payment_no',
    label: 'REDM Payment A/C No,',
    type: 'text',
  },
  {
    name: 'payment_date',
    label: 'Payment Date',
    type: 'date',
  },
  {
    name: 'transfer_type',
    label: 'Transfer Type',
    type: 'text',
  },
  {
    name: 'sa_reference',
    label: 'SA Reference No.',
    type: 'text',
  },
  {
    name: 'payment_proof',
    label: 'Payment Proof Image',
    type: 'file',
    format: 'identity-image', // for v-viewer capability
  },
  {
    name: 'rejection_reason',
    label: 'Rejection Reason',
    type: 'text',
    multiline: true,
    rule: {
      required: true,
    },
  },
];

export const dummyTransaction = {
  created_at: '1999-04-05',
  type: 'subscription',
  reference_id: '#SINVEST_ID',
  ifua: '#IFUA',
  fund_code: '#FUND_CODE',
  nominal: 20000,
  sinvest_nominal: 20001,
  unit: 15,
  sinvest_unit: 15.1,
  all_unit: 0,
  fee_nominal: 1,
  bi_code: '#BI_CODE',
  payment_no: '#PAYMENT_NO',
  payment_date: '1999-04-08',
  transfer_type: 'manual',
  sa_reference: '#SA_REFERENCE',
};

export default {};
