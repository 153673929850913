<template>
  <div>
    <bl-table-av zebra>
      <thead>
        <tr>
          <th
            v-for="(header, index) in tableHeader"
            :key="index"
            class="table-header"
          >
            {{ header }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <template v-if="loadState === 'loading'">
          <tr class="table-loading-row u-align-center">
            <td :colspan="tableHeader.length + 1">
              <bl-spinner-av
                variant="red"
                size="large"
              />
            </td>
          </tr>
        </template>
        <template v-if="loadState === 'success'">
          <tr
            v-if="!tableData.length"
            class="table-empty-row u-align-center"
          >
            <td :colspan="tableHeader.length + 1">
              Tidak ada Data
            </td>
          </tr>
          <component
            :is="rowComponent"
            v-for="(rowDatum, index) in tableData"
            v-else
            :key="index"
            :row-data="rowDatum"
            :is-draft="isDraft"
            :action-button="actionButton"
            :action-root-url="actionRootUrl"
            @retry="retry"
          />
        </template>
        <template v-if="loadState === 'error'">
          <tr class="table-error-row u-align-center">
            <td :colspan="tableHeader.length + 1">
              Gagal mendapatkan data, <a @click.prevent="reload">muat ulang</a>
            </td>
          </tr>
        </template>
      </tbody>
    </bl-table-av>
  </div>
</template>

<script>
import BlSpinnerAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlSpinnerAv';
import BlTableAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlTableAv';
import TableRowData from './TableRowData';
import FileManagementRow from './types/FileManagementRow';

export default {
  name: 'Table',
  components: {
    BlTableAv,
    BlSpinnerAv,
    TableRowData,
    FileManagementRow,
  },
  props: {
    isDraft: {
      type: Boolean,
      default: false,
    },
    tableHeader: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    loadState: {
      type: String,
      required: true,
    },
    actionButton: {
      type: Array,
      default: () => [],
    },
    actionRootUrl: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rowComponent() {
      return (
        {
          file_management: 'FileManagementRow',
        }[this.type] || 'TableRowData'
      );
    },
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    retry(payload) {
      this.$emit('retry', payload);
    },
  },
};
</script>
