<template>
  <tr>
    <td class="report-row__created-date">
      {{ report.created_at | humanizeDate }}
    </td>
    <td class="report-row__file-name">
      {{ report.file_name }}
    </td>
    <td>
      <bl-button-av class="report-row__download" size="tiny" @click.native="download">
        Download
      </bl-button-av>
      <file-downloader ref="FileDownloader" />
    </td>
  </tr>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';
import queryString from 'query-string';
import FileDownloader from 'partner-admin/components/FileDownloader';

const endpoint = `${process.env.PARTNER_API_URL}/_exclusive/aria-reports`;

export default {
  name: 'ReportTableRow',
  filters: {
    humanizeDate(value) {
      if (value) return dayjs(value).format('DD MMMM YYYY');
      return '-';
    },
  },
  components: { BlButtonAv, FileDownloader },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
  },
  methods: {
    download() {
      const payload = {
        access_token: this.tokenObj.token,
      };

      const url = new URL(`${endpoint}/${this.report.id}/download?${queryString.stringify(payload)}`);

      this.$refs.FileDownloader.download(`${url}`);
    },
  },
};
</script>
