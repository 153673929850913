import apiInterface from 'partner-admin/shared/helpers/apiInterface';

const host = process.env.PARTNER_API_URL;
const EXCLUSIVE_URL = `${host}/_exclusive`;

const endpoints = {
  products: `${EXCLUSIVE_URL}/products`,
  drafts: `${EXCLUSIVE_URL}/drafts`,
  bulkDrafts: `${EXCLUSIVE_URL}/drafts/bulk-update/status`,
  users: `${host}users`,
  histories: `${EXCLUSIVE_URL}/histories`,
  staffs: `${EXCLUSIVE_URL}/staffs`,
  staffsMe: `${EXCLUSIVE_URL}/staffs/me`,
  staffSummaries: `${EXCLUSIVE_URL}/staffs/summaries`,
  policies: `${EXCLUSIVE_URL}/policies`,
  investors: `${EXCLUSIVE_URL}/investors`,
  registrationAttributes: `${EXCLUSIVE_URL}/investors/registration-attributes`,
  bankMappings: `${EXCLUSIVE_URL}/investors/bank-mappings`,
  checkNIK: `${EXCLUSIVE_URL}/investors/nik-infos`,
  rejectionReasons: `${EXCLUSIVE_URL}/investors/rejection-reasons`,
  investorRevisesRejectionReasons: `${EXCLUSIVE_URL}/investors/revisions/rejection-reasons`,
  upload: `${EXCLUSIVE_URL}/investors/upload`,
  investorRevises: `${EXCLUSIVE_URL}/investors/revisions`,
  transactions: `${EXCLUSIVE_URL}/transactions`,
  transactionFileUpload: `${EXCLUSIVE_URL}/transactions/upload`,
  navs: `${EXCLUSIVE_URL}/navs`,
  navsFileUpload: `${EXCLUSIVE_URL}/navs/upload`,
  transactionsFiles: `${EXCLUSIVE_URL}/transactions/files`,
  portfolios: `${EXCLUSIVE_URL}/portfolios`,
  uploadFiles: `${EXCLUSIVE_URL}/upload-files`,
  configs: `${EXCLUSIVE_URL}/configs`,
  bundleDocuments: `${EXCLUSIVE_URL}/bundle-documents`,
  changePassword: `${EXCLUSIVE_URL}/users/passwords`,
  reports: `${EXCLUSIVE_URL}/aria-reports`,

  portfolioSummariesByInvestor: `${EXCLUSIVE_URL}/portfolios/summaries`,

  // AML Reports
  amlReportsConfigurations: `${EXCLUSIVE_URL}/aml-reports/configurations`,
  amlReportsConfigurationsHistories: `${EXCLUSIVE_URL}/aml-reports/configurations/histories`,

  // Agents
  agents: `${EXCLUSIVE_URL}/agents`,

  // Exclusive Users
  exclusiveUsers: `${EXCLUSIVE_URL}/users`,

  // Exchange Rate
  exchangeRates: `${EXCLUSIVE_URL}/exchange-rates`,
};

const generateInterface = apiInterface.createGenerator('bib-partner', endpoints);

const interfaces = {
  getProduct: generateInterface('get', 'products', { withId: true }),
  getProducts: generateInterface('get', 'products', { withData: true }),
  getDraft: generateInterface('get', 'drafts', { withId: true }),
  getDrafts: generateInterface('get', 'drafts', { withData: true }),
  patchDraftStatus: generateInterface('patch', 'drafts', {
    withId: true,
    withData: true,
    appendUrl: 'status',
  }),
  patchBulkDraftStatus: generateInterface('patch', 'bulkDrafts', { withData: true }),
  getUserProfile: generateInterface('get', 'users', { withId: true }),
  postProducts: generateInterface('post', 'products', { withData: true }),
  putProducts: generateInterface('put', 'products', { withId: true, withData: true }),
  getHistories: generateInterface('get', 'histories', {
    customSegments: true,
    withData: true,
  }),
  getStaffs: generateInterface('get', 'staffs', { withData: true }),
  getStaff: generateInterface('get', 'staffs', { withId: true }),
  getStaffsMe: generateInterface('get', 'staffsMe'),
  getStaffSummaries: generateInterface('get', 'staffSummaries'),
  postStaffs: generateInterface('post', 'staffs', { withData: true }),
  patchStaff: generateInterface('patch', 'staffs', { withId: true, withData: true }),
  patchStaffStatus: generateInterface('patch', 'staffs', { withId: true, withData: true, appendUrl: 'status' }),
  putStaff: generateInterface('put', 'staffs', { withId: true, withData: true }),
  getPolicies: generateInterface('get', 'policies'),
  getInvestor: generateInterface('get', 'investors', { withId: true }),
  getInvestors: generateInterface('get', 'investors', { withData: true }),
  putInvestorRegister: generateInterface('put', 'investors', {
    withId: true,
    withData: true,
    appendUrl: 'register',
  }),
  putInvestorRevise: generateInterface('put', 'investors', {
    withId: true,
    withData: true,
    appendUrl: 'revise',
  }),
  getInvestorRevise: generateInterface('get', 'investorRevises', {
    withId: true,
  }),
  getInvestorRevises: generateInterface('get', 'investorRevises', {
    withData: true,
  }),
  patchInvestorRevisesStatus: generateInterface('patch', 'investorRevises', {
    withId: true,
    withData: true,
    appendUrl: 'status',
  }),
  getRegistrationAttributes: generateInterface('get', 'registrationAttributes'),
  getBankMappings: generateInterface('get', 'bankMappings'),
  getIdentityData: generateInterface('get', 'checkNIK', { withData: true }),
  getRejectionsReasons: generateInterface('get', 'rejectionReasons'),
  getRevisionRejectionsReasons: generateInterface('get', 'investorRevisesRejectionReasons'),
  uploadInvestorFile: generateInterface('post', 'upload', { withData: true }),
  getTransactions: generateInterface('get', 'transactions', { withData: true }),
  getTransaction: generateInterface('get', 'transactions', { withId: true }),
  uploadTransactionFile: generateInterface('post', 'transactionFileUpload', { withData: true }),
  uploadNavFile: generateInterface('post', 'navsFileUpload', { withData: true }),
  getPortfolios: generateInterface('get', 'portfolios', { withData: true }),
  getPortfolio: generateInterface('get', 'portfolios', { withId: true, withData: true }),
  getPortfolioSummariesByInvestor: generateInterface('get', 'portfolioSummariesByInvestor', { withData: true }),
  getNavs: generateInterface('get', 'navs', { withData: true }),
  getNav: generateInterface('get', 'navs', { withId: true }),
  getUploadFiles: generateInterface('get', 'uploadFiles', { withData: true }),
  patchUploadFiles: generateInterface('patch', 'uploadFiles', {
    withId: true,
    withData: true,
    appendUrl: 'status',
  }),
  patchTransaction: generateInterface('patch', 'transactions', {
    withId: true,
    withData: true,
    appendUrl: 'status',
  }),
  getConfigs: generateInterface('get', 'configs'),

  // Bundle document
  getBundleDocuments: generateInterface('get', 'bundleDocuments', { withData: true }),
  getBundleDocument: generateInterface('get', 'bundleDocuments', { withId: true }),

  changePassword: generateInterface('put', 'changePassword', { withData: true }),

  // Aria Reports
  getReportList: generateInterface('get', 'reports', { withData: true }),

  // AML Reports
  getAmlReportsConfigurations: generateInterface('get', 'amlReportsConfigurations'),
  putAmlReportsConfigurations: generateInterface('put', 'amlReportsConfigurations', { withData: true }),
  getAmlReportsConfigurationsHistories: generateInterface('get', 'amlReportsConfigurationsHistories', {
    withData: true,
  }),

  // Agents
  getAgents: generateInterface('get', 'agents', { withData: true }),
  getAgent: generateInterface('get', 'agents', { withId: true }),
  postAgents: generateInterface('post', 'agents', { withData: true }),

  // Exclusive Users
  getExclusiveUsers: generateInterface('get', 'exclusiveUsers', { withData: true }),
  postForgotPassword: generateInterface('post', 'exclusiveUsers', {
    withId: true,
    appendUrl: 'forgot-password',
  }),

  // Exchange Rates
  getExchangeRates: generateInterface('get', 'exchangeRates'),
  patchExchangeRates: generateInterface('patch', 'exchangeRates', { withData: true }),
};

export default interfaces;
