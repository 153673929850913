<template>
  <div>
    <h1 class="u-txt--bold u-mrgn-bottom--1">
      {{ title }}
    </h1>
    <basic-field label="Bank Name">
      <bl-text-field-mv class="old_bank_name" :value="oldData.bank_name" readonly />
      <bl-text-field-mv v-if="newData.bank_name" class="new_bank_name" :value="newData.bank_name" readonly is-error />
    </basic-field>
    <basic-field label="Bank Branch">
      <bl-text-field-mv class="old_bank_branch" :value="oldData.bank_branch" readonly />
      <bl-text-field-mv
        v-if="newData.bank_branch"
        class="new_bank_branch"
        :value="newData.bank_branch"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account Currency">
      <bl-text-field-mv class="old_account_ccy" :value="oldData.account_ccy" readonly />
      <bl-text-field-mv
        v-if="newData.account_ccy"
        class="new_account_ccy"
        :value="newData.account_ccy"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account No.">
      <bl-text-field-mv class="old_account_no" :value="oldData.account_no" readonly />
      <bl-text-field-mv
        v-if="newData.account_no"
        class="new_account_no"
        :value="newData.account_no"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account Name">
      <bl-text-field-mv class="old_account_name" :value="oldData.account_name" readonly />
      <bl-text-field-mv
        v-if="isEditFieldShown('account_name')"
        v-model="account_name"
        v-validate="fieldRules.account_name"
        :is-error="isInputStyleError('account_name')"
        class="new_account_name"
        name="account_name"
      />
      <bl-field-error-av v-if="fieldErrorMessage('account_name')">
        {{ fieldErrorMessage('account_name', 'Bank Account Name') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Bank Valid">
      <bl-text-field-mv class="old_account_valid" :value="oldData.account_valid ? 'Yes' : 'No'" readonly />
      <bl-dropdown-av
        v-if="isEditFieldShown('account_valid')"
        v-model="account_valid"
        v-validate="fieldRules.account_valid"
        :options="$options.BooleanDropdownOptions"
        :is-error="isInputStyleError('account_valid')"
        track-by="value"
        label="label"
        class="new_account_valid"
        name="account_valid"
      />
      <bl-field-error-av v-if="fieldErrorMessage('account_valid')">
        {{ fieldErrorMessage('account_valid', 'Bank Valid') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Bank Account Name (system)">
      <bl-text-field-mv class="old_checked_account_name" :value="oldData.checked_account_name" readonly />
      <bl-text-field-mv
        v-if="newData.checked_account_name"
        class="new_checked_account_name"
        :value="newData.checked_account_name"
        readonly
        is-error
      />
    </basic-field>
  </div>
</template>
<script>
import InvestorRevisesEditMixin from '../../mixins/InvestorRevisesEditMixin';

export default {
  name: 'InvestorReviseEditBank',
  editableFields: ['account_name', 'account_valid'],
  mixins: [InvestorRevisesEditMixin],
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
    };
  },
  created() {
    this.populateData();
  },
  methods: {
    populateData() {
      this.$options.editableFields.forEach(field => {
        if (!['account_valid'].includes(field)) {
          this[field] = this.newData[field];
        }
      });

      const newAccountValid = this.$options.BooleanDropdownOptions.find(
        opt => opt.value === this.newData.account_valid,
      );

      if (this.newData.account_valid !== undefined && newAccountValid) {
        this.account_valid = newAccountValid;
      }
    },
    getPayload() {
      if (!this.isFormEditable) {
        return null;
      }

      return {
        investor_bank_id: this.newData.investor_bank_id || this.oldData.investor_bank_id,
        bank_name: this.newData.bank_name || this.oldData.bank_name,
        bank_branch: this.newData.bank_branch || this.oldData.bank_branch,
        account_no: this.newData.account_no || this.oldData.account_no,
        account_name: this.account_name || this.oldData.account_name,
        account_valid: this.account_valid === null ? null : this.account_valid.value,
        checked_account_name: this.newData.checked_account_name || this.oldData.checked_account_name,
        account_ccy: this.newData.account_ccy || this.oldData.account_ccy,
      };
    },
  },
};
</script>
