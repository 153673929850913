import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import populateDraft from 'partner-admin/helpers/populateDraft';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      draft: null,
      investor: null,
      loadState: 'idle',
    };
  },
  methods: {
    retrieveInitialData() {
      this.getDraft()
        .then(this.getInvestor)
        .then(this.populateDraftWithOriginalData)
        .then(this.validatePageAccess)
        .then(this.renderForm)
        .catch(e => {
          this.loadState = 'error';
          this.showErrorNotif(e);
        });
    },
    getDraft() {
      return new Promise((resolve, reject) => {
        const draftId = this.$route.params.id;
        if (draftId) {
          this.loadState = 'loading';
          this.$api
            .getDraft(draftId)
            .then(({ data }) => {
              this.draft = data;
              resolve();
            })
            .catch(reject);
        }
      });
    },
    getInvestor() {
      return new Promise((resolve, reject) => {
        const investorId = this.draft.record_id;
        if (investorId) {
          this.$api
            .getInvestor(investorId)
            .then(({ data }) => {
              this.investor = data;
              resolve();
            })
            .catch(reject);
        } else {
          resolve();
        }
      });
    },
    populateDraftWithOriginalData() {
      return new Promise(resolve => {
        this.draft = { ...this.draft, data: populateDraft(this.draft.data, this.investor) };
        resolve();
      });
    },
    validatePageAccess() {
      return new Promise(resolve => {
        let allowedPage;
        if (
          this.draft.state === 'created' &&
          ['created', 'sinvest_rejected'].includes(this.investor.state) &&
          this.isAllowed('investor', 'edit')
        ) {
          allowedPage = 'investorDraftKyc';
        }
        if (this.draft.state === 'submitted' && this.isAllowed('investor', 'approve_reject')) {
          allowedPage = 'investorDraftReview';
        }
        if (allowedPage && this.$route.name !== allowedPage) {
          this.$router.replace({ name: allowedPage });
        }
        resolve();
      });
    },
    renderForm() {
      this.loadState = 'success';
    },
  },
};
