<template>
  <router-link :to="to" class="c-tab__list" tag="li" active-class="is-active">
    <a class="c-tab__link">
      <slot />
    </a>
  </router-link>
</template>

<script>
/* istanbul ignore file */
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>
