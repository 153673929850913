<template>
  <div>
    <div class="c-tab__content__head">
      <router-link :to="`/portofolios`">
        &lt; Kembali Ke Halaman Daftar Portofolio User
      </router-link>

      <user-detail-info class="u-mrgn-top--2" mode="productPortofolios" :portofolios="portofoliosData" />

      <div class="u-txt--bold">
        <div class="u-mrgn-top--2">
          {{ productName }}
        </div>
        <div class="u-mrgn-top--2">Balance: {{ currentUnitData }}</div>
      </div>

      <div class="o-layout o-layout--responsive u-mrgn-top--2">
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-date-picker-mv
              v-model="chosenDate"
              class="u-width-1"
              is-has-multiple-calendar
              is-date-range-enabled
              :begin-date="chosenDate.beginDate || currentDate"
              :end-date="chosenDate.endDate"
              multiple-calendar
            />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-dropdown-av
              v-model="type"
              placeholder="Type"
              :options="$options.typeOptions"
              track-by="code"
              label="label"
            />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-button-av color="red" @click="searchPortfolio">
              Search
            </bl-button-av>
          </bl-field-row-mv>
        </bl-field-mv>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="false"
        :table-header="['Date', 'Type', 'Trx Number', 'Unit', 'Unit Awal', 'Unit Akhir']"
        :table-data="tableData"
        :load-state="productPortfoliosLoadState"
        @reload="retrievePortfolio"
      />
      <bl-pagination-mv
        v-if="productPortfoliosTotal > limit"
        class="u-mrgn-top--4"
        :total="productPortfoliosTotal"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import Table from 'partner-admin/components/Table';
import { delimiter } from 'partner-admin/shared/helpers/stringHelper';
import dayjs from 'dayjs';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import { transactionTypeMap, transactionTypeOptions } from 'partner-admin/constant/transactionType';
import UserDetailInfo from '../components/UserDetailInfo';

export default {
  name: 'PortofoliosProductViewPage',
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    BlPaginationMv,
    UserDetailInfo,
    BlDropdownAv,
    BlDatePickerMv,
  },
  typeOptions: [{ code: '', label: 'All Type' }, ...transactionTypeOptions],
  mixins: [PolicyMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      type: '',
      currentDate,
      chosenDate: {},
      offset: 0,
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      productPortfolios: 'getProductPortfolios',
      productPortfoliosLoadState: 'getProductPortfoliosLoadState',
      productPortfoliosTotal: 'getProductPortfoliosTotal',
    }),
    productName() {
      if (this.productPortfolios && this.productPortfolios.product) {
        return `${this.productPortfolios.product.fund_code} - ${this.productPortfolios.product.fund_name}`;
      }
      return '-';
    },
    currentUnitData() {
      return delimiter(this.productPortfolios && this.productPortfolios.unit);
    },
    portofoliosData() {
      if (this.productPortfolios) {
        return {
          investor: this.productPortfolios.investor,
          total_amount: this.productPortfolios.current,
          last_update: this.productPortfolios.updated_at,
          ccy: this.productPortfolios.product ? this.productPortfolios.product.fund_ccy : '',
          profit: this.productPortfolios.growth,
          percentage: this.productPortfolios.growth_percentage || 0,
          pending_unit: this.productPortfolios.outstanding_unit,
          agent: this.productPortfolios.agent || {},
        };
      }
      return { investor: {} };
    },
    tableData() {
      return (
        this.productPortfolios &&
        this.productPortfolios.mutations.map(portfolio => [
          portfolio.id,
          dayjs(portfolio.updated_at).format('DD MMMM YYYY HH:mm'),
          transactionTypeMap[portfolio.trigger_type] || portfolio.trigger_type,
          portfolio.trigger_id || '-',
          delimiter(portfolio.unit) || '-',
          delimiter(portfolio.initial_unit) || '-',
          delimiter(portfolio.final_unit) || '-',
        ])
      );
    },
    productId() {
      return this.$route.params.product_id;
    },
    portfolioId() {
      return this.$route.params.id;
    },
    defaultPayload() {
      return { id: this.portfolioId, offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return {
        start_date: this.chosenDate.beginDate ? dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD') : '',
        end_date: this.chosenDate.endDate ? dayjs(this.chosenDate.endDate).format('YYYY-MM-DD') : '',
        trigger_type: this.type && this.type.code,
      };
    },
  },
  created() {
    this.retrievePortfolio(this.defaultPayload);
  },
  methods: {
    ...mapActions(['retrievePortfolio']),
    searchPortfolio() {
      this.offset = 0;
      this.getPortfolio();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getPortfolio();
    },
    getPortfolio() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrievePortfolio(payload);
    },
  },
};
</script>
