<template>
  <div>
    <div v-if="loadState === 'success'" class="o-layout">
      <div class="o-layout__item u-5of12">
        <div class="o-layout u-pad-left--4">
          <investor-revises-edit-data
            ref="InvestorRevisesEditData"
            :investor="investorRevise.investor"
            :old-data="oldInvestorData"
            :new-data="newInvestorData"
            :registration-attributes="registrationAttributes"
          />
        </div>
      </div>
      <div class="o-layout__item u-1of12">
        &nbsp;
      </div>
      <div class="o-layout__item u-6of12">
        <basic-field label="Identity File">
          <div v-if="oldInvestorData.identity_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.identity_url" />
            </div>
          </div>
        </basic-field>
        <basic-field label="NPWP File">
          <div v-if="oldInvestorData.npwp_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.npwp_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Selfie File">
          <div v-if="oldInvestorData.selfie_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorData.selfie_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Signature File">
          <img v-if="oldInvestorData.signature_url" :src="oldInvestorData.signature_url" width="200px" height="auto" />
        </basic-field>
        <investor-revises-edit-bank
          v-for="(oldInvestorBank, idx) in oldInvestorBanks"
          ref="InvestorRevisesEditBank"
          :key="oldInvestorBank.investor_bank_id || oldInvestorBank.id"
          :investor="investorRevise.investor"
          :old-data="oldInvestorBank"
          :new-data="newInvestorBanks[idx]"
          :title="`Bank ${idx + 1}`"
        />
      </div>
      <draft-rejection-reason class="o-layout__item" :draft="draft" form="kyc" />
      <div class="o-layout__item">
        <bl-button-av color="green" block @click.native="onClickApprove">
          Pengajuan Approval Perubahan
        </bl-button-av>
      </div>
      <div class="o-layout__item u-pad-top--4">
        <div v-if="rejectionReasons.length">
          <investor-rejection-reasons-field
            ref="InvestorRejectionReasonsField"
            :field="{ name: 'admin_rejection_reason', label: 'Rejection Reasons' }"
            :rejection-reasons="rejectionReasons"
          />
          <bl-button-av color="red" block @click.native="onClickReject">
            Pengajuan Rejection Perubahan
          </bl-button-av>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import InvestorRejectionReasonsField from 'partner-admin/components/RejectionReasonsField';
import DraftRejectionReason from 'partner-admin/components/DraftRejectionReason';
import InvestorRevisesEditData from './InvestorRevisesEditData';
import InvestorRevisesEditBank from './InvestorRevisesEditBank';
import InvestorRevisesFormMixin from '../../mixins/InvestorRevisesFormMixin';

export default {
  name: 'InvestorRevisesEditForm',
  components: {
    BlButtonAv,
    InvestorRevisesEditData,
    InvestorRevisesEditBank,
    InvestorRejectionReasonsField,
    DraftRejectionReason,
  },
  mixins: [InvestorRevisesFormMixin],
  created() {
    this.setupFormData();
  },
  methods: {
    setupFormData() {
      return new Promise((resolve, reject) => {
        this.loadState = 'loading';
        const apis = [this.$api.getRegistrationAttributes(), this.$api.getRevisionRejectionsReasons()];
        Promise.all(apis)
          .then(([registrationAttributesResponse, rejectionReasonsResponse]) => {
            this.registrationAttributes = registrationAttributesResponse.data;
            this.rejectionReasons = this.processRejectionReason(rejectionReasonsResponse.data);
            this.loadState = 'success';
            resolve();
          })
          .catch(e => {
            this.loadState = 'error';
            this.showErrorNotif(e);
            reject(e);
          });
      });
    },
    processRejectionReason(rejectionReasons) {
      return rejectionReasons.filter(reason => reason.key !== 'ktp_duplicate');
    },
    onClickApprove() {
      this.$validator.validate();
      if (this.errors.items.length) {
        this.scrollToError(this.errors.items[0].field);
        return;
      }

      let payload = {
        id: this.investorRevise.id,
      };

      if (this.investorRevise.data_revise) {
        payload = {
          ...payload,
          ...this.$refs.InvestorRevisesEditData.getPayload(),
        };
      }
      if (this.investorRevise.bank_revises[0]) {
        const bankData = [];

        this.$refs.InvestorRevisesEditBank.forEach(editBankForm => {
          const editPayload = editBankForm.getPayload();
          if (editPayload) {
            bankData.push({ ...editPayload });
          }
        });

        if (bankData.length) {
          payload = {
            ...payload,
            bank_data: [...bankData],
          };
        }
      }

      this.$api
        .patchInvestorRevisesStatus(payload)
        .then(() => {
          this.showSuccessNotif('Berhasil melakukan pengajuan persetujuan perubahan data investor');
          this.$router.push({
            name: 'investorRevisesDraft',
          });
        })
        .catch(this.showErrorNotif);
    },
    onClickReject() {
      const rejectionValue = this.$refs.InvestorRejectionReasonsField.inputModel;
      if (rejectionValue) {
        const payload = {
          id: this.investorRevise.id,
          admin_rejection_reason: rejectionValue,
        };

        this.$api
          .patchInvestorRevisesStatus(payload)
          .then(() => {
            this.showSuccessNotif('Berhasil melakukan pengajuan penolakan perubahan data investor');
            this.$router.push({
              name: 'investorRevisesDraft',
            });
          })
          .catch(this.showErrorNotif);
      }
    },
    scrollToError(field) {
      // 90 is offset for header and form
      // https://stackoverflow.com/a/49842367
      const firstField = document.querySelector(`[name="${field}"]`);
      if (firstField) {
        window.scroll({
          top: firstField.getBoundingClientRect().top + window.scrollY - 90,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>
