import api from 'partner-admin/api';

const states = {
  histories: [],
  userProfiles: [],
  historyLoadState: 'idle',
  total: 0,
};

const getters = {
  getHistories: state => state.histories,
  getHistoryLoadState: state => state.historyLoadState,
  getUserProfiles: state => state.userProfiles,
  getHistoriesTotal: state => state.total,
};

const mutations = {
  setHistories: (state, val) => {
    state.histories = val;
  },
  setHistoryLoadState: (state, val) => {
    state.historyLoadState = val;
  },
  addUserProfiles: (state, val) => {
    state.userProfiles.push(val);
  },
  setHistoriesTotal: (state, val) => {
    state.total = val;
  },
};

function getUserProfiles({ state, commit }, userIds) {
  userIds.forEach(id => {
    const found = state.userProfiles.find(user => user.id === id);
    if (!found) {
      api.getStaff(id).then(response => {
        commit('addUserProfiles', response.data);
      });
    }
  });
}

const actions = {
  retrieveHistories({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (state.historyLoadState !== 'loading') {
        commit('setHistoryLoadState', 'loading');
        api
          .getHistories(payload)
          .then(response => {
            commit('setHistoryLoadState', 'success');
            commit('setHistories', response.data);
            commit('setHistoriesTotal', response.meta.total);
            const set = new Set(state.histories.map(history => history.actor_id));
            getUserProfiles({ state, commit }, set);
            resolve(response);
          })
          .catch(error => {
            commit('setHistoryLoadState', 'error');
            reject(error);
          });
      } else {
        resolve(state.histories);
      }
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
