<template>
  <Table
    :load-state="loadState"
    :rows="staffs"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <roles-table-row
      v-for="(staff, index) in staffs"
      :key="index"
      :staff="staff"
      @edit="edit"
      @refresh="refresh"
    />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import RolesTableRow from './RolesTableRow';

export default {
  name: 'RolesTable',
  components: {
    RolesTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Name', 'Email', 'Role', 'Active', 'Blocked', 'Action'],
  props: {
    staffs: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    edit(payload) {
      this.$emit('edit', payload);
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>
