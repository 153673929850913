import api from 'partner-admin/api';

const states = {
  policies: null,
  policiesLoadState: 'idle',
  isSuperStaff: false,
  canAccessReport: false,
};

const getters = {
  getPolicies: state => state.policies,
  getPoliciesLoadState: state => state.policiesLoadState,
  getIsSuperStaff: state => state.isSuperStaff,
  getCanAccessReport: state => state.canAccessReport,
};

const mutations = {
  setPolicies: (state, val) => {
    state.policies = val.features;
    state.isSuperStaff = val.super_staff;
    state.canAccessReport = val.features.aria_report.aum || val.features.aria_report.investor_individual;
  },
  setPoliciesLoadState: (state, val) => {
    state.policiesLoadState = val;
  },
  resetPolicies: state => {
    state.policies = null;
    state.policiesLoadState = 'idle';
    state.isSuperStaff = false;
    state.canAccessReport = false;
  },
};

const actions = {
  retrievePolicies({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.policies && state.policiesLoadState !== 'loading') {
        commit('setPoliciesLoadState', 'loading');
        api
          .getPolicies()
          .then(response => {
            commit('setPoliciesLoadState', 'success');
            commit('setPolicies', response.data);
            resolve(response);
          })
          .catch(response => {
            if (response.meta && response.meta.http_status && [404, 401].includes(response.meta.http_status)) {
              commit('setPoliciesLoadState', 'no access');
            } else {
              commit('setPoliciesLoadState', 'error');
            }
            reject(response);
          });
      } else {
        resolve(state.policies);
      }
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
