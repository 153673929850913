<template>
  <dl class="u-mrgn-bottom--2">
    <dt class="attributes u-mrgn-bottom--2">
      <strong> {{ change.key }} </strong>
    </dt>
    <dd class="diff">
      <ul class="o-list u-mrgn-left--5">
        <li
          v-if="oldValue"
          class="del history-list-item-row-old-value"
          style="background-color: #fee; color: #b00"
        >
          {{ formatDiff(change.key, oldValue) }}
        </li>
        <li
          class="ins history-list-item-row-new-value"
          style="background-color: #dfd; color: #080"
        >
          {{ formatDiff(change.key, newValue) }}
        </li>
      </ul>
    </dd>
  </dl>
</template>
<script>
import dayjs from 'dayjs';

export default {
  name: 'HistoryListItemRow',
  props: {
    change: {
      type: Object,
      required: true,
    },
  },
  computed: {
    oldValue() {
      return this.change.value[0];
    },
    newValue() {
      return this.change.value[1];
    },
  },
  methods: {
    formatDiff(key, value) {
      const regex = new RegExp('_at$');
      let result = value;
      if (regex.test(key)) {
        result = this.formatDate(value);
      }
      return result;
    },
    formatDate(date) {
      return date ? dayjs(date).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
};
</script>
