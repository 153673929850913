export const provinceCodesIdentityMapping = {
  ACEH: 11,
  BALI: 51,
  BANTEN: 36,
  BENGKULU: 17,
  GORONTALO: 75,
  JAKARTA: 31,
  JAMBI: 15,
  'JAWA BARAT': 32,
  'JAWA TENGAH': 33,
  'JAWA TIMUR': 35,
  'KALIMANTAN BARAT': 61,
  'KALIMANTAN SELATAN': 63,
  'KALIMANTAN TENGAH': 62,
  'KALIMANTAN TIMUR': 64,
  'KALIMANTAN UTARA': 65,
  'KEPULAUAN BANGKA BELITUNG': 19,
  'KEPULAUAN RIAU': 21,
  LAMPUNG: 18,
  MALUKU: 81,
  'MALUKU UTARA': 82,
  'NUSA TENGGARA BARAT': 52,
  'NUSA TENGGARA TIMUR': 53,
  'P A P U A': 94,
  'PAPUA BARAT': 91,
  RIAU: 14,
  'SULAWESI BARAT': 76,
  'SULAWESI SELATAN': 73,
  'SULAWESI TENGAH': 72,
  'SULAWESI TENGGARA': 74,
  'SULAWESI UTARA': 71,
  'SUMATERA BARAT': 13,
  'SUMATERA SELATAN': 16,
  'SUMATERA UTARA': 12,
  YOGYAKARTA: 34,
};

export default {};
