/*
  This class is used to wrap interfaces
*/
import RequestManager from './RequestManager';

function wrapInterface(interfaces = {}) {
  const requestManager = new RequestManager(wrapInterface.tokenManagerInstance);

  const wrappedInterfaces = {};

  Object.keys(interfaces).forEach(key => {
    wrappedInterfaces[key] = (...args) => {
      return interfaces[key].bind(requestManager)(...args);
    };
  });

  wrappedInterfaces.login = requestManager.login.bind(requestManager);
  wrappedInterfaces.logout = requestManager.logout.bind(requestManager);
  wrappedInterfaces.fetchToken = requestManager.fetchToken.bind(requestManager);
  return wrappedInterfaces;
}

wrapInterface.setup = tokenManagerInstance => {
  wrapInterface.tokenManagerInstance = tokenManagerInstance;
};

export default wrapInterface;
