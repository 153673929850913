<template>
  <tr>
    <td class="user-index-table-row__name">
      {{ user.name }}
    </td>
    <td class="user-index-table-row__email">
      {{ user.email }}
    </td>
    <td class="user-index-table-row__verified">
      {{ user.email_verified ? 'Sudah diverifikasi' : 'Belum diverifikasi' }}
    </td>
    <td class="user-index-table-row__active">
      {{ user.active ? 'Aktif' : 'Tidak Aktif' }}
    </td>
    <td>
      <bl-button-av
        v-if="isShowResetPasswordButton"
        size="tiny"
        class="user-index-table-row__reset-password"
        @click.native="reset"
      >
        Reset password
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';

export default {
  name: 'UserIndexTableRow',
  components: { BlButtonAv },
  mixins: [ErrorMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isShowResetPasswordButton() {
      return this.user.email_verified && this.user.active;
    },
  },
  methods: {
    reset() {
      this.$emit('reset', this.user);
    },
  },
};
</script>
