export function findField(data, fieldName) {
  const exist = data.find(datum => datum.field === fieldName);
  if (exist) {
    if (typeof exist.new_value === 'boolean' || exist.new_value) {
      return exist.new_value;
    }
    return exist.old_value;
  }
  return '';
}

export default {
  findField,
};
