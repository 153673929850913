<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-8of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-date-picker-mv
                  v-model="chosenDate"
                  class="u-width-1"
                  is-has-multiple-calendar
                  is-date-range-enabled
                  :begin-date="chosenDate.beginDate || currentDate"
                  :end-date="chosenDate.endDate"
                  multiple-calendar
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="filter.action" placeholder="Type" :options="$options.actionOptions" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av v-model="filter.state" placeholder="State" :options="$options.stateOptions" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-button-av
                  color="red"
                  class="transactions-file-management-page__search"
                  @click.native="searchUploadFiles"
                >
                  Search
                </bl-button-av>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <upload-file-management-table
        :load-state="loadState"
        :files="files"
        :total="totalFile"
        :limit="filter.limit"
        :offset="filter.offset"
        @reload="getUploadFiles"
        @changePage="setOffset"
        @retry="retryFileUpload"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import UploadFileManagementTable from '../components/UploadFileManagementTable';

export default {
  name: 'FileManagementIndex',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlDatePickerMv,
    BlDropdownAv,
    BlButtonAv,
    UploadFileManagementTable,
  },
  actionOptions: [
    '',
    `transaction_sinvest_status`,
    `transaction_sinvest_unit`,
    'transaction_sinvest_status_switching',
    `transaction_recon`,
    'investor_sinvest_sid',
    'investor_sinvest_ifua',
    'investor_sinvest_bank',
    'nav_sinvest',
    'investor_sinvest_revise_data',
    'investor_sinvest_revise_bank',
  ],
  stateOptions: ['', 'created', 'queued', 'completed', 'uncompleted'],
  mixins: [PolicyMixin, NotifMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      chosenDate: {},
      currentDate,
      filter: {
        action: undefined,
        state: undefined,
        start_date: undefined,
        end_date: undefined,
        offset: 0,
        limit: 20,
      },
      totalFile: 0,
      files: [],
      loadState: 'idle',
    };
  },
  computed: {
    formattedFilter() {
      const { beginDate, endDate } = this.chosenDate;
      return {
        ...this.filter,
        action: this.filter.action || undefined,
        start_date: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
      };
    },
  },
  created() {
    this.getUploadFiles();
  },
  methods: {
    searchUploadFiles() {
      this.filter.offset = 0;
      this.getUploadFiles();
    },
    getUploadFiles() {
      this.loadState = 'loading';
      this.$api
        .getUploadFiles(this.formattedFilter)
        .then(({ data, meta }) => {
          const { offset, total } = meta;
          this.files = data;
          this.filter.offset = offset;
          this.totalFile = total;
          this.loadState = 'success';
        })
        .catch(e => {
          this.showErrorNotif(e);
          this.loadState = 'error';
        });
    },
    retryFileUpload(payload) {
      this.$api
        .patchUploadFiles({
          id: payload.id,
          state: 'queued',
        })
        .then(() => {
          this.showSuccessNotif('File berhasil dimuat ulang');
          this.searchUploadFiles();
        })
        .catch(this.showErrorNotif);
    },
    setOffset(query) {
      this.filter.offset = query.offset;
      this.getUploadFiles();
    },
  },
};
</script>
