export default {
  methods: {
    getBooleanDropdownValue(value) {
      if (value === null || value === undefined) {
        return '';
      }
      return value ? 'Yes' : 'No';
    },
  },
};
