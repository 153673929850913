import mutations from './mutations';

const states = {
  investorDraftFilters: null,
  investorRevisesDraftFilters: null,
};

export default {
  namespaced: true,
  state: { ...states },
  mutations,
};
