<template>
  <ul class="c-pagination">
    <li
      v-if="showFirstLastButtons"
      class="c-pagination__item"
    >
      <a
        :class="{ 'is-disabled': currentPage === 1 }"
        :disabled="currentPage === 1"
        class="c-pagination__btn"
        @click="setPage(1)"
      >
        <bl-icon-av
          variant="chevron-left"
          style="margin-right: -12px;"
        />
        <bl-icon-av variant="chevron-left" />
      </a>
    </li>
    <li class="c-pagination__item">
      <a
        :class="{ 'is-disabled': currentPage === 1 }"
        :disabled="currentPage === 1"
        class="c-pagination__btn"
        @click="gotoPrev"
      >
        <bl-icon-av variant="arrow-backward" />
      </a>
    </li>
    <li
      v-for="(page, index) in displayedLinks"
      :key="index"
      class="c-pagination__item"
    >
      <a
        :class="{ 'is-current': page === currentPage }"
        class="c-pagination__link"
        @click="setPage(page)"
      >
        {{ page }}
      </a>
    </li>
    <li class="c-pagination__item">
      <a
        :class="{ 'is-disabled': currentPage === numOfPages }"
        :disabled="currentPage === numOfPages"
        class="c-pagination__btn"
        @click="gotoNext"
      >
        <bl-icon-av variant="arrow-forward" />
      </a>
    </li>
    <li
      v-if="showFirstLastButtons"
      class="c-pagination__item"
    >
      <a
        :class="{ 'is-disabled': currentPage === numOfPages }"
        :disabled="currentPage === numOfPages"
        class="c-pagination__btn"
        @click="setPage(numOfPages)"
      >
        <bl-icon-av
          variant="chevron-right"
          style="margin-right: -12px;"
        />
        <bl-icon-av variant="chevron-right" />
      </a>
    </li>
  </ul>
</template>

<script>
/* eslint-disable prefer-spread */
import BlIconAv from '../../atoms/BlIconAv';
import queryMode from './data/queryMode.json';

export default {
  name: 'BlPaginationMv',
  status: 'ready',
  release: '0.1.1',
  components: {
    BlIconAv,
  },
  props: {
    /**
     * Pagination total
     */
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    /**
     * Pagination offset
     */
    offset: {
      type: Number,
      default: 0,
    },
    /**
     * Pagination limit
     */
    limit: {
      type: Number,
      default: 10,
      validator: value => value > 0,
    },
    /**
     * Pagination max link
     */
    maxLinks: {
      type: Number,
      default: 5,
    },
    /**
     * Pagination query mode
     * `offset, page`
     */
    queryMode: {
      type: String,
      default: 'offset',
      validator: value => queryMode.indexOf(value) !== -1,
    },
    /**
     * Whether the pagination show first last
     * `true , false`
     */
    showFirstLast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numOfPages() {
      return Math.ceil(this.total / this.limit);
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    displayedLinks() {
      const pageOffset = Math.floor(this.maxLinks / 2);
      let minPage = null;
      let maxPage = null;

      if (this.numOfPages > this.maxLinks) {
        if (this.currentPage <= pageOffset) {
          minPage = 1;
          maxPage = this.maxLinks;
        } else if (this.currentPage >= this.numOfPages - pageOffset) {
          minPage = this.numOfPages - this.maxLinks;
          maxPage = this.numOfPages;
        } else {
          minPage = this.currentPage - pageOffset;
          maxPage = this.currentPage + pageOffset;
        }
      } else {
        minPage = 1;
        maxPage = this.numOfPages;
      }

      return Array.apply(null, { length: maxPage + 1 - minPage }).map((_, idx) => idx + minPage);
    },
    showFirstLastButtons() {
      return this.showFirstLast && this.numOfPages > this.maxLinks;
    },
  },
  methods: {
    setPage(page) {
      const offset = (page - 1) * this.limit;
      let query = {
        limit: this.limit,
        offset,
      };

      if (this.queryMode === 'page') {
        query = { page };
      }

      this.$emit('changePage', query);
    },
    gotoPrev() {
      this.setPage(this.currentPage - 1);
    },
    gotoNext() {
      this.setPage(this.currentPage + 1);
    },
  },
};
</script>

<style src="./BlPaginationMv.style.scss" lang="scss"></style>
