import Table from 'partner-admin/components/TableWrapper';

export default {
  components: {
    Table,
  },
  props: {
    loadState: {
      type: String,
      required: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    refresh() {
      this.$emit('refresh');
    },
    changePage(payload) {
      this.$emit('changePage', payload);
    },
  },
};
