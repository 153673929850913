<template>
  <div>
    <div v-if="isAllowed('product', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        KYC Investor
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-draft-edit-form :investor="investor" :draft="draft" />
    </div>
  </div>
</template>

<script>
import InvestorDraftMixin from '../mixins/InvestorDraftMixin';
import InvestorDraftEditForm from '../components/InvestorDraftEditForm';

export default {
  name: 'InvestorDraftKycPage',
  components: { InvestorDraftEditForm },
  mixins: [InvestorDraftMixin],
};
</script>
