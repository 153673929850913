<template>
  <div>
    <summary-feature
      v-for="feature in featureTypes"
      :key="feature.code"
      :title="feature.text"
      :table-data="getData(feature.code)"
      :load-state="loadState"
      @reload="reload"
    />
  </div>
</template>

<script>
import SummaryFeature from './SummaryFeature';

export default {
  name: 'SummaryTable',
  components: {
    SummaryFeature,
  },
  props: {
    summaries: {
      type: Array,
      default: () => [],
    },
    loadState: {
      type: String,
      default: 'idle',
    },
  },
  data() {
    return {
      featureTypes: [
        { code: 'investor', text: 'Investor' },
        { code: 'investor_revise', text: 'Investor Update' },
        { code: 'product', text: 'Product' },
        { code: 'transaction', text: 'Transaction' },
        { code: 'nav', text: 'NAV' },
        { code: 'aria_report', text: 'ARIA Report' },
        { code: 'portfolio', text: 'Portfolio' },
        { code: 'document', text: 'Upload SKT' },
        { code: 'aml_report', text: 'AML & CFT Report' },
        { code: 'agent', text: 'Agent' },
      ],
    };
  },
  methods: {
    getData(key) {
      return this.summaries.map(summary => {
        const feature = summary.features ? summary.features[key] : {};
        return { roleGroup: summary.name, feature };
      });
    },
    reload() {
      this.$emit('reload');
    },
  },
};
</script>
