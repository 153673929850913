<template>
  <Table
    :load-state="loadState"
    :rows="investorRevises"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <investor-history-update-table-row
      v-for="investorRevise in investorRevises"
      :key="investorRevise.id"
      :investor-revise="investorRevise"
    />
  </Table>
</template>
<script>
/* istanbul ignore next */
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import InvestorHistoryUpdateTableRow from './InvestorHistoryUpdateTableRow';

export default {
  name: 'InvestorHistoryUpdateTable',
  components: {
    InvestorHistoryUpdateTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Update Date', 'Nama', 'NIK', 'Status'],
  props: {
    investorRevises: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
