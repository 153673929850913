<template>
  <div class="notification-force-layer">
    <transition name="slide-down-fade">
      <bl-notification-mv
        v-if="notif"
        :variant="notif.type"
        class="notification-force-layer--center"
        v-html="notif.text"
      />
    </transition>
  </div>
</template>
<script>
import BlNotificationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlNotificationMv';

export default {
  name: 'Notification',
  components: { BlNotificationMv },
  duration: 3000,
  data() {
    return {
      notif: null,
    };
  },
  created() {
    /* istanbul ignore next */
    this.$root.$on('showFlashNotif', this.showFlashNotif);
  },
  beforeDestroy() {
    /* istanbul ignore next */
    this.$root.$off('showFlashNotif', this.showFlashNotif);
  },
  methods: {
    showFlashNotif(notif) {
      this.notif = notif;
      /* istanbul ignore next */
      this.$options.timeout = setTimeout(() => {
        this.notif = null;
      }, this.$options.duration);
    },
  },
};
</script>
<style scoped>
.notification-force-layer {
  position: fixed;
  top: 120px;
  left: 50%;
  z-index: 999999 !important;
}
.notification-force-layer--center {
  position: relative;
  left: -50%;
  min-width: 400px;
}
.slide-down-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-down-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-down-fade-enter,
.slide-down-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
