<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/file-management">
        &lt; Kembali Ke Halaman File Management Transaksi
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <history-list
        :load-state="historiesLoadState"
        :history-data="histories"
        :user-profiles="userProfiles"
        :limit="limit"
        :offset="offset"
        :total="historiesTotal"
        @changePage="setOffset"
        @reload="getHistories()"
      />
    </div>
  </div>
</template>

<script>
import HistoryList from 'partner-admin/components/HistoryList';
import HistoryMixin from 'partner-admin/mixins/historyMixin';

export default {
  name: 'FileManagementHistoryPage',
  components: {
    HistoryList,
  },
  mixins: [HistoryMixin],
};
</script>
