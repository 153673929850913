<template>
  <span
    :class="getWrapperClasses()"
    class="datepicker__date-wrapper u-display-inline-block"
  >
    <span
      :title="title"
      class="datepicker__date-day u-display-inline-block"
      :class="getDateClasses()"
      @click="onClick"
    >
      {{ date.value }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'BlCalendarDateAv',
  props: {
    date: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    getWrapperClasses() {
      const date = this.date;
      return {
        'datepicker__date-wrapper--range-begin': date.beginDateRange && !date.endDateRange,
        'datepicker__date-wrapper--range-end': date.endDateRange && !date.beginDateRange,
        'datepicker__date-wrapper--range-start': date.beginDateRange && date.endDateRange,
        'datepicker__date-wrapper--range': date.inRange,
      };
    },
    getDateClasses() {
      const date = this.date;
      return {
        'datepicker__date-day--inactive': date.outdated || date.limited,
        'datepicker__date-day--active': !date.outdated && !date.limited,
        'datepicker__date-day--selected': date.selectedDate,
        'datepicker__date-day--active-charcoal': !date.outdated && !date.limited && !date.inRange,
        'datepicker__date-day--active-red':
          !date.outdated && !date.limited && !date.inRange && (date.holiday != null || date.todayDate),
        'datepicker__date-day--range': date.inRange,
        'datepicker__date-day--range-end': date.endDateRange,
        'u-txt--bold': date.todayDate,
      };
    },
    onClick(e) {
      if (!this.date.outdated && !this.date.limited) {
        this.$emit('onClick', e);
      }
    },
  },
};
</script>

<style src="./BlCalendarDateAv.style.scss" lang="scss"></style>
