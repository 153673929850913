<template>
  <div>
    <bl-dialog-mv :active.sync="uploadSktConfirmationDialog" class="custom-dialog" title="Upload SKT">
      <div style="margin-bottom: 18px">
        Apakah anda yakin akan melakukan upload SKT?
        <div class="upload-skt-confirmation__product-name">Produk: {{ product.fund_name }}</div>
        <div class="upload-skt-confirmation__date">SKT Tanggal: {{ date | humanizeDate }}</div>
      </div>

      <div style="text-align: right">
        <bl-button-av @click="uploadSktConfirmationDialog = false">
          Tidak
        </bl-button-av>
        <bl-button-av color="red" @click="submit">
          Upload
        </bl-button-av>
      </div>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import dayjs from 'dayjs';

export default {
  name: 'UploadSktConfirmationDialog',
  filters: {
    humanizeDate(value) {
      if (value) return dayjs(value).format('DD MMMM YYYY');
      return '-';
    },
  },
  components: {
    BlDialogMv,
    BlButtonAv,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uploadSktConfirmationDialog: false,
    };
  },
  methods: {
    openUploadSktConfirmationDialog() {
      this.uploadSktConfirmationDialog = true;
    },
    submit() {
      this.uploadSktConfirmationDialog = false;
      this.$emit('submit');
    },
  },
};
</script>
