<template>
  <div>
    <basic-field label="Nomor Identitas">
      <bl-text-field-mv class="investor_identity_number" :value="investor.identity_number" readonly />
    </basic-field>
    <basic-field label="NPWP">
      <bl-text-field-mv class="investor_npwp_number" :value="investor.npwp_number" readonly />
    </basic-field>
    <basic-field label="IFUA (Investor Fund Unit Account)">
      <bl-text-field-mv class="investor_ifua" :value="investor.ifua" readonly />
    </basic-field>
    <basic-field label="SID (Single Investor Identification)">
      <bl-text-field-mv class="investor_sid" :value="investor.sid" readonly />
    </basic-field>
    <basic-field label="Investor Type">
      <bl-text-field-mv class="investor_investor_type" :value="investor.investor_type" readonly />
    </basic-field>
    <basic-field label="Agent">
      <bl-text-field-mv class="investor_agent" :value="investor.agent && investor.agent.name" readonly />
    </basic-field>
    <basic-field label="Nama">
      <bl-text-field-mv class="investor_name" :value="investor.name" readonly />
    </basic-field>
    <basic-field label="Tempat Lahir">
      <bl-text-field-mv class="investor_birth_place" :value="investor.birth_place" readonly />
    </basic-field>
    <basic-field label="Tanggal Lahir">
      <bl-text-field-mv class="investor_birth_date" :value="investor.birth_date" readonly />
    </basic-field>
    <basic-field label="Jenis Kelamin">
      <bl-text-field-mv class="investor_gender" :value="investor.gender" readonly />
    </basic-field>
    <basic-field label="Provinsi">
      <bl-text-field-mv class="investor_province" :value="investor.province" readonly />
    </basic-field>
    <basic-field label="Kota/Kabupaten">
      <bl-text-field-mv class="investor_city" :value="investor.city" readonly />
    </basic-field>
    <basic-field label="Kecamatan">
      <bl-text-field-mv class="investor_sub_district" :value="investor.sub_district" readonly />
    </basic-field>
    <basic-field label="Kel Desa">
      <bl-text-field-mv class="investor_village" :value="investor.village" readonly />
    </basic-field>
    <basic-field label="RT/RW">
      <bl-text-field-mv class="investor_neighborhood" :value="investor.neighborhood" readonly />
    </basic-field>
    <basic-field label="Alamat">
      <bl-text-field-mv class="investor_address" :value="investor.address" readonly />
    </basic-field>
    <basic-field label="Kode Pos">
      <bl-text-field-mv class="investor_postal_code" :value="investor.postal_code" readonly />
    </basic-field>
    <basic-field label="Domisili Provinsi">
      <bl-text-field-mv class="investor_domicile_province" :value="investor.domicile_province" readonly />
    </basic-field>
    <basic-field label="Domisili Kota/Kabupaten">
      <bl-text-field-mv class="investor_domicile_city" :value="investor.domicile_city" readonly />
    </basic-field>
    <basic-field label="Domisili Kecamatan">
      <bl-text-field-mv class="investor_domicile_sub_district" :value="investor.domicile_sub_district" readonly />
    </basic-field>
    <basic-field label="Domisili Kel Desa">
      <bl-text-field-mv class="investor_domicile_village" :value="investor.domicile_village" readonly />
    </basic-field>
    <basic-field label="Domisili RT/RW">
      <bl-text-field-mv class="investor_domicile_neighborhood" :value="investor.domicile_neighborhood" readonly />
    </basic-field>
    <basic-field label="Domisili Alamat">
      <bl-text-field-mv class="investor_domicile_address" :value="investor.domicile_address" readonly />
    </basic-field>
    <basic-field label="Domisili Kode Pos">
      <bl-text-field-mv class="investor_domicile_postal_code" :value="investor.domicile_postal_code" readonly />
    </basic-field>
    <basic-field label="Agama">
      <bl-text-field-mv class="investor_religion" :value="investor.religion" readonly />
    </basic-field>
    <basic-field label="Status Perkawinan">
      <bl-text-field-mv class="investor_marital_status" :value="investor.marital_status" readonly />
    </basic-field>
    <basic-field label="Pekerjaan">
      <bl-text-field-mv class="investor_occupation" :value="investor.occupation" readonly />
    </basic-field>
    <basic-field label="Education">
      <bl-text-field-mv class="investor_education" :value="investor.education" readonly />
    </basic-field>
    <basic-field label="Income Level">
      <bl-text-field-mv class="investor_income_level" :value="investor.income_level" readonly />
    </basic-field>
    <basic-field label="Risk Profile">
      <bl-text-field-mv class="investor_risk_profile" :value="investor.risk_profile" readonly />
    </basic-field>
    <basic-field label="Investment Objective">
      <bl-text-field-mv class="investor_investment_objective" :value="investor.investment_objective" readonly />
    </basic-field>
    <basic-field label="Source of Fund">
      <bl-text-field-mv class="investor_source_of_fund" :value="investor.source_of_fund" readonly />
    </basic-field>
    <basic-field label="E-mail">
      <bl-text-field-mv class="investor_email" :value="investor.email" readonly />
    </basic-field>
    <basic-field label="Beneficiary Owner">
      <bl-text-field-mv class="investor_bo" :value="getBooleanDropdownValue(investor.bo)" readonly />
    </basic-field>
    <basic-field label="Politically Exposed Persons">
      <bl-text-field-mv class="investor_pep" :value="getBooleanDropdownValue(investor.pep)" readonly />
    </basic-field>
    <basic-field label="Phone">
      <bl-text-field-mv class="investor_phone" :value="investor.phone" readonly />
    </basic-field>
  </div>
</template>
<script>
import InvestorViewMixin from '../../mixins/InvestorViewMixin';

export default {
  name: 'InvestorViewData',
  mixins: [InvestorViewMixin],
  props: {
    investor: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
