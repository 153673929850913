<template>
  <div>
    <h1 class="u-txt--bold u-mrgn-bottom--1">
      {{ title }}
    </h1>
    <basic-field label="Bank Name">
      <bl-text-field-mv class="investor_bank_name" :value="investorBankData.bank_name" readonly />
    </basic-field>
    <basic-field label="Bank Branch">
      <bl-text-field-mv class="investor_bank_branch" :value="investorBankData.bank_branch" readonly />
    </basic-field>
    <basic-field label="Bank Account Currency">
      <bl-text-field-mv class="investor_account_ccy" :value="investorBankData.account_ccy" readonly />
    </basic-field>
    <basic-field label="Bank Account No.">
      <bl-text-field-mv class="investor_account_no" :value="investorBankData.account_no" readonly />
    </basic-field>
    <basic-field label="Bank Account Name">
      <bl-text-field-mv class="investor_account_name" :value="investorBankData.account_name" readonly />
    </basic-field>
    <basic-field label="Bank Valid">
      <bl-text-field-mv
        class="investor_account_valid"
        :value="getBooleanDropdownValue(investorBankData.account_valid)"
        readonly
      />
    </basic-field>
    <basic-field label="Bank Account Name (system)">
      <bl-text-field-mv class="investor_checked_account_name" :value="investorBankData.checked_account_name" readonly />
    </basic-field>
    <hr />
  </div>
</template>
<script>
import InvestorViewMixin from '../../mixins/InvestorViewMixin';

export default {
  name: 'InvestorViewBank',
  mixins: [InvestorViewMixin],
  props: {
    investorBankData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
