<template>
  <div>
    <component
      :is="fieldComponents"
      v-for="field in inputFields"
      :key="field.name"
      :ref="field.name"
      :field="field"
      :layout="fieldLayout"
      :readonly="['draft', 'view'].includes(form) || checkEditReadonly(form, field)"
    />
  </div>
</template>
<script>
import InputField from 'partner-admin/components/InputField';
import ReadonlyField from 'partner-admin/components/ReadonlyField';
import ReviewField from 'partner-admin/components/ReviewField';
import FormMixin from 'partner-admin/mixins/formMixin';
import { FormTypes } from './config';
import { getProductInputFields } from './helper';

export default {
  name: 'ProductForm',
  components: { InputField, ReadonlyField, ReviewField },
  mixins: [FormMixin],
  props: {
    form: {
      type: String,
      default: 'new',
      validator(value) {
        return FormTypes.includes(value);
      },
    },
    draft: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputFields: [],
      registrationAttributes: [],
    };
  },
  computed: {
    fieldComponents() {
      return {
        new: 'input-field',
        edit: 'input-field',
        view: 'readonly-field',
        review: 'review-field',
      }[this.form];
    },
    inputFieldsComponent() {
      return this.inputFields.map(field => this.$refs[field.name][0]);
    },
    fieldLayout() {
      if (this.form === 'view') {
        return 'inline';
      }
      return 'basic';
    },
    percentageFieldNames() {
      return this.inputFields
        .filter(field => field.type === 'number' && field.format === 'percentage')
        .map(field => field.name);
    },
  },
  created() {
    this.inputFields = getProductInputFields(this.form);
    this.setupFormData()
      .then(() => {
        if (['edit', 'view'].includes(this.form)) {
          this.inputFields = this.generateProductField(this.product);
        }

        if (['review'].includes(this.form)) {
          this.inputFields = this.generateReviewProductField(this.draft);
        }
      })
      .catch(this.handleError);
  },
  methods: {
    setupFormData() {
      return new Promise((resolve, reject) => {
        this.$api
          .getRegistrationAttributes()
          .then(({ data }) => {
            this.registrationAttributes = data;
            this.processRegistrationAttributes(data);
            resolve();
          })
          .catch(reject);
      });
    },
    processRegistrationAttributes(attributes) {
      const options = attributes.banks.map(opt => ({
        value: opt,
        label: opt,
      }));
      this.updateInputField('cb_name', { options });
    },
    generateProductField(product) {
      const data = this.inputFields.map(input => {
        const inputValue = this.getInputValue(input, product);
        if (this.percentageFieldNames.includes(input.name)) {
          const calculate = inputValue ? Number((inputValue.replace(',', '.') * 100).toFixed(4)) : 0;
          return { ...input, value: calculate.toString().replace('.', ',') };
        }

        if (input.name === 'institution_allocation' && inputValue) {
          return { ...input, value: this.formatInstitutionAllocation(inputValue) };
        }

        if (input.name === 'instrument_allocation' && inputValue) {
          return { ...input, value: this.formatInstrumentAllocation(inputValue) };
        }

        return { ...input, value: inputValue };
      });
      return data;
    },
    generateReviewProductField(draftsData) {
      const data = this.inputFields.map(input => {
        const formattedInput = this.getInputFormattedDraft(input, draftsData);
        return formattedInput;
      });
      return data;
    },
    validateAndGetFormData() {
      return new Promise(resolve => {
        this.inputFieldsComponent.forEach(field => field.validate());
        this.$nextTick(() => {
          if (!this.errors.items.length) {
            resolve(this.constructProductPayload());
          } else {
            this.scrollToError(this.errors.items[0]);
          }
        });
      });
    },
    constructProductPayload() {
      const payload = {};
      this.inputFieldsComponent.forEach(input => {
        if (input.inputModel !== undefined) {
          let value = this.getInputPayload(input);
          if (input.field.type === 'file') {
            payload[`${input.field.name}_file_name`] = input.inputModel.file_name;
            value = value.file;
          }
          if (this.percentageFieldNames.includes(input.field.name)) {
            value = Number((value / 100).toFixed(6));
          }

          if (input.field.name === 'institution_allocation') {
            const dataInstitution = value;
            let dataArrayInstitution = dataInstitution.split(/\r?\n/);         
            const mappedData = dataArrayInstitution.map((el) => {
              const data = {
                name: el || '',
              };
              return data;
            });

            value = mappedData
          }

          if (input.field.name === 'instrument_allocation') {
            const dataInstrument = value;
            let arrInstrument = dataInstrument.split(/\r?\n/)
            const instruments = arrInstrument.map((el) => {
              const instrumentItem = el.split('-');
              const instrument = {
                name: instrumentItem[0].trim(),
                percentage: Number(instrumentItem[1].trim().replace(/%/g, '')) / 100,
              };

              return instrument;
            });
            value = instruments
          }

          payload[input.field.name] = value;
        }
      });

      return payload;

    },
  },
};
</script>
