const handleBankDataField = (draft, original, fieldName) => {
  const originalBankData = original[fieldName];
  if (originalBankData && originalBankData.length) {
    const data = [];
    originalBankData.forEach(bankData => {
      // search bank data in draft
      const searchDraftBank = draft && draft.value.find(draftBank => draftBank.record_id === bankData.id);

      // bank data keys, exclude id in draft
      const bankKeys = Object.keys(bankData).filter(key => key !== 'id');

      // there is changes
      if (searchDraftBank && searchDraftBank.data.length) {
        const draftBankData = searchDraftBank.data;

        data.push({
          record_type: 'Bib::InvestorBank',
          record_id: bankData.id,
          data: bankKeys.map(bankKey => {
            // find field in draft
            const findField = draftBankData.find(draftBankDatum => draftBankDatum.field === bankKey);

            // field key found in draft return draft data
            if (findField) return findField;

            // field key not found in draft
            return {
              field: bankKey,
              old_value: bankData[bankKey],
              new_value: null,
            };
          }),
        });
      } else {
        // no changes, use investor data
        data.push({
          record_type: 'Bib::InvestorBank',
          record_id: bankData.id,
          data: bankKeys.map(bankKey => {
            return {
              field: bankKey,
              old_value: bankData[bankKey],
              new_value: null,
            };
          }),
        });
      }
    });
    return {
      field: fieldName,
      value: data,
    };
  }
  // if bank_data null / empty
  return {
    field: fieldName,
    value: [],
  };
};

/**
 * Description:
 * Populate draft with new item per missing investor data and fill it with oldValue
 * Draft need to be populated because it only return changed field, and our form need all field to show properly
 *
 * @param {Array}   draftData     Array of draft data (field changed in creation of draft)
 * @param {Object}  original  Object original data that will populate draft
 */

const populateDraft = (draftData, original) => {
  const keys = Object.keys(original);
  const newDraft = keys.map(key => {
    // handle file url attribute to form friendly attribute
    const fieldName = key;

    const exist = draftData.find(item => item.field === fieldName);

    if (fieldName === 'bank_data') {
      return handleBankDataField(exist, original, fieldName);
    }

    if (exist) {
      return exist;
    }

    return {
      field: fieldName,
      old_value: original[key] !== null ? original[key] : '',
      new_value: null,
    };
  });
  return newDraft;
};

export default populateDraft;
