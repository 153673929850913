<template>
  <div>
    <div class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        View Produk
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <product-form form="review" :draft="draft.data" />
    </div>
  </div>
</template>

<script>
import ProductDraftMixin from '../mixins/ProductDraftMixin';

export default {
  name: 'ProductDraftViewPage',
  mixins: [ProductDraftMixin],
};
</script>
