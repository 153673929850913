<template>
  <bl-field-mv>
    <bl-field-row-mv>
      <bl-field-label-av>{{ field.label }}</bl-field-label-av>
      <div v-for="reason in rejectionReasons" :key="reason.key">
        <bl-checkbox-av
          :name="field.name"
          :label="reason.value"
          :value="reason.bit_value"
          :checked="selectedReasons"
          :disabled="isDisabled(reason.bit_value) || readonly"
          @change="updateChosenReasons"
        />
      </div>
      <bl-field-error-av v-if="validateError">
        {{ validateError }}
      </bl-field-error-av>
    </bl-field-row-mv>
  </bl-field-mv>
</template>
<script>
/* eslint-disable no-bitwise */
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlCheckboxAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlCheckboxAv';

export default {
  name: 'RejectionReasonsField',
  components: { BlFieldMv, BlFieldRowMv, BlFieldLabelAv, BlCheckboxAv, BlFieldErrorAv },
  props: {
    field: {
      type: Object,
      required: true,
    },
    rejectionReasons: {
      type: Array,
      required: true,
    },
    adminRejectionReason: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputModel: undefined,
      selectedReasons: [],
    };
  },
  computed: {
    validateError() {
      const { name } = this.field;
      const errorMessage = this.errors.first(name) || '';
      return errorMessage.replace(name, this.field.label);
    },
    allowedBitValue() {
      if (this.selectedReasons.length) {
        const reasons = this.selectedReasons.map(bitValue =>
          this.rejectionReasons.find(reason => reason.bit_value === bitValue),
        );
        const allowedBit = reasons.reduce((finalBit, currentReason) => finalBit & currentReason.bit, reasons[0].bit);
        return allowedBit;
      }
      return undefined;
    },
  },
  watch: {
    selectedReasons(bitses) {
      if (bitses.length) {
        this.inputModel = bitses.reduce((finalBit, currentBit) => finalBit | currentBit, bitses[0]);
      } else {
        this.inputModel = undefined;
      }
    },
  },
  created() {
    if (this.adminRejectionReason) {
      this.rejectionReasons.forEach(reason => {
        if (reason.bit_value & this.adminRejectionReason) {
          this.selectedReasons.push(reason.bit_value);
        }
      });
    }
  },
  methods: {
    updateChosenReasons(reasons) {
      this.selectedReasons = reasons;
    },
    isDisabled(reason) {
      if (!this.allowedBitValue) {
        return false;
      }
      return !(this.allowedBitValue & reason);
    },
    validate() {
      this.$validator.validate();
    },
  },
};
</script>
