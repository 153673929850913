<template>
  <div class="c-panel__title">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlPanelTitleAv',
  status: 'ready',
  release: '0.4.0',
};
</script>

<style src="./BlPanelTitleAv.style.scss" lang="scss"></style>
