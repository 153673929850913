<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <bl-field-mv class="o-layout__item u-4of12">
          <bl-field-row-mv>
            <bl-text-field-mv v-model="filter.email" class="user-index__email" placeholder="Email" />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-2of12">
          <bl-field-row-mv>
            <bl-button-av class="user-index__search" color="red" @click="search">
              Search
            </bl-button-av>
          </bl-field-row-mv>
        </bl-field-mv>
      </div>

      <user-index-reset-password-confirmation
        v-model="isShowConfirmation"
        :user="selectedUser"
        @submit="sendResetPassword"
      />
    </div>

    <div class="c-tab__content__body">
      <user-index-table
        :load-state="loadState"
        :users="users"
        :total="usersTotal"
        :limit="filter.limit"
        :offset="filter.offset"
        @reload="retrieveUsers"
        @changePage="setOffset"
        @reset="openFormDialog"
      />
    </div>
  </div>
</template>

<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import UserIndexTable from '../components/UserIndexTable';
import UserIndexResetPasswordConfirmation from '../components/UserIndexResetPasswordConfirmation';

export default {
  name: 'RolesIndexPage',
  components: {
    UserIndexTable,
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlButtonAv,
    UserIndexResetPasswordConfirmation,
  },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      users: [],
      loadState: 'idle',
      usersTotal: 0,
      filter: {
        offset: 0,
        limit: 10,
        email: undefined,
      },
      selectedUser: {},
      isShowConfirmation: false,
    };
  },
  methods: {
    retrieveInitialData() {
      this.retrieveUsers();
    },
    retrieveUsers() {
      this.loadState = 'loading';

      this.$api
        .getExclusiveUsers(this.filter)
        .then(({ data, meta }) => {
          this.users = data;
          this.usersTotal = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.showErrorNotif(error);
          this.loadState = 'error';
        });
    },
    setOffset(query) {
      this.filter.offset = query.offset;
      this.retrieveUsers();
    },
    openFormDialog(user) {
      this.selectedUser = user;

      this.$nextTick(() => {
        this.isShowConfirmation = true;
      });
    },
    sendResetPassword(id) {
      this.$api
        .postForgotPassword(id)
        .then(({ data }) => {
          this.showSuccessNotif(data.message);
        })
        .catch(this.showErrorNotif);
    },
    search() {
      this.filter.offset = 0;
      this.retrieveUsers();
    },
  },
};
</script>
