<template>
  <Table
    :load-state="loadState"
    :rows="investors"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <investor-table-row v-for="investor in investors" :key="investor.id" :investor="investor" />
  </Table>
</template>
<script>
/* istanbul ignore next */
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import InvestorTableRow from './InvestorTableRow';

export default {
  name: 'InvestorTable',
  components: {
    InvestorTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Registration Date', 'SID', 'IFUA', 'NIK', 'Email', 'Agent', 'Waiting Update', 'State', 'Risk'],
  props: {
    investors: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
