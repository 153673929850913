/* eslint-disable no-unused-vars, no-undef */
import Vue from 'partner-admin/lib/vue';
import VeeValidate, { Validator } from 'vee-validate';
import id from 'vee-validate/dist/locale/id';
// import { notifyErrors } from 'shared/helpers/logger';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import Viewer from 'v-viewer';
import './oauth';
import api from './api';
import router from './config/router';
import store from './config/store';
import App from './App.vue';
import './lib/bazaar-dweb/styles/global.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/BlTabMv.style.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/components/BlTabContentBodyMv/BlTabContentBodyMv.style.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/components/BlTabContentMv/BlTabContentMv.style.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/components/BlTabContentTitleAv/BlTabContentTitleAv.style.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/components/BlTabNavItemAv/BlTabNavItemAv.style.scss';
import './lib/bazaar-dweb/components/molecules/BlTabMv/components/BlTabNavMv/BlTabNavMv.style.scss';

Vue.prototype.$api = api;

Vue.use(VeeValidate);
Validator.localize('id', id);
dayjs.locale('id');

// investor ktp viewer
Vue.use(Viewer, {
  defaultOptions: {
    inline: true,
    title: false,
    navbar: false,
    fullscreen: false,
    minHeight: 350,
  },
});

const app = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  // errorCaptured(e) {
  //   notifyErrors('BIB_DASHBOARD_ADMIN', e);
  // },
});
