<template>
  <div class="o-flag__body">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFlagBodyAv',
  status: 'ready',
  release: '0.1.1',
};
</script>
