<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/portofolios">
        &lt; Kembali Ke Halaman Daftar Portofolio
      </router-link>

      <user-detail-info class="u-mrgn-top--2" mode="detailPortofolios" :portofolios="portofoliosData" />

      <div class="o-layout o-layout--responsive u-mrgn-top--2">
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-dropdown-av
              v-model="product"
              placeholder="Fund Name"
              :options="productOptions"
              track-by="id"
              label="fund_name"
              searchable
            />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-button-av color="red" @click="searchPortfolios">
              Search
            </bl-button-av>
          </bl-field-row-mv>
        </bl-field-mv>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="false"
        :table-header="['Fund Code', 'Fund Name', 'CCY', 'Unit', 'Total Amount', 'Keuntungan', 'Last Update']"
        :table-data="tableData"
        :load-state="investorPortfoliosLoadState"
        :action-button="['view_list']"
        :action-root-url="'/portofolios'"
        @reload="searchPortfolios"
      />
      <bl-pagination-mv
        v-if="investorPortfoliosTotal > limit"
        class="u-mrgn-top--4"
        :total="investorPortfoliosTotal"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import Table from 'partner-admin/components/Table';
import dayjs from 'dayjs';
import { delimiter } from 'partner-admin/shared/helpers/stringHelper';
import { money } from 'partner-admin/shared/helpers/numberHelper';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';
import UserDetailInfo from '../components/UserDetailInfo';

export default {
  name: 'PortofoliosInvestorViewPage',
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    BlPaginationMv,
    BlDropdownAv,
    UserDetailInfo,
  },
  mixins: [PolicyMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      product: '',
    };
  },
  computed: {
    ...mapGetters({
      investorPortfolios: 'getInvestorPortfolios',
      investorPortfoliosLoadState: 'getInvestorPortfoliosLoadState',
      investorPortfoliosTotal: 'getInvestorPortfoliosTotal',
      portfolioSummariesByInvestor: 'getPortfolioSummariesByInvestor',
      products: 'getProducts',
    }),
    portofoliosData() {
      if (this.investorPortfolios && this.investorPortfolios.length) {
        return {
          investor: this.investorPortfolios[0].investor,
          total_amount_portfolio: this.portfolioSummariesByInvestor.current,
          ccy: this.investorPortfolios[0].product ? this.investorPortfolios[0].product.fund_ccy : '',
          last_update_portfolio: this.portfolioSummariesByInvestor.last_updated_at,
          profit_portfolio: this.portfolioSummariesByInvestor.growth,
          percentage_portfolio: this.portfolioSummariesByInvestor.growth_percentage || 0,
          pending_unit: this.investorPortfolios[0].outstanding_unit,
          agent: this.investorPortfolios[0].agent || {},
        };
      }
      return { investor: {} };
    },
    tableData() {
      return this.investorPortfolios.map(portfolio => [
        { id: portfolio.id, product_id: portfolio.product && portfolio.product.id },
        (portfolio.product && portfolio.product.fund_code) || '-',
        (portfolio.product && portfolio.product.fund_name) || '-',
        (portfolio.product && portfolio.product.fund_ccy) || '-',
        delimiter(portfolio.unit) || '-',
        money(portfolio.current, 2, '') || '-',
        `${money(portfolio.growth, 2, '')} (${portfolio.growth_percentage || '0'}%)`,
        dayjs(portfolio.updated_at).format('DD MMMM YYYY HH:mm'),
      ]);
    },
    productOptions() {
      return ['', ...this.products];
    },
    investorId() {
      return this.$route.params.investor_id;
    },
    defaultPayload() {
      return { investor_id: this.investorId, offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return { product_id: this.product.id };
    },
  },
  created() {
    this.retrieveInvestorPortfolios(this.defaultPayload);
    this.retrieveProducts({ state: 'approved', limit: 999, offset: 0 });
    this.retrievePortfolioSummaries(this.investorId);
  },
  methods: {
    ...mapActions(['retrieveInvestorPortfolios', 'retrieveProducts', 'retrievePortfolioSummaries']),
    searchPortfolios() {
      this.offset = 0;
      this.getPortfolios();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getPortfolios();
    },
    getPortfolios() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveInvestorPortfolios(payload);
    },
  },
};
</script>
