<template>
  <tr>
    <td>
      <bl-checkbox-av v-if="isAllowed('investor_revise', 'final_approve_reject')" v-model="isChecked" />
    </td>
    <td class="investor-revises-checked-table-row__approved-at">
      {{ investorChecked.approved_at | humanizeDate }}
    </td>
    <td class="investor-revises-checked-table-row__pep">
      {{ newData.pep }}
    </td>
    <td class="investor-revises-checked-table-row__bo">
      {{ newData.bo }}
    </td>
    <td class="investor-revises-checked-table-row__identity-number">
      {{ newData.identity_number }}
    </td>
    <td class="investor-revises-checked-table-row__email">
      {{ newData.email }}
    </td>
    <td class="investor-revises-checked-table-row__state">
      {{ investorChecked.state }}
    </td>
    <td>
      <bl-button-av
        v-if="isAllowed('investor_revise', 'final_approve_reject')"
        class="investor-revises-checked-table-row__view"
        :href="viewUrl"
        @click.prevent.native="gotoDraftView"
      >
        Lihat
      </bl-button-av>
      <bl-button-av
        v-if="isAllowed('investor_revise', 'view_log')"
        class="investor-revises-checked-table-row__history"
        :href="historyUrl"
        @click.prevent.native="gotoDraftHistory"
      >
        Riwayat
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlCheckboxAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlCheckboxAv';
import policyMixin from 'partner-admin/mixins/policyMixin';
import dayjs from 'dayjs';

export default {
  name: 'InvestorRevisesCheckedTableRow',
  filters: {
    humanizeDate(val) {
      if (val) {
        return dayjs(val).format('DD MMMM YYYY');
      }
      return '-';
    },
  },
  components: { BlButtonAv, BlCheckboxAv },
  mixins: [policyMixin],
  props: {
    investorChecked: {
      type: Object,
      required: true,
    },
    chosenInvestors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isChecked: this.chosenInvestors.includes(this.investorChecked.id),
    };
  },
  computed: {
    newData() {
      return this.investorChecked.record.data_revise
        ? this.investorChecked.record.data_revise.last_approved
        : this.investorChecked.record.investor;
    },
    viewUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/revises/checked/${this.investorChecked.id}/review`;
    },
    historyUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/${this.investorChecked.id}/history?type=draft`;
    },
  },
  watch: {
    isChecked: {
      handler(val) {
        this.$emit('chooseInvestor', { id: this.investorChecked.id, checked: val });
      },
    },
    chosenInvestors: {
      handler(val) {
        this.isChecked = val.includes(this.investorChecked.id);
      },
    },
  },
  methods: {
    gotoDraftView() {
      this.$router.push({
        name: 'investorRevisesCheckedReview',
        params: {
          id: this.investorChecked.id,
        },
      });
    },
    gotoDraftHistory() {
      this.$router.push({
        name: 'investorHistory',
        params: {
          id: this.investorChecked.id,
        },
        query: {
          type: 'draft',
        },
      });
    },
  },
};
</script>
