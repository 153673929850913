<template>
  <div>
    <p>
      <span>
        Changes at <strong>{{ createdDate }}</strong>
      </span>
      <span
        v-if="actor"
        class="history-list-item-actor-id"
      >
        by <a :href="actorLink"> {{ actor.username }} </a>
      </span>
      <span
        v-if="history.agent"
        class="history-list-item-agent"
      > from {{ history.agent }} </span>
    </p>
    <history-list-item-row
      v-for="key in changeKeys"
      :key="key"
      :change="generateChange(key)"
      class="u-mrgn-bottom--6"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import HistoryListItemRow from './HistoryListItemRow';

export default {
  name: 'HistoryListItem',
  components: { HistoryListItemRow },
  props: {
    history: {
      type: Object,
      required: true,
    },
    userProfiles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    changeKeys() {
      return this.history.changes ? Object.keys(this.history.changes) : [];
    },
    actor() {
      return this.userProfiles.find(user => user.id === this.history.actor_id);
    },
    actorLink() {
      return this.actor ? `/policies/u/${this.actor.username}` : '#';
    },
    createdDate() {
      const date = this.history.created_at;
      return date ? dayjs(date).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
  methods: {
    generateChange(key) {
      return {
        key,
        value: this.history.changes[key],
      };
    },
  },
};
</script>
