<template>
  <label class="c-inp-wrap">
    <input
      type="radio"
      class="c-inp c-inp--radio"
      :checked="isChecked"
      :disabled="disabled"
      @change="onChange"
    >
    <span
      v-if="$slots.default"
      :class="labelClass"
    > <slot /> </span>
    <span
      v-else
      :class="labelClass"
    > {{ label }} </span>
  </label>
</template>

<script>
export default {
  name: 'BlRadioButtonAv',
  status: 'ready',
  release: '1.0.1',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * Radio Label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Whether the radio is checked
     * `true , false`
     */
    checked: {
      type: [String, Number, Boolean],
      default: false,
    },
    /**
     * Radio Value
     */
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    /**
     * The color of the radio.
     * `blue, red`
     */
    color: {
      type: String,
      required: false,
      validator: value => {
        return ['blue', 'red'].indexOf(value) !== -1;
      },
      default: 'blue',
    },
    /**
     * Whether the radio is disabled
     * `true , false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      if (this.isBoolean) {
        return this.checked;
      }
      return this.checked === this.value;
    },
    isBoolean() {
      return typeof this.checked === 'boolean';
    },
    labelClass() {
      return ['c-inp__inner-lbl', this.color === 'red' ? 'c-inp__inner-lbl--red' : ''];
    },
  },
  methods: {
    onChange() {
      if (this.isBoolean) {
        this.$emit('change', !this.checked);
      } else {
        this.$emit('change', this.value);
      }
    },
  },
};
</script>

<style src="./BlRadioButtonAv.style.scss" lang="scss"></style>
