<template>
  <Table
    :load-state="loadState"
    :rows="files"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <upload-file-management-table-row
      v-for="file in files"
      :key="file.id"
      :file="file"
      @retry="retry"
      @openNotes="openNotes"
    />
    <bl-dialog-mv :active.sync="showNoteDialog" title="Note">
      <div style="margin-bottom: 18px" v-html="notes" />

      <bl-button-av block color="red" @click="showNoteDialog = false">
        Tutup
      </bl-button-av>
    </bl-dialog-mv>
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import UploadFileManagementTableRow from './UploadFileManagementTableRow';

export default {
  name: 'UploadFileManagementTable',
  components: {
    UploadFileManagementTableRow,
    BlButtonAv,
    BlDialogMv,
  },
  mixins: [tableWrapperMixin],
  headers: ['Date Upload', 'Time Upload', 'Type', 'Filename', 'State', 'Notes', ''],
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showNoteDialog: false,
      notes: '',
    };
  },
  methods: {
    retry(payload) {
      this.$emit('retry', payload);
    },
    openNotes(notes) {
      this.notes = notes;
      this.showNoteDialog = true;
    },
  },
};
</script>
