<template>
  <div>
    <div class="c-tab__content__head">
      <router-link :to="backLink">
        {{ backText }}
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <history-list
        :load-state="historiesLoadState"
        :history-data="histories"
        :user-profiles="userProfiles"
        :limit="limit"
        :offset="offset"
        :total="historiesTotal"
        @changePage="setOffset"
        @reload="getHistories()"
      />
    </div>
  </div>
</template>

<script>
import HistoryMixin from 'partner-admin/mixins/historyMixin';
import HistoryList from 'partner-admin/components/HistoryList';

export default {
  name: 'InvestorHistoryPage',
  mapType: {
    investor: 'Investor',
    draft: 'Draft',
  },
  components: {
    HistoryList,
  },
  mixins: [HistoryMixin],
  computed: {
    backLink() {
      if (this.type === 'draft') {
        return '/investors/drafts';
      }
      return '/investors';
    },
    backText() {
      return `< Kembali Ke Halaman Daftar ${this.$options.mapType[this.type]}`;
    },
  },
};
</script>
