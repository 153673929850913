<template>
  <div>
    <bl-field-mv>
      <bl-field-row-mv>
        <bl-field-label-av> Email </bl-field-label-av>
        <bl-text-field-mv
          v-model="email"
          type="email"
        />
        <bl-field-error-av
          v-if="emailErrorMessage"
          class="login-form__email-error"
        >
          {{ emailErrorMessage }}
        </bl-field-error-av>
      </bl-field-row-mv>
    </bl-field-mv>
    <bl-field-mv>
      <bl-field-row-mv>
        <bl-field-label-av> Password </bl-field-label-av>
        <bl-text-field-mv
          v-model="password"
          type="password"
        />
        <bl-field-error-av
          v-if="passwordErrorMessage"
          class="login-form__password-error"
        >
          {{ passwordErrorMessage }}
        </bl-field-error-av>
      </bl-field-row-mv>
    </bl-field-mv>

    <div class="u-pad-top--2">
      <bl-field-error-av
        v-if="submitErrorMessage"
        class="login-form__submit-error u-pad-bottom--1"
      >
        {{ submitErrorMessage }}
      </bl-field-error-av>
      <bl-button-av
        class="login-form__login"
        color="red"
        block
        has-spinner
        :activted="isSubmitting"
        @click.native="submit"
      >
        Login
      </bl-button-av>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import notifMixin from 'partner-admin/mixins/notifMixin';

export default {
  name: 'LoginForm',
  emailValidationRegex: /^[.a-zA-z0-9@-]+$/,
  emailFormatRegex: /\S+@\S+\.\S+/,
  passwordRegex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^0-9a-zA-Z]).{12,}$/,
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlFieldLabelAv,
    BlFieldErrorAv,
    BlButtonAv,
  },
  mixins: [notifMixin],
  data() {
    return {
      email: '',
      isEmailDirty: false,
      password: '',
      isPasswordDirty: false,
      submitErrorMessage: '',
      isSubmitting: false,
    };
  },
  computed: {
    isEmailLengthInvalid() {
      return this.isEmailDirty && this.email.length < 4;
    },
    isEmailFormatInvalid() {
      return (
        this.isEmailDirty &&
        (!this.$options.emailValidationRegex.test(this.email) || !this.$options.emailFormatRegex.test(this.email))
      );
    },
    isEmailValid() {
      return !this.isEmailLengthInvalid && !this.isEmailFormatInvalid;
    },
    emailErrorMessage() {
      if (this.isEmailLengthInvalid) {
        return 'Min. email 4 karakter';
      }
      if (this.isEmailFormatInvalid) {
        return 'Format email salah';
      }
      return '';
    },
    isPasswordLengthInvalid() {
      return this.isPasswordDirty && this.password.length < 12;
    },
    isPasswordFormatInvalid() {
      const bracketExist = /[<>]/g.test(this.password);
      return (this.isPasswordDirty && !this.$options.passwordRegex.test(this.password)) || bracketExist;
    },
    isPasswordValid() {
      return !this.isPasswordLengthInvalid && !this.isPasswordFormatInvalid;
    },
    passwordErrorMessage() {
      if (this.isPasswordLengthInvalid) {
        return 'Min. password 12 karakter';
      }
      if (this.isPasswordFormatInvalid) {
        return 'Format password salah';
      }
      return '';
    },
  },
  watch: {
    email() {
      this.isEmailDirty = true;
    },
    password() {
      this.isPasswordDirty = true;
    },
  },
  methods: {
    ...mapActions('session', ['login']),
    submit() {
      this.isEmailDirty = true;
      this.isPasswordDirty = true;
      this.submitErrorMessage = '';

      if (this.isPasswordValid && this.isEmailValid) {
        this.iisSubmitting = true;
        const payload = {
          username: this.email,
          password: this.password,
        };
        this.login(payload)
          .then(() => {
            this.$router.push({ name: 'transactionsIndex' });
          })
          .catch(e => {
            if (e.errors) {
              this.submitErrorMessage = e.errors[0].message;
            } else if (e.message === 'Oauth::MustChangePassword') {
              this.$router.push({ name: 'changePasswordPage' });
            } else {
              this.showErrorNotif(e);
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
