<template>
  <div :class="['c-btn-group', isEqual ? 'c-btn-group--equal' : '']">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'BlButtonGroupMv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * Whether the button is equal or not
     * `true , false`
     */
    isEqual: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./BlButtonGroupMv.style.scss" lang="scss"></style>
