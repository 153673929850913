<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" class="custom-dialog" :title="`Pilih Tanggal ${title}`">
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-date-picker-mv
            v-model="chosenDate"
            class="u-width-1"
            is-has-multiple-calendar
            is-date-range-enabled
            :begin-date="chosenDate.beginDate || currentDate"
            :end-date="chosenDate.endDate"
            multiple-calendar
          />
        </bl-field-row-mv>
        <bl-field-row-mv>
          <bl-dropdown-av
            v-if="showAgentDropdown"
            v-model="agent"
            class="download-investor-file-sheet__agent u-mrgn-top--4"
            placeholder="Pilih Agent"
            track-by="id"
            label="name"
            :options="[$options.blankOption, ...agentOptions]"
          />
        </bl-field-row-mv>
        <bl-field-row-mv>
          <bl-checkbox-av v-model="isRedownload">
            Re-download file
          </bl-checkbox-av>
        </bl-field-row-mv>
      </bl-field-mv>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          class="download-investor-file-sheet__cancel"
          style="width: 50%; display: inline-block;"
          @click="cancelAction"
        >
          Cancel
        </bl-button-av>
        <bl-button-av
          class="download-investor-file-sheet__download"
          style="width: 50%; display: inline-block;"
          color="red"
          @click="downloadInvestor"
        >
          Download
        </bl-button-av>
        <file-downloader ref="FileDownloader" />
      </bl-button-group-mv>
    </bl-dialog-mv>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'partner-admin/lib/vuex';
import queryString from 'querystring';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlCheckboxAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlCheckboxAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import FileDownloader from 'partner-admin/components/FileDownloader';
import readFile from 'partner-admin/shared/helpers/file/readFile';

const downloadUrl = `${process.env.PARTNER_API_URL}/_exclusive/investors/download`;

export default {
  name: 'DownloadInvestorFileSheet',
  blankOption: { id: '', name: 'All' },
  components: {
    BlDialogMv,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonGroupMv,
    BlButtonAv,
    BlCheckboxAv,
    BlDatePickerMv,
    FileDownloader,
    BlDropdownAv,
  },
  props: {
    agentOptions: {
      type: Array,
      required: true,
    },
  },
  titleMap: {
    sid: 'Download SID',
    ifua: 'Download IFUA',
    revise_data: 'Update Investor',
    revise_bank: 'Update Bank',
    bank: 'Download Bank',
  },
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      confirmationDialog: false,
      actionType: '',
      chosenDate: {},
      isRedownload: false,
      agent: this.$options.blankOption,
      currentDate,
      fileModel: null,
    };
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    title() {
      return this.$options.titleMap[this.actionType] || this.actionType;
    },
    showAgentDropdown() {
      return ['sid', 'ifua', 'bank'].includes(this.actionType);
    },
  },
  watch: {
    confirmationDialog(value) {
      if (!value) this.resetValue();
    },
  },
  methods: {
    resetValue() {
      this.actionType = '';
      this.chosenDate = {};
      this.isRedownload = false;
      this.agent = this.$options.blankOption;
      this.fileModel = null;
    },
    cancelAction() {
      this.confirmationDialog = false;
    },
    downloadInvestor() {
      const token = this.tokenObj.token;

      const payload = {
        start_date: dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD'),
        end_date: dayjs(this.chosenDate.endDate).format('YYYY-MM-DD'),
        type: this.actionType,
        redownload: this.isRedownload,
        access_token: token,
      };

      if (this.agent.id) {
        payload.agent_id = this.agent.id;
      }

      const url = new URL(`${downloadUrl}?${queryString.stringify(payload)}`);

      this.$refs.FileDownloader.download(`${url}`);
    },
    openDialog(type) {
      this.actionType = type;
      this.confirmationDialog = true;
    },
    onFileChange(files) {
      readFile(files[0]).then(({ file, name }) => {
        this.fileModel = {
          file_name: name,
          file,
        };
      });
    },
  },
};
</script>

<style scoped>
.custom-dialog /deep/ .c-dialog__content {
  max-width: 680px !important;
}
.custom-dialog /deep/ .c-dialog__panel__body {
  max-height: 560px !important;
}
.custom-dialog /deep/ .c-popover__outer {
  position: relative;
}
</style>
