export const transactionTypeMap = {
  subscription: 'Subscription',
  redemption: 'Redemption',
  'switching-in': 'Switching In',
  'switching-out': 'Switching Out',
  dividend: 'Dividend',
  'transfer-in': 'Transfer Asset In',
  'transfer-out': 'Transfer Asset Out',
};

export const transactionTypeOptions = Object.keys(transactionTypeMap).reduce((arr, key) => {
  return [...arr, { code: key, label: transactionTypeMap[key] }];
}, []);

export default {
  transactionTypeMap,
  transactionTypeOptions,
};
