<template>
  <table :class="tableClasses">
    <slot />
  </table>
</template>

<script>
import { columnsWidth, size, border } from './data/type';

export default {
  name: 'BlTableAv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The columns width type of the Table.
     * `default, auto, equal`
     */
    columnsWidth: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return columnsWidth;
        }
        return columnsWidth.indexOf(value) !== -1;
      },
    },
    /**
     * The size of the Table.
     * `default, narrow, tight`
     */
    size: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return size;
        }
        return size.indexOf(value) !== -1;
      },
    },
    /**
     * The border type of the Table.
     * `default, rows, columns, cells`
     */
    border: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return border;
        }
        return border.indexOf(value) !== -1;
      },
    },
    /**
     * The fixed size of the Table.
     */
    fixedSize: {
      type: Boolean,
      default: false,
    },
    /**
     * The zebra style of the Table.
     */
    zebra: {
      type: Boolean,
      default: false,
    },
    /**
     * The transaction type of the Table.
     */
    transaction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableClasses() {
      const array = ['c-table'];
      if (this.columnsWidth !== 'default') {
        array.push(`c-table--${this.columnsWidth}`);
      }
      if (this.size !== 'default') {
        array.push(`c-table--${this.size}`);
      }
      if (this.border !== 'default') {
        array.push(`c-table--${this.border}`);
      }
      if (this.fixedSize) {
        array.push(`c-table--fixed-size`);
      }
      if (this.zebra) {
        array.push(`c-table--zebra`);
      }
      if (this.transaction) {
        array.push(`c-table--transaction`);
      }
      return array;
    },
  },
};
</script>

<style src="./BlTableAv.style.scss" lang="scss"></style>
