<template>
  <tr>
    <td class="agent-table-row__name" style="width: 300px">
      {{ agent.name }}
    </td>
    <td class="agent-table-row__code">
      {{ agent.client_code_prefix }}
    </td>
  </tr>
</template>
<script>
export default {
  name: 'AgentTableRow',
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
};
</script>
