/**
 * @module helpers/number-helper
 */

/**
 * Delimit number
 *
 * @example
 * delimiter(1000000)
 * // => '1.000.000'
 * delimiter(1000000.12, 2)
 * // => '1.000.000,12'
 *
 * @param  {Number|String} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(val, precision = 0) {
  const str = `${val}`; // convert to string
  const x = str.split('.');
  const delimited = x[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  let result = `${val < 0 ? '-' : ''}${delimited}`;
  if (precision > 0 && x[1]) {
    let pre;
    const multiplier = 10 ** precision;
    if (x[1].length > precision) {
      pre = Math.round(parseInt(x[1].substr(0, precision + 1), 10) / 10);
    } else {
      pre = Math.round(parseInt(x[1], 10) * multiplier) / multiplier;
    }
    result += `,${pre}`;
  }
  return result;
}

export const listCurrency = [
  {
    ccy: 'IDR',
    symbol: 'Rp',
  },
  {
    ccy: 'USD',
    symbol: '$',
  },
  {
    ccy: 'EUR',
    symbol: '€',
  },
];

/**
 * Convert number to money
 *
 * @example
 * money(1000000)
 * // => 'Rp1.000.000'
 *
 * @param  {Number|String} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val, precision = 2, ccy = 'IDR') {
  const selectedCurrency = listCurrency.find(item => item.ccy === ccy.toUpperCase());
  const prefix = selectedCurrency ? selectedCurrency.symbol : '';
  const int = delimiter(parseInt(val, 10) || 0, precision);
  const abs = Math.abs(val);
  const fraction = (abs < 1 ? abs : Math.abs(val % Math.floor(abs))) || 0;
  const fractionStr =
    fraction > 0
      ? fraction
          .toFixed(precision)
          .slice(1)
          .replace('.', ',')
      : '';
  if (int.charAt(0) === '-') {
    return int.replace(/^-/g, `-${prefix}`) + fractionStr;
  }
  return `${prefix}${int}${fractionStr}`;
}

export function round(val, precision = 2) {
  const factor = 10 ** precision;
  return Math.round((val + 0.00001) * factor) / factor;
}

export default {
  delimiter,
  money,
  round,
};
