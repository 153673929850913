import { createKeyValueCombination as createOptions } from 'partner-admin/shared/helpers/arrayHelper';

export const FormTypes = ['new', 'edit', 'view', 'review'];

export const ProductInputFields = [
  {
    name: 'fund_code',
    label: 'Fund Code',
    type: 'text',
    rule: {
      regex: /^[0-9A-Z]{5}[0-9A-Z]{9}[0-9]{2}$/,
    },
  },
  {
    name: 'fund_name',
    label: 'Fund Name',
    type: 'text',
    rule: {
      max: 100,
    },
  },
  {
    name: 'fund_short_name',
    label: 'Fund Short Name',
    type: 'text',
    rule: {
      length: 6,
    },
  },
  {
    name: 'im_code',
    label: 'IM Code',
    type: 'text',
    rule: {
      length: 5,
    },
  },
  {
    name: 'im_name',
    label: 'IM Name',
    type: 'text',
    rule: {
      max: 100,
    },
  },
  {
    name: 'fund_type',
    label: 'Fund Type',
    type: 'select',
    options: createOptions(['Money Market', 'Fixed Income', 'Equity', 'Mixed Asset']),
  },
  {
    name: 'fund_ccy',
    label: 'Fund Currency',
    type: 'text',
    hint: '3 karakter, contoh: IDR, USD',
    rule: {
      length: 3,
    },
  },
  {
    name: 'sharia_compliance',
    label: 'Sharia Compliance',
    type: 'select',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  {
    name: 'launch_date',
    label: 'Launch Date',
    type: 'date',
    options: {},
  },
  {
    name: 'initial_nav',
    label: 'Initial NAV',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      regex: /^\d{1,14}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'isin_code',
    label: 'ISIN Code',
    type: 'text',
    rule: {
      max: 100,
    },
  },
  {
    name: 'cb_code',
    label: 'Custodian Bank Code',
    type: 'text',
    rule: {
      length: 5,
    },
  },
  {
    name: 'cb_name',
    label: 'Custodian Bank Name',
    type: 'select',
    searchable: true,
  },
  {
    name: 'cb_account_no',
    label: 'Custodian Bank Account Number',
    type: 'text',
    rule: {
      numeric: true,
    },
  },
  {
    name: 'cb_account_name',
    label: 'Custodian Bank Account Name',
    type: 'text',
  },
  // {
  //   name: 'cb_account_id',
  //   label: 'Custodian Bank Account ID',
  //   type: 'number',
  // },
  {
    name: 'aum',
    label: 'Asset Under Management',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      regex: /^\d{1,28}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'institution_allocation',
    label: 'Institution Allocation',
    multiline: true,
    type: 'text',
  },
  {
    name: 'instrument_allocation',
    label: 'Instrument Allocation',
    multiline: true,
    type: 'text',
    hint: `Format Instrument Allocation 'instrument - nilai%'`
  },
  {
    name: 'intermediary_bank',
    label: 'Intermediary Bank',
    type: 'text',
  },
  {
    name: 'min_subscription',
    label: 'Minimal Subscription',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      numeric: true,
    },
  },
  {
    name: 'max_subscription_fee',
    label: 'Max Subscription Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'min_subsequent_subscription',
    label: 'Minimal Subsequent Subscription',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      numeric: true,
    },
  },
  {
    name: 'subscription_fee',
    label: 'Subscription Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'min_redemption',
    label: 'Minimal Redemption',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      numeric: true,
    },
  },
  {
    name: 'max_redemption_fee',
    label: 'Max Redemption Fee(%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'max_redemption_per_day',
    label: 'Maximum Redemption per Day',
    type: 'number',
    format: 'money-without-currency',
    rule: {
      numeric: true,
    },
  },
  {
    name: 'redemption_fee',
    label: 'Redemption Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },

  {
    name: 'switching_in_fee',
    label: 'Switching In Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'max_switching_in_fee',
    label: 'Max Switching In Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'switching_out_fee',
    label: 'Switching Out Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'max_switching_out_fee',
    label: 'Max Switching Out Fee (%)',
    type: 'number',
    format: 'percentage',
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'residual_value',
    label: 'Minimum Dana Mengendap',
    type: 'number',
    rule: {
      regex: /^\d{1,14}(,\d{0,4}){0,1}$/,
      numeric: true,
    },
  },
  {
    name: 'residual_type',
    label: 'Minimum Dana Mengendap (satuan)',
    type: 'select',
    options: [
      { label: 'Unit', value: 'unit' },
      { label: 'Nominal', value: 'nominal' },
    ],
  },
  {
    name: 'management_fee_percentage',
    label: 'Management Fee (%)',
    type: 'number',
    format: 'percentage',
    editReadonly: false,
    rule: {
      regex: /^\d{1,6}(,\d{0,4}){0,1}$/,
    },
  },
  {
    name: 'management_fee_config',
    label: 'AUM Calculation Type',
    type: 'select',
    options: [
      { label: 'Ending Balance T-0 x NAB T-0', value: 1 },
      { label: 'Ending Balance T-1 x NAB T-0', value: 2 },
      { label: 'Ending Balance T+1 x NAB T+1', value: 3 },
      { label: 'Ending Balance T-1 x NAB T-1', value: 4 },
    ],
    editReadonly: false,
  },
  {
    name: 'prospectus',
    label: 'Prospectus File',
    type: 'file',
    format: 'pdf',
    hint: 'File harus dengan ekstensi .pdf',
    rule: {
      custom_file_extension: {
        ext_regex: /.pdf$/,
        message: 'Format file prospektus salah',
      },
    },
  },
  {
    name: 'factsheet',
    label: 'Factsheet File',
    type: 'file',
    format: 'pdf',
    hint: 'File harus dengan ekstensi .pdf',
    rule: {
      custom_file_extension: {
        ext_regex: /.pdf$/,
        message: 'Format file factsheet salah',
      },
    },
  },
  {
    name: 'logo',
    label: 'Logo File',
    type: 'file',
    format: 'image',
    hint: 'File harus dengan ekstensi .jpg/.jpeg/.png',
    rule: {
      custom_file_extension: {
        ext_regex: /.(png|jpeg|jpg)$/,
        message: 'Format gambar logo salah',
      },
    },
  },
];

export default {};
