<template>
  <tr>
    <td class="investor-table-row__created">
      {{ investor.created_at | formatDateTime }}
    </td>
    <td class="investor-table-row__sid">
      {{ investor.sid }}
    </td>
    <td class="investor-table-row__ifua">
      {{ investor.ifua }}
    </td>
    <td class="investor-table-row__identity">
      {{ investor.identity_number }}
    </td>
    <td class="investor-table-row__email">
      {{ investor.email }}
    </td>
    <td class="investor-table-row__agent">
      {{ investor.agent && investor.agent.name }}
    </td>
    <td class="investor-table-row__waiting-update">
      {{ investor.waiting_update }}
    </td>
    <td class="investor-table-row__state">
      {{ investor.state }}
    </td>
    <td class="investor-table-row__risk">
      {{ investor.risk }}
    </td>
    <td>
      <bl-button-av class="investor-table-row__view" :href="viewUrl" @click.prevent.native="gotoView">
        Lihat
      </bl-button-av>
      <bl-button-av
        v-if="isAllowed('investor', 'view_log')"
        class="investor-table-row__riwayat"
        :href="historyUrl"
        @click.prevent.native="gotoHistory"
      >
        Riwayat
      </bl-button-av>
      <bl-button-av
        v-if="isAllowed('investor_revise', 'view_list')"
        class="investor-table-row__history"
        :href="historyUpdateUrl"
        @click.prevent.native="gotoHistoryUpdate"
      >
        History
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';

export default {
  name: 'InvestorTableRow',
  components: { BlButtonAv },
  filters: {
    formatDateTime(val) {
      return val ? dayjs(val).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
  mixins: [policyMixin],
  props: {
    investor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    viewUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/${this.investor.id}/view`;
    },
    historyUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/${this.investor.id}/history?type=investor`;
    },
    historyUpdateUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/${
        this.investor.id
      }/history-update?name=${this.investor.name || ''}`;
    },
  },
  methods: {
    gotoView() {
      this.$router.push({
        name: 'investorView',
        params: {
          id: this.investor.id,
        },
      });
    },
    gotoHistory() {
      this.$router.push({
        name: 'investorHistory',
        params: {
          id: this.investor.id,
        },
        query: {
          type: 'investor',
        },
      });
    },
    gotoHistoryUpdate() {
      this.$router.push({
        name: 'investorHistoryUpdate',
        params: {
          id: this.investor.id,
        },
        query: {
          name: this.investor.name,
        },
      });
    },
  },
};
</script>
