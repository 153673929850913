export function buildErrorMessage(errors) {
  let message = '';
  errors.forEach(data => {
    if (data.field) {
      message += `${data.field} `;
    }
    message += `${data.message}<br />`;
  });
  return message.replace(/<br[^>]*>?$/gi, '');
}

export function getErrorMessage(error) {
  if (error.errors && error.errors.length) {
    return buildErrorMessage(error.errors);
  }
  return 'Terjadi kesalahan pada sistem, silakan coba kembali';
}

export function getErrorNotification(error, source = 'mobile') {
  if (source === 'desktop') {
    return {
      text: getErrorMessage(error),
      type: 'error',
    };
  }
  return {
    content: getErrorMessage(error),
    type: 'warning',
  };
}

export default {
  getErrorMessage,
  getErrorNotification,
};
