export default {
  name: {
    max: 100,
  },
  birth_place: {
    max: 100,
  },
  postal_code: {
    numeric: true,
    length: 5,
  },
  email: {
    email: true,
  },
};
