<template>
  <div
    ref="popoverWrapper"
    :class="[{ 'c-popover--hover-trigger': hover }, popoverClasses]"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <div
      ref="popoverToggler"
      @click="handleToggle"
    >
      <slot />
    </div>

    <div class="c-popover__outer">
      <div
        class="c-popover__inner"
        @click="handleClickOnInner"
      >
        <div
          v-if="$slots.header"
          class="c-popover__header"
        >
          <slot name="header" />
        </div>

        <slot name="content" />

        <div
          v-if="$slots.footer"
          class="c-popover__footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sizes from './data/sizes.json';
import triggers from './data/triggers.json';
import positions from './data/positions.json';

export default {
  name: 'BlPopoverAv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The size of the Popover.
     * `default,small, medium, large, xlarge, xxlarge, auto`
     */
    size: {
      type: String,
      default: 'default',
      validator: value => {
        return sizes.indexOf(value) !== -1;
      },
    },
    /**
     * The trigger of the Popover.
     * `click, manual, hover`
     */
    trigger: {
      type: String,
      default: 'click',
      validator: value => {
        return triggers.indexOf(value) !== -1;
      },
    },
    /**
     * The position of the Popover.
     * `default, top, right`
     */
    position: {
      type: String,
      default: 'default',
      validator: value => {
        return positions.indexOf(value) !== -1;
      },
    },
    /**
     * The position top of the Popover.
     */
    upward: {
      type: Boolean,
      default: false,
    },
    /**
     * Use for disable the Popover
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Use to keep the Popover show
     */
    keepPopover: {
      type: Boolean,
      default: false,
    },
    /**
     * Use for disable the Popover
     */
    delay: {
      type: Number,
      default: 0,
    },
    /**
     * Use with trigger `manual` for show the Popover
     */
    show: {
      type: Boolean,
      default: false,
    },
    /**
     * The dark background of the Popover.
     */
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      timer: null,
    };
  },
  computed: {
    popoverClasses() {
      const mainClass = 'c-popover';
      let classes = [mainClass, 'u-display-inline-block'];

      Object.keys(this.$props).forEach(propName => {
        const propValue = this.$props[propName];

        if (propValue) {
          if (propName === 'trigger' && propValue === 'click') {
            classes.push(`${mainClass}--${propValue}-trigger`);
          } else if (propName === 'trigger' && propValue === 'manual') {
            if (this.show && !this.disabled) {
              classes.push('is-open');
            } else {
              classes = classes.filter(item => item !== 'is-open');
            }
          } else if (propName === 'upward') {
            classes.push(`${mainClass}--${propName}`);
          } else if (propName === 'disabled') {
            classes.push(`${mainClass}--${propName}`);
          } else if (propName === 'dark') {
            classes.push(`${mainClass}--${propName}`);
          } else {
            classes.push(`${mainClass}--${propValue}`);
          }
        }
      });

      return classes;
    },
  },
  created() {
    if (this.trigger === 'click') {
      document.addEventListener('click', this.handleClick);
    }
  },
  destroyed() {
    if (this.trigger === 'click') {
      document.removeEventListener('click', this.handleClick);
    }
  },
  methods: {
    handleMouseOver() {
      if (this.trigger === 'hover') {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.hover = true;
        }, this.delay);
      }
    },

    handleMouseLeave() {
      if (this.trigger === 'hover') {
        clearTimeout(this.timer);
        this.hover = false;
      }
    },

    handleClick(event) {
      if (!this.$refs.popoverWrapper.contains(event.target)) {
        this.closePopover();
      }
    },

    handleToggle() {
      if (this.trigger === 'click' && !this.disabled) {
        this.$refs.popoverWrapper.classList.toggle('is-open');
      }
    },

    handleClickOnInner() {
      if (!this.keepPopover) {
        this.handleToggle();
      }
    },

    closePopover() {
      this.$refs.popoverWrapper.classList.remove('is-open');
    },
  },
};
</script>

<style src="./BlPopoverAv.style.scss" lang="scss"></style>
