import api from 'partner-admin/api';

const states = {
  navs: [],
  navsLoadState: 'idle',
  navsTotal: 0,
};

const getters = {
  getNavs: state => state.navs,
  getNavsLoadState: state => state.navsLoadState,
  getNavsTotal: state => state.navsTotal,
};

const mutations = {
  setNavs: (state, val) => {
    state.navs = val;
  },
  setNavsLoadState: (state, val) => {
    state.navsLoadState = val;
  },
  setNavsTotal: (state, val) => {
    state.navsTotal = val;
  },
};

const actions = {
  retrieveNavs({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.navsLoadState !== 'loading') {
        commit('setNavsLoadState', 'loading');
        api
          .getNavs(payload)
          .then(response => {
            commit('setNavsLoadState', 'success');
            commit('setNavs', response.data);
            commit('setNavsTotal', response.meta.total);
            resolve(response.data);
          })
          .catch(response => {
            commit('setNavsLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.navs);
      }
    });
  },
  retrieveNavProduct({ state, dispatch }, id) {
    return new Promise((resolve, reject) => {
      dispatch('retrieveNavs')
        .then(() => {
          const found = state.navs.find(nav => nav.id === id);
          if (found) {
            resolve(found.product && found.product.id);
            return;
          }
          resolve(null);
        })
        .catch(reject);
    });
  },
  retrieveNavDetail({ state, dispatch }, id) {
    return new Promise((resolve, reject) => {
      dispatch('retrieveNavProduct', id)
        .then(productId => {
          if (productId) {
            const data = state.navs.filter(nav => nav.id === id);
            resolve(data);
          }
        })
        .catch(reject);
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
