import { getErrorMessage } from 'partner-admin/shared/helpers/errorHelper';

export default {
  methods: {
    handleError(payload) {
      let text = getErrorMessage(payload);
      if (typeof payload === 'string' && payload.indexOf('<!DOCTYPE html>') === -1) {
        text = payload;
      }

      this.$root.$emit('showFlashNotif', {
        text,
        type: 'error',
      });
    },
  },
};
