<template>
  <div>
    <div class="o-layout o-layout--responsive">
      <div class="o-layout__item u-6of12">
        <field-detail-info :info-data="investorData" />
      </div>

      <div class="o-layout__item u-6of12">
        <field-detail-info :info-data="rightData" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { money, delimiter } from 'partner-admin/shared/helpers/numberHelper';
import FieldDetailInfo from './FieldDetailInfo';

export default {
  name: 'UserDetailInfo',
  components: {
    FieldDetailInfo,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    portofolios: {
      type: Object,
      required: true,
    },
  },
  computed: {
    investorData() {
      return [
        { label: 'SID', value: this.portofolios.investor.sid },
        { label: 'IFUA', value: this.portofolios.investor.ifua },
        { label: 'NIK', value: this.portofolios.investor.identity_number },
        { label: 'Agent', value: this.portofolios.agent && this.portofolios.agent.name },
        { label: 'Email', value: this.portofolios.investor.email },
        { label: 'Name', value: this.portofolios.investor.name },
      ];
    },
    detailPortofoliosData() {
      return [
        { label: 'Total Portofolio', value: money(this.portofolios.total_amount_portfolio) },
        {
          label: 'Keuntungan',
          value: `${money(this.portofolios.profit_portfolio)} (${this.sign}${this.portofolios.percentage_portfolio}%)`,
        },
        { label: 'Last Update', value: dayjs(this.portofolios.last_update_portfolio).format('DD-MMM-YYYY hh:mm') },
      ];
    },
    productPortofoliosData() {
      return [
        {
          label: 'Total Amount',
          value: money(this.portofolios.total_amount, 2, this.portofolios.ccy),
        },
        {
          label: 'Keuntungan',
          value: `${money(this.portofolios.profit, 2, this.portofolios.ccy)} (${this.sign}${
            this.portofolios.percentage
          }%)`,
        },
        { label: 'Pending Redeem (unit)', value: delimiter(this.portofolios.pending_unit, 4) },
      ];
    },
    sign() {
      return parseInt(this.portofolios.percentage, 10) >= 0 ? '+' : '';
    },
    rightData() {
      return this.mode === 'productPortofolios' ? this.productPortofoliosData : this.detailPortofoliosData;
    },
  },
};
</script>
