<template>
  <div>
    <div class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Draft Investor
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-draft-view-form :draft="draft" :investor="investor" />
    </div>
  </div>
</template>

<script>
import InvestorDraftMixin from '../mixins/InvestorDraftMixin';
import InvestorDraftViewForm from '../components/InvestorDraftViewForm';

export default {
  name: 'InvestorDraftViewPage',
  components: { InvestorDraftViewForm },
  mixins: [InvestorDraftMixin],
};
</script>
