<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="spinnerSize"
    :height="spinnerSize"
    viewBox="0 0 40 40"
    enable-background="new 0 0 40 40"
    xml:space="preserve"
    v-bind="$attrs"
  >
    <path
      :opacity="backOpacity"
      :fill="backColor"
      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
      s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
      c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
    />
    <path
      :opacity="frontOpacity"
      :fill="frontColor"
      d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
      C22.32,8.481,24.301,9.057,26.013,10.047z"
      transform="rotate(300 20 20)"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 20 20"
        to="360 20 20"
        dur="0.5s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>

<script>
import { variant, size } from './data/type';

export default {
  name: 'BlSpinnerAv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The type color of the Spinner.
     * `red, white, default`
     */
    variant: {
      type: String,
      default: 'default',
      validator: value => variant.indexOf(value) !== -1,
    },
    /**
     * The type size of the Spinner.
     * `small, medium, large`
     */
    size: {
      type: String,
      default: 'medium',
      validator: value => size.indexOf(value) !== -1,
    },
  },
  computed: {
    backOpacity() {
      switch (this.variant) {
        case 'red':
          return '1';
        case 'white':
          return '0.2';
        default:
          return '0.2';
      }
    },
    backColor() {
      switch (this.variant) {
        case 'red':
          return '#d71149';
        case 'white':
          return '#fff';
        default:
          return '#000';
      }
    },
    frontOpacity() {
      switch (this.variant) {
        case 'red':
          return '0.8';
        case 'white':
          return '1';
        default:
          return '1';
      }
    },
    frontColor() {
      switch (this.variant) {
        case 'red':
          return '#fff';
        case 'white':
          return '#fff';
        default:
          return '#d71149';
      }
    },
    spinnerSize() {
      switch (this.size) {
        case 'small':
          return '20px';
        case 'large':
          return '60px';
        default:
          return '40px';
      }
    },
  },
};
</script>
