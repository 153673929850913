<template>
  <Table
    :load-state="loadState"
    :rows="agents"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <agent-table-row v-for="agent in agents" :key="agent.id" :agent="agent" />
  </Table>
</template>
<script>
/* istanbul ignore next */
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import AgentTableRow from './AgentTableRow';

export default {
  name: 'AgentTable',
  components: {
    AgentTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Agent Name', 'Agent Code'],
  props: {
    agents: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
