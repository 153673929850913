<template>
  <div
    :class="getClasses"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'BlPanelMv',
  status: 'ready',
  release: '0.4.0',
  props: {
    /**
     * Whether the panel is accordion
     * `true , false`
     */
    accordion: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the panel is active
     * `true , false`
     */
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      const baseClass = 'c-panel';
      return [baseClass, this.accordion ? `${baseClass}--accordion` : '', this.active ? 'is-active' : ''];
    },
  },
};
</script>
<style src="./BlPanelMv.style.scss" lang="scss"></style>
