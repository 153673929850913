import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      draft: null,
      investorRevise: null,
      loadState: 'idle',
    };
  },
  methods: {
    retrieveInitialData() {
      this.loadState = 'loading';
      this.getDraft()
        .then(this.getInvestorRevises)
        .then(this.validatePageAccess)
        .then(this.renderForm)
        .catch(e => {
          this.loadState = 'error';
          this.showErrorNotif(e);
        });
    },
    getDraft() {
      return new Promise((resolve, reject) => {
        if (this.$route.query.type === 'investor-revise') {
          resolve();
          return;
        }

        this.$api
          .getDraft(this.$route.params.id)
          .then(({ data }) => {
            this.draft = data;
            resolve();
          })
          .catch(reject);
      });
    },
    getInvestorRevises() {
      return new Promise((resolve, reject) => {
        const investorReviseId = this.draft ? this.draft.record_id : this.$route.params.id;
        if (investorReviseId) {
          this.$api
            .getInvestorRevise(investorReviseId)
            .then(({ data }) => {
              this.investorRevise = data;
              resolve();
            })
            .catch(reject);
        } else {
          resolve();
        }
      });
    },
    validatePageAccess() {
      return new Promise(resolve => {
        let allowedPage;
        if (this.draft && this.draft.state === 'created' && this.isAllowed('investor_revise', 'edit')) {
          allowedPage = 'investorRevisesEdit';
        }
        if (this.draft && this.draft.state === 'submitted' && this.isAllowed('investor_revise', 'approve_reject')) {
          allowedPage = 'investorRevisesReview';
        }
        if (
          this.draft &&
          ['final_approved', 'final_rejected'].includes(this.draft.state) &&
          this.isAllowed('investor_revise', 'view_list')
        ) {
          allowedPage = 'investorRevisesView';
        }
        if (allowedPage && this.$route.name !== allowedPage) {
          this.$router.replace({ name: allowedPage });
        }
        resolve();
      });
    },
    renderForm() {
      this.loadState = 'success';
    },
  },
};
