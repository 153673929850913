<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/transactions">
        &lt; Kembali Ke Halaman Daftar Transaksi
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <history-list
        :load-state="historiesLoadState"
        :history-data="histories"
        :user-profiles="userProfiles"
        :limit="limit"
        :offset="offset"
        :total="historiesTotal"
        @changePage="setOffset"
        @reload="getHistories()"
      />
    </div>
  </div>
</template>

<script>
import HistoryMixin from 'partner-admin/mixins/historyMixin';
import HistoryList from 'partner-admin/components/HistoryList';

export default {
  name: 'TransactionsHistoryPage',
  components: {
    HistoryList,
  },
  mixins: [HistoryMixin],
};
</script>
