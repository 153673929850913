<template>
  <multi-select
    v-model="internalValue"
    :class="getClasses"
    :disabled="disabled"
    :required="required"
    :options="options"
    :searchable="searchable"
    :max-height="maxHeight"
    :track-by="trackBy"
    :multiple="multiple"
    v-bind="$attrs"
    :show-no-options="showNoOptions"
    @search-change="searchChange"
  >
    <!-- @slot Slot to provide a noResult -->
    <template slot="noResult">
      <slot name="noResult">
        Pilihan tidak ditemukan
      </slot>
    </template>
    <!-- @slot Slot to provide a maxElements -->
    <template slot="maxElements">
      <slot name="maxElements" />
    </template>
    <!-- @slot Slot to provide a afterList -->
    <template slot="afterList">
      <slot name="afterList" />
    </template>
    <!-- @slot Slot to provide a noOptions -->
    <template slot="noOptions">
      <slot name="noOptions" />
    </template>
    <!-- @slot Slot to provide a options -->
    <template name="options">
      <slot name="options" />
    </template>
  </multi-select>
</template>

<script>
import multiSelect from 'vue-multiselect';
import sizeOptions from './data/sizes.json';
import widthOptions from './data/widths.json';

export default {
  name: 'BlDropdownAv',
  status: 'ready',
  release: '0.1.1',
  components: { multiSelect },
  props: {
    /**
     * Whether the dropdown is multiple select or not
     * `true , false`
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * The sizes of the dropdown.
     * `small, large, default`
     */
    size: {
      type: String,
      validator: (value, getValue) => {
        if (getValue) {
          return sizeOptions;
        }
        return sizeOptions.indexOf(value) !== -1;
      },
      default: 'default',
    },
    /**
     * The width of the dropdown.
     * `narrow, mid, wide, default`
     */
    width: {
      type: String,
      validator: (value, getValue) => {
        if (getValue) {
          return widthOptions;
        }
        return widthOptions.indexOf(value) !== -1;
      },
      default: 'default',
    },
    /**
     * The max height of the dropdown.
     */
    maxHeight: {
      type: Number,
      default: 300,
    },
    /**
     * The options of the dropdown.
     */
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    /**
     * Whether the dropdown is error
     * `true , false`
     */
    isError: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the dropdown is success
     * `true , false`
     */
    isSuccess: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the dropdown is searchable
     * `true , false`
     */
    searchable: {
      type: Boolean,
      default: false,
    },
    /**
     * Track by
     */
    trackBy: {
      type: String,
      default: '',
    },
    /**
     * Whether the dropdown is disabled
     * `true , false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the dropdown is required
     * `true , false`
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the dropdown is show no options
     * `true , false`
     */
    showNoOptions: {
      type: Boolean,
      default: false,
    },
    /**
     * The value of the dropdown
     */
    value: {
      type: null,
      default() {
        return [];
      },
    },
  },
  computed: {
    internalValue: {
      get() {
        if (this.value || this.value === 0) {
          return Array.isArray(this.value) ? this.value : [this.value];
        }
        return [];
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getClasses() {
      const baseClass = 'multiselect';
      return [
        baseClass,
        this.size !== 'default' ? `${baseClass}--${this.size}` : '',
        this.size !== 'default' ? `${baseClass}--${this.width}` : '',
        this.isError ? `${baseClass}--error` : '',
        this.isSuccess ? `${baseClass}--success` : '',
        this.disabled ? `${baseClass}--disabled` : '',
        this.value ? `${baseClass}--selected` : '',
      ];
    },
  },
  methods: {
    searchChange(value) {
      this.$emit('search-change', value);
    },
  },
};
</script>

<style src="./BlDropdownAv.style.scss" lang="scss"></style>
