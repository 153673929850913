<template>
  <div class="u-pad--4">
    <aml-cft-tab />
    <div class="c-tab__content is-active u-pad--4">
      <div style="font-size: 24px" class="u-mrgn-bottom--4">
        Configuration History Page
      </div>

      <Loader
        :load-state="loadState"
        :loading-class="['u-mrgn-v--6']"
        :error-class="['u-mrgn-v--6', 'u-align-center']"
        @reload="retrieveInitialData"
      >
        <bl-panel-mv
          v-for="configuration in configurations"
          :key="configuration.created_at"
          class="aml-cft-configuration-history-page__item"
        >
          <bl-panel-body-mv>
            <div class="aml-cft-configuration-history-page__item-edited-by u-mrgn-bottom--3">
              Edited by <b>{{ configuration.created_by }}</b> - {{ configuration.created_at | formatDateTime }}
            </div>
            <div class="u-mrgn-bottom--1">
              <span> Rules #1 Cumulative transaction amount: </span>
              <span class="aml-cft-configuration-history-page__item-gmv">
                {{ configuration.transaction_gmv.general | money }}
              </span>
            </div>
            <div class="u-mrgn-bottom--1">
              <span> Rules #2 Cumulative transaction number: </span>
              <span class="aml-cft-configuration-history-page__item-count">
                {{ configuration.transaction_count.general | money }}
              </span>
            </div>
            <div>Rules #3 Cumulative transaction amount against monthly income:</div>
            <ul class="u-mrgn-left--8 u-mrgn-bottom--1">
              <li
                v-for="field in configuration.transaction_gmv.monthly_incomes"
                :key="field.value"
                class="u-pad-left--0"
              >
                <span class="aml-cft-configuration-history-page__item-monthly-income-key"> {{ field.key }}: </span>
                <span class="aml-cft-configuration-history-page__item-monthly-income-value">
                  {{ field.value | money }}
                </span>
              </li>
            </ul>
            <div>
              Rules #4 Cumulative transaction amount against occupation:
            </div>
            <ul class="u-mrgn-left--8 u-mrgn-bottom--1">
              <li v-for="field in configuration.transaction_gmv.occupations" :key="field.value" class="u-pad-left--0">
                <span class="aml-cft-configuration-history-page__item-occupation-key"> {{ field.key }}: </span>
                <span class="aml-cft-configuration-history-page__item-occupation-value">
                  {{ field.value | money }}
                </span>
              </li>
            </ul>
            <div>
              Rules #5 Cumulative transaction amount against PEP & BO:
            </div>
            <ul class="u-mrgn-left--8">
              <li v-for="field in ['pep', 'bo']" :key="field" class="u-pad-left--0">
                <span :class="`aml-cft-configuration-history-page__item-${field}-key`">
                  {{ field.toUpperCase() }}:
                </span>
                <span :class="`aml-cft-configuration-history-page__item-${field}-value`">
                  {{ configuration.transaction_gmv[field] | money }}
                </span>
              </li>
            </ul>
          </bl-panel-body-mv>
        </bl-panel-mv>
      </Loader>

      <bl-pagination-mv
        v-if="filter.total > filter.limit"
        class="aml-cft-configuration-history-page__pagination u-mrgn-top--4"
        :total="filter.total"
        :limit="filter.limit"
        :offset="filter.offset"
        :max-links="10"
        @changePage="changePage"
      />
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import BlPanelMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv';
import BlPanelBodyMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelBodyMv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';
import Loader from 'partner-admin/components/Loader';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import AmlCftTab from '../components/AmlCftTab';

export default {
  name: 'AmlCftConfigurationHistoryPage',
  components: { AmlCftTab, BlPanelMv, BlPanelBodyMv, BlPaginationMv, Loader },
  filters: {
    formatDateTime(date) {
      if (date) return dayjs(date).format('DD MMMM YYYY (HH:mm:ss [WIB])');
      return '';
    },
    money,
  },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      configurations: [],
      loadState: 'idle',
      filter: {
        offset: 0,
        limit: 10,
        total: 0,
      },
    };
  },
  methods: {
    retrieveInitialData() {
      this.loadState = 'loading';

      const payload = {
        limit: this.filter.limit,
        offset: this.filter.offset,
      };

      this.$api
        .getAmlReportsConfigurationsHistories(payload)
        .then(({ data, meta }) => {
          this.configurations = data;

          const { limit, offset, total } = meta;
          this.filter = {
            ...this.filter,
            limit,
            offset,
            total,
          };

          this.loadState = 'success';
        })
        .catch(e => {
          this.loadState = 'error';
          this.showErrorNotif(e);
        });
    },
    changePage(payload) {
      this.filter = {
        ...this.filter,
        ...payload,
      };
      this.retrieveInitialData();
    },
  },
};
</script>
