<template>
  <span class="download-button__container" />
</template>
<script>
import notifMixin from 'partner-admin/mixins/notifMixin';
import axios from 'axios';

export default {
  name: 'FileDownloader',
  mixins: [notifMixin],
  methods: {
    postDownload(url, data, token) {
      axios({
        url,
        method: 'POST',
        responseType: 'blob',
        data,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(this.onRequestSuccess)
        .catch(this.onRequestError);
    },
    download(url) {
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then(this.onRequestSuccess)
        .catch(this.onRequestError);
    },
    onRequestSuccess(response) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const regResult = /filename=(?<res>.+)/.exec(response.headers['content-disposition']);
      const fileName = regResult.groups.res.replaceAll('"', '');
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.querySelector('.download-button__container').appendChild(link);
      link.click();
      link.remove();
    },
    onRequestError(e) {
      const error = e.response ? e.response.data : e;

      if (error instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const errorData = JSON.parse(reader.result);
          this.showErrorNotif(errorData);
        };
        reader.readAsText(error);
      } else {
        this.showErrorNotif(error);
      }
    },
  },
};
</script>
