<template>
  <div
    class="c-panel__head"
    @click="handleClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlPanelHeadMv',
  status: 'ready',
  release: '0.4.0',
  props: {
    click: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style src="./BlPanelHeadMv.style.scss" lang="scss"></style>
