<template>
  <tr>
    <td class="transaction-table-row__created">
      {{ transaction.created_at | formatDateTime }}
    </td>
    <td class="transaction-table-row__order-id">
      {{ transaction.id }}
    </td>
    <td class="transaction-table-row__reference">
      {{ transaction.sinvest_reference_number || '-' }}
    </td>
    <td class="transaction-table-row__type">
      {{ transactionType }}
    </td>
    <td class="transaction-table-row__ifua">
      {{ (transaction.investor && transaction.investor.ifua) || '-' }}
    </td>
    <td class="transaction-table-row__product">
      {{ transaction.product.fund_name }}
    </td>
    <td class="transaction-table-row__fund-currency">
      {{ transaction.product.fund_ccy }}
    </td>
    <td class="transaction-table-row__gross-nominal">
      {{ transaction.portfolio_gross_nominal || transaction.initial_gross_nominal | moneyWithoutCurrency }}
    </td>
    <td class="transaction-table-row__amount">
      {{ transaction.portfolio_nominal || transaction.initial_nominal | moneyWithoutCurrency }}
    </td>
    <td class="transaction-table-row__fee">
      {{ transaction.portfolio_fee || transaction.initial_fee | moneyWithoutCurrency }}
    </td>
    <td class="transaction-table-row__agent">
      {{ transaction.agent && transaction.agent.name }}
    </td>
    <td class="transaction-table-row__state">
      {{ transaction.state }}
    </td>
    <td class="transaction-table-row__unit-ashmore">
      {{ transaction.portfolio_unit || transaction.initial_unit || '-' }}
    </td>
    <td class="transaction-table-row__unit-cb">
      {{ transaction.sinvest_unit || '-' }}
    </td>
    <td>
      <bl-button-av class="transaction-table-row__view" :href="viewUrl" @click.prevent.native="gotoView">
        Lihat
      </bl-button-av>
      <bl-button-av
        v-if="isAllowed('transaction', 'view_log')"
        class="transaction-table-row__history"
        :href="historyUrl"
        @click.prevent.native="gotoHistory"
      >
        Riwayat
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import dayjs from 'dayjs';
import { transactionTypeMap } from 'partner-admin/constant/transactionType';

export default {
  name: 'TransactionTableRow',
  components: { BlButtonAv },
  filters: {
    moneyWithoutCurrency(val) {
      return money(val, undefined, '');
    },
    formatDateTime(val) {
      return val ? dayjs(val).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
  mixins: [policyMixin],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    viewUrl() {
      return `${window.location.origin}/bukainvestasi/manage/transactions/${this.transaction.id}/view`;
    },
    historyUrl() {
      return `${window.location.origin}/bukainvestasi/manage/transactions/${this.transaction.id}/history?type=transaction`;
    },
    transactionType() {
      const { type } = this.transaction;
      return transactionTypeMap[type] || type;
    },
  },
  methods: {
    gotoView() {
      this.$router.push({
        name: 'transactionsView',
        params: {
          id: this.transaction.id,
        },
      });
    },
    gotoHistory() {
      this.$router.push({
        name: 'transactionsHistory',
        params: {
          id: this.transaction.id,
        },
        query: {
          type: 'transaction',
        },
      });
    },
  },
};
</script>
