<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-width-1 u-align-right">
          <bl-button-av @click.native="openFormDialog">
            New Staff
          </bl-button-av>
          <bl-button-av @click="gotoRolesSummaryPage">
            Access Info
          </bl-button-av>
          <roles-form-dialog
            ref="RolesFormDialog"
            :form="formType"
            :staff="selectedStaff"
            :roles-list="rolesList"
            @refresh="retrieveStaffs"
          />
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <roles-table
        :load-state="loadState"
        :staffs="staffs"
        :total="staffsTotal"
        :limit="limit"
        :offset="offset"
        @reload="retrieveStaffs"
        @refresh="retrieveStaffs"
        @changePage="setOffset"
        @edit="openFormDialog"
      />
    </div>
  </div>
</template>

<script>
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import RolesTable from '../components/RolesTable';
import RolesFormDialog from '../components/RolesFormDialog';

export default {
  name: 'RolesIndexPage',
  components: {
    RolesTable,
    RolesFormDialog,
    BlButtonAv,
  },
  mixins: [PolicyMixin, ErrorMixin],
  data() {
    return {
      rolesList: [],
      loadState: 'idle',
      staffs: [],
      staffsTotal: 0,
      offset: 0,
      limit: 10,
      formType: 'new',
      selectedStaff: {},
    };
  },
  created() {
    this.retrieveStaffs();
    this.retrieveStaffSummary();
  },
  methods: {
    retrieveStaffs() {
      this.loadState = 'loading';
      this.$api
        .getStaffs({ offset: this.offset, limit: this.limit })
        .then(({ data, meta }) => {
          this.staffs = data;
          this.staffsTotal = meta.total;
          this.loadState = 'success';
        })
        .catch(error => {
          this.handleError(error);
          this.loadState = 'error';
        });
    },
    retrieveStaffSummary() {
      this.$api
        .getStaffSummaries({ limit: 99 })
        .then(({ data }) => {
          this.rolesList = data;
        })
        .catch(this.handleError);
    },
    setOffset(query) {
      this.offset = query.offset;
      this.retrieveStaffs();
    },
    openFormDialog({ formType = 'new', selectedStaff = {} }) {
      this.formType = formType;
      this.selectedStaff = selectedStaff;
      this.$nextTick(() => {
        this.$refs.RolesFormDialog.openRolesFormDialog();
      });
    },
    gotoRolesSummaryPage() {
      this.$router.push({
        name: 'RolesSummary',
      });
    },
  },
};
</script>
