<template>
  <label class="c-inp-wrap">
    <input
      type="checkbox"
      class="c-inp c-inp--checkbox"
      :checked="isChecked"
      :disabled="disabled"
      @change="onChange"
    >
    <span
      v-if="$slots.default"
      :class="labelClass"
    >
      <slot />
    </span>
    <span
      v-else
      :class="labelClass"
    >{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'BlCheckboxAv',
  status: 'ready',
  release: '0.1.5',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * Checkbox Label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Whether the checkbox is checked
     * `true , false`
     */
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    /**
     * Checkbox Value
     */
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    /**
     * The color of the checkbox.
     * `blue, red`
     */
    color: {
      type: String,
      required: false,
      validator: value => {
        return ['blue', 'red'].includes(value);
      },
      default: 'blue',
    },
    /**
     * Whether the checkbox is disabled
     * `true , false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      if (this.isArray) {
        return this.checked.includes(this.value);
      }

      return this.checked;
    },
    labelClass() {
      return ['c-inp__inner-lbl', this.color === 'red' ? 'c-inp__inner-lbl--red' : ''];
    },
    isArray() {
      return Array.isArray(this.checked);
    },
    isBoolean() {
      return typeof this.checked === 'boolean';
    },
  },
  methods: {
    onChange() {
      if (this.isArray) {
        const checked = this.checked;
        const found = checked.includes(this.value);
        let returnData = [];
        if (found) {
          returnData = checked.filter(c => c !== this.value);
        } else {
          returnData = [...checked, this.value];
        }
        this.$emit('change', returnData);
      }

      if (this.isBoolean) {
        this.$emit('change', !this.checked);
      }
    },
  },
};
</script>

<style src="./BlCheckboxAv.style.scss" lang="scss"></style>
