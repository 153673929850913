/*
  This class is used to manage jwt token
*/

/* eslint-disable prefer-promise-reject-errors */

import axios from 'axios';

const STORAGE_KEY = `bib_${process.env.PARTNER_ID}_token`;
const STORAGE_KEY_DEV = `${STORAGE_KEY}_DEV`;

class TokenManager {
  constructor() {
    this.env = process.env.NODE_ENV;
    this.storage = global.localStorage;
    this.storageLabel = process.env.NODE_ENV === 'development' ? STORAGE_KEY_DEV : STORAGE_KEY;
    this.authUrl = process.env.PARTNER_AUTH_URL;
    this.authPayload = {
      client_id: process.env.PARTNER_CLIENT_ID,
      client_secret: process.env.PARTNER_CLIENT_SECRET,
    };
  }

  loadToken() {
    return JSON.parse(this.storage.getItem(this.storageLabel));
  }

  saveToken({ data }) {
    const now = new Date().getTime();
    const expiredAt = now + data.expires_in * 1000;
    const finalData = {
      ...data,
      expired_at: expiredAt,
    };

    this.storage.setItem(this.storageLabel, JSON.stringify(finalData));
  }

  destroyToken() {
    this.storage.removeItem(this.storageLabel);
  }

  isTokenExpired() {
    const token = this.loadToken();
    if (token) {
      // added expired_at attribute check token with local time minus 3 min buffer
      const buffer = 3 * 60 * 1000;
      const expiredAt = token.expired_at - buffer;
      const now = new Date().getTime();

      return expiredAt < now;
    }
    return false;
  }

  fetchFreshToken(opts) {
    return axios({
      url: `${this.authUrl}/token`,
      method: 'POST',
      data: {
        grant_type: 'refresh_token',
        ...this.authPayload,
        ...opts,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  fetchToken(authData = {}) {
    return new Promise((resolve, reject) => {
      const token = this.loadToken();

      if (token === null) {
        this.fetchFreshToken(authData)
          .then(({ data }) => {
            this.saveToken(data);
            resolve(this.loadToken());
          })
          .catch(reject);
      } else if (this.isTokenExpired()) {
        this.fetchFreshToken({ refresh_token: token.refresh_token })
          .then(({ data }) => {
            this.saveToken(data);
            resolve(this.loadToken());
          })
          .catch(() => {
            this.destroyToken();
            reject({
              errors: [{ message: 'Session expired, please refresh page to re-login' }],
            });
          });
      } else {
        resolve(token);
      }
    });
  }

  revokeToken() {
    return new Promise(resolve => {
      const tokenObj = this.loadToken();

      if (tokenObj) {
        axios({
          url: `${this.authUrl}/revocation`,
          method: 'POST',
          data: {
            token: tokenObj.token,
            ...this.authPayload,
          },
        }).finally(() => {
          this.destroyToken();
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  refreshToken() {
    this.destroyToken();
    this.fetchToken();
  }
}

export default TokenManager;
