<template>
  <div>
    <div class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Review Produk Baru
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <product-form form="review" :draft="draft.data" />
      <div class="u-mrgn-top--10">
        <draft-checker-action @action="handleAction" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProductDraftMixin from '../mixins/ProductDraftMixin';
import DraftCheckerAction from '../components/DraftCheckerAction';

export default {
  name: 'ProductDraftReviewPage',
  components: { DraftCheckerAction },
  mixins: [ProductDraftMixin],
  methods: {
    ...mapActions(['populateNewDraft']),
    handleAction(action) {
      const payload = {
        id: this.$route.params.id,
        state: action,
      };
      const actionMap = {
        approved: 'menyetujui',
        rejected: 'menolak',
      };
      this.$api
        .patchDraftStatus(payload)
        .then(({ data }) => {
          this.populateNewDraft(data);
          this.showSuccessNotif(`Berhasil ${actionMap[action]} draft product`);
          this.$router.push({
            name: 'productIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
