<template>
  <tr>
    <td>
      {{ staff.name }}
    </td>
    <td>
      {{ staff.email }}
    </td>
    <td>
      {{ staff.role && staff.role.name }}
    </td>
    <td>
      {{ staff.active ? 'Aktif' : 'Tidak Aktif' }}
    </td>
    <td>
      {{ staff.blocked ? 'Diblok' : 'Tidak diblok' }}
    </td>
    <td>
      <bl-button-av
        v-if="isSuperStaff"
        size="tiny"
        @click.native="activate"
      >
        {{ staff.active ? 'Deactivate' : 'Activate' }}
      </bl-button-av>
      <bl-button-av
        v-if="isSuperStaff && staff.blocked"
        size="tiny"
        @click.native="block"
      >
        Unblock
      </bl-button-av>
      <bl-button-av
        v-if="isSuperStaff"
        size="tiny"
        @click.native="edit"
      >
        Edit
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';

export default {
  name: 'RolesTableRow',
  components: { BlButtonAv },
  mixins: [policyMixin, ErrorMixin],
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  methods: {
    edit() {
      this.$emit('edit', { formType: 'edit', selectedStaff: this.staff });
    },
    activate() {
      this.request('active', !this.staff.active);
    },
    block() {
      this.request('blocked', !this.staff.blocked);
    },
    request(action, value) {
      const message = {
        active: {
          true: 'User berhasil di aktifkan',
          false: 'User berhasil di deaktifkan',
        },
        blocked: {
          true: 'User berhasil di block',
          false: 'User berhasil di unblock',
        },
      };
      this.$api
        .patchStaffStatus({ id: this.staff.id, [action]: value })
        .then(() => {
          this.$root.$emit('showFlashNotif', {
            text: message[action][value],
            type: 'success',
          });
          this.$emit('refresh');
        })
        .catch(this.handleError);
    },
  },
};
</script>
