<template>
  <tr>
    <td class="file-management-row__date-upload">
      {{ humanizeDate(rowData.created_at) }}
    </td>
    <td class="file-management-row__time-upload">
      {{ humanizeTime(rowData.created_at) }}
    </td>
    <td class="file-management-row__type">
      {{ rowData.action }}
    </td>
    <td class="file-management-row__filename">
      <a :href="fileDownloadUrl" target="_blank"> {{ rowData.file_name }} </a>
    </td>
    <td class="file-management-row__state">
      {{ rowData.state }}
    </td>
    <td class="file-management-row__notes">
      {{ notes }}
    </td>
    <td>
      <button v-if="allowedRetry" class="c-btn c-btn--medium file-management-row__retry-button" @click="retry">
        Retry
      </button>
      <button
        v-if="allowedHistory"
        class="c-btn c-btn--medium file-management-row__history-button"
        @click="gotoHistory"
      >
        Riwayat
      </button>
    </td>
  </tr>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import dayjs from 'dayjs';
import PolicyMixin from 'partner-admin/mixins/policyMixin';

export default {
  name: 'FileManagementRow',
  mixins: [PolicyMixin],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    notes() {
      return this.rowData.state === 'uncompleted' && this.rowData.next_process_row !== undefined
        ? `Failed row ${this.rowData.next_process_row}`
        : '';
    },
    fileDownloadUrl() {
      const token = this.tokenObj.token;
      const url = new URL(
        `/_exclusive/upload-files/${this.rowData.id}/download?access_token=${token}`,
        process.env.PARTNER_API_URL,
      );
      return url;
    },
    allowedRetry() {
      return this.rowData.state === 'uncompleted' && this.isAllowed('transaction', 'upload');
    },
    allowedHistory() {
      return this.isAllowed('transaction', 'view_log');
    },
  },
  methods: {
    humanizeDate(date) {
      return dayjs(date).format('DD MMMM YYYY');
    },
    humanizeTime(time) {
      return dayjs(time).format('HH:mm:ss');
    },
    retry() {
      this.$emit('retry', { id: this.rowData.id });
    },
    gotoHistory() {
      this.$router.push({
        name: 'transactionsFileManagementHistory',
        params: {
          id: this.rowData.id,
        },
        query: {
          type: 'upload_file',
        },
      });
    },
  },
};
</script>
