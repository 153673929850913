<template>
  <div>
    <bl-dialog-mv
      :active.sync="rolesFormDialog"
      :title="text.title"
    >
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-text-field-mv
            v-model="name"
            placeholder="Nama"
          />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-field-mv>
        <bl-field-row-mv>
          <bl-text-field-mv
            v-model="email"
            placeholder="Email"
          />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-field-mv>
        <bl-field-row-mv>
          <bl-dropdown-av
            v-model="role"
            placeholder="Pilih Role"
            track-by="id"
            label="name"
            :options="rolesList"
          />
        </bl-field-row-mv>
      </bl-field-mv>

      <bl-field-group-mv>
        <bl-field-mv inline>
          <bl-text-field-mv
            v-model="password"
            readonly
            placeholder="Password"
          />
        </bl-field-mv>
        <bl-field-mv inline>
          <bl-button-av
            block
            @click="generatePassword"
          >
            Generate Password
          </bl-button-av>
        </bl-field-mv>
      </bl-field-group-mv>
      <bl-field-hint-av style="margin-bottom: 18px">
        Please send password to user and ask to reset.
      </bl-field-hint-av>

      <bl-field-error-av
        v-if="errorMessage"
        style="margin-bottom: 18px"
        v-html="errorMessage"
      />
      <bl-button-av
        color="red"
        block
        has-spinner
        :activated="isSubmitting"
        @click="submit"
      >
        {{ text.buttonText }}
      </bl-button-av>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldGroupMv';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  name: 'RolesFormDialog',
  components: {
    BlDialogMv,
    BlButtonAv,
    BlDropdownAv,
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlFieldGroupMv,
    BlFieldHintAv,
    BlFieldErrorAv,
  },
  mixins: [NotifMixin],
  props: {
    form: {
      type: String,
      required: true,
      validator(value) {
        return ['new', 'edit'].includes(value);
      },
    },
    staff: {
      type: Object,
      default: () => ({}),
    },
    rolesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rolesFormDialog: false,
      name: '',
      email: '',
      password: '',
      role: null,
      isSubmitting: false,
      errorMessage: '',
    };
  },
  computed: {
    text() {
      if (this.form === 'new') {
        return {
          title: 'Create New Staff',
          buttonText: 'Create',
        };
      }
      return {
        title: 'Edit Staff',
        buttonText: 'Edit',
      };
    },
  },
  methods: {
    openRolesFormDialog() {
      this.reset();
      if (this.form === 'edit') {
        this.name = this.staff.name;
        this.email = this.staff.email;
        this.role = this.staff.role;
      }

      this.rolesFormDialog = true;
    },
    reset() {
      this.name = '';
      this.email = '';
      this.password = '';
      this.role = null;
      this.errorMessage = '';
    },
    generatePassword() {
      const array = ['abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', '0123456789', '~!@#$%^&*()_+?'];

      const pass = [];
      array.forEach(value => {
        for (let i = 0, n = value.length; i < 4; i += 1) {
          pass.push(value.charAt(Math.floor(Math.random() * n)));
        }
      });

      this.shuffle(pass);
    },
    shuffle(pass) {
      let currentIndex = pass.length;
      let temporaryValue;
      let randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = pass[currentIndex];
        pass[currentIndex] = pass[randomIndex];
        pass[randomIndex] = temporaryValue;
      }

      this.password = pass.join('');
    },
    validateField() {
      this.errorMessage = '';
      let flag = true;
      if (!this.name) {
        flag = false;
        this.errorMessage += 'Nama harus diisi <br />';
      }

      if (!this.email) {
        flag = false;
        this.errorMessage += 'Email harus diisi <br />';
      }

      if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(this.email)) {
        flag = false;
        this.errorMessage += 'Format email salah <br />';
      }

      if (!this.role) {
        flag = false;
        this.errorMessage += 'Role tidak boleh kosong <br />';
      }

      if (this.form === 'new' && !this.password) {
        flag = false;
        this.errorMessage += 'Password tidak boleh kosong <br />';
      }
      return flag;
    },
    submit() {
      if (!this.validateField()) {
        return;
      }
      this.isSubmitting = true;
      const apiMap = {
        new: 'postStaffs',
        edit: 'putStaff',
      };

      const message = {
        new: 'User successfully created',
        edit: 'User successfully edited',
      };

      const payload = {
        name: this.name,
        email: this.email,
        password: this.password,
        role_id: this.role.id,
      };

      if (this.form === 'edit') {
        payload.id = this.staff.id;
      }

      this.$api[apiMap[this.form]](payload)
        .then(() => {
          this.$root.$emit('showFlashNotif', {
            text: message[this.form],
            type: 'success',
          });
          this.$emit('refresh');
        })
        .catch(this.showErrorNotif)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
