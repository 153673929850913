<template>
  <transition name="c-dialog-transition">
    <div
      v-if="visible"
      :class="sizingClass"
      :style="{ height: window.height + 'px' }"
      class="c-dialog"
    >
      <div
        class="c-dialog__wrapper"
        @mousedown.stop="closeModal"
        @touchstart.stop="closeModal"
      >
        <div
          ref="modal"
          class="c-dialog__content"
        >
          <button
            v-if="showCloseBtn"
            class="c-dialog__close"
            type="button"
            @click="closeModal()"
          >
            <bl-icon-av variant="close" />
          </button>

          <div
            class="c-dialog__panel"
            @mousedown.stop
            @touchstart.stop
          >
            <div
              v-if="title"
              class="c-dialog__panel__head"
            >
              <div class="c-dialog__panel__title">
                {{ title }}
              </div>
            </div>
            <div class="c-dialog__panel__body">
              <slot />
            </div>
            <div
              v-if="hasFooter"
              class="c-dialog__panel__footer"
            >
              <!-- @slot Slot to provide a footer-->
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BlIconAv from '../../atoms/BlIconAv';

export default {
  name: 'BlDialogMv',
  status: 'ready',
  release: '0.4.2',
  components: {
    BlIconAv,
  },
  props: {
    /**
     * Title Dialog
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Size Dialog
     * `small, medium, large`
     */
    size: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'medium', 'large'].includes(value);
      },
    },
    /**
     * Show Close Button
     * `true, false`
     */
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    /**
     * Active Visible Dialog
     * `true, false`
     */
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.active || false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    sizingClass() {
      if (this.size !== 'default') {
        return `c-dialog--${this.size}`;
      }
      return '';
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
  watch: {
    active(value) {
      this.visible = value;
      this.bodyHandle();
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  },
  methods: {
    closeModal() {
      this.$emit('update:active', false);
    },
    onWindowResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    bodyHandle() {
      if (this.visible) {
        document.documentElement.style.overflowY = 'hidden';
      } else {
        document.documentElement.style.overflowY = '';
      }
    },
  },
};
</script>

<style src="./BlDialogMv.style.scss" lang="scss"></style>
