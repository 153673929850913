<template>
  <Table
    :load-state="loadState"
    :rows="investorCheckedData"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <component
      :is="tableRowComponent"
      v-for="investorChecked in investorCheckedData"
      :key="investorChecked.id"
      :investor-checked="investorChecked"
      :chosen-investors="chosenInvestors"
      @chooseInvestor="chooseInvestor"
    />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import InvestorCheckedTableRow from './InvestorCheckedTableRow';
import InvestorRevisesCheckedTableRow from './InvestorRevisesCheckedTableRow';

export default {
  name: 'InvestorCheckedTable',
  components: {
    InvestorCheckedTableRow,
    InvestorRevisesCheckedTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['', 'Update Date', 'PEP', 'BO', 'NIK', 'Email', 'Status', ''],
  props: {
    recordType: {
      type: String,
      required: true,
      validator(value) {
        return ['Bib::Investor', 'Bib::InvestorRevise'].includes(value);
      },
    },
    investorCheckedData: {
      type: Array,
      default: () => [],
    },
    chosenInvestors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tableRowComponent() {
      return {
        'Bib::Investor': 'InvestorCheckedTableRow',
        'Bib::InvestorRevise': 'InvestorRevisesCheckedTableRow',
      }[this.recordType];
    },
  },
  methods: {
    chooseInvestor(payload) {
      this.$emit('chooseInvestor', payload);
    },
  },
};
</script>
