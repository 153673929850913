<template>
  <div>
    <bl-button-av class="agent-new-dialog__add" color="red" @click="showDialog = true">
      New Agent
    </bl-button-av>
    <bl-dialog-mv :active.sync="showDialog" title="Add New Agent" class="draft-checker-action__text">
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-field-label-av> Partner Name </bl-field-label-av>
          <bl-text-field-mv v-model="agent.name" />
        </bl-field-row-mv>
        <bl-field-row-mv class="u-mrgn-top--2">
          <bl-field-label-av> Partner Code </bl-field-label-av>
          <bl-text-field-mv v-model="agent.client_code_prefix" />
        </bl-field-row-mv>
      </bl-field-mv>

      <div style="display: flex; justify-content: space-between; margin-top: 28px;">
        <div style="flex-grow: 0; flex-shrink: 0; flex-basis: 50%; padding-right: 6px;">
          <bl-button-av class="agent-new-dialog__cancel" block @click="showDialog = false">
            Cancel
          </bl-button-av>
        </div>
        <div style="flex-grow: 0; flex-shrink: 0; flex-basis: 50%; padding-left: 6px;">
          <bl-button-av
            class="agent-new-dialog__submit"
            color="red"
            block
            has-spinner
            :disabled="isSubmitting"
            :activated="isSubmitting"
            @click="submit"
          >
            Save
          </bl-button-av>
        </div>
      </div>
    </bl-dialog-mv>
  </div>
</template>
<script>
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  name: 'AgentNewDialog',
  components: {
    BlDialogMv,
    BlButtonAv,
    BlFieldMv,
    BlFieldRowMv,
    BlFieldLabelAv,
    BlTextFieldMv,
  },
  mixins: [NotifMixin],
  data() {
    return {
      showDialog: false,
      isSubmitting: false,
      agent: {
        name: null,
        client_code_prefix: null,
      },
    };
  },
  methods: {
    submit() {
      this.isSubmitting = true;
      this.$api
        .postAgents(this.agent)
        .then(() => {
          this.showSuccessNotif('Successfully add new agent');
          this.showDialog = false;
          this.$emit('submitSuccess');
        })
        .catch(this.showErrorNotif)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
