<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/products">
        &lt; Kembali Ke Halaman Daftar Produk
      </router-link>
      <div class="o-layout o-layout--responsive u-mrgn-top--2">
        <bl-field-mv class="o-layout__item u-2of12">
          <bl-field-row-mv>
            <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-2of12">
          <bl-field-row-mv>
            <bl-text-field-mv v-model="productCode" placeholder="Kode Produk" />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-2of12">
          <bl-field-row-mv>
            <bl-text-field-mv v-model="productName" placeholder="Nama Produk" />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-field-mv class="o-layout__item u-2of12">
          <bl-field-row-mv>
            <bl-dropdown-av
              v-model="type"
              track-by="code"
              label="text"
              placeholder="Type"
              :options="$options.typeOptions"
            />
          </bl-field-row-mv>
        </bl-field-mv>
        <bl-button-av class="o-layout__item u-2of12 u-mrgn-left--4" color="red" @click="searchDrafts()">
          Search
        </bl-button-av>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="true"
        :table-header="[
          'Nama Produk',
          'Tipe Produk',
          'Kode Produk',
          'CCY',
          'NAV',
          'State',
          'Type',
          'Perubahan Terakhir',
        ]"
        :table-data="tableData"
        :load-state="draftsLoadState"
        :action-button="['view_list', 'view_log']"
        :action-root-url="'/products'"
        @reload="retrieveDrafts"
      />
      <bl-pagination-mv
        v-if="draftsTotal > limit"
        class="u-mrgn-top--4"
        :total="draftsTotal"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyMixin';
import Table from 'partner-admin/components/Table';
import { findField } from 'partner-admin/shared/helpers/tableHelper';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import dayjs from 'dayjs';
import { findObjectInArray } from 'partner-admin/shared/helpers/arrayHelper';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';

export default {
  name: 'ProductDraftPage',
  draftRecordType: 'Bib::Product',
  stateOptions: ['', 'created', 'submitted', 'approved', 'rejected'],
  typeOptions: [
    { code: '', text: '' },
    { code: 'draft_create', text: 'New' },
    { code: 'draft_update', text: 'Update' },
  ],
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlTextFieldMv,
    BlButtonAv,
    BlPaginationMv,
  },
  mixins: [PolicyPageMixin],
  data() {
    return {
      offset: 0,
      limit: 10,
      state: '',
      type: '',
      productCode: '',
      productName: '',
    };
  },
  computed: {
    ...mapGetters({
      drafts: 'getDrafts',
      draftsLoadState: 'getDraftsLoadState',
      draftsTotal: 'getDraftsTotal',
      draftsFilter: 'getDraftsFilter',
    }),
    tableData() {
      return this.drafts.map(draft => [
        draft.id,
        findField(draft.data, 'fund_name'),
        findField(draft.data, 'fund_type'),
        findField(draft.data, 'fund_code'),
        findField(draft.data, 'fund_ccy'),
        money(findField(draft.data, 'initial_nav'), undefined, ''),
        draft.state,
        findObjectInArray(this.$options.typeOptions, 'code', draft.action).text,
        dayjs(draft.updated_at).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB'),
      ]);
    },
    defaultPayload() {
      return { offset: this.offset, limit: this.limit, record_type: this.$options.draftRecordType };
    },
    searchPayload() {
      return {
        state: this.state,
        action: this.type.code,
        'filter[product][fund_name]': this.productName,
        'filter[product][fund_code]': this.productCode,
      };
    },
  },
  created() {
    this.getDrafts();
  },
  methods: {
    ...mapActions(['retrieveDrafts']),
    retrieveInitialData() {
      this.retrieveDrafts(this.defaultPayload);
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getDrafts();
    },
    searchDrafts() {
      this.offset = 0;
      this.getDrafts();
    },
    getDrafts() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveDrafts(payload);
    },
  },
};
</script>
