<template>
  <tr>
    <td class="sticky">
      {{ rowData[0] }}
    </td>
    <td v-for="(data, index) in rowData.slice(1)" :key="index" class="u-align-center">
      <bl-icon-av v-if="data" variant="checkfill" />
    </td>
  </tr>
</template>

<script>
import BlIconAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlIconAv';

export default {
  name: 'SummaryRowData',
  components: { BlIconAv },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: #ccc;
  font-weight: bold;
}
</style>
