<template>
  <ul class="c-tab__nav">
    <router-link :to="{ name: 'AmlCftConfiguration' }" class="c-tab__list" tag="li" active-class="is-active">
      <a class="c-tab__link">
        Configuration
      </a>
    </router-link>
    <router-link :to="{ name: 'AmlCftConfigurationHistory' }" class="c-tab__list" tag="li" active-class="is-active">
      <a class="c-tab__link">
        History
      </a>
    </router-link>
  </ul>
</template>
<script>
export default {
  name: 'AmlCftTab',
};
</script>
