<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/investors">
        &lt; Kembali Ke Halaman Daftar Investor
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <ul class="c-tab__nav">
        <li class="c-tab__list  is-active">
          <a class="c-tab__link" @click.prevent="">
            <span> New Draft </span>
          </a>
        </li>
        <li v-if="isAllowed('investor_revise', 'view_list')" class="c-tab__list">
          <a class="investor-draft-page__revise c-tab__link" @click="gotoInvestorRevisesDraft">
            <span> Updated Draft </span>
          </a>
        </li>
      </ul>
      <div class="c-tab__content is-active">
        <div class="c-tab__content__head">
          <div class="o-layout o-layout--responsive u-mrgn-top--2">
            <div class="o-layout__item o-layout o-layout--responsive u-10of12">
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-3of12">
                  <bl-field-row-mv>
                    <bl-date-picker-mv
                      v-model="chosenDate"
                      class="u-width-1"
                      is-has-multiple-calendar
                      is-date-range-enabled
                      :begin-date="chosenDate.beginDate || currentDate"
                      :end-date="chosenDate.endDate"
                      multiple-calendar
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-3of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-3of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av
                      v-model="sinvestStatus"
                      placeholder="SINVEST status"
                      :options="$options.sinvestStatusOptions"
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-text-field-mv v-model="identityNumber" placeholder="NIK" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-text-field-mv v-model="email" placeholder="Email" />
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av v-model="risk" :options="$options.riskOptions" placeholder="Risk" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av
                      v-model="agentIds"
                      placeholder="Agent"
                      track-by="id"
                      label="name"
                      :options="agentOptions"
                      multiple
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-button-av color="red" class="investor-draft-page__search" @click.native="searchDrafts()">
                      Search
                    </bl-button-av>
                    <bl-button-av
                      class="investor-draft-page__reset u-mrgn-left--1"
                      color="red"
                      @click.native="resetFilter"
                    >
                      Reset Filter
                    </bl-button-av>
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
            </div>
            <div class="o-layout__item o-layout o-layout--responsive u-2of12">
              <bl-button-av class="investor-draft-page__checked" color="red" @click.native="gotoInvestorChecked">
                Second Approval
              </bl-button-av>

              <investor-draft-download-action class="u-mrgn-top--2" />
            </div>
          </div>
        </div>

        <div class="c-tab__content__body">
          <draft-table
            :drafts="drafts"
            :record-type="$options.recordType"
            :total="draftsTotal"
            :limit="limit"
            :offset="offset"
            :load-state="draftsLoadState"
            @reload="getDrafts"
            @refresh="getDrafts"
            @changePage="setOffset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapMutations, mapGetters, mapActions } from 'partner-admin/lib/vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import AgentOptionsMixin from 'partner-admin/mixins/agentOptionsMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';

import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import DraftTable from '../components/DraftTable';
import InvestorDraftDownloadAction from '../components/InvestorDraftDownloadAction';

export default {
  name: 'InvestorDraftPage',
  stateOptions: ['', 'created', 'submitted', 'approved', 'rejected'],
  riskOptions: ['', 'high', 'low'],
  sinvestStatusOptions: ['', 'investor_created', 'investor_sinvest_rejected'],
  recordType: 'Bib::Investor',
  action: 'draft_create',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    DraftTable,
    BlDatePickerMv,
    BlTextFieldMv,
    BlDropdownAv,
    InvestorDraftDownloadAction,
  },
  mixins: [PolicyPageMixin, AgentOptionsMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      offset: 0,
      limit: 10,
      chosenDate: {},
      currentDate,
      state: '',
      identityNumber: '',
      email: '',
      agentIds: '',
      risk: '',
      sinvestStatus: '',
    };
  },
  computed: {
    ...mapState('filters', {
      investorDraftFilters: state => state.investorDraftFilters,
    }),
    ...mapGetters({
      drafts: 'getDrafts',
      draftsLoadState: 'getDraftsLoadState',
      draftsTotal: 'getDraftsTotal',
    }),
    defaultPayload() {
      return {
        offset: this.offset,
        limit: this.limit,
        record_type: this.$options.recordType,
        action: this.$options.action,
      };
    },
    searchPayload() {
      return {
        state: this.state,
        start_date: this.chosenDate.beginDate ? dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD') : '',
        end_date: this.chosenDate.endDate ? dayjs(this.chosenDate.endDate).format('YYYY-MM-DD') : '',
        'filter[investor][identity_number]': this.identityNumber,
        'filter[investor][email]': this.email,
        'filter[investor][agent_id][]': this.agentIds ? this.agentIds.map(agent => agent.id) : undefined,
        'filter[investor][risk]': this.risk,
        action: this.sinvestStatus ? this.sinvestStatus : 'draft_create',
      };
    },
  },
  methods: {
    ...mapActions(['retrieveDrafts']),
    ...mapMutations('filters', ['setInvestorDraftFilters']),
    retrieveInitialData() {
      if (this.investorDraftFilters) {
        this.state = this.investorDraftFilters.state;
        if (this.investorDraftFilters.start_date) {
          this.chosenDate = {
            beginDate: dayjs(this.investorDraftFilters.start_date).valueOf(),
            endDate: dayjs(this.investorDraftFilters.end_date).valueOf(),
          };
        }
        this.identityNumber = this.investorDraftFilters['filter[investor][identity_number]'];
        this.email = this.investorDraftFilters['filter[investor][email]'];
        this.risk = this.investorDraftFilters['filter[investor][risk]'];
        this.offset = this.investorDraftFilters.offset;
        this.sinvestStatus =
          this.investorDraftFilters.action === 'draft_create' ? '' : this.investorDraftFilters.action;
      }

      this.getDrafts();
      this.retrieveAgentOptions();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getDrafts();
    },
    resetFilter() {
      this.identityNumber = '';
      this.email = '';
      this.agentIds = '';
      this.chosenDate = {};
      this.state = '';
      this.risk = '';
      this.offset = 0;
      this.sinvestStatus = '';
      this.getDrafts();
    },
    searchDrafts() {
      this.offset = 0;
      this.getDrafts();
    },
    getDrafts() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.setInvestorDraftFilters(payload);
      this.retrieveDrafts(payload);
    },
    gotoInvestorChecked() {
      this.$router.push({
        name: 'investorCheckedIndex',
      });
    },
    gotoInvestorRevisesDraft() {
      this.$router.push({
        name: 'investorRevisesDraft',
      });
    },
  },
};
</script>
