<template>
  <label :class="getClasses"> <slot /> </label>
</template>

<script>
import { size } from './data/type';

export default {
  name: 'BlFieldLabelAv',
  status: 'ready',
  release: '0.4.0',
  props: {
    size: {
      type: String,
      validator: value => {
        return size.indexOf(value) !== -1;
      },
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      const baseClass = 'c-fld__lbl';
      return [
        baseClass,
        this.size ? `${baseClass}--${this.size}` : '',
        this.inline ? `${baseClass}--inline` : '',
        this.block ? `${baseClass}--block` : '',
      ];
    },
  },
};
</script>

<style src="./BlFieldLabelAv.style.scss" lang="scss"></style>
