<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" title="Konfirmasi" class="draft-checker-action__text">
      > Anda yakin untuk {{ actionText }} produk ini?
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          style="width: 50%; display: inline-block;"
          class="draft-checker-action__dialog-cancel-button"
          @click.native="cancelAction()"
        >
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%; display: inline-block;"
          color="red"
          class="draft-checker-action__dialog-confirm-button"
          @click.native="emitAction()"
        >
          Ya
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
    <bl-button-av color="red" class="draft-checker-action__open-approve-dialog-button" @click.native="approveDialog">
      Setuju
    </bl-button-av>
    <bl-button-av class="draft-checker-action__open-reject-dialog-button" @click.native="rejectDialog">
      Tolak
    </bl-button-av>
  </div>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';

export default {
  name: 'DraftCheckerAction',
  components: { BlDialogMv, BlButtonGroupMv, BlButtonAv },
  data() {
    return {
      confirmationDialog: false,
      action: null,
    };
  },
  computed: {
    actionText() {
      return (
        {
          approved: 'menyetujui',
          rejected: 'menolak',
        }[this.action] || ''
      );
    },
  },
  methods: {
    approveDialog() {
      this.action = 'approved';
      this.confirmationDialog = true;
    },
    rejectDialog() {
      this.action = 'rejected';
      this.confirmationDialog = true;
    },
    cancelAction() {
      this.action = null;
      this.confirmationDialog = false;
    },
    emitAction() {
      this.confirmationDialog = false;
      this.$emit('action', this.action);
    },
  },
};
</script>
