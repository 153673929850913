import dayjs from 'dayjs';

export default {
  methods: {
    getInputValue(input, original) {
      const fieldName = input.name;
      let inputValue = original[fieldName];
      if (input.type === 'file') {
        inputValue = original[`${input.name}_url`];
      }
      if ([undefined, null].includes(inputValue)) {
        return null;
      }
      if (input.type === 'date') {
        inputValue = dayjs(inputValue).valueOf();
      }
      if (input.type === 'radio') {
        inputValue = inputValue === true ? 1 : 0;
      }
      if (input.type === 'number') {
        inputValue = `${inputValue}`.replace('.', ',');
      }
      if (input.type === 'select') {
        const selectedOption = input.options.find(opt => opt.value === inputValue);
        if (selectedOption) {
          inputValue = selectedOption;
        }
      }
      return inputValue;
    },
    getInputPayload(input) {
      let value = input.inputModel;
      if (input.field.type === 'select') {
        value = input.inputModel.value;
      }
      if (input.field.type === 'date') {
        value = dayjs(value).format('YYYY-MM-DD');
      }
      if (input.field.type === 'number') {
        value = Number(`${value}`.replace(',', '.'));
      }

      // TODO: fix radio
      if (input.field.type === 'radio') {
        value = input.inputModel;
      }
      return value;
    },
    getInputFormattedDraft(input, draftsData) {
      const draftField = draftsData.find(draft => {
        const draftFieldName = draft.field;
        return draftFieldName === input.name;
      });

      if (draftField) {
        const value = {
          oldValue: draftField.old_value,
          newValue: draftField.new_value,
        };

        if (
          [
            'subscription_fee',
            'redemption_fee',
            'switching_in_fee',
            'switching_out_fee',
            'management_fee_percentage',
            'max_subscription_fee',
            'max_redemption_fee',
            'max_switching_in_fee',
            'max_switching_out_fee',
          ].includes(input.name)
        ) {
          value.oldValue = Number((value.oldValue * 100).toFixed(4));
          if (value.newValue) {
            value.newValue = Number((value.newValue * 100).toFixed(4));
          }
        }


        if (input.name === 'institution_allocation') {
          value.oldValue = this.formatInstitutionAllocation(value.oldValue);
          value.newValue = this.formatInstitutionAllocation(value.newValue);
        }

        if (input.name === 'instrument_allocation') {
          value.oldValue = this.formatInstrumentAllocation(value.oldValue);
          value.newValue = this.formatInstrumentAllocation(value.newValue);
        }

        return { ...input, ...{ value } };
      }
      return { ...input, ...{ value: { oldValue: null, newValue: '' } } };
    },
    checkEditReadonly(form, field) {
      return !!(form === 'edit' && field.editReadonly);
    },
    updateInputField(name, prop) {
      const index = this.inputFields.findIndex(field => field.name === name);
      if (index > -1) {
        const newObject = { ...this.inputFields[index], ...prop };
        this.$set(this.inputFields, index, newObject);
      }
    },
    scrollToError(item) {
      // 90 is offset for header and form
      // https://stackoverflow.com/a/49842367
      const firstField = document.querySelector(`[name="${item.field}"]`);
      window.scroll({
        top: firstField.getBoundingClientRect().top + window.scrollY - 90,
        behavior: 'smooth',
      });
    },
    formatInstitutionAllocation(institutions) {
      if (!institutions) {
        return '';
      }
      return institutions.map(institution => institution.name).join('\n');
    },
    formatInstrumentAllocation(instruments) {
      if (!instruments) {
        return '';
      }
      return instruments
        .map(instrument => `${instrument.name} - ${Number(instrument.percentage * 100).toFixed(0)}%`)
        .join('\n');
    },
  },
};
