/**
 * @module helpers/date-helper
 */
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import id from 'date-fns/locale/id';
import addMinutes from 'date-fns/add_minutes';
import omit from 'lodash/omit';
import { prependZero } from './number-helper';

export const day = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

export const dayShort = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];

export const daySuperShort = ['Mn', 'Sn', 'Sl', 'Rb', 'Km', 'Jm', 'Sb'];

export const workdays = ['Sn', 'Sl', 'Rb', 'Km', 'Jm'];

export const weekend = ['Mn', 'Sb'];

export const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'];

const TIMEZONE_NAMES = {
  'UTC+0': 'GMT',
  'UTC+1': 'CET',
  'UTC+2': 'EET',
  'UTC+3': 'EEDT',
  'UTC+3.5': 'IRST',
  'UTC+4': 'MSD',
  'UTC+4.5': 'AFT',
  'UTC+5': 'PKT',
  'UTC+5.5': 'IST',
  'UTC+6': 'BST',
  'UTC+6.5': 'MST',
  'UTC+7': 'WIB',
  'UTC+8': 'WITA',
  'UTC+9': 'WIT',
  'UTC+9.5': 'ACST',
  'UTC+10': 'AEST',
  'UTC+10.5': 'ACDT',
  'UTC+11': 'AEDT',
  'UTC+11.5': 'NFT',
  'UTC+12': 'NZST',
  'UTC-1': 'AZOST',
  'UTC-2': 'GST',
  'UTC-3': 'BRT',
  'UTC-3.5': 'NST',
  'UTC-4': 'CLT',
  'UTC-4.5': 'VET',
  'UTC-5': 'EST',
  'UTC-6': 'CST',
  'UTC-7': 'MST',
  'UTC-8': 'PST',
  'UTC-9': 'AKST',
  'UTC-9.5': 'MIT',
  'UTC-10': 'HST',
  'UTC-11': 'SST',
  'UTC-12': 'BIT',
};

const DURATION_TYPE = {
  second: 'detik',
  seconds: 'detik',
  minute: 'menit',
  minutes: 'menit',
  hour: 'jam',
  hours: 'jam',
  day: 'hari',
  days: 'hari',
  month: 'bulan',
  months: 'bulan',
  year: 'tahun',
  years: 'tahun',
};

export function dateToObject(date, withTime = false) {
  let result = {
    date: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  };

  if (!withTime) {
    result = omit(result, ['hour', 'minute', 'second']);
  }

  return result;
}

/**
 * Return Date
 *
 * @param  {Date} date
 *
 * @return {Date}
 */
export function getDaysInMonth(date) {
  const localDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return localDay.getDate();
}

/**
 * Return year is leap
 *
 * @param {Date} date
 */
export function isLeapYear(date) {
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 1) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param {*} dateObject
 */
export function getOnlyDate(dateObject) {
  return `${dateObject.getFullYear()}-${prependZero(dateObject.getMonth() + 1)}-${prependZero(dateObject.getDate())}`;
}

/**
 *
 * @param {*} value
 */

export function getTimeString({ value, extractDateObjectFromValue = false }) {
  let localValue = value;
  if (extractDateObjectFromValue) {
    localValue = dateToObject(value, true);
  }
  const dateString = `${localValue.year}-${prependZero(localValue.month)}-${prependZero(localValue.date)}`;
  const timeString = `${prependZero(localValue.hour)}:${prependZero(localValue.minute)}:${prependZero(
    localValue.second,
  )}`;
  return `${dateString}T${timeString}Z`;
}

export function isValidDate(dateString) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return dateString.match(regEx) !== null;
}

/**
 * Returns human readable timestamp format
 *
 * @example
 * humanizeTimestamp('2017-06-23T14:15:53.886+07:00', '%day% %dd% %mmm% %yyyy% - %hh%:%mi% WIB')
 * // => Jumat 23 Jun 2017 - 14:15 WIB
 *
 * @param  {String} timestamp timestamp format
 * @param  {String} format specific wanted format to humanize the timestamp (in-casesensitive)
 *
 * @return {String}
 */
export function humanizeTimestamp(timestamp, formatScope = '%day%, %dd% %month% %yyyy%') {
  const dateTime = new Date(timestamp);
  let offset = (-1 * dateTime.getTimezoneOffset()) / 60;
  offset = `UTC${offset >= 0 ? `+${offset}` : offset}`;
  const timezone = TIMEZONE_NAMES[offset];
  const timeMapping = {
    '%hh%': `0${dateTime.getHours()}`.slice(-2),
    '%mi%': `0${dateTime.getMinutes()}`.slice(-2),
    '%dd%': `0${dateTime.getDate()}`.slice(-2),
    '%day%': day[dateTime.getDay()],
    '%mm%': `0${dateTime.getMonth() + 1}`.slice(-2),
    '%mmm%': monthShort[dateTime.getMonth()],
    '%month%': months[dateTime.getMonth()],
    '%yyyy%': dateTime.getFullYear(),
    '%yy%': dateTime
      .getFullYear()
      .toString()
      .substr(2, 2),
    '%tz%': timezone,
  };
  return formatScope.replace(/%(.*?)%/gi, n => timeMapping[n]);
}

/**
 * Time Filter
 * @param value {string} ISO 8601 format, e.g '2017-01-31T02:30:00+07:00'
 * @return hour:minute e.g '02:30'
 */
export const customTime = value => {
  if (value === undefined) {
    throw new Error('value cant be null');
  }

  if (typeof value === 'number') {
    throw new Error('type must be string or date');
  }

  if (typeof timestamp === 'string') {
    return value.substring(11, 16);
  }
  if (value instanceof Date) {
    return `${prependZero(value.getHours())}:${prependZero(value.getMinutes())}`;
  }
  const date = new Date(value);
  return `${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
};

// new lib from tix
export function toTimeZone(date, timezone = null) {
  if (timezone == null) {
    return date;
  }

  const thisTimezone = date.getTimezoneOffset();
  const diff = thisTimezone - timezone;

  return addMinutes(date, diff);
}

/**
 * Poor man's timezone guesser.
 * Assuming that input is in ISO 8601 formatted string.
 */
export function extractTimezone(dateStr) {
  if (dateStr[dateStr.length - 1] === 'Z') {
    return 0;
  }
  const zone = dateStr.split(/(\+|-)/).slice(-2);
  const mark = zone[0] === '+' ? -1 : 1;
  const numbers = zone[1].split(':').map(e => parseInt(e, 10));
  const minutes = numbers[0] * 60 + numbers[1];

  return mark * minutes;
}

export function dateFormat(date, fmt = 'YYYY-MM-DD', timezone = null) {
  let dateToBeFormatted = date;
  let newTimezone;

  if (dateToBeFormatted == null) {
    dateToBeFormatted = new Date();
  }

  if (typeof date === 'string') {
    dateToBeFormatted = parse(date);
    newTimezone = extractTimezone(date);
  }
  if (timezone !== null) {
    newTimezone = timezone;
  }
  if (newTimezone !== null) {
    dateToBeFormatted = toTimeZone(dateToBeFormatted, newTimezone);
  }

  return format(dateToBeFormatted, fmt, { locale: id });
}

/**
 * Translate duration type to Bahasa Indonesia
 *
 * @example
 * localizeDuration('day')
 * // => hari
 *
 * @param  {String} type duration type
 *
 * @return {String}
 */
export function localizeDuration(type) {
  return DURATION_TYPE[type] || '';
}

export default {
  day,
  dayShort,
  daySuperShort,
  workdays,
  weekend,
  months,
  monthShort,
  getDaysInMonth,
  isLeapYear,
  getOnlyDate,
  getTimeString,
  humanizeTimestamp,
  customTime,
  dateToObject,
  dateFormat,
  extractTimezone,
  toTimeZone,
  localizeDuration,
};
