<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" :title="`Upload ${fileType}`">
      <bl-field-mv>
        <bl-field-label-av>{{ $options.actionTextEnum[fileType] }} File</bl-field-label-av>
        <bl-field-row-mv>
          <bl-upload-field-av
            ref="input"
            v-model="inputModel"
            class="upload-transactions-action__file-input"
            @input="onFileChange"
          />
        </bl-field-row-mv>
      </bl-field-mv>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          style="width: 50%; display: inline-block;"
          class="upload-transactions-action__cancel-button"
          @click.native="cancelAction()"
        >
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%; display: inline-block;"
          color="red"
          :disabled="!allowUpload"
          class="upload-transactions-action__upload-button"
          @click.native="upload"
        >
          Upload
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
    <bl-button-av
      v-if="isAllowed('transaction', 'upload')"
      block
      class="upload-transactions-action__reconcile-button u-mrgn-bottom--1"
      @click.native="openDialog('recon')"
    >
      Upload Reconcile
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'upload_sinvest')"
      block
      class="upload-transactions-action__transaction-state-button u-mrgn-bottom--1"
      @click.native="openDialog('sinvest_status')"
    >
      Upload Transaction State
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'upload_sinvest')"
      block
      class="upload-transactions-action__transaction-unit-button u-mrgn-bottom--1"
      @click.native="openDialog('sinvest_unit')"
    >
      Upload Transaction Unit
    </bl-button-av>
  </div>
</template>

<script>
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlUploadFieldAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlUploadFieldAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import policyMixin from 'partner-admin/mixins/policyMixin';

export default {
  name: 'UploadTransactionsAction',
  components: { BlFieldMv, BlFieldRowMv, BlFieldLabelAv, BlDialogMv, BlButtonAv, BlUploadFieldAv, BlButtonGroupMv },
  mixins: [ErrorMixin, policyMixin],
  actionTextEnum: {
    recon: 'Reconcile',
    sinvest_status: 'Transaction State',
    sinvest_unit: 'Transaction Unit',
  },
  data() {
    return {
      confirmationDialog: false,
      inputModel: '',
      fileType: '',
    };
  },
  computed: {
    allowUpload() {
      return this.inputModel && this.inputModel.file && this.inputModel.file_name;
    },
  },
  methods: {
    cancelAction() {
      this.confirmationDialog = false;
      this.inputModel = null;
    },
    upload() {
      this.$api
        .uploadTransactionFile({
          action: this.fileType,
          attachment: this.inputModel.file,
          attachment_file_name: this.inputModel.file_name,
        })
        .then(() => {
          this.confirmationDialog = false;
          this.$root.$emit('showFlashNotif', {
            text: `File ${this.fileType} berhasil di-upload`,
            type: 'success',
          });
        })
        .catch(this.handleError);
    },
    onFileChange(files) {
      if (files[0]) {
        const vm = this;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          vm.inputModel = {
            file_name: files[0].name,
            file: reader.result,
          };
        });
        reader.readAsDataURL(files[0]);
      }
    },
    openDialog(type) {
      this.fileType = type;
      this.confirmationDialog = true;
    },
  },
};
</script>
