<template>
  <div :class="notificationClasses">
    <bl-icon-av
      v-if="closable"
      variant="close"
      class="c-notification__close"
      @click="$emit('close')"
    />
    <bl-flag-mv v-if="withIcon">
      <bl-flag-head-av>
        <bl-icon-av
          :variant="variantIcon"
          :size="sizeIcon"
        />
      </bl-flag-head-av>
      <bl-flag-body-av> <slot /> </bl-flag-body-av>
    </bl-flag-mv>
    <slot v-else />
  </div>
</template>

<script>
import BlIconAv from '../../atoms/BlIconAv';
import BlFlagMv from '../BlFlagMv';
import BlFlagBodyAv from '../BlFlagMv/components/BlFlagBodyAv';
import BlFlagHeadAv from '../BlFlagMv/components/BlFlagHeadAv';

import variants from './data/variants.json';
import sizes from './data/sizes.json';
import sizeIcons from './data/sizeIcons.json';

export default {
  name: 'BlNotificationMv',
  status: 'ready',
  release: '0.1.1',
  components: {
    BlIconAv,
    BlFlagMv,
    BlFlagHeadAv,
    BlFlagBodyAv,
  },
  props: {
    /**
     * The variant of the notification.
     * `default, gray, success, error, white, sand`
     */
    variant: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return variants;
        }

        return variants.indexOf(value) !== -1;
      },
    },
    /**
     * The sizes of the notification.
     * `small, default`
     */
    size: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return sizes;
        }
        return sizes.indexOf(value) !== -1;
      },
    },
    /**
     * The sizes of the icon notification.
     * `base, medium, large, huge`
     */
    sizeIcon: {
      type: String,
      default: 'medium',
      validator: (value, getValue) => {
        if (getValue) {
          return sizeIcons;
        }
        return sizeIcons.indexOf(value) !== -1;
      },
    },
    /**
     * Icon name see
     * <a href="https://bukalapak.github.io/pictograph/">Bukalapak Pictograph Icon</a>
     */
    variantIcon: {
      type: String,
      default: 'help',
    },
    /**
     * Whether the notification is active
     * `true , false`
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification has thick border
     * `true , false`
     */
    thick: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification has dashed border
     * `true , false`
     */
    dashed: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification has solid border
     * `true , false`
     */
    solid: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification is contextual
     * `true , false`
     */
    contextual: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification is announcement
     * `true , false`
     */
    announcement: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification is closable
     * `true , false`
     */
    closable: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the notification with icon
     * `true , false`
     */
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      booleanIndependentProps: ['thick', 'dashed', 'contextual', 'announcement'],
    };
  },
  computed: {
    notificationClasses() {
      const mainClass = 'c-notification';
      const classes = [mainClass];

      Object.keys(this.$props).forEach(propName => {
        const propValue = this.$props[propName];
        if (
          propValue &&
          propValue !== 'default' &&
          propName !== 'variantIcon' &&
          propName !== 'closable' &&
          propName !== 'withIcon' &&
          propName !== 'solid'
        ) {
          if (propName === 'active') {
            classes.push('is-active');
          } else if (propName === 'variant') {
            classes.push(this.solid ? `${mainClass}--${propValue}-solid` : `${mainClass}--${propValue}`);
          } else if (this.booleanIndependentProps.indexOf(propName) !== -1) {
            classes.push(`${mainClass}--${propName}`);
          } else {
            classes.push(`${mainClass}--${propValue}`);
          }
        }
      });

      return classes;
    },
  },
};
</script>

<style src="./BlNotificationMv.style.scss" lang="scss"></style>
