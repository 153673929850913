<template>
  <div class="u-pad-top--8">
    <bl-panel-mv style="width: 400px; margin: auto" class="u-align-left">
      <bl-panel-head-mv>
        <bl-panel-title-av> CHANGE PASSWORD ADMINISTRATOR </bl-panel-title-av>
      </bl-panel-head-mv>
      <bl-panel-body-mv>
        <bl-field-mv>
          <bl-field-row-mv>
            <bl-text-field-mv v-model="oldPassword" type="password" placeholder="Old Password" />
          </bl-field-row-mv>
        </bl-field-mv>

        <bl-field-mv>
          <bl-field-row-mv>
            <bl-text-field-mv v-model="newPassword" type="password" placeholder="New Password" />
          </bl-field-row-mv>
        </bl-field-mv>

        <bl-field-mv>
          <bl-field-row-mv>
            <bl-text-field-mv v-model="confirmPassword" type="password" placeholder="Confirm New Password" />
          </bl-field-row-mv>
        </bl-field-mv>

        <bl-field-error-av
          v-if="errorMessage"
          class="change-password-page__error"
          style="margin-bottom: 18px"
          v-html="errorMessage"
        />
        <bl-button-av
          class="change-password-page__submit"
          color="red"
          block
          has-spinner
          :disabled="isSubmitting"
          :activated="isSubmitting"
          @click.native="changePassword"
        >
          Change Password
        </bl-button-av>
      </bl-panel-body-mv>
    </bl-panel-mv>
  </div>
</template>
<script>
import BlPanelMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv';
import BlPanelHeadMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelHeadMv';
import BlPanelBodyMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelBodyMv';
import BlPanelTitleAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelTitleAv';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import notifMixin from 'partner-admin/mixins/notifMixin';

export default {
  name: 'ChangePasswordPage',
  components: {
    BlPanelMv,
    BlPanelHeadMv,
    BlPanelBodyMv,
    BlPanelTitleAv,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    BlFieldErrorAv,
    BlTextFieldMv,
  },
  mixins: [notifMixin],
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      isSubmitting: false,
    };
  },
  methods: {
    validate() {
      this.errorMessage = '';
      if (!this.oldPassword) {
        this.errorMessage = 'Old Password is empty';
        return false;
      }

      if (/[<>]/g.test(this.oldPassword)) {
        this.errorMessage = 'Old Password cannot contain < and >';
        return false;
      }

      if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^0-9a-zA-Z]).{12,}$/.test(this.newPassword)) {
        this.errorMessage =
          'Password length must be more than 12 characters and contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.';
        return false;
      }

      if (/[<>]/g.test(this.newPassword)) {
        this.errorMessage = 'New Password cannot contain < and >';
        return false;
      }

      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = 'New Password and Confirm Password are mismatch';
        return false;
      }
      return true;
    },
    changePassword() {
      if (!this.validate()) {
        return;
      }

      this.isSubmitting = true;
      this.$api
        .changePassword({
          old_password: this.oldPassword,
          new_password: this.newPassword,
          password_confirmation: this.confirmPassword,
        })
        .then(response => {
          const TokenManagerInstance = new window.Oauth.TokenManager();
          TokenManagerInstance.saveToken(response);
        })
        .then(() => {
          this.showSuccessNotif('Change Password Success');
          this.$router.push('/');
        })
        .catch(this.showErrorNotif)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
