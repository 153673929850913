<template>
  <div class="u-mrgn-bottom--4">
    <h1 class="u-txt--large u-txt--bold u-mrgn-bottom--1">
      {{ title }}
    </h1>
    <bl-table-av zebra border="columns" style="display: block;overflow-x: auto;">
      <thead>
        <tr>
          <th class="sticky">
            Function
          </th>
          <th v-for="(header, index) in headers" :key="index" class="summary-feature__header u-txt--bold">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loadState === 'loading'">
          <tr class="summary-feature__loading u-align-center">
            <td :colspan="headers.length + 1">
              <bl-spinner-av variant="red" size="large" />
            </td>
          </tr>
        </template>
        <template v-if="loadState === 'success'">
          <tr v-if="!tableData.length" class="summary-feature__empty u-align-center">
            <td :colspan="headers.length + 1">
              No Data
            </td>
          </tr>
          <summary-row-data
            v-for="(featureKey, index) in featureKeys"
            v-else
            :key="index"
            :row-data="generateRowData(featureKey)"
          />
        </template>
        <template v-if="loadState === 'error'">
          <tr class="summary-feature__error u-align-center">
            <td :colspan="headers.length + 1">
              Failed to load data, <a class="summary-feature__reload" @click.prevent="reload">reload</a>
            </td>
          </tr>
        </template>
      </tbody>
    </bl-table-av>
  </div>
</template>

<script>
import BlSpinnerAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlSpinnerAv';
import BlTableAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlTableAv';
import SummaryRowData from './SummaryRowData';

export default {
  name: 'SummaryFeature',
  functionMap: {
    view_list: 'View {title} List',
    view_log: 'View {title} History',
    edit: 'Edit {title} Data',
    approve_reject: 'Approve and Reject {title}',
    download: 'Download {title}',
    upload: 'Upload {title}',
    create: 'Create New {title}',
    update_nav: 'Update NAV {title}',
    transaction_confirmation: 'View Upload SKT ',
    account_statement: 'View Account Statement',
    final_approve_reject: 'Approve and Reject (Second Approval) {title}',
    download_sinvest: 'Download {title} from S-INVEST',
    upload_sinvest: 'Upload {title} to S-INVEST',
    aum: 'Download AUM Report',
    investor_individual: 'Download Investor Individual Report',
    config: 'View and Edit {title} configuration',
  },
  components: {
    SummaryRowData,
    BlTableAv,
    BlSpinnerAv,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    loadState: {
      type: String,
      default: 'idle',
    },
  },
  computed: {
    featureKeys() {
      let key = [];
      this.tableData.forEach(data => {
        if (data.feature) {
          key = [...key, ...Object.keys(data.feature)];
        }
      });
      return Array.from(new Set([...key]));
    },
    headers() {
      return this.tableData.map(data => data.roleGroup);
    },
  },
  methods: {
    generateRowData(key) {
      const value = this.tableData.map(data => data.feature[key]);
      return [this.getFunctionName(key), ...value];
    },
    getFunctionName(key) {
      let desc =
        (this.$options.functionMap[key] && this.$options.functionMap[key].replace('{title}', this.title)) || key;

      // Special conditions
      if (desc && this.title === 'Transaction' && ['upload', 'download'].includes(key)) {
        desc = `${desc} (Recon Data)`;
      }

      return desc;
    },
    reload() {
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: #333;
  color: #fff;
  font-weight: bold;
}
</style>
