<template>
  <div>
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-8of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-text-field-mv v-model="fundCode" placeholder="Fund Code" />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-dropdown-av
                  v-model="product"
                  placeholder="Product"
                  track-by="id"
                  label="fund_name"
                  :options="productOptions"
                />
              </bl-field-row-mv>
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-button-av color="red" @click="searchNavs">
                  Search
                </bl-button-av>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>

        <div class="o-layout__item u-4of12 u-align-right">
          <massNavUploadButton v-if="isAllowed('nav', 'upload_sinvest')" ref="massNavUpload" @upload="uploadFile" />
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="false"
        :table-header="['NAV date', 'Fund Code', 'Product', 'Fund Currency', 'NAV value', 'Last Update', 'State']"
        :table-data="tableData"
        :load-state="'success'"
        :action-button="['view_list']"
        :action-root-url="'/navs'"
        @reload="searchNavs"
      />
      <bl-pagination-mv
        v-if="navsTotal > limit"
        class="u-mrgn-top--4"
        :total="navsTotal"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import Table from 'partner-admin/components/Table';
import dayjs from 'dayjs';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';
import massNavUploadButton from '../components/massNavUpload';

export default {
  name: 'NavIndexPage',
  components: {
    Table,
    massNavUploadButton,
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlButtonAv,
    BlPaginationMv,
    BlDropdownAv,
  },
  mixins: [PolicyMixin, ErrorMixin],
  data() {
    return {
      fundCode: '',
      product: '',
      offset: 0,
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      navs: 'getNavs',
      navsLoadState: 'getNavsLoadState',
      navsTotal: 'getNavsTotal',
      products: 'getProducts',
    }),
    tableData() {
      return (
        this.navs &&
        this.navs.map(nav => [
          nav.product && nav.product.id,
          dayjs(nav.date).format('DD MMMM YYYY'),
          nav.product && nav.product.fund_code,
          nav.product && nav.product.fund_name,
          nav.product && nav.product.fund_ccy,
          nav.value,
          nav.date,
          nav.state,
        ])
      );
    },
    productOptions() {
      return ['', ...this.products];
    },
    defaultPayload() {
      return { offset: this.offset, limit: this.limit };
    },
    searchPayload() {
      return { product_id: this.product.id, fund_code: this.fundCode };
    },
  },
  created() {
    this.retrieveNavs(this.defaultPayload);
    this.retrieveProducts({ state: 'approved' });
  },
  methods: {
    ...mapActions(['retrieveNavs', 'retrieveProducts']),
    searchNavs() {
      this.offset = 0;
      this.getNavs();
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getNavs();
    },
    getNavs() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.retrieveNavs(payload);
    },
    uploadFile(navFile) {
      this.$api
        .uploadNavFile({
          action: 'sinvest',
          attachment: navFile.file,
          attachment_file_name: navFile.file_name,
        })
        .then(() => {
          this.$root.$emit('showFlashNotif', {
            text: `File ${navFile.file_name} berhasil di upload`,
            type: 'success',
          });
          this.$refs.massNavUpload.handleFinishRequest(true);
          this.retrieveNavs(this.defaultPayload);
        })
        .catch(error => {
          this.$refs.massNavUpload.handleFinishRequest(false);
          this.handleError(error);
        });
    },
  },
};
</script>
