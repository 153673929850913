/*
  This class is used to manage request with TokenManager
*/

import axios from 'axios';
import axiosRetry from 'axios-retry';
import jwtDecode from 'jwt-decode';

function doRequest(requestParam) {
  const axiosClient = axios.create();

  axiosRetry(axiosClient, { retries: 3 });

  return axiosClient.request(requestParam);
}

function getRequestParam(url, method, tokenObj, opts) {
  const tokenType = {
    bearer: 'Bearer',
  }[tokenObj.token_type];

  return {
    ...opts,
    url,
    method,
    'axios-retry': { retries: 3 },
    headers: {
      Accept: 'application/json',
      Authorization: `${tokenType} ${tokenObj.token}`,
      ...opts.headers,
    },
  };
}

class RequestManager {
  constructor(TokenManager) {
    this.TokenManager = TokenManager;
  }

  request(url, method, opts = {}) {
    return this.TokenManager.fetchToken()
      .then(tokenObj => {
        const requestParam = getRequestParam(url, method, tokenObj, opts);
        return doRequest(requestParam);
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          throw error.response.data;
        } else {
          throw error;
        }
      });
  }

  fetchToken() {
    return new Promise((resolve, reject) => {
      const token = this.TokenManager.loadToken();

      if (token) {
        this.TokenManager.fetchToken()
          .then(resolve)
          .catch(e => {
            if (e.response) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      } else {
        resolve(null);
      }
    });
  }

  login(payload) {
    return this.TokenManager.fetchToken({
      grant_type: 'password',
      ...payload,
    })
      .then(tokenObj => {
        const decoded = jwtDecode(tokenObj.token);
        if (decoded.must_change_password) {
          return Promise.reject(new Error('Oauth::MustChangePassword'));
        }
        return Promise.resolve(tokenObj);
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      });
  }

  logout() {
    return this.TokenManager.revokeToken()
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      });
  }
}

['GET', 'POST', 'PATCH', 'PUT', 'DELETE'].forEach(method => {
  const methodName = method.toLowerCase();

  RequestManager.prototype[methodName] = function requestWrapper(url, _, options = {}) {
    // _ is used to pass scope in secure client, but not used here
    return this.request(url, method, options);
  };
});

export default RequestManager;
