<template>
  <div class="o-layout">
    <div class="o-layout__item u-6of12 u-pad-right--6 u-border-right--1--ash-light">
      <div class="u-txt--large u-mrgn-bottom--4">
        Switching Out Transaction
      </div>
      <transaction-switching-form
        :transaction="switchingOutTransaction"
        :target-transaction="switchingInTransaction"
        @approve="handleApproval"
      />
    </div>
    <div class="o-layout__item u-6of12">
      <div class="u-txt--large u-mrgn-bottom--4">
        Switching In Transaction
      </div>
      <transaction-switching-form
        :transaction="switchingInTransaction"
        :target-transaction="switchingOutTransaction"
        @approve="handleApproval"
      />
    </div>
    <div class="o-layout__item">
      <transaction-switching-rejection-form :transaction="switchingOutTransaction" @reject="handleRejection" />
    </div>
  </div>
</template>

<script>
import TransactionSwitchingForm from './TransactionSwitchingForm';
import TransactionSwitchingRejectionForm from './TransactionSwitchingRejectionForm';

export default {
  name: 'TransactionSwitchingFormContainer',
  components: { TransactionSwitchingForm, TransactionSwitchingRejectionForm },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      switchingInTransaction: {},
      switchingOutTransaction: {},
    };
  },

  watch: {
    transaction: {
      handler(transaction) {
        const { type, switching_out: switchingOut, switching_in: switchingIn } = transaction;

        if (type === 'switching-in') {
          this.switchingInTransaction = transaction;
          this.switchingOutTransaction = switchingOut || {};
        } else {
          this.switchingOutTransaction = transaction;
          this.switchingInTransaction = switchingIn || {};
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    handleRejection(payload) {
      this.$emit('reject', payload);
    },
    handleApproval(payload) {
      this.$emit('approve', payload);
    },
  },
};
</script>
