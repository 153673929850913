<template>
  <div>
    <div class="c-tab__content__head">
      <div class="u-txt--large u-mrgn-bottom--4">
        {{ productName }}
      </div>
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-10of12">
          <div class="o-layout o-layout--responsive">
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-date-picker-mv
                v-model="chosenDate"
                class="u-width-1"
                is-has-multiple-calendar
                :begin-date="chosenDate || currentDate"
                multiple-calendar
              />
            </bl-field-mv>
            <bl-field-mv class="o-layout__item u-3of12">
              <bl-field-row-mv>
                <bl-button-av color="red" @click="getNavs(true)">
                  Search
                </bl-button-av>
              </bl-field-row-mv>
            </bl-field-mv>
          </div>
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <Table
        :is-draft="false"
        :table-header="['NAV date', 'Fund Code', 'Product', 'Fund Currency', 'NAV value', 'Last Update', 'State']"
        :table-data="tableData"
        :action-button="['retry']"
        :load-state="'success'"
      />
      <bl-pagination-mv
        v-if="navTotal > meta.limit"
        class="u-mrgn-top--4"
        :total="navTotal"
        :limit="meta.limit"
        :offset="meta.offset"
        :max-links="10"
        @changePage="setOffset"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import Table from 'partner-admin/components/Table';

import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';

export default {
  name: 'NavViewPage',
  components: {
    Table,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    BlDatePickerMv,
    BlPaginationMv,
  },
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      currentDate,
      chosenDate: null,
      updateNavDialog: false,
      updateNavValue: null,
      nav: [],
      productId: Number(this.$route.params.id),
      meta: {
        offset: 0,
        limit: 20,
      },
      navTotal: 0,
      productName: '',
    };
  },
  computed: {
    tableData() {
      return (
        this.nav &&
        this.nav.map(nav => [
          nav.id,
          dayjs(nav.date).format('DD MMMM YYYY'),
          nav.product && nav.product.fund_code,
          nav.product && nav.product.fund_name,
          nav.product && nav.product.fund_ccy,
          nav.value,
          nav.date,
          nav.state,
        ])
      );
    },
  },
  created() {
    this.getNavs();
  },
  methods: {
    ...mapActions(['retrieveNavDetail']),
    getNavs(fromSearch = false) {
      const payload = {
        product_id: this.productId,
        offset: this.meta.offset,
        limit: this.meta.limit,
      };

      if (fromSearch && this.chosenDate) {
        payload.offset = 0;
        payload.date = dayjs(this.chosenDate).format('YYYY-MM-DD');
      }

      this.$api
        .getNavs(payload)
        .then(response => {
          this.nav = response.data;
          const { total, limit, offset } = response.meta;
          this.meta = { limit, offset };
          this.navTotal = total;
          this.setProductName();
        })
        .catch(this.handleError);
    },
    setProductName() {
      if (this.nav.length) {
        this.productName = (this.nav[0].product && this.nav[0].product.fund_name) || '';
      }
    },
    setOffset(query) {
      this.meta.offset = query.offset;
      this.getNavs();
    },
  },
};
</script>
