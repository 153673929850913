<template>
  <div class="u-pad-top--8">
    <bl-panel-mv
      style="width: 400px; margin: auto"
      class="u-align-left"
    >
      <bl-panel-head-mv>
        <bl-panel-title-av> LOGIN ADMINISTRATOR </bl-panel-title-av>
      </bl-panel-head-mv>
      <bl-panel-body-mv>
        <login-form />
      </bl-panel-body-mv>
    </bl-panel-mv>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import BlPanelMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv';
import BlPanelHeadMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelHeadMv';
import BlPanelBodyMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelBodyMv';
import BlPanelTitleAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPanelMv/components/BlPanelTitleAv';
import LoginForm from './LoginForm';

export default {
  name: 'LoginPage',
  components: {
    BlPanelMv,
    BlPanelHeadMv,
    BlPanelBodyMv,
    BlPanelTitleAv,
    LoginForm,
  },
  computed: {
    ...mapState('session', {
      isLoggedIn: 'isLoggedIn',
    }),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'index' });
    }
  },
};
</script>
