<template>
  <Table
    :load-state="loadState"
    :rows="users"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <user-index-table-row v-for="user in users" :key="user.id" :user="user" @reset="reset" />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import UserIndexTableRow from './UserIndexTableRow';

export default {
  name: 'UserIndexTable',
  components: {
    UserIndexTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: ['Name', 'Email', 'Verified', 'Active', 'Action'],
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    reset(payload) {
      this.$emit('reset', payload);
    },
  },
};
</script>
