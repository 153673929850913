<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/roles">
        &lt; Back to Staff List
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <summary-table :summaries="summaries" :load-state="loadState" @reload="retrieveSummaries" />
    </div>
  </div>
</template>

<script>
import notifMixin from 'partner-admin/mixins/notifMixin';
import SummaryTable from '../components/SummaryTable';

export default {
  name: 'RolesSummaryPage',
  components: {
    SummaryTable,
  },
  mixins: [notifMixin],
  data() {
    return {
      summaries: [],
      loadState: 'idle',
    };
  },
  created() {
    this.retrieveSummaries();
  },
  methods: {
    retrieveSummaries() {
      this.loadState = 'loading';
      this.$api
        .getStaffSummaries()
        .then(({ data }) => {
          this.summaries = data;
          this.loadState = 'success';
        })
        .catch(e => {
          this.showErrorNotif(e);
          this.loadState = 'error';
        });
    },
  },
};
</script>
