import BasicField from 'partner-admin/components/BasicField';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  components: {
    BasicField,
  },
  mixins: [NotifMixin],
  props: {
    investorRevise: {
      type: Object,
      required: true,
    },
    draft: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true,
    },
  },
  data() {
    return {
      loadState: 'idle',
      registrationAttributes: null,
      rejectionReasons: [],
    };
  },
  computed: {
    newInvestorData() {
      const newData = {};
      const base = this.investorRevise.data_revise || {};
      Object.keys(base).forEach(key => {
        if (this.oldInvestorData[key] !== base[key]) {
          newData[key] = base[key];
        }
      });

      delete newData.last_approved;
      return newData;
    },
    oldInvestorData() {
      const lastApproved = this.investorRevise.data_revise ? this.investorRevise.data_revise.last_approved : {};
      const oldInvestor = { ...this.investorRevise.investor };

      delete oldInvestor.bank_data;

      return {
        ...oldInvestor,
        ...lastApproved,
      };
    },
    newInvestorBanks() {
      const newBanks = [];
      this.investorRevise.investor.bank_data.forEach((oldBank, idx) => {
        const newBank = {};
        const base = this.investorRevise.bank_revises.find(bank => bank.investor_bank_id === oldBank.id) || {};

        Object.keys(base).forEach(key => {
          const oldInvestorBank = this.oldInvestorBanks[idx];
          if (oldInvestorBank[key] !== base[key]) {
            newBank[key] = base[key];
          }
        });

        delete newBank.last_approved;
        newBanks.push(newBank);
      });

      return newBanks;
    },
    oldInvestorBanks() {
      const oldBanks = [];

      this.investorRevise.investor.bank_data.forEach(oldBank => {
        const bankRevise = this.investorRevise.bank_revises.find(bank => bank.investor_bank_id === oldBank.id) || {};
        const lastApproved = bankRevise.last_approved || {};
        oldBanks.push({
          ...oldBank,
          ...lastApproved,
        });
      });

      return oldBanks;
    },
  },
};
