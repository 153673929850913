<template>
  <div>
    <div v-if="isAllowed('product', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Lihat Produk
      </h1>
    </div>

    <div v-if="product" class="c-tab__content__body">
      <product-form form="view" :product="product" />
      <div v-if="isAllowed('product', 'edit')" class="u-mrgn-top--10">
        <bl-button-av color="red" size="large" block @click="gotoEditPage">
          Ubah Data
        </bl-button-av>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import ProductForm from '../components/ProductForm';

export default {
  name: 'ProductViewPage',
  components: { ProductForm, BlButtonAv },
  mixins: [PolicyPageMixin, ErrorMixin],
  data() {
    return {
      product: null,
    };
  },
  methods: {
    gotoEditPage() {
      this.$router.push({
        name: 'productEdit',
        params: {
          id: this.$route.params.id,
        },
      });
    },
    retrieveInitialData() {
      this.$api
        .getProduct(this.$route.params.id)
        .then(response => {
          this.product = response.data;
        })
        .catch(this.handleError);
    },
  },
};
</script>
