export function isSameArray(arrayOne, arrayTwo) {
  if (Array.isArray(arrayOne) && Array.isArray(arrayTwo)) {
    const temp1 = [...arrayOne];
    const temp2 = [...arrayTwo];
    return JSON.stringify(temp1.sort()) === JSON.stringify(temp2.sort());
  }
  return false;
}

export function createArrayWithRange(low, high) {
  const ar = [];
  for (let i = low; i <= high; i += 1) {
    ar.push(i);
  }
  return ar;
}

export function findObjectInArray(array, byField, value) {
  return array.find(member => member[byField] === value) || {};
}

export function createKeyValueCombination(items, type = 'dup', keyName = 'label', valueName = 'value') {
  if (!['inc', 'dup'].includes(type)) {
    throw new Error('type not supported');
  }
  const ar = items.map((item, index) => {
    let value;
    switch (type) {
      case 'inc':
        value = index + 1; // incremental, start at 1
        break;
      default:
        value = item; // duplicate key value
    }
    return {
      [`${keyName}`]: item,
      [`${valueName}`]: value,
    };
  });
  return ar;
}

export function buildOptions(collections) {
  return collections.map(item => ({ key: item.id, value: item.label }));
}

export function getOption(collections, value, findBy = 'key') {
  return collections.find(item => item[findBy] === value);
}

export default {};
