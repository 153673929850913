<template>
  <span
    :class="iconClasses"
    @click="$emit('click')"
    @bind="$attrs"
  />
</template>
<script>
import sizes from './data/sizes.json';

export default {
  name: 'BlIconAv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The sizes of the icon.
     * `base, medium, large, huge`
     */
    size: {
      type: String,
      default: 'base',
      validator: (value, getValue) => {
        if (getValue) {
          return sizes;
        }
        return sizes.indexOf(value) !== -1;
      },
    },
    /**
     * Whether the icon is duotone
     * `true , false`
     */
    duotone: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon name see
     * <a href="https://bukalapak.github.io/pictograph/">Bukalapak Pictograph Icon</a>
     */
    variant: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClasses() {
      const baseClass = 'c-icon';
      const mainClass = this.duotone ? `${baseClass}-duotone` : baseClass;
      const sizeClass = this.size ? `${mainClass}--${this.size}` : '';
      const variantClass = this.variant ? `${mainClass}--${this.variant}` : '';

      return [mainClass, sizeClass, variantClass];
    },
  },
};
</script>
