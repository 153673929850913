<template>
  <component
    :is="layoutType"
    :label="field.label"
  >
    <bl-text-field-mv
      v-if="['text', 'date', 'number', 'select', 'radio'].includes(field.type) && oldValue"
      ref="input"
      :value="oldValue"
      :name="field.name"
      :multiline="field.multiline === true"
      readonly
    />
    <bl-text-field-mv
      v-if="['text', 'date', 'number', 'select', 'radio'].includes(field.type) && newValue"
      ref="input"
      :value="newValue"
      :name="field.name"
      :is-error="true"
      :multiline="field.multiline === true"
      readonly
    />
    <div v-if="field.type === 'file'">
      <template v-if="field.format === 'pdf'">
        <bl-button-av
          v-if="oldValue"
          :href="oldValue"
        >
          {{ field.label }}
        </bl-button-av>
        <bl-button-av
          v-if="newValue"
          :href="newValue"
          color="red"
        >
          {{ field.label }} - new
        </bl-button-av>
      </template>
      <template v-if="field.format === 'image'">
        <img
          v-if="oldValue"
          :src="oldValue"
          width="250px"
          height="auto"
          style="margin-right: 25px;"
        >
        <img
          v-if="newValue"
          :src="newValue"
          width="250px"
          height="auto"
          style="border: 2px solid red"
        >
      </template>
      <template v-if="field.format === 'identity-image'">
        <div
          v-if="oldValue"
          style="min-height: 350px;"
        >
          <div v-viewer>
            <img
              :src="oldValue"
              class="u-hidden"
            >
          </div>
        </div>
        <div
          v-if="newValue"
          style="min-height: 366px; border: 4px solid red; margin-top: 5px; padding: 4px;"
        >
          <div>
            <div v-viewer>
              <img
                :src="newValue"
                class="u-hidden"
              >
            </div>
          </div>
        </div>
      </template>
    </div>
    <bl-field-hint-av v-if="showHint && field.hint">
      {{ field.hint }}
    </bl-field-hint-av>
  </component>
</template>
<script>
import dayjs from 'dayjs';
import { money } from 'partner-admin/shared/helpers/numberHelper';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import InlineField from '../InlineField';
import BasicField from '../BasicField';

export default {
  name: 'ReviewField',
  components: { BasicField, InlineField, BlTextFieldMv, BlButtonAv, BlFieldHintAv },
  props: {
    field: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'basic',
      validator(value) {
        return ['basic', 'inline'].includes(value);
      },
    },
    showHint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    newValue() {
      if (this.field && this.field.value && this.field.value.newValue !== null) {
        return this.sanitizeValue(this.field.value.newValue);
      }
      return '';
    },
    oldValue() {
      if (this.field && this.field.value && this.field.value.oldValue !== null) {
        return this.sanitizeValue(this.field.value.oldValue);
      }
      return '';
    },
    layoutType() {
      return {
        basic: 'basic-field',
        inline: 'inline-field',
      }[this.layout];
    },
  },
  methods: {
    sanitizeValue(value) {
      let inputValue = value;
      if (this.field.type === 'date') {
        inputValue = dayjs(inputValue).format('DD MMMM YYYY');
      }
      if (this.field.type === 'number') {
        if (this.field.format === 'money') {
          inputValue = money(inputValue);
        }
        if (this.field.format === 'percentage') {
          const formatted = `${inputValue}`.replace('.', ',');
          inputValue = `${formatted}%`;
        }
        if (this.field.format === 'decimal') {
          inputValue = `${inputValue}`.replace('.', ',');
        }
      }
      if (['radio', 'select'].includes(this.field.type)) {
        const option = this.field.options.find(opt => opt.value === inputValue);
        inputValue = option ? option.label : '';
      }
      return inputValue;
    },
  },
};
</script>
