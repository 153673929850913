import { mapActions, mapGetters } from 'vuex';
import NotifMixin from './notifMixin';

export default {
  mixins: [NotifMixin],
  computed: {
    ...mapGetters({
      histories: 'getHistories',
      historiesTotal: 'getHistoriesTotal',
      historiesLoadState: 'getHistoryLoadState',
      userProfiles: 'getUserProfiles',
    }),
    type() {
      return this.$route.query.type;
    },
  },
  data() {
    return {
      limit: 10,
      offset: 0,
    };
  },
  created() {
    this.getHistories();
  },
  methods: {
    ...mapActions(['retrieveHistories']),
    getHistories() {
      this.retrieveHistories({
        customSegments: [this.$route.params.id, this.type],
        limit: this.limit,
        offset: this.offset,
      }).catch(this.showErrorNotif);
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getHistories();
    },
  },
};
