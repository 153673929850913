<template>
  <div :class="getBaseClass">
    <template v-if="prefixType === 'button'">
      <div :class="getPrefixClass">
        <slot name="prefix" />
      </div>
    </template>
    <template v-if="prefixType === 'icon'">
      <i :class="getPrefixClass" />
    </template>
    <template v-if="prefixType === 'text'">
      <div :class="getPrefixClass">
        {{ prefixContext }}
      </div>
    </template>
    <slot name="input" />
    <template v-if="postfixType === 'button'">
      <div :class="getPostfixClass">
        <slot name="postfix" />
      </div>
    </template>
    <template v-if="postfixType === 'icon'">
      <i :class="getPostfixClass" />
    </template>
    <template v-if="postfixType === 'text'">
      <div :class="getPostfixClass">
        <slot name="postfix" /> {{ postfixContext }}
      </div>
    </template>
  </div>
</template>
<script>
import { postfixType, prefixType, size } from './data/type';

export default {
  name: 'BlInputGroupMv',
  status: 'ready',
  release: '0.1.1',
  props: {
    /**
     * The postfix type of the input group.
     * `icon, button, text, ''`
     */
    postfixType: {
      type: String,
      required: false,
      validator: value => {
        return value ? postfixType.indexOf(value) !== -1 : true;
      },
      default: '',
    },
    /**
     * The prefix type of the input group.
     * `icon, button, text, ''`
     */
    prefixType: {
      type: String,
      required: false,
      validator: value => {
        return value ? prefixType.indexOf(value) !== -1 : true;
      },
      default: '',
    },
    /**
     * The postfix context
     */
    postfixContext: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The prefix context
     */
    prefixContext: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The postfix icon
     */
    postfixIcon: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The prefix icon
     */
    prefixIcon: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The sizes of the input group.
     * `small, large, ''`
     */
    size: {
      type: String,
      required: false,
      validator: value => {
        return size.indexOf(value) !== -1;
      },
      default: '',
    },
    /**
     * Whether the input group is error
     * `true , false`
     */
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getBaseClass() {
      const baseClass = 'c-inp-grp';
      if (this.postfixType === 'icon') {
        return [`${baseClass}-icon`, `${baseClass}-icon--right`];
      }
      if (this.prefixType === 'icon') {
        return `${baseClass}-icon`;
      }
      return `${baseClass}-table`;
    },
    getPostfixClass() {
      const baseClass = 'c-inp';
      const errorClass = [this.isError ? `${baseClass}--error` : ''];
      if (this.postfixType === 'text') {
        return [`${baseClass}__ctx`, this.size ? `${baseClass}__ctx--${this.size}` : '', ...errorClass];
      }
      if (this.postfixType === 'icon') {
        return [
          `${baseClass}__icon`,
          'c-icon',
          this.postfixIcon ? `c-icon--${this.postfixIcon}` : '',
          this.size === 'large' ? 'c-icon--medium' : '',
          ...errorClass,
        ];
      }
      return [this.postfixType === 'button' ? `${baseClass}__btn` : '', ...errorClass];
    },
    getPrefixClass() {
      const baseClass = 'c-inp';
      const errorClass = [this.isError ? `${baseClass}--error` : ''];
      if (this.prefixType === 'text') {
        return [`${baseClass}__ctx`, this.size ? `${baseClass}__ctx--${this.size}` : '', ...errorClass];
      }
      if (this.prefixType === 'icon') {
        return [
          `${baseClass}__icon`,
          'c-icon',
          this.prefixIcon ? `c-icon--${this.prefixIcon}` : '',
          this.size === 'large' ? 'c-icon--medium' : '',
          ...errorClass,
        ];
      }
      return [this.prefixType === 'button' ? `${baseClass}__btn` : '', ...errorClass];
    },
  },
};
</script>
<style src="./BlInputGroupMv.style.scss" lang="scss"></style>
