<template>
  <div>
    <div v-if="isAllowed('product', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Edit Produk
      </h1>
    </div>

    <div v-if="product" class="c-tab__content__body">
      <product-form ref="ProductForm" :product="product" form="edit" />
      <!-- TODO if maker show edit button -->
      <div class="u-mrgn-top--10">
        <bl-button-av color="red" @click="saveProduct">
          Simpan Perubahan
        </bl-button-av>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import ProductForm from '../components/ProductForm';

export default {
  name: 'ProductEditPage',
  components: { ProductForm, BlButtonAv },
  mixins: [PolicyPageMixin, ErrorMixin],
  data() {
    return {
      product: null,
    };
  },
  methods: {
    ...mapActions(['populateNewDraft']),
    saveProduct() {
      this.$refs.ProductForm.validateAndGetFormData().then(formData => {
        const payload = {
          id: this.$route.params.id,
          ...formData,
        };
        this.$api
          .putProducts(payload)
          .then(response => {
            this.populateNewDraft(response.data);
            this.$root.$emit('showFlashNotif', {
              text: 'Berhasil Mengubah data product',
              type: 'success',
            });
            this.$router.push({
              name: 'productDraft',
            });
          })
          .catch(this.handleError);
      });
    },
    retrieveInitialData() {
      this.$api
        .getProduct(this.$route.params.id)
        .then(response => {
          this.product = response.data;
        })
        .catch(this.hanldeError);
    },
  },
};
</script>
