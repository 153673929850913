<template>
  <div class="u-pad--4">
    <aml-cft-tab />
    <div class="c-tab__content is-active u-pad--4">
      <div class="o-layout">
        <div class="o-layout__item u-6of12">
          <div style="font-size: 24px" class="u-mrgn-bottom--4">
            Configuration Page
          </div>

          <Loader
            :load-state="loadState"
            :loading-class="['u-mrgn-v--6']"
            :error-class="['u-mrgn-v--6', 'u-align-center']"
            @reload="retrieveInitialData"
          >
            <div class="u-mrgn-bottom--4">
              <div class="u-mrgn-bottom--2" style="font-size: 16px">
                Rules #1 Cumulative transaction amount
              </div>
              <bl-text-field-mv
                v-model="configuration.transaction_gmv.general"
                class="aml-cft-configuration-page__gmv"
                type="number"
                min="0"
                :formatter="$options.numberFormatter"
              />
            </div>

            <div class="u-mrgn-bottom--4">
              <div class="u-mrgn-bottom--2" style="font-size: 16px">
                Rules #2 Cumulative transaction number
              </div>
              <bl-text-field-mv
                v-model="configuration.transaction_count.general"
                class="aml-cft-configuration-page__count"
                type="number"
                min="0"
                :formatter="$options.numberFormatter"
              />
            </div>

            <div class="u-mrgn-bottom--4">
              <div class="u-mrgn-bottom--2" style="font-size: 16px">
                Rules #3 Cumulative transaction amount against monthly income
              </div>

              <inline-field
                v-for="field in configuration.transaction_gmv.monthly_incomes"
                :key="field.key"
                :label="field.key"
              >
                <bl-text-field-mv
                  v-model="field.value"
                  class="aml-cft-configuration-page__monthly-incomes"
                  type="number"
                  min="0"
                  :formatter="$options.numberFormatter"
                />
              </inline-field>
            </div>

            <div class="u-mrgn-bottom--4">
              <div class="u-mrgn-bottom--2" style="font-size: 16px">
                Rules #4 Cumulative transaction amount against occupation
              </div>

              <inline-field
                v-for="field in configuration.transaction_gmv.occupations"
                :key="field.key"
                :label="field.key"
              >
                <bl-text-field-mv
                  v-model="field.value"
                  class="aml-cft-configuration-page__occupations"
                  type="number"
                  min="0"
                  :formatter="$options.numberFormatter"
                />
              </inline-field>
            </div>

            <div class="u-mrgn-bottom--8">
              <div class="u-mrgn-bottom--2" style="font-size: 16px">
                Rules #5 Cumulative transaction amount against PEP & BO
              </div>

              <basic-field label="PEP">
                <bl-text-field-mv
                  v-model="configuration.transaction_gmv.pep"
                  class="aml-cft-configuration-page__pep"
                  type="number"
                  min="0"
                  :formatter="$options.numberFormatter"
                />
              </basic-field>

              <basic-field label="BO">
                <bl-text-field-mv
                  v-model="configuration.transaction_gmv.bo"
                  class="aml-cft-configuration-page__bo"
                  type="number"
                  min="0"
                  :formatter="$options.numberFormatter"
                />
              </basic-field>
            </div>

            <bl-button-av
              class="aml-cft-configuration-page__submit"
              block
              color="red"
              has-spinner
              :disabled="isSubmitting"
              :activated="isSubmitting"
              @click="submit"
            >
              Save
            </bl-button-av>
          </Loader>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import BasicField from 'partner-admin/components/BasicField';
import InlineField from 'partner-admin/components/InlineField';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import Loader from 'partner-admin/components/Loader';
import NotifMixin from 'partner-admin/mixins/notifMixin';
import AmlCftTab from '../components/AmlCftTab';

export default {
  name: 'AmlCftConfigurationPage',
  components: { AmlCftTab, BasicField, InlineField, BlTextFieldMv, BlButtonAv, Loader },
  numberFormatter: {
    format: val => val,
    deformat: val => Number(val),
  },
  mixins: [PolicyPageMixin, NotifMixin],
  data() {
    return {
      configuration: {
        transaction_count: {
          general: null,
        },
        transaction_gmv: {
          general: null,
          monthly_incomes: [],
          occupations: [],
          pep: null,
          bo: null,
        },
      },
      loadState: 'idle',
      isSubmitting: false,
    };
  },
  methods: {
    retrieveInitialData() {
      this.loadState = 'loading';
      this.$api
        .getAmlReportsConfigurations()
        .then(({ data }) => {
          this.configuration = data;
          this.loadState = 'success';
        })
        .catch(e => {
          this.loadState = 'error';
          this.showErrorNotif(e);
        });
    },
    submit() {
      this.isSubmitting = true;

      const payload = {
        transaction_count: this.configuration.transaction_count,
        transaction_gmv: this.configuration.transaction_gmv,
      };

      this.$api
        .putAmlReportsConfigurations(payload)
        .then(() => {
          this.showSuccessNotif('Edit configuration success');
          this.$router.push({
            name: 'AmlCftIndex',
          });
        })
        .catch(this.showErrorNotif)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
