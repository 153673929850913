import api from 'partner-admin/api';

const states = {
  transactions: [],
  transactionsLoadState: 'idle',
  transactionsTotal: 0,
};

const getters = {
  getTransactions: state => state.transactions,
  getTransactionsLoadState: state => state.transactionsLoadState,
  getTransactionsTotal: state => state.transactionsTotal,
};

const mutations = {
  setTransactions: (state, val) => {
    state.transactions = val;
  },
  setTransactionsLoadState: (state, val) => {
    state.transactionsLoadState = val;
  },
  setTransactionsTotal: (state, val) => {
    state.transactionsTotal = val;
  },
};

const actions = {
  retrieveTransactions({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.transactionsLoadState !== 'loading') {
        commit('setTransactionsLoadState', 'loading');
        api
          .getTransactions(payload)
          .then(response => {
            commit('setTransactionsLoadState', 'success');
            commit('setTransactions', response.data);
            commit('setTransactionsTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setTransactionsLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.transactions);
      }
    });
  },
  retrieveTransaction({ state }, id) {
    return new Promise((resolve, reject) => {
      if (state.transactions.length) {
        const found = state.transactions.find(trx => trx.order_id === id);
        if (found) {
          resolve(found);
          return;
        }
      }
      api
        .getTransaction(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
