<template>
  <div>
    <div v-if="isAllowed('product', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Tambah Produk Baru
      </h1>
    </div>

    <div class="c-tab__content__body">
      <product-form ref="ProductForm" form="new" />
      <div class="u-mrgn-top--10">
        <bl-button-av color="red" @click="saveProduct">
          Simpan
        </bl-button-av>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import ProductForm from '../components/ProductForm';

export default {
  name: 'ProductAddPage',
  components: { ProductForm, BlButtonAv },
  mixins: [PolicyMixin, ErrorMixin],
  methods: {
    ...mapActions(['populateNewDraft']),
    saveProduct() {
      this.$refs.ProductForm.validateAndGetFormData().then(formData => {
        this.$api
          .postProducts(formData)
          .then(response => {
            this.populateNewDraft(response.data);
            this.$root.$emit('showFlashNotif', {
              text: 'Berhasil Menambah Draft Product',
              type: 'success',
            });
            this.$router.push({
              name: 'productDraft',
            });
          })
          .catch(this.handleError);
      });
    },
  },
};
</script>
