export default function readFile(file) {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error('Error read file'));

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const { name, type, size } = file;

        resolve({
          name,
          size,
          type,
          file: reader.result,
        });
      },
      false,
    );
    reader.readAsDataURL(file);
  });
}
