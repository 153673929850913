<template>
  <div>
    <h1 class="u-txt--bold u-mrgn-bottom--1">
      {{ title }}
    </h1>
    <basic-field label="Bank Name">
      <bl-text-field-mv class="old_bank_name" :value="oldData.bank_name" readonly />
      <bl-text-field-mv v-if="newData.bank_name" class="new_bank_name" :value="newData.bank_name" readonly is-error />
    </basic-field>
    <basic-field label="Bank Branch">
      <bl-text-field-mv class="old_bank_branch" :value="oldData.bank_branch" readonly />
      <bl-text-field-mv
        v-if="newData.bank_branch"
        class="new_bank_branch"
        :value="newData.bank_branch"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account Currency">
      <bl-text-field-mv class="old_account_ccy" :value="oldData.account_ccy" readonly />
      <bl-text-field-mv
        v-if="newData.account_ccy"
        class="new_account_ccy"
        :value="newData.account_ccy"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account No.">
      <bl-text-field-mv class="old_account_no" :value="oldData.account_no" readonly />
      <bl-text-field-mv
        v-if="newData.account_no"
        class="new_account_no"
        :value="newData.account_no"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account Name">
      <bl-text-field-mv class="old_account_name" :value="oldData.account_name" readonly />
      <bl-text-field-mv
        v-if="newData.account_name"
        class="new_account_name"
        :value="newData.account_name"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Valid">
      <bl-text-field-mv class="old_account_valid" :value="getBooleanDropdownValue(oldData.account_valid)" readonly />
      <bl-text-field-mv
        v-if="newData.account_valid !== undefined"
        class="new_account_valid"
        :value="getBooleanDropdownValue(newData.account_valid)"
        readonly
        is-error
      />
    </basic-field>
    <basic-field label="Bank Account Name (system)">
      <bl-text-field-mv class="old_checked_account_name" :value="oldData.checked_account_name" readonly />
      <bl-text-field-mv
        v-if="newData.checked_account_name"
        class="new_checked_account_name"
        :value="newData.checked_account_name"
        readonly
        is-error
      />
    </basic-field>
    <hr />
  </div>
</template>
<script>
import InvestorRevisesViewMixin from '../../mixins/InvestorRevisesViewMixin';

export default {
  name: 'InvestorRevisesViewBank',
  mixins: [InvestorRevisesViewMixin],
};
</script>
