var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-tab__content__head"},[_c('router-link',{attrs:{"to":"/products"}},[_vm._v("\n      < Kembali Ke Halaman Daftar Produk\n    ")]),_vm._v(" "),_c('div',{staticClass:"o-layout o-layout--responsive u-mrgn-top--2"},[_c('bl-field-mv',{staticClass:"o-layout__item u-2of12"},[_c('bl-field-row-mv',[_c('bl-dropdown-av',{attrs:{"placeholder":"State","options":_vm.$options.stateOptions},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)],1),_vm._v(" "),_c('bl-field-mv',{staticClass:"o-layout__item u-2of12"},[_c('bl-field-row-mv',[_c('bl-text-field-mv',{attrs:{"placeholder":"Kode Produk"},model:{value:(_vm.productCode),callback:function ($$v) {_vm.productCode=$$v},expression:"productCode"}})],1)],1),_vm._v(" "),_c('bl-field-mv',{staticClass:"o-layout__item u-2of12"},[_c('bl-field-row-mv',[_c('bl-text-field-mv',{attrs:{"placeholder":"Nama Produk"},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1)],1),_vm._v(" "),_c('bl-field-mv',{staticClass:"o-layout__item u-2of12"},[_c('bl-field-row-mv',[_c('bl-dropdown-av',{attrs:{"track-by":"code","label":"text","placeholder":"Type","options":_vm.$options.typeOptions},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_vm._v(" "),_c('bl-button-av',{staticClass:"o-layout__item u-2of12 u-mrgn-left--4",attrs:{"color":"red"},on:{"click":function($event){return _vm.searchDrafts()}}},[_vm._v("\n        Search\n      ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"c-tab__content__body"},[_c('Table',{attrs:{"is-draft":true,"table-header":[
        'Nama Produk',
        'Tipe Produk',
        'Kode Produk',
        'CCY',
        'NAV',
        'State',
        'Type',
        'Perubahan Terakhir' ],"table-data":_vm.tableData,"load-state":_vm.draftsLoadState,"action-button":['view_list', 'view_log'],"action-root-url":'/products'},on:{"reload":_vm.retrieveDrafts}}),_vm._v(" "),(_vm.draftsTotal > _vm.limit)?_c('bl-pagination-mv',{staticClass:"u-mrgn-top--4",attrs:{"total":_vm.draftsTotal,"limit":_vm.limit,"offset":_vm.offset,"max-links":10},on:{"changePage":_vm.setOffset}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }