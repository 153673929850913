export default {
  props: {
    size: {
      type: String,
      required: false,
      validator: value => {
        return ['small', 'large', 'narrow', 'mid', 'wide', ''].indexOf(value) !== -1;
      },
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderless: {
      type: Boolean,
      required: false,
      default: false,
    },
    noborder: {
      type: Boolean,
      required: false,
      default: false,
    },
    naked: {
      type: Boolean,
      required: false,
      default: false,
    },
    invisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    invisiblePure: {
      type: Boolean,
      required: false,
      default: false,
    },
    noSpinner: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholderWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getClasses() {
      const baseClass = 'c-inp';
      return [
        baseClass,
        this.size ? `${baseClass}--${this.size}` : '',
        this.disabled ? `${baseClass}--disabled` : '',
        this.isError ? `${baseClass}--error` : '',
        this.isSuccess ? `${baseClass}--success` : '',
        this.borderless ? `${baseClass}--borderless` : '',
        this.noborder ? `${baseClass}--noborder` : '',
        this.naked ? `${baseClass}--naked` : '',
        this.invisible ? `${baseClass}--invisible` : '',
        this.invisiblePure ? `${baseClass}--invisible ${baseClass}--invisible-pure` : '',
        this.noSpinner ? `${baseClass}--no-spinner` : '',
        this.placeholderWhite ? `${baseClass}-placeholder--white` : '',
      ];
    },
    isDisabled() {
      return this.disabled;
    },
  },
};
