<template>
  <div>
    <div v-if="isAllowed('investor', 'view_list')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Lihat Investor Update
      </h1>
    </div>
    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-revises-edit-form :investor-revise="investorRevise" :draft="draft" />
    </div>
  </div>
</template>
<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import InvestorRevisesEditForm from '../components/InvestorRevisesEditForm';
import InvestorRevisesMixin from '../mixins/InvestorRevisesMixin';

export default {
  name: 'InvestorRevisesEditPage',
  components: { InvestorRevisesEditForm },
  mixins: [PolicyPageMixin, InvestorRevisesMixin],
};
</script>
