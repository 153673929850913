<template>
  <tr>
    <td class="skt-row__upload-date">
      {{ sktDocument.created_at | humanizeDate }}
    </td>
    <td class="skt-row__product">
      {{ sktDocument.product && sktDocument.product.fund_name }}
    </td>
    <td class="skt-row__skt-date">
      {{ sktDocument.date | humanizeDate }}
    </td>
    <td>
      <a v-if="sktDocument.state === 'uncompleted'" class="skt-row__state" @click.prevent="showError">{{
        sktDocument.state
      }}</a>
      <span v-else class="skt-row__state">{{ sktDocument.state }}</span>
    </td>
    <td class="skt-row__upload-by">
      {{ sktDocument.maker_username }}
    </td>
    <td>
      <bl-button-av class="skt-row__view" size="tiny" @click="download">
        Lihat
      </bl-button-av>
      <file-downloader ref="FileDownloader" />
    </td>
  </tr>
</template>
<script>
import { mapState } from 'partner-admin/lib/vuex';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';
import queryString from 'query-string';
import FileDownloader from 'partner-admin/components/FileDownloader';

const endpoint = `${process.env.PARTNER_API_URL}/_exclusive/bundle-documents`;

export default {
  name: 'UploadSktTableRow',
  filters: {
    humanizeDate(value) {
      if (value) return dayjs(value).format('DD MMMM YYYY');
      return '-';
    },
  },
  components: { BlButtonAv, FileDownloader },
  props: {
    sktDocument: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
  },
  methods: {
    download() {
      const token = this.tokenObj.token;

      const payload = {
        access_token: token,
      };
      const url = new URL(`${endpoint}/${this.sktDocument.id}/download?${queryString.stringify(payload)}`);

      this.$refs.FileDownloader.download(`${url}`);
    },
    showError() {
      this.$emit('showError', this.sktDocument.error_notes);
    },
  },
};
</script>
