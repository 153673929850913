export default {
  identity_number: {
    numeric: true,
    length: 16,
  },
  npwp_number: {
    numeric: true,
    length: 15,
  },
  name: {
    max: 100,
  },
  birth_place: {
    max: 100,
  },
  postal_code: {
    numeric: true,
    length: 5,
  },
  domicile_postal_code: {
    numeric: true,
    length: 5,
  },
  email: {
    email: true,
  },
};
