import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BasicField from 'partner-admin/components/BasicField';

export default {
  components: { BlTextFieldMv, BasicField, BlDropdownAv, BlFieldErrorAv },
  BooleanDropdownOptions: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  props: {
    investorBankData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
    };
  },
  computed: {
    isFormEditable() {
      return Object.keys(this.newData).length;
    },
    requiredFields() {
      return Object.keys(this.newData);
    },
    fieldRules() {
      const finalRules = {};

      // Make user updated data required
      this.requiredFields.forEach(field => {
        finalRules[field] = {
          ...finalRules[field],
          required: true,
        };
      });

      // Validate admin edited field
      const remainingFields = this.$options.editableFields.filter(
        field => !this.requiredFields.includes(field) && field !== 'account_valid',
      );
      remainingFields.forEach(field => {
        finalRules[field] = {
          ...finalRules[field],
          required: true,
        };
      });

      return finalRules;
    },
    investorBankDraftData() {
      const newData = {};
      const oldData = {};
      const base = this.investorBankData.data || {};

      base.forEach(data => {
        newData[data.field] = data.new_value;
        oldData[data.field] = data.old_value;
      });
      return { id: this.investorBankData.record_id, newData, oldData };
    },
    oldData() {
      return this.investorBankDraftData.oldData || {};
    },
    newData() {
      return this.investorBankDraftData.newData || {};
    },
  },
  methods: {
    fieldErrorMessage(field, label = '') {
      const errorMessage = this.errors.first(field) || '';
      return errorMessage.replace(field, label);
    },
  },
};
