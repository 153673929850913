<template>
  <div>
    <div class="c-tab__content__head">
      <router-link to="/investors">
        &lt; Kembali Ke Halaman Daftar Investor
      </router-link>
    </div>

    <div class="c-tab__content__body">
      <ul class="c-tab__nav">
        <li class="c-tab__list">
          <a v-if="isAllowed('investor', 'view_list')" class="c-tab__link" @click="gotoInvestorDraft">
            <span> New Draft </span>
          </a>
        </li>
        <li class="c-tab__list is-active">
          <a class="c-tab__link" @click.prevent="">
            <span> Updated Draft </span>
          </a>
        </li>
      </ul>
      <div class="c-tab__content is-active">
        <div class="c-tab__content__head">
          <div class="o-layout o-layout--responsive u-mrgn-top--2">
            <div class="o-layout__item o-layout o-layout--responsive u-10of12">
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-date-picker-mv
                      v-model="chosenDate"
                      class="u-width-1"
                      is-has-multiple-calendar
                      is-date-range-enabled
                      :begin-date="chosenDate.beginDate || currentDate"
                      :end-date="chosenDate.endDate"
                      multiple-calendar
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av v-model="state" placeholder="State" :options="$options.stateOptions" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av
                      v-model="agentIds"
                      placeholder="Agent"
                      track-by="id"
                      label="name"
                      :options="agentOptions"
                      multiple
                    />
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-text-field-mv v-model="identityNumber" placeholder="NIK" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-text-field-mv v-model="email" placeholder="Email" />
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
              <div class="o-layout__item o-layout o-layout--responsive">
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-dropdown-av v-model="risk" :options="$options.riskOptions" placeholder="Risk" />
                  </bl-field-row-mv>
                </bl-field-mv>
                <bl-field-mv class="o-layout__item u-4of12">
                  <bl-field-row-mv>
                    <bl-button-av class="investor-revises-draft-page__search" color="red" @click.native="searchDrafts">
                      Search
                    </bl-button-av>
                    <bl-button-av
                      class="investor-revises-draft-page__reset u-mrgn-left--1"
                      color="red"
                      @click.native="resetFilter"
                    >
                      Reset Filter
                    </bl-button-av>
                  </bl-field-row-mv>
                </bl-field-mv>
              </div>
            </div>
            <div class="o-layout__item o-layout o-layout--responsive u-2of12">
              <bl-button-av color="red" @click="gotoInvestorChecked">
                Second Approval
              </bl-button-av>
            </div>
          </div>
        </div>

        <div class="c-tab__content__body">
          <draft-table
            :drafts="drafts"
            record-type="Bib::InvestorRevise"
            :total="draftsTotal"
            :limit="limit"
            :offset="offset"
            :load-state="loadState"
            @reload="getDrafts"
            @refresh="getDrafts"
            @changePage="setOffset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'partner-admin/lib/vuex';
import dayjs from 'dayjs';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import ErrorMixin from 'partner-admin/mixins/errorMixin';
import AgentOptionsMixin from 'partner-admin/mixins/agentOptionsMixin';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import DraftTable from '../../InvestorPage/components/DraftTable';

export default {
  name: 'InvestorRevisesDraftPage',
  stateOptions: ['', 'created', 'submitted', 'approved', 'rejected'],
  riskOptions: ['', 'high', 'low'],
  components: { BlFieldMv, BlFieldRowMv, BlButtonAv, BlTextFieldMv, BlDropdownAv, BlDatePickerMv, DraftTable },
  mixins: [PolicyPageMixin, ErrorMixin, AgentOptionsMixin],
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      offset: 0,
      limit: 10,
      chosenDate: {},
      currentDate,
      state: '',
      identityNumber: '',
      email: '',
      drafts: [],
      draftsTotal: 0,
      loadState: 'idle',
      agentIds: '',
      risk: '',
    };
  },
  computed: {
    ...mapState('filters', {
      investorRevisesDraftFilters: state => state.investorRevisesDraftFilters,
    }),
    defaultPayload() {
      return {
        offset: this.offset,
        limit: this.limit,
        record_type: 'Bib::InvestorRevise',
        action: 'draft_create',
      };
    },
    searchPayload() {
      return {
        state: this.state,
        start_date: this.chosenDate.beginDate ? dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD') : '',
        end_date: this.chosenDate.endDate ? dayjs(this.chosenDate.endDate).format('YYYY-MM-DD') : '',
        'filter[investor][identity_number]': this.identityNumber,
        'filter[investor][email]': this.email,
        'filter[investor][agent_id][]': this.agentIds ? this.agentIds.map(agent => agent.id) : undefined,
        'filter[investor][risk]': this.risk,
      };
    },
  },
  methods: {
    ...mapMutations('filters', ['setInvestorRevisesDraftFilters']),
    retrieveInitialData() {
      if (this.investorRevisesDraftFilters) {
        this.state = this.investorRevisesDraftFilters.state;
        if (this.investorRevisesDraftFilters.start_date) {
          this.chosenDate = {
            beginDate: dayjs(this.investorRevisesDraftFilters.start_date).valueOf(),
            endDate: dayjs(this.investorRevisesDraftFilters.end_date).valueOf(),
          };
        }
        this.identityNumber = this.investorRevisesDraftFilters['filter[investor][identity_number]'];
        this.email = this.investorRevisesDraftFilters['filter[investor][email]'];
        this.risk = this.investorRevisesDraftFilters['filter[investor][risk]'];
        this.offset = this.investorRevisesDraftFilters.offset;
      }

      this.getDrafts();
      this.retrieveAgentOptions();
    },
    getDrafts() {
      const payload = Object.assign(this.defaultPayload, this.searchPayload);
      this.setInvestorRevisesDraftFilters(payload);
      this.retrieveDrafts(payload);
    },
    retrieveDrafts(payload) {
      this.loadState = 'loading';
      this.$api
        .getDrafts(payload)
        .then(({ data, meta }) => {
          this.drafts = data;
          this.draftsTotal = meta.total;
        })
        .then(this.retrieveDraftRecords)
        .then(() => {
          this.loadState = 'success';
        })
        .catch(e => {
          this.loadState = 'error';
          this.handleError(e);
        });
    },
    retrieveDraftRecords() {
      const recordIds = this.drafts.map(draft => draft.record_id);
      const uniqueRecordIds = Array.from(new Set(recordIds));
      const apis = uniqueRecordIds.map(id => this.$api.getInvestorRevise(id));
      Promise.all(apis)
        .then(investorReviseRecords => {
          this.drafts.forEach((draft, idx) => {
            const investorReviseRecordIdx = investorReviseRecords.findIndex(
              record => record.data.id === draft.record_id,
            );
            if (investorReviseRecordIdx !== -1) {
              const record = investorReviseRecords[investorReviseRecordIdx].data;
              this.$set(this.drafts, idx, { ...this.drafts[idx], record });
            }
          });
          return Promise.resolve();
        })
        .catch(e => Promise.reject(e));
    },
    setOffset(query) {
      this.offset = query.offset;
      this.getDrafts();
    },
    searchDrafts() {
      this.offset = 0;
      this.getDrafts();
    },
    resetFilter() {
      this.identityNumber = '';
      this.email = '';
      this.chosenDate = {};
      this.state = '';
      this.risk = '';
      this.offset = 0;
      this.getDrafts();
    },
    gotoInvestorDraft() {
      this.$router.push({
        name: 'investorDraft',
      });
    },
    gotoInvestorChecked() {
      this.$router.push({
        name: 'investorRevisesCheckedIndex',
      });
    },
  },
};
</script>
