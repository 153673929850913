<template>
  <bl-field-mv>
    <bl-field-row-mv>
      <bl-field-label-av v-if="label">
        {{ label }}
      </bl-field-label-av>
      <slot />
    </bl-field-row-mv>
  </bl-field-mv>
</template>
<script>
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';

export default {
  name: 'BasicField',
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlFieldLabelAv,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
