<template>
  <Loader :load-state="loadState">
    <div v-if="historyData.length === 0" class="history-empty">
      Tidak ada data log
    </div>
    <template v-else>
      <history-list-item
        v-for="history in historyData"
        :key="history.id"
        :history="history"
        :user-profiles="userProfiles"
      />

      <bl-pagination-mv
        v-if="total > limit"
        :total="total"
        :limit="limit"
        :offset="offset"
        :max-links="10"
        @changePage="changePage"
        class="u-mrgn-top--4"
      />
    </template>
  </Loader>
</template>

<script>
import BlPaginationMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlPaginationMv';
import HistoryListItem from './HistoryListItem';
import Loader from '../Loader';

export default {
  name: 'HistoryList',
  components: { HistoryListItem, Loader, BlPaginationMv },
  props: {
    historyData: {
      type: Array,
      default: () => [],
    },
    userProfiles: {
      type: Array,
      default: () => [],
    },
    loadState: {
      type: String,
      default: 'loading',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changePage(payload) {
      this.$emit('changePage', payload);
    },
  },
};
</script>
