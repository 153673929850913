<template>
  <div class="c-fld__col">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlFieldColMv',
  status: 'ready',
  release: '0.4.0',
};
</script>

<style src="./BlFieldColMv.style.scss" lang="scss"></style>
