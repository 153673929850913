import api from 'partner-admin/api';
import { constructInvestor } from './helper';

const states = {
  investors: [],
  investorsLoadState: 'idle',
  investorsTotal: 0,
};

const getters = {
  getInvestors: state => state.investors,
  getInvestorsLoadState: state => state.investorsLoadState,
  getInvestorsTotal: state => state.investorsTotal,
};

const mutations = {
  setInvestors: (state, val) => {
    state.investors = val;
  },
  setInvestorsLoadState: (state, val) => {
    state.investorsLoadState = val;
  },
  setInvestorsTotal: (state, val) => {
    state.investorsTotal = val;
  },
};

const actions = {
  retrieveInvestors({ state, commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      if (state.investorsLoadState !== 'loading') {
        commit('setInvestorsLoadState', 'loading');
        api
          .getInvestors(payload)
          .then(response => {
            commit('setInvestorsLoadState', 'success');
            commit('setInvestors', response.data);
            commit('setInvestorsTotal', response.meta.total);
            resolve(response);
          })
          .catch(response => {
            commit('setInvestorsLoadState', 'error');
            reject(response);
          });
      } else {
        resolve(state.investors);
      }
    });
  },
  getInvestorById({ state }, investorId) {
    return new Promise((resolve, reject) => {
      const storeInvestor = state.investors.find(investor => investor.id === Number(investorId));
      if (storeInvestor) {
        const formattedInvestor = constructInvestor({ ...storeInvestor });
        resolve(formattedInvestor);
        return;
      }
      api
        .getInvestor(investorId)
        .then(response => {
          const formattedInvestor = constructInvestor(response.data);
          resolve(formattedInvestor);
        })
        .catch(reject);
    });
  },
};

export default {
  state: { ...states },
  getters,
  mutations,
  actions,
};
