<template>
  <div class="o-layout u-mrgn-top--3">
    <div class="o-layout__item u-10of12">
      <div class="o-layout">
        <bl-field-mv class="o-layout__item u-6of12">
          <bl-field-row-mv>
            <bl-text-field-mv v-model="filter.keywords" placeholder="Search by NIK or email" multiline />
          </bl-field-row-mv>
        </bl-field-mv>
      </div>

      <div class="o-layout u-mrgn-bottom--3">
        <bl-field-mv class="o-layout__item u-3of12">
          <bl-field-row-mv>
            <bl-date-picker-mv
              v-model="filter.chosenDate"
              class="u-width-1"
              is-has-multiple-calendar
              is-date-range-enabled
              :begin-date="filter.chosenDate.beginDate || $options.currentDate"
              :end-date="filter.chosenDate.endDate"
              multiple-calendar
            />
          </bl-field-row-mv>
        </bl-field-mv>

        <bl-field-mv class="o-layout__item u-3of12">
          <bl-button-av class="investor-checked-filter__search" color="red" block @click.native="findInvestors">
            Search
          </bl-button-av>
        </bl-field-mv>
      </div>
    </div>
  </div>
</template>
<script>
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import { explode } from 'partner-admin/shared/helpers/stringHelper';
import dayjs from 'dayjs';

export default {
  name: 'InvestorCheckedFilter',
  currentDate: new Date().setHours(0, 0, 0, 0),
  components: {
    BlTextFieldMv,
    BlFieldMv,
    BlFieldRowMv,
    BlButtonAv,
    BlDatePickerMv,
  },
  data() {
    return {
      filter: {
        keywords: undefined,
        chosenDate: {},
      },
    };
  },
  methods: {
    findInvestors() {
      const filter = {
        'filter[investor][identity_number][]': this.filter.keywords ? explode(this.filter.keywords) : undefined,
        'filter[investor][email][]': this.filter.keywords ? explode(this.filter.keywords) : undefined,
        start_date: this.filter.chosenDate.beginDate
          ? dayjs(this.filter.chosenDate.beginDate).format('YYYY-MM-DD')
          : undefined,
        end_date: this.filter.chosenDate.endDate
          ? dayjs(this.filter.chosenDate.endDate).format('YYYY-MM-DD')
          : undefined,
      };

      this.$emit('search', filter);
    },
  },
};
</script>
