<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" :title="`Upload ${title}`">
      <bl-field-mv>
        <bl-field-label-av class="upload-investor-action__text"> {{ title }} File </bl-field-label-av>
        <bl-field-row-mv>
          <bl-upload-field-av
            ref="input"
            v-model="inputModel"
            class="upload-investor-action__file-input"
            @input="onFileChange"
          />
        </bl-field-row-mv>
      </bl-field-mv>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          class="upload-investor-action__cancel-button"
          style="width: 50%; display: inline-block;"
          @click.native="cancelAction()"
        >
          Cancel
        </bl-button-av>
        <bl-button-av
          class="upload-investor-action__upload-button"
          style="width: 50%; display: inline-block;"
          color="red"
          @click.native="upload()"
        >
          Upload
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlUploadFieldAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlUploadFieldAv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';

export default {
  name: 'UploadInvestorFileSheet',
  titleMap: {
    revise_data: 'Amandement Investor',
    revie_bank: 'Amandement Bank',
  },
  components: { BlDialogMv, BlFieldMv, BlFieldRowMv, BlFieldLabelAv, BlButtonGroupMv, BlButtonAv, BlUploadFieldAv },
  mixins: [ErrorMixin],
  data() {
    return {
      confirmationDialog: false,
      inputModel: '',
      fileType: '',
    };
  },
  computed: {
    title() {
      return this.$options.titleMap[this.fileType] || this.fileType;
    },
  },
  methods: {
    cancelAction() {
      this.confirmationDialog = false;
      this.inputModel = null;
    },
    upload() {
      this.$api
        .uploadInvestorFile({
          attachment: this.inputModel.file,
          attachment_file_name: this.inputModel.file_name,
          action: this.fileType.toLowerCase(),
        })
        .then(() => {
          this.confirmationDialog = false;
          this.$root.$emit('showFlashNotif', {
            text: `File ${this.fileType} berhasil di upload`,
            type: 'success',
          });
        })
        .catch(this.handleError);
    },
    onFileChange(files) {
      if (files[0]) {
        const vm = this;
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            vm.inputModel = {
              file_name: files[0].name,
              file: reader.result,
            };
          },
          false,
        );
        reader.readAsDataURL(files[0]);
      }
    },
    openDialog(type) {
      this.fileType = type;
      this.confirmationDialog = true;
    },
  },
};
</script>
