import BasicField from 'partner-admin/components/BasicField';
import NotifMixin from 'partner-admin/mixins/notifMixin';

export default {
  components: {
    BasicField,
  },
  mixins: [NotifMixin],
  props: {
    investor: {
      type: Object,
      required: true,
    },
    draft: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true,
    },
  },
  data() {
    return {
      loadState: 'idle',
      registrationAttributes: null,
      rejectionReasons: [],
    };
  },
  computed: {
    investorDraftData() {
      const newData = {};
      const oldData = {};
      let bankData = [];
      const base = this.draft.data || {};

      base.forEach(data => {
        if (data.field === 'bank_data') {
          bankData = [...data.value];
        } else {
          newData[data.field] = data.new_value;
          oldData[data.field] = data.old_value;
        }
      });

      return { newData, oldData, bankData };
    },
    newInvestorDraftData() {
      return this.investorDraftData.newData;
    },
    oldInvestorDraftData() {
      return this.investorDraftData.oldData;
    },
    investorDraftBanks() {
      return this.investorDraftData.bankData || [];
    },
  },
};
