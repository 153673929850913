<template>
  <div>
    <div v-if="isAllowed('investor', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Review Draft Investor
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-draft-view-form :draft="draft" :investor="investor" />
      <draft-checker-action :draft="draft" @action="handleAction" />
    </div>
  </div>
</template>

<script>
import InvestorDraftMixin from 'partner-admin/modules/InvestorPage/mixins/InvestorDraftMixin';
import InvestorDraftViewForm from 'partner-admin/modules/InvestorPage/components/InvestorDraftViewForm';
import DraftCheckerAction from 'partner-admin/components/DraftCheckerAction';

export default {
  name: 'InvestorCheckedReviewPage',
  components: { InvestorDraftViewForm, DraftCheckerAction },
  mixins: [InvestorDraftMixin],
  methods: {
    handleAction({ action, rejectionReason }) {
      const payload = {
        id: this.$route.params.id,
        state: action,
        rejection_reason: rejectionReason,
      };
      const actionMap = {
        final_rejected: 'menolak',
        final_approved: 'menyetujui',
      };
      this.$api
        .patchDraftStatus(payload)
        .then(() => {
          this.$root.$emit('showFlashNotif', {
            text: `Berhasil ${actionMap[action]} draft investor`,
            type: 'success',
          });
          this.$router.push({
            name: 'investorCheckedIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
