<template>
  <div>
    <div v-if="loadState === 'success'" class="o-layout">
      <div class="o-layout__item u-5of12">
        <div class="o-layout u-pad-left--4">
          <investor-draft-edit-data
            ref="InvestorEditData"
            :investor="investor"
            :new-data="newInvestorDraftData"
            :old-data="oldInvestorDraftData"
            :registration-attributes="registrationAttributes"
          />
        </div>
      </div>
      <div class="o-layout__item u-1of12">
        &nbsp;
      </div>
      <div class="o-layout__item u-6of12">
        <basic-field label="Identity File">
          <div v-if="oldInvestorDraftData.identity_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.identity_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="NPWP File">
          <div v-if="oldInvestorDraftData.npwp_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.npwp_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Selfie File">
          <div v-if="oldInvestorDraftData.selfie_url">
            <div v-viewer style="min-height: 350px;">
              <img :src="oldInvestorDraftData.selfie_url" class="u-hidden" />
            </div>
          </div>
        </basic-field>
        <basic-field label="Signature File">
          <img
            v-if="oldInvestorDraftData.signature_url"
            :src="oldInvestorDraftData.signature_url"
            width="200px"
            height="auto"
          />
        </basic-field>
        <investor-draft-edit-bank
          v-for="(investorDraftBank, index) in investorDraftBanks"
          ref="InvestorEditBank"
          :key="index"
          :investor-bank-data="investorDraftBank"
          :title="`Bank ${index + 1}`"
        />
      </div>
      <draft-rejection-reason class="o-layout__item" :draft="draft" form="kyc" />
      <div class="o-layout__item">
        <bl-button-av color="green" block @click.native="onClickApprove">
          Pengajuan Approval Perubahan
        </bl-button-av>
      </div>
      <div class="o-layout__item u-pad-top--4">
        <div v-if="rejectionReasons.length">
          <investor-rejection-reasons-field
            ref="InvestorRejectionReasonsField"
            :field="{ name: 'admin_rejection_reason', label: 'Rejection Reasons' }"
            :rejection-reasons="rejectionReasons"
          />
          <template v-if="showCustomRejectionReason">
            <basic-field label="Custom Rejection Reason">
              <bl-text-field-mv
                v-model="customRejectionReason"
                class="investor-draft-edit-form__custom-rejection-reason"
              />
              <bl-field-hint-av>
                Isi salah satu, Rejection Reason atau Custom Rejection Reason
              </bl-field-hint-av>
            </basic-field>
          </template>
          <bl-button-av color="red" block @click.native="onClickReject">
            Pengajuan Rejection Perubahan
          </bl-button-av>
        </div>
      </div>
      <nik-checker-dialog ref="NikCheckerDialog" @approve="submitApprove" />
    </div>
  </div>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import InvestorRejectionReasonsField from 'partner-admin/components/RejectionReasonsField';
import DraftRejectionReason from 'partner-admin/components/DraftRejectionReason';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import InvestorDraftEditData from './InvestorDraftEditData';
import InvestorDraftEditBank from './InvestorDraftEditBank';
import InvestorDraftFormMixin from '../../mixins/InvestorDraftFormMixin';
import NikCheckerDialog from '../NikCheckerDialog';

export default {
  name: 'InvestorDraftEditForm',
  components: {
    BlButtonAv,
    InvestorDraftEditData,
    InvestorDraftEditBank,
    InvestorRejectionReasonsField,
    DraftRejectionReason,
    NikCheckerDialog,
    BlFieldHintAv,
    BlTextFieldMv,
  },
  mixins: [InvestorDraftFormMixin],
  data() {
    return {
      customRejectionReason: '',
    };
  },
  computed: {
    showCustomRejectionReason() {
      return this.investor.state === 'sinvest_rejected';
    },
  },
  created() {
    this.setupFormData();
  },
  methods: {
    setupFormData() {
      return new Promise((resolve, reject) => {
        this.loadState = 'loading';
        const apis = [this.$api.getRegistrationAttributes(), this.$api.getRejectionsReasons()];
        Promise.all(apis)
          .then(([registrationAttributesResponse, rejectionReasonsResponse]) => {
            this.registrationAttributes = registrationAttributesResponse.data;
            this.rejectionReasons = this.processRejectionReason(rejectionReasonsResponse.data);
            this.loadState = 'success';
            resolve();
          })
          .catch(e => {
            this.loadState = 'error';
            this.showErrorNotif(e);
            reject(e);
          });
      });
    },
    processRejectionReason(rejectionReasons) {
      return rejectionReasons.filter(reason => reason.key !== 'ktp_duplicate');
    },
    validateInput() {
      return new Promise(resolve => {
        this.$refs.InvestorEditData.$validator.validate().then(() => {
          this.$refs.InvestorEditBank.forEach((investorBank, index) => {
            investorBank.$validator.validate();
            if (index === this.$refs.InvestorEditBank.length - 1) {
              resolve();
            }
          });
        });
      });
    },
    onClickApprove() {
      this.validateInput().then(() => {
        if (this.errors.items.length) {
          this.scrollToError(this.errors.items[0].field);
          return;
        }

        const payload = {
          id: this.investor.id,
          ...this.$refs.InvestorEditData.getPayload(),
          bank_data: this.$refs.InvestorEditBank.map(investorBank => investorBank.getPayload()),
        };

        this.$refs.NikCheckerDialog.checkIdentitiyCombination(payload).then(this.submitApprove);
      });
    },
    submitApprove(payload) {
      const api = {
        created: this.$api.putInvestorRegister,
        sinvest_rejected: this.$api.putInvestorRevise,
      }[this.investor.state];

      api(payload)
        .then(() => {
          this.showSuccessNotif('Berhasil melakukan KYC');
          this.$router.push({
            name: 'investorDraft',
          });
        })
        .catch(this.showErrorNotif);
    },
    onClickReject() {
      const rejectionValue = this.$refs.InvestorRejectionReasonsField.inputModel;
      if (rejectionValue || this.customRejectionReason) {
        const payload = {
          id: this.investor.id,
          admin_rejection_reason: rejectionValue,
          custom_rejection_reason: this.customRejectionReason,
        };

        if (rejectionValue === 1) {
          payload.identity_number = this.$refs.InvestorEditData.getPayload().identity_number;
        }

        const api = {
          created: this.$api.putInvestorRegister,
          sinvest_rejected: this.$api.putInvestorRevise,
        }[this.investor.state];

        api(payload)
          .then(() => {
            this.showSuccessNotif('Berhasil melakukan KYC');
            this.$router.push({
              name: 'investorDraft',
            });
          })
          .catch(this.showErrorNotif);
      } else {
        this.showErrorNotif('Pilih alasan menolak terlebih dahulu');
      }
    },
    scrollToError(field) {
      // 90 is offset for header and form
      // https://stackoverflow.com/a/49842367
      const firstField = document.querySelector(`[name="${field}"]`);
      if (firstField) {
        window.scroll({
          top: firstField.getBoundingClientRect().top + window.scrollY - 90,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>
