<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" title="Konfirmasi" class="draft-checker-action__text">
      Anda yakin untuk {{ actionText }} investor ini?
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          style="width: 50%;
          display: inline-block;"
          class="draft-checker-action__dialog-cancel-button"
          @click.native="cancelAction()"
        >
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%;
          display: inline-block;"
          color="red"
          class="draft-checker-action__dialog-confirm-button"
          @click.native="emitAction()"
        >
          Ya
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
    <div class="u-pad-top--5" style="border-top: 1px solid gray">
      <bl-field-mv class="u-width-1">
        <bl-field-row-mv>
          <bl-field-label-av>Alasan Menolak</bl-field-label-av>
          <bl-text-field-mv v-model="rejectionReason" :is-error="reasonError" placeholder="Alasan menolak" multiline />
          <bl-field-hint-av>Harus diisi jika ingin menolak</bl-field-hint-av>
        </bl-field-row-mv>
      </bl-field-mv>
      <bl-button-av color="red" class="draft-checker-action__open-approve-dialog-button" @click.native="approveDialog">
        Setuju
      </bl-button-av>
      <bl-button-av class="draft-checker-action__open-reject-dialog-button" @click.native="rejectDialog">
        Tolak
      </bl-button-av>
    </div>
  </div>
</template>
<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';

export default {
  name: 'DraftCheckerAction',
  components: {
    BlDialogMv,
    BlButtonGroupMv,
    BlButtonAv,
    BlFieldMv,
    BlFieldRowMv,
    BlTextFieldMv,
    BlFieldHintAv,
    BlFieldLabelAv,
  },
  props: {
    draft: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmationDialog: false,
      action: null,
      rejectionReason: '',
      reasonError: false,
    };
  },
  computed: {
    actionText() {
      return (
        {
          approved: 'menyetujui',
          rejected: 'menolak',
          submitted: 'memperbarui',
          final_approved: 'menyetujui',
          final_rejected: 'menolak',
        }[this.action] || ''
      );
    },
  },
  watch: {
    rejectionReason(val) {
      if (val) {
        this.reasonError = false;
      }
    },
  },
  methods: {
    approveDialog() {
      const actionMap = {
        investor_update: 'submitted',
        draft_create: 'approved',
      };
      this.action = this.draft.state === 'approved' ? 'final_approved' : actionMap[this.draft.action] || 'approved';
      this.confirmationDialog = true;
    },
    rejectDialog() {
      if (!this.rejectionReason.trim()) {
        this.reasonError = true;
        return;
      }
      this.action = this.draft.state === 'approved' ? 'final_rejected' : 'rejected';
      this.confirmationDialog = true;
    },
    cancelAction() {
      this.action = null;
      this.confirmationDialog = false;
    },
    emitAction() {
      this.confirmationDialog = false;
      this.$emit('action', { action: this.action, rejectionReason: this.rejectionReason.trim() });
    },
  },
};
</script>
