import { render, staticRenderFns } from "./TransactionSwitchingRejectionForm.vue?vue&type=template&id=d16ca43e&"
import script from "./TransactionSwitchingRejectionForm.vue?vue&type=script&lang=js&"
export * from "./TransactionSwitchingRejectionForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports