<template>
  <div>
    <h1 class="u-txt--bold u-mrgn-bottom--1">
      {{ title }}
    </h1>
    <basic-field label="Bank Name">
      <bl-text-field-mv
        v-model="bank_name"
        v-validate="fieldRules.bank_name"
        class="bank_name"
        name="bank_name"
        :is-error="!!fieldErrorMessage('bank_name')"
      />
      <bl-field-error-av v-if="fieldErrorMessage('bank_name')">
        {{ fieldErrorMessage('bank_name', 'Bank Name') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Bank Account Currency">
      <bl-text-field-mv class="account_ccy" :value="newData.account_ccy || oldData.account_ccy" readonly />
    </basic-field>
    <basic-field label="Bank Branch">
      <bl-text-field-mv class="bank_branch" :value="newData.bank_branch || oldData.bank_branch" readonly />
    </basic-field>
    <basic-field label="Bank Account No.">
      <bl-text-field-mv class="account_no" :value="newData.account_no || oldData.account_no" readonly />
    </basic-field>
    <basic-field label="Bank Account Name">
      <bl-text-field-mv
        v-model="account_name"
        v-validate="fieldRules.account_name"
        :is-error="!!fieldErrorMessage('account_name')"
        class="account_name"
        name="account_name"
      />
      <bl-field-error-av v-if="fieldErrorMessage('account_name')">
        {{ fieldErrorMessage('account_name', 'Bank Account Name') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Bank Valid">
      <bl-dropdown-av
        v-model="account_valid"
        v-validate="fieldRules.account_valid"
        :options="$options.BooleanDropdownOptions"
        :is-error="!!fieldErrorMessage('account_valid')"
        track-by="value"
        label="label"
        class="account_valid"
        name="account_valid"
      />
      <bl-field-error-av v-if="fieldErrorMessage('account_valid')">
        {{ fieldErrorMessage('account_valid', 'Bank Valid') }}
      </bl-field-error-av>
    </basic-field>
    <basic-field label="Bank Account Name (system)">
      <bl-text-field-mv
        class="checked_account_name"
        :value="newData.checked_account_name || oldData.checked_account_name"
        readonly
      />
    </basic-field>
    <hr />
  </div>
</template>
<script>
import InvestorDraftEditBankMixin from '../../mixins/InvestorDraftEditBankMixin';

export default {
  name: 'InvestorDraftEditBank',
  editableFields: ['bank_name', 'account_name', 'account_valid'],
  mixins: [InvestorDraftEditBankMixin],
  data() {
    const formFields = {};
    this.$options.editableFields.forEach(field => {
      formFields[field] = null;
    });

    return {
      ...formFields,
    };
  },
  created() {
    this.populateData();
  },
  methods: {
    populateData() {
      this.$options.editableFields.forEach(field => {
        if (!['account_valid'].includes(field)) {
          this[field] = this.newData[field] !== null ? this.newData[field] : this.oldData[field];
        }
      });

      const accountValidValue =
        this.newData.account_valid !== null ? this.newData.account_valid : this.oldData.account_valid;
      const newAccountValid = this.$options.BooleanDropdownOptions.find(opt => opt.value === accountValidValue);
      if (accountValidValue !== undefined && newAccountValid) {
        this.account_valid = newAccountValid;
      }
    },
    getPayload() {
      if (!this.isFormEditable) {
        return {};
      }

      return {
        investor_bank_id: this.investorBankDraftData.id,
        bank_name: this.bank_name,
        bank_branch: this.newData.bank_branch || this.oldData.bank_branch,
        account_no: this.newData.account_no || this.oldData.account_no,
        account_name: this.account_name,
        account_valid: this.account_valid === null ? null : this.account_valid.value,
        checked_account_name: this.newData.checked_account_name || this.oldData.checked_account_name,
        account_ccy: this.newData.account_ccy || this.oldData.account_ccy,
      };
    },
  },
};
</script>
