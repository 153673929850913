<template>
  <dd class="c-deflist__value">
    <slot />
  </dd>
</template>
<script>
export default {
  name: 'BlListDefinitionValueAv',
  status: 'ready',
  release: '0.1.1',
};
</script>
