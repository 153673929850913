import { provinceCodesIdentityMapping as provinceMap } from './constants';

export function checkBirthDate(baseNik, input) {
  if (!baseNik || !input) return false;

  const date = input.split('-');
  const birthYear = Number(date[0].slice(2));
  const birthMonth = Number(date[1]);
  const birthDate = Number(date[2]);

  let baseBirthDate = Number(baseNik.slice(6, 8));
  baseBirthDate = baseBirthDate > 40 ? baseBirthDate - 40 : baseBirthDate;
  const baseBirthMonth = Number(baseNik.slice(8, 10));
  const baseBirthYear = Number(baseNik.slice(10, 12));

  const isBornDateCorrect = baseBirthDate === birthDate;
  const isBornMonthCorrect = birthMonth === baseBirthMonth;
  const isBornYearCorrect = birthYear === baseBirthYear;

  return isBornDateCorrect && isBornMonthCorrect && isBornYearCorrect;
}

export function checkGender(baseNik, gender) {
  if (!baseNik || !gender) return false;
  if (!['Male', 'Female'].includes(gender)) return false;

  const baseBirthDate = Number(baseNik.slice(6, 8));

  const expectedGender = baseBirthDate > 40 ? 'Female' : 'Male';
  return expectedGender === gender;
}

export function checkProvince(baseNik, province) {
  if (!baseNik || !province) return false;

  const baseProvinceId = Number(baseNik.slice(0, 2));
  return baseProvinceId === provinceMap[province];
}

export default {};
