import { mapGetters } from 'vuex';
import policyMixin from './policyMixin';

export default {
  mixins: [policyMixin],
  computed: {
    ...mapGetters({
      policiesLoadState: 'getPoliciesLoadState',
    }),
  },
  watch: {
    policiesLoadState: {
      handler(value) {
        // handle concurrent request policy in policy mixin
        if (value === 'success') {
          this.checkPagePolicy();
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkPagePolicy() {
      const routerMetaPolicy = this.$route.meta && this.$route.meta.policy;

      if (routerMetaPolicy) {
        const { resource, action, redirect } = routerMetaPolicy;

        if (!this.isAllowed(resource, action)) {
          this.$root.$emit('showFlashNotif', {
            text: 'Anda tidak dapat mengakses halaman tersebut',
            type: 'error',
          });

          // redirect to previous page or to redirect?
          this.$router.push({ name: redirect });
        } else if (typeof this.retrieveInitialData === 'function') {
          this.retrieveInitialData();
        }
      }
    },
  },
};
