<template>
  <div class="c-panel__body">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlPanelBodyMv',
  status: 'ready',
  release: '0.4.0',
};
</script>

<style src="./BlPanelBodyMv.style.scss" lang="scss"></style>
