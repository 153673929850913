import VueRouter from 'vue-router';
import Vue from 'partner-admin/lib/vue';

import ProductPage from '../modules/ProductPage/MainPage';
import ProductIndexPage from '../modules/ProductPage/pages/ProductIndexPage';
import ProductAddPage from '../modules/ProductPage/pages/ProductAddPage';
import ProductEditPage from '../modules/ProductPage/pages/ProductEditPage';
import ProductDraftViewPage from '../modules/ProductPage/pages/ProductDraftViewPage';
import ProductDraftReviewPage from '../modules/ProductPage/pages/ProductDraftReviewPage';
import ProductViewPage from '../modules/ProductPage/pages/ProductViewPage';
import ProductHistoryPage from '../modules/ProductPage/pages/ProductHistoryPage';
import ProductDraftPage from '../modules/ProductPage/pages/ProductDraftPage';

import InvestorPage from '../modules/InvestorPage/MainPage';
import InvestorIndexPage from '../modules/InvestorPage/pages/InvestorIndexPage';
import InvestorDraftPage from '../modules/InvestorPage/pages/InvestorDraftPage';
import InvestorDraftViewPage from '../modules/InvestorPage/pages/InvestorDraftViewPage';
import InvestorDraftKycPage from '../modules/InvestorPage/pages/InvestorDraftKycPage';
import InvestorDraftReviewPage from '../modules/InvestorPage/pages/InvestorDraftReviewPage';
import InvestorViewPage from '../modules/InvestorPage/pages/InvestorViewPage';
import InvestorHistoryPage from '../modules/InvestorPage/pages/InvestorHistoryPage';
import InvestorHistoryUpdatePage from '../modules/InvestorPage/pages/InvestorHistoryUpdatePage';

import InvestorCheckedIndexPage from '../modules/InvestorCheckedPage/pages/InvestorCheckedIndexPage';
import InvestorCheckedReviewPage from '../modules/InvestorCheckedPage/pages/InvestorCheckedReviewPage';
import InvestorRevisesCheckedReviewPage from '../modules/InvestorCheckedPage/pages/InvestorRevisesCheckedReviewPage';

import InvestorRevisesDraftPage from '../modules/InvestorRevisesPage/pages/InvestorRevisesDraftPage';
import InvestorRevisesViewPage from '../modules/InvestorRevisesPage/pages/InvestorRevisesViewPage';
import InvestorRevisesEditPage from '../modules/InvestorRevisesPage/pages/InvestorRevisesEditPage';
import InvestorRevisesReviewPage from '../modules/InvestorRevisesPage/pages/InvestorRevisesReviewPage';

import RolesPage from '../modules/RolesPage/MainPage.vue';
import RolesIndexPage from '../modules/RolesPage/pages/RolesIndexPage';
import RolesSummaryPage from '../modules/RolesPage/pages/RolesSummaryPage';

import TransactionsPage from '../modules/TransactionsPage/MainPage';
import TransactionsIndexPage from '../modules/TransactionsPage/pages/TransactionsIndexPage';
import TransactionsViewPage from '../modules/TransactionsPage/pages/TransactionsViewPage';
import TransactionsHistoryPage from '../modules/TransactionsPage/pages/TransactionsHistoryPage';

import FileManagementPage from '../modules/FileManagementPage/MainPage';
import FileManagementIndex from '../modules/FileManagementPage/pages/FileManagementIndex';
import FileManagementHistoryPage from '../modules/FileManagementPage/pages/FileManagementHistoryPage';

import NavPage from '../modules/NavPage/MainPage';
import NavIndexPage from '../modules/NavPage/pages/NavIndexPage';
import NavViewPage from '../modules/NavPage/pages/NavViewPage';

import PortofoliosPage from '../modules/PortofoliosPage/MainPage';
import PortofoliosIndexPage from '../modules/PortofoliosPage/pages/PortofoliosIndexPage';
import PortofoliosInvestorViewPage from '../modules/PortofoliosPage/pages/PortofoliosInvestorViewPage';
import PortofoliosProductViewPage from '../modules/PortofoliosPage/pages/PortofoliosProductViewPage';

import ReportPage from '../modules/ReportPage/MainPage';

import UploadSktPage from '../modules/UploadSktPage/MainPage';
import UploadSktIndexPage from '../modules/UploadSktPage/pages/UploadSktIndexPage';

import AccountStatementIndexPage from '../modules/AccountStatement/pages/AccountStatementIndexPage';

import LoginPage from '../modules/session/LoginPage';
import ChangePasswordPage from '../modules/ChangePasswordPage/ChangePasswordPage';

import AmlCftPage from '../modules/AmlCftPage/MainPage';
import AmlCftIndexPage from '../modules/AmlCftPage/pages/AmlCftIndexPage';
import AmlCftConfigurationPage from '../modules/AmlCftPage/pages/AmlCftConfigurationPage';
import AmlCftConfigurationHistoryPage from '../modules/AmlCftPage/pages/AmlCftConfigurationHistoryPage';

import AgentPage from '../modules/AgentPage';

import ExchangeRatePage from '../modules/ExchangeRatePage';

import UserPage from '../modules/UserPage/MainPage';
import UserIndexPage from '../modules/UserPage/pages/UserIndexPage';

Vue.use(VueRouter);

function generateMetaPolicy(resource, action, redirect) {
  return {
    policy: {
      resource,
      action,
      redirect,
    },
  };
}
const defaultRedirect = 'rolesIndex';

const routes = [
  {
    path: '',
    redirect: { name: 'transactionsIndex' },
  },
  {
    path: '/products',
    component: ProductPage,
    children: [
      {
        path: '',
        name: 'productIndex',
        component: ProductIndexPage,
        meta: generateMetaPolicy('product', 'view_list', defaultRedirect),
      },
      {
        path: 'drafts',
        name: 'productDraft',
        component: ProductDraftPage,
        meta: generateMetaPolicy('product', 'view_list', 'productIndex'),
      },
      {
        path: 'drafts/add',
        name: 'productDraftNew',
        component: ProductAddPage,
        meta: generateMetaPolicy('product', 'create', 'productIndex'),
      },
      {
        path: 'drafts/:id/view',
        name: 'productDraftView',
        component: ProductDraftViewPage,
        meta: generateMetaPolicy('product', 'view_list', 'productIndex'),
      },
      {
        path: 'drafts/:id/review',
        name: 'productDraftReview',
        component: ProductDraftReviewPage,
        meta: generateMetaPolicy('product', 'approve_reject', 'productIndex'),
      },
      {
        path: ':id/edit',
        name: 'productEdit',
        component: ProductEditPage,
        meta: generateMetaPolicy('product', 'edit', 'productIndex'),
      },
      {
        path: ':id/view',
        name: 'productView',
        component: ProductViewPage,
        meta: generateMetaPolicy('product', 'view_list', 'productIndex'),
      },
      {
        path: ':id/history',
        name: 'productHistory',
        component: ProductHistoryPage,
        meta: generateMetaPolicy('product', 'view_log', 'productIndex'),
      },
    ],
  },
  {
    path: '/investors',
    component: InvestorPage,
    children: [
      {
        path: '',
        name: 'investorIndex',
        component: InvestorIndexPage,
        meta: generateMetaPolicy('investor', 'view_list', defaultRedirect),
      },
      {
        path: 'drafts',
        name: 'investorDraft',
        component: InvestorDraftPage,
        meta: generateMetaPolicy('investor', 'view_list', 'investorIndex'),
      },
      {
        path: 'drafts/:id/view',
        name: 'investorDraftView',
        component: InvestorDraftViewPage,
        meta: generateMetaPolicy('investor', 'view_list', 'investorIndex'),
      },
      {
        path: 'drafts/:id/kyc',
        name: 'investorDraftKyc',
        component: InvestorDraftKycPage,
        meta: generateMetaPolicy('investor', 'edit', 'investorIndex'),
      },
      {
        path: 'drafts/:id/review',
        name: 'investorDraftReview',
        component: InvestorDraftReviewPage,
        meta: generateMetaPolicy('investor', 'approve_reject', 'investorIndex'),
      },
      {
        path: ':id/view',
        name: 'investorView',
        component: InvestorViewPage,
        meta: generateMetaPolicy('investor', 'view_list', 'investorIndex'),
      },
      {
        path: ':id/history',
        name: 'investorHistory',
        component: InvestorHistoryPage,
      },
      {
        path: ':id/history-update',
        name: 'investorHistoryUpdate',
        component: InvestorHistoryUpdatePage,
        meta: generateMetaPolicy('investor_revise', 'view_list', 'investorIndex'),
      },
      {
        path: 'checked/drafts',
        name: 'investorCheckedIndex',
        component: InvestorCheckedIndexPage,
        meta: generateMetaPolicy('investor_revise', 'view_list', 'investorIndex'),
      },
      {
        path: 'checked/:id/review',
        name: 'investorCheckedReview',
        component: InvestorCheckedReviewPage,
        meta: generateMetaPolicy('investor', 'final_approve_reject', 'investorIndex'),
      },
      {
        path: 'revises/drafts',
        name: 'investorRevisesDraft',
        component: InvestorRevisesDraftPage,
        meta: generateMetaPolicy('investor_revise', 'view_list', 'investorIndex'),
      },
      {
        path: 'revises/checked/drafts',
        name: 'investorRevisesCheckedIndex',
        component: InvestorCheckedIndexPage,
        meta: generateMetaPolicy('investor_revise', 'view_list', 'investorIndex'),
      },
      {
        path: 'revises/:id/view',
        name: 'investorRevisesView',
        component: InvestorRevisesViewPage,
        meta: generateMetaPolicy('investor_revise', 'view_list', 'investorIndex'),
      },
      {
        path: 'revises/:id/edit',
        name: 'investorRevisesEdit',
        component: InvestorRevisesEditPage,
        meta: generateMetaPolicy('investor_revise', 'edit', 'investorIndex'),
      },
      {
        path: 'revises/:id/review',
        name: 'investorRevisesReview',
        component: InvestorRevisesReviewPage,
        meta: generateMetaPolicy('investor_revise', 'approve_reject', 'investorIndex'),
      },
      {
        path: 'revises/checked/:id/review',
        name: 'investorRevisesCheckedReview',
        component: InvestorRevisesCheckedReviewPage,
        meta: generateMetaPolicy('investor_revise', 'final_approve_reject', 'investorIndex'),
      },
    ],
  },
  {
    path: '/transactions',
    component: TransactionsPage,
    children: [
      {
        path: '',
        name: 'transactionsIndex',
        component: TransactionsIndexPage,
        meta: generateMetaPolicy('transaction', 'view_list', defaultRedirect),
      },
      {
        path: ':id/view',
        name: 'transactionsView',
        component: TransactionsViewPage,
        meta: generateMetaPolicy('transaction', 'view_list', 'transactionsIndex'),
      },
      {
        path: ':id/history',
        name: 'transactionsHistory',
        component: TransactionsHistoryPage,
        meta: generateMetaPolicy('transaction', 'view_log', 'transactionsIndex'),
      },
    ],
  },
  {
    path: '/file-management',
    component: FileManagementPage,
    children: [
      {
        path: '',
        name: 'fileManagementIndex',
        component: FileManagementIndex,
      },
      {
        path: ':id/history',
        name: 'fileManagementHistory',
        component: FileManagementHistoryPage,
      },
    ],
  },
  {
    path: '/navs',
    component: NavPage,
    children: [
      {
        path: '',
        name: 'navIndex',
        component: NavIndexPage,
        meta: generateMetaPolicy('nav', 'view_list', defaultRedirect),
      },
      {
        path: ':id/view',
        name: 'navView',
        component: NavViewPage,
        meta: generateMetaPolicy('nav', 'view_list', defaultRedirect),
      },
    ],
  },
  {
    path: '/portofolios',
    component: PortofoliosPage,
    children: [
      {
        path: '',
        name: 'portofoliosIndex',
        component: PortofoliosIndexPage,
        meta: generateMetaPolicy('portfolio', 'view_list', defaultRedirect),
      },
      {
        path: ':id/investor/:investor_id',
        name: 'portofoliosInvestorView',
        component: PortofoliosInvestorViewPage,
        meta: generateMetaPolicy('portfolio', 'view_list', defaultRedirect),
      },
      {
        path: ':id/product/:product_id',
        name: 'portofoliosProductView',
        component: PortofoliosProductViewPage,
        meta: generateMetaPolicy('portfolio', 'view_list', defaultRedirect),
      },
    ],
  },
  {
    path: '/reports',
    name: 'reportIndex',
    component: ReportPage,
  },
  {
    path: '/upload-skt',
    component: UploadSktPage,
    children: [
      {
        path: '',
        name: 'uploadSktIndex',
        component: UploadSktIndexPage,
        meta: generateMetaPolicy('document', 'transaction_confirmation', defaultRedirect),
      },
      {
        path: 'account-statement',
        name: 'AccountStatementIndex',
        component: AccountStatementIndexPage,
        meta: generateMetaPolicy('document', 'account_statement', defaultRedirect),
      },
    ],
  },
  {
    path: '/aml-cft',
    component: AmlCftPage,
    children: [
      {
        path: '',
        name: 'AmlCftIndex',
        component: AmlCftIndexPage,
        meta: generateMetaPolicy('aml_report', 'download', defaultRedirect),
      },
      {
        path: 'configuration',
        name: 'AmlCftConfiguration',
        component: AmlCftConfigurationPage,
        meta: generateMetaPolicy('aml_report', 'config', 'AmlCftIndex'),
      },
      {
        path: 'configuration-history',
        name: 'AmlCftConfigurationHistory',
        component: AmlCftConfigurationHistoryPage,
        meta: generateMetaPolicy('aml_report', 'config', 'AmlCftIndex'),
      },
    ],
  },
  {
    path: '/agents',
    name: 'agentIndex',
    component: AgentPage,
    meta: generateMetaPolicy('agent', 'view_list', defaultRedirect),
  },
  {
    path: '/roles',
    component: RolesPage,
    children: [
      {
        path: '',
        name: 'RolesIndex',
        component: RolesIndexPage,
      },
      {
        path: 'summary',
        name: 'RolesSummary',
        component: RolesSummaryPage,
      },
    ],
  },
  {
    path: '/users',
    name: 'userIndex',
    component: UserPage,
    children: [
      {
        path: '',
        name: 'UserIndex',
        component: UserIndexPage,
        meta: generateMetaPolicy('investor', 'user_management', defaultRedirect),
      },
    ],
  },
  {
    path: '/exchange-rate',
    name: 'ExchangeRateIndex',
    component: ExchangeRatePage,
    meta: generateMetaPolicy('exchange_rate', 'view_list', defaultRedirect),
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/change-password',
    name: 'changePasswordPage',
    component: ChangePasswordPage,
  },
  {
    path: '/*',
    redirect() {
      window.location.href = '/404';
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: `${process.env.PARTNER_BASE_ROUTE_PATH}`,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
