<template>
  <bl-field-mv>
    <bl-field-row-mv>
      <bl-field-label-av>{{ field.label }}</bl-field-label-av>
      <bl-text-field-mv
        v-if="['text', 'number'].includes(field.type)"
        ref="input"
        v-model="inputModel"
        v-validate="field.rule"
        :name="field.name"
        :is-error="isError"
        :readonly="readonly"
        :multiline="field.multiline === true"
      />
      <div v-if="field.type === 'radio'">
        <!-- TODO Fix Radio Button Boolean Bugs -->
        <bl-radio-button-av
          v-for="option in field.options"
          ref="input"
          :key="option.value"
          v-model="inputModel"
          v-validate="field.rule"
          :name="field.name"
          :value="option.value"
          :label="option.label"
          :is-error="isError"
          :disabled="readonly"
        />
      </div>
      <div v-if="field.type === 'date'">
        <vue-datepicker
          v-model="inputModel"
          v-validate="field.rule"
          :name="field.name"
          :disabled="readonly"
          :input-class="isError ? 'c-inp--error' : ''"
          :language="$options.datepickerLocale"
        />
      </div>
      <bl-dropdown-av
        v-if="field.type === 'select'"
        ref="input"
        v-model="inputModel"
        v-validate="field.rule"
        :name="field.name"
        :options="field.options"
        :is-error="isError"
        :disabled="readonly"
        :searchable="field.searchable || false"
        track-by="value"
        label="label"
      />
      <bl-upload-field-av
        v-if="field.type === 'file'"
        ref="input"
        v-model="inputModel"
        v-validate="field.rule"
        :name="field.name"
        :is-error="isError"
        :disabled="readonly"
        @input="onFileChange"
      />
      <bl-field-hint-av v-if="field.hint">
        {{ field.hint }}
      </bl-field-hint-av>
      <bl-field-error-av v-if="validateError">
        {{ validateError }}
      </bl-field-error-av>
    </bl-field-row-mv>
  </bl-field-mv>
</template>
<script>
import vueDatepicker from 'vuejs-datepicker';
import { id } from 'vuejs-datepicker/dist/locale';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlFieldLabelAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldLabelAv';
import BlFieldHintAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldHintAv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlUploadFieldAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlUploadFieldAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlRadioButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlRadioButtonAv';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';

export default {
  name: 'InputField',
  components: {
    vueDatepicker,
    BlFieldMv,
    BlFieldRowMv,
    BlFieldLabelAv,
    BlTextFieldMv,
    BlRadioButtonAv,
    BlDropdownAv,
    BlUploadFieldAv,
    BlFieldHintAv,
    BlFieldErrorAv,
  },
  datepickerLocale: id,
  props: {
    field: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputModel: null,
    };
  },
  computed: {
    validateError() {
      const { name } = this.field;
      
      let errorMessage = this.errors.first(name) || '';
      
      if (name === 'instrument_allocation' && this.inputModel) {
        const dataInstrument = this.inputModel;
        let arrInstrument = dataInstrument.split(/\r?\n/)
        
        arrInstrument.forEach((el) => {
          if (el && !el.includes('-')) {
            errorMessage = 'Format Instrument Allocation tidak sesuai';
          }
        });
      }

      return errorMessage.replace(name, this.field.label);
    },
    isError() {
      return Boolean(this.validateError);
    },
  },
  watch: {
    field: {
      handler(newField) {
        this.field = newField;
        this.inputModel = newField.value;
      },
      immediate: true,
      deep: true,
    },
    inputModel: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update', { ...this.field, value: newVal });
        }
      },
    },
  },
  created() {
    /* istanbul ignore next */
    this.$validator.extend('custom_file_extension', {
      getMessage: (field, args) => args.message,
      validate: (value, args) => {
        if (!value || !value.file_name) {
          return !this.field.rule.required;
        }

        const fileName = value.file_name;
        const regex = args.ext_regex;
        return regex.test(fileName);
      },
    });
  },
  methods: {
    validate() {
      this.$validator.validate();
    },
    onFileChange(files) {
      if (files[0]) {
        const vm = this;
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            vm.inputModel = {
              file_name: files[0].name,
              file: reader.result,
            };
          },
          false,
        );
        reader.readAsDataURL(files[0]);
      }
    },
  },
};
</script>
