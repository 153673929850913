<template>
  <Table
    :load-state="loadState"
    :rows="transactions"
    :headers="$options.headers"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <transaction-table-row v-for="transaction in transactions" :key="transaction.id" :transaction="transaction" />
  </Table>
</template>
<script>
/* istanbul ignore next */
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import TransactionTableRow from './TransactionTableRow';

export default {
  name: 'TransactionTable',
  components: {
    TransactionTableRow,
  },
  mixins: [tableWrapperMixin],
  headers: [
    'Transaction Date',
    'Order Id',
    'Reference No',
    'Type',
    'IFUA',
    'Product',
    'CCY',
    'Gross Amount',
    'Amount',
    'Fee',
    'Agent',
    'State',
    'Unit Ashmore',
    'Unit CB',
  ],
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
};
</script>
