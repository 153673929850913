<template>
  <tr>
    <td class="investor-history-update-table-row__created">
      {{ investorRevise.created_at | formatDateTime }}
    </td>
    <td class="investor-history-update-table-row__name">
      {{ newData.name }}
    </td>
    <td class="investor-history-update-table-row__identity">
      {{ newData.identity_number }}
    </td>
    <td class="investor-history-update-table-row__state">
      {{ investorRevise.state }}
    </td>
    <td>
      <bl-button-av
        v-if="isAllowed('investor_revise', 'view_list')"
        class="investor-history-update-table-row__view"
        :href="viewUrl"
        @click.prevent.native="gotoView"
      >
        Lihat
      </bl-button-av>
    </td>
  </tr>
</template>
<script>
import policyMixin from 'partner-admin/mixins/policyMixin';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import dayjs from 'dayjs';

export default {
  name: 'InvestorHistoryUpdateTableRow',
  components: { BlButtonAv },
  filters: {
    formatDateTime(val) {
      return val ? dayjs(val).format('dddd, DD MMMM YYYY, Pukul HH:mm:ss WIB') : '';
    },
  },
  mixins: [policyMixin],
  props: {
    investorRevise: {
      type: Object,
      required: true,
    },
  },
  computed: {
    newData() {
      if (this.investorRevise) {
        if (this.investorRevise.data_revise) {
          return this.investorRevise.data_revise.last_approved;
        }
        return this.investorRevise.investor;
      }
      return this.investorRevise;
    },
    viewUrl() {
      return `${window.location.origin}/${process.env.PARTNER_BASE_ROUTE_PATH}/investors/revises/${this.investorRevise.id}/view?type=investor-revise`;
    },
  },
  methods: {
    gotoView() {
      this.$router.push({
        name: 'investorRevisesView',
        params: {
          id: this.investorRevise.id,
        },
        query: {
          type: 'investor-revise',
        },
      });
    },
  },
};
</script>
