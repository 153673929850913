<template>
  <Table
    :load-state="loadState"
    :rows="drafts"
    :headers="tableHeader"
    :total-row-data="total"
    :limit="limit"
    :offset="offset"
    @reload="reload"
    @changePage="changePage"
  >
    <component :is="tableRowComponent" v-for="draft in drafts" :key="draft.id" :draft="draft" />
  </Table>
</template>
<script>
import tableWrapperMixin from 'partner-admin/mixins/tableWrapperMixin';
import DraftInvestorTableRow from './DraftInvestorTableRow';
import DraftInvestorReviseTableRow from './DraftInvestorReviseTableRow';

export default {
  name: 'DraftTable',
  components: {
    DraftInvestorTableRow,
    DraftInvestorReviseTableRow,
  },
  mixins: [tableWrapperMixin],
  props: {
    drafts: {
      type: Array,
      default: () => [],
    },
    recordType: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    loadState: {
      type: String,
      default: 'idle',
    },
    limit: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tableHeader() {
      return {
        'Bib::Investor': ['Update', 'NIK', 'Email', 'Agent', 'Risk', 'State', 'Priority', 'SINVEST rejected?', ''],
        'Bib::InvestorRevise': ['Update', 'NIK', 'Email', 'Agent', 'Risk', 'State', ''],
      }[this.recordType];
    },
    tableRowComponent() {
      return {
        'Bib::Investor': 'DraftInvestorTableRow',
        'Bib::InvestorRevise': 'DraftInvestorReviseTableRow',
      }[this.recordType];
    },
  },
};
</script>
