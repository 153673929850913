<template>
  <div>
    <div v-if="isAllowed('investor', 'approve_reject')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Review Investor Update
      </h1>
    </div>
    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-revises-view-form :investor-revise="investorRevise" :draft="draft" />
      <draft-checker-action :draft="draft" class="u-mrgn-top--10" @action="handleAction" />
    </div>
  </div>
</template>
<script>
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import DraftCheckerAction from 'partner-admin/components/DraftCheckerAction';
import InvestorRevisesMixin from '../mixins/InvestorRevisesMixin';
import InvestorRevisesViewForm from '../components/InvestorRevisesViewForm';

export default {
  name: 'InvestorRevisesReviewPage',
  components: { InvestorRevisesViewForm, DraftCheckerAction },
  mixins: [PolicyPageMixin, InvestorRevisesMixin],
  methods: {
    handleAction({ action, rejectionReason }) {
      const payload = {
        id: this.$route.params.id,
        state: action,
        rejection_reason: rejectionReason,
      };
      const actionMap = {
        approved: 'menyetujui',
        rejected: 'menolak',
      };
      this.$api
        .patchDraftStatus(payload)
        .then(() => {
          this.showSuccessNotif(`Berhasil ${actionMap[action]} pembaruan investor`);
          this.$router.push({
            name: 'investorRevisesDraft',
          });
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
