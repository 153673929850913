<template>
  <div>
    <div v-if="isAllowed('investor', 'create')" class="c-tab__content__head">
      <h1 class="u-txt--large u-txt--bold">
        Review Investor Update
      </h1>
    </div>

    <div v-if="loadState === 'success'" class="c-tab__content__body">
      <investor-revises-view-form :investor-revise="investorRevise" />
      <draft-checker-action :draft="draft" @action="handleAction" />
    </div>
  </div>
</template>

<script>
import InvestorRevisesViewForm from 'partner-admin//modules/InvestorRevisesPage/components/InvestorRevisesViewForm';
import InvestorRevisesMixin from 'partner-admin/modules/InvestorRevisesPage/mixins/InvestorRevisesMixin';
import DraftCheckerAction from 'partner-admin/components/DraftCheckerAction';

export default {
  name: 'InvestorCheckedReviewPage',
  components: { InvestorRevisesViewForm, DraftCheckerAction },
  mixins: [InvestorRevisesMixin],
  methods: {
    handleAction({ action, rejectionReason }) {
      const payload = {
        id: this.$route.params.id,
        state: action,
        rejection_reason: rejectionReason,
      };
      const actionMap = {
        final_rejected: 'menolak',
        final_approved: 'menyetujui',
      };
      this.$api
        .patchDraftStatus(payload)
        .then(() => {
          this.showSuccessNotif(`Berhasil ${actionMap[action]} pembaruan investor`);
          this.$router.push({
            name: 'investorRevisesCheckedIndex',
          });
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
