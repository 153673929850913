<template>
  <div @click="handleClick">
    <template v-if="multiline">
      <textarea
        v-model="internalValue"
        tabindex="0"
        :class="getClasses"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :required="required"
        :max-length="maxLength"
        v-bind="$attrs"
        @blur="handleBlur"
        @focus="handleFocus"
        @change="$emit('change', $event)"
        @keydown.up="$emit('onArrowUp')"
        @keydown.down="$emit('onArrowDown')"
        @keydown.enter="$emit('onEnter')"
      />
    </template>
    <template v-else>
      <template v-if="errorWithIcon">
        <div
          class="c-inp-grp-icon c-inp-grp-icon--right"
          :class="getClasses"
          :disabled="isDisabled"
          :required="required"
          @blur="handleBlur"
          @focus="handleFocus"
        >
          <input
            v-model="internalValue"
            tabindex="0"
            :class="getClasses"
            :placeholder="placeholder"
            v-bind="$attrs"
            @change="$emit('change', $event)"
            @keydown.up="$emit('onArrowUp')"
            @keydown.down="$emit('onArrowDown')"
            @keydown.enter="$emit('onEnter')"
          >
          <bl-icon-av
            variant="error"
            class="c-inp__icon"
          />
        </div>
      </template>
      <template v-else-if="clearable">
        <div
          class="c-inp-grp-icon c-inp-grp-icon--right"
          :class="getClasses"
          :disabled="isDisabled"
          :required="required"
          @blur="handleBlur"
          @focus="handleFocus"
        >
          <input
            ref="inputClearable"
            v-model="internalValue"
            tabindex="0"
            :class="getClasses"
            :placeholder="placeholder"
            v-bind="$attrs"
            @change="$emit('change', $event)"
            @keydown.up="$emit('onArrowUp')"
            @keydown.down="$emit('onArrowDown')"
            @keydown.enter="$emit('onEnter')"
          >
          <bl-spinner-av
            v-if="spinner"
            variant="red"
            size="small"
            class="c-inp__icon u-mrgn-right--0"
          />
          <bl-icon-av
            v-if="isValueNotEmpty"
            variant="cancel"
            class="c-inp__icon u-pointer"
            @click="clearableClicked"
          />
        </div>
      </template>
      <template v-else>
        <div
          :class="{
            ['c-inp-grp-icon']: spinner,
            ['c-inp-grp-icon--right']: spinner,
          }"
        >
          <input
            v-model="internalValue"
            tabindex="0"
            :class="getClasses"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :required="required"
            v-bind="$attrs"
            @blur="handleBlur"
            @focus="handleFocus"
            @change="$emit('change', $event)"
            @keydown.up="$emit('onArrowUp')"
            @keydown.down="$emit('onArrowDown')"
            @keydown.enter="$emit('onEnter')"
            @keydown.tab="$emit('onTab', $event)"
          >
          <bl-spinner-av
            v-if="spinner"
            variant="red"
            size="small"
            class="c-inp__icon u-mrgn-right--0"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import BaseInput from '../../../mixins/BaseInput';
import BlIconAv from '../../atoms/BlIconAv';
import BlSpinnerAv from '../../atoms/BlSpinnerAv';

export default {
  name: 'BlTextFieldMv',
  status: 'ready',
  release: '0.1.3',
  components: {
    BlIconAv,
    BlSpinnerAv,
  },
  mixins: [BaseInput],
  props: {
    /**
     * Whether the textfield is multiline
     * `true , false`
     */
    multiline: {
      type: Boolean,
      default: false,
    },
    /**
     * Error with icon
     * `true , false`
     */
    errorWithIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Value
     */
    value: {
      type: [String, Number],
      default: '',
    },
    /**
     * Formatter
     */
    formatter: {
      type: Object,
      default: null,
    },
    /**
     * Whether the textfield has spinner
     * `true , false`
     */
    spinner: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the textfield is clearable
     * `true , false`
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Maxlength
     */
    maxLength: {
      type: Number,
      default: 999,
    },
  },
  data() {
    return {
      isValueNotEmpty: false,
    };
  },
  computed: {
    internalValue: {
      get() {
        if (this.formatter) {
          return this.formatter.format(this.value);
        }
        return this.value;
      },
      set(value) {
        this.isValueNotEmpty = value.length > 0;
        if (this.formatter) {
          return this.$emit('input', this.formatter.deformat(value));
        }
        return this.$emit('input', value);
      },
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    handleBlur() {
      this.$emit('blur');
    },
    handleFocus() {
      this.$emit('focus');
    },
    clearableClicked() {
      this.internalValue = '';
      this.$refs.inputClearable.focus();
    },
  },
};
</script>

<style src="../BlInputGroupMv/BlInputGroupMv.style.scss" lang="scss"></style>
