<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" :title="`Approve Investor`">
      <div>
        Kamu yakin akan approve investor?
      </div>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          style="width: 50%; display: inline-block;"
          has-spinner
          :activated="isSubmitting"
          @click="closeDialog"
        >
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%; display: inline-block;"
          has-spinner
          :activated="isSubmitting"
          color="red"
          @click="bulkApprove"
        >
          Approve
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
  </div>
</template>

<script>
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import ErrorMixin from 'partner-admin/mixins/errorMixin';

export default {
  name: 'InvestorCheckedApproveConfirmation',
  components: {
    BlDialogMv,
    BlButtonGroupMv,
    BlButtonAv,
  },
  mixins: [ErrorMixin],
  props: {
    chosenInvestors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmationDialog: false,
      isSubmitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.confirmationDialog = false;
    },
    openDialog() {
      this.confirmationDialog = true;
    },
    bulkApprove() {
      this.isSubmitting = true;
      this.$api
        .patchBulkDraftStatus({
          ids: this.chosenInvestors,
          state: 'final_approved',
        })
        .then(() => {
          this.$emit('refresh');
          this.closeDialog();
        })
        .catch(this.handleError)
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
