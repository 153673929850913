<template>
  <tr>
    <td v-for="(data, index) in rowData.slice(1)" :key="index">
      {{ data }}
    </td>
    <td v-if="actionButton.length">
      <button v-if="checkAction('retry')" @click="retry" class="c-btn c-btn--medium">
        Retry
      </button>
      <router-link v-if="checkAction('view_list')" :to="viewLink" class="c-btn c-btn--medium" tag="button">
        Lihat
      </router-link>
      <router-link
        v-if="checkAction('investor_detail')"
        :to="investorDetailLink"
        class="c-btn c-btn--medium"
        tag="button"
      >
        Investor Detail
      </router-link>
      <router-link v-if="checkAction('edit')" :to="editLink" class="c-btn c-btn--medium" tag="button">
        Edit
      </router-link>
      <router-link v-if="checkAction('view_log')" :to="historyLink" class="c-btn c-btn--medium" tag="button">
        Riwayat
      </router-link>
    </td>
  </tr>
</template>

<script>
/* Should not scale up this file, instead move type to types folder */

import PolicyMixin from 'partner-admin/mixins/policyMixin';

export default {
  name: 'TableRowData',
  mixins: [PolicyMixin],
  typeMap: {
    products: 'product',
    investors: 'investor',
    roles: 'staff',
    transactions: 'transaction',
    portofolios: 'portfolio',
    navs: 'nav',
  },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    isDraft: {
      type: Boolean,
      required: true,
    },
    actionButton: {
      type: Array,
      default: () => [],
    },
    actionRootUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    viewLink() {
      if (this.isDraft) {
        return `${this.actionRootUrl}/drafts/${this.rowData[0]}/view`;
      }
      if (this.actionRootUrl === '/portofolios') {
        return `/portofolios/${this.rowData[0].id}/product/${this.rowData[0].product_id}`;
      }
      return `${this.actionRootUrl}/${this.rowData[0]}/view`;
    },
    investorDetailLink() {
      return `${this.actionRootUrl}/${this.rowData[0].id}/investor/${this.rowData[0].investor_id}`;
    },
    historyLink() {
      return `${this.actionRootUrl}/${this.rowData[0]}/history?type=${
        this.isDraft ? 'draft' : `${this.$options.typeMap[this.actionRootUrl.slice(1)]}`
      }`;
    },
    editLink() {
      return `${this.actionRootUrl}/${this.rowData[0]}/edit`;
    },
  },
  methods: {
    checkAction(type) {
      let action = type;
      if (['/roles'].includes(this.actionRootUrl)) {
        return this.actionButton.includes(type);
      }

      if (action === 'investor_detail') {
        action = 'view_list';
      }

      return (
        this.actionButton.includes(type) && this.isAllowed(this.$options.typeMap[this.actionRootUrl.slice(1)], action)
      );
    },
    retry() {
      this.$emit('retry', { id: this.rowData[0] });
    },
  },
};
</script>
