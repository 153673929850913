<template>
  <div class="c-tab__content is-active">
    <div class="c-tab__content__head">
      <div class="o-layout o-layout--responsive">
        <div class="o-layout__item u-align-right">
          <agent-new-dialog v-if="isAllowed('agent', 'create')" @submitSuccess="onSubmitSuccess" />
        </div>
      </div>
    </div>

    <div class="c-tab__content__body">
      <agent-table
        :load-state="loadState"
        :agents="agents"
        :total="filter.total"
        :limit="filter.limit"
        :offset="filter.offset"
        @reload="retrieveInitialData"
        @changePage="onChangePage"
      />
    </div>
  </div>
</template>
<script>
import NotifMixin from 'partner-admin/mixins/notifMixin';
import PolicyPageMixin from 'partner-admin/mixins/policyPageMixin';
import AgentNewDialog from './components/AgentNewDialog';
import AgentTable from './components/AgentTable';

const INITIAL_FILTER = {
  limit: 99,
  offset: 0,
  total: 0,
};

export default {
  name: 'AgentPage',
  components: { AgentTable, AgentNewDialog },
  mixins: [NotifMixin, PolicyPageMixin],
  data() {
    return {
      loadState: 'idle',
      agents: [],
      filter: { ...INITIAL_FILTER },
    };
  },
  methods: {
    retrieveInitialData() {
      const { limit, offset } = this.filter;
      const payload = { limit, offset };

      this.loadState = 'loading';

      this.$api
        .getAgents(payload)
        .then(({ data, meta }) => {
          this.agents = data;
          this.filter.total = meta.total;
          this.loadState = 'success';
        })
        .catch(e => {
          this.loadState = 'error';
          this.showErrorNotif(e);
        });
    },
    onChangePage({ offset }) {
      this.filter = {
        ...this.filter,
        offset,
      };
      this.retrieveInitialData();
    },
    onSubmitSuccess() {
      this.filter = { ...INITIAL_FILTER };
      this.retrieveInitialData();
    },
  },
};
</script>
