<template>
  <component
    :is="tag"
    :class="buttonClasses"
    :disabled="disabled"
    :href="href"
    :target="linkTarget"
    @click="handleClick"
  >
    <template v-if="hasSpinner">
      <i class="c-btn--spinner__icon" />
      <span class="c-btn--spinner__text"><slot /></span>
    </template>
    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
import sizes from './data/sizes.json';
import shapes from './data/shapes.json';
import colors from './data/colors.json';

export default {
  name: 'BlButtonAv',
  status: 'ready',
  release: '0.2.2',
  props: {
    /**
     * The sizes of the button.
     * `tiny, small, large, default`
     */
    size: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return sizes;
        }
        return sizes.indexOf(value) !== -1;
      },
    },

    /**
     * The shapes of the button.
     * `square, pill, default`
     */
    shape: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return shapes;
        }
        return shapes.indexOf(value) !== -1;
      },
    },

    /**
     * The colors of the button.
     * `red, green, yellow, pink, default`
     */
    color: {
      type: String,
      default: 'default',
      validator: (value, getValue) => {
        if (getValue) {
          return colors;
        }
        return colors.indexOf(value) !== -1;
      },
    },

    /**
     * Whether the button is disabled or not
     * `true , false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the button is ghost or not
     * `true , false`
     */
    isGhost: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the button is block or not
     * `true , false`
     */
    block: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the button has spinner or not
     * `true , false`
     */
    hasSpinner: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the button is clicked or not
     * `true , false`
     */
    clicked: {
      type: Function,
      default: null,
    },

    /**
     * Whether the button is activated or not
     * `true , false`
     */
    activated: {
      type: Boolean,
      default: false,
    },

    /**
     * Href Button
     * `true , false`
     */
    href: {
      type: String,
      default: undefined,
    },
    /**
     * Target Button
     * `true , false`
     */
    target: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    buttonClasses() {
      const array = ['c-btn'];
      array.push(`c-btn--${this.size}`);
      array.push(`c-btn--${this.shape}`);
      array.push(`c-btn--${this.color}`);
      if (this.isGhost) {
        array.push('c-btn--ghost');
      }
      if (this.block) {
        array.push('c-btn--block');
      }
      if (this.hasSpinner) {
        array.push('c-btn--spinner');
      }
      if (this.activated) {
        array.push('is-active');
      }
      return array;
    },
    tag() {
      return this.href ? 'a' : 'button';
    },
    linkTarget() {
      return this.href && this.target ? this.target : undefined;
    },
  },
  methods: {
    /**
     * Event click after user clicked.
     *
     * @event click
     */
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
<style src="./BlButtonAv.style.scss" lang="scss"></style>
