<template>
  <div v-if="showReject" class="u-mrgn-top--6">
    <inline-field label="Rejection Reason">
      <bl-text-field-mv v-model="rejectionReason" :is-error="isRejectionReasonDirty && !rejectionReason" />
      <bl-field-error-av
        v-if="isRejectionReasonDirty && !rejectionReason"
        class="transaction-switching-form__reject-error"
      >
        Wajib diisi
      </bl-field-error-av>
    </inline-field>

    <bl-button-av
      v-if="canReject"
      class="transaction-switching-form__reject"
      color="red"
      @click="checkRejectTransaction"
    >
      Reject
    </bl-button-av>
  </div>
</template>

<script>
import InlineField from 'partner-admin/components/InlineField';
import BlTextFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlTextFieldMv';
import BlFieldErrorAv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldErrorAv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';

export default {
  name: 'TransactionSwitchingRejectionForm',

  components: {
    InlineField,
    BlTextFieldMv,
    BlFieldErrorAv,
    BlButtonAv,
  },

  props: {
    transaction: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      rejectionReason: '',
      isRejectionReasonDirty: false,
    };
  },

  computed: {
    showReject() {
      return ['cancelled', 'sinvest_rejected'].includes(this.transaction.state) || this.transaction.rejection_reason;
    },
    canReject() {
      return !this.transaction.rejection_reason;
    },
  },

  watch: {
    transaction: {
      handler(transaction) {
        this.rejectionReason = transaction.rejection_reason;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    checkRejectTransaction() {
      this.isRejectionReasonDirty = true;
      if (!this.rejectionReason) return;

      this.rejectTransaction();
    },

    rejectTransaction() {
      const payload = {
        id: this.transaction.id,
        rejection_reason: this.rejectionReason,
      };
      this.$emit('reject', payload);
    },
  },
};
</script>
