<template>
  <div>
    <bl-dialog-mv :active.sync="confirmationDialog" class="custom-dialog" :title="`Download Draft Investor`">
      <bl-field-mv>
        <bl-field-row-mv>
          <bl-date-picker-mv
            v-model="chosenDate"
            class="u-width-1"
            is-has-multiple-calendar
            is-date-range-enabled
            :begin-date="chosenDate.beginDate || currentDate"
            :end-date="chosenDate.endDate"
            multiple-calendar
          />
        </bl-field-row-mv>
      </bl-field-mv>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          class="investor-draft-download-action__cancel"
          style="width: 50%; display: inline-block;"
          @click="cancelAction"
        >
          Cancel
        </bl-button-av>
        <bl-button-av
          class="investor-draft-download-action__download"
          style="width: 50%; display: inline-block;"
          color="red"
          @click="downloadInvestor"
        >
          Download
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
    <bl-button-av block class="investor-draft-download-action__button u-mrgn-bottom--1" color="red" @click="openDialog">
      Download Draft Investor
    </bl-button-av>
  </div>
</template>

<script>
import { mapState } from 'partner-admin/lib/vuex';
import dayjs from 'dayjs';
import queryString from 'querystring';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';

export default {
  name: 'InvestorDraftDownloadAction',
  components: { BlDialogMv, BlFieldMv, BlFieldRowMv, BlButtonGroupMv, BlButtonAv, BlDatePickerMv },
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      confirmationDialog: false,
      chosenDate: {},
      currentDate,
    };
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
  },
  methods: {
    cancelAction() {
      this.confirmationDialog = false;
    },
    downloadInvestor() {
      const token = this.tokenObj.token;
      const payload = {
        start_date: dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD'),
        end_date: dayjs(this.chosenDate.endDate).format('YYYY-MM-DD'),
        record_type: 'Bib::Investor',
        state: 'submitted',
        access_token: token,
      };

      const url = new URL(
        `${process.env.PARTNER_API_URL}/_exclusive/drafts/download?${queryString.stringify(payload)}`,
      );
      window.open(`${url}`, '_blank');
    },
    openDialog() {
      this.confirmationDialog = true;
    },
  },
};
</script>

<style scoped>
.custom-dialog /deep/ .c-dialog__content {
  max-width: 680px !important;
}
.custom-dialog /deep/ .c-dialog__panel__body {
  max-height: 560px !important;
}
.custom-dialog /deep/ .c-popover__outer {
  position: relative;
}
</style>
