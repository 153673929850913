<template>
  <div>
    <bl-dialog-mv
      :active.sync="confirmationDialog"
      class="custom-dialog download-transactions-action__dialog"
      :title="`Download ${actionText}`"
    >
      <div class="o-layout o-layout--responsive">
        <bl-field-mv class="o-layout__item u-12of12">
          <bl-dropdown-av
            v-model="product"
            placeholder="Product"
            track-by="isin_code"
            label="fund_name"
            :options="products"
            class="download-transactions-action__product-input"
            multiple
          />
        </bl-field-mv>

        <bl-field-mv v-if="isRecon" class="o-layout__item u-12of12">
          <bl-field-row-mv>
            <bl-date-picker-mv
              v-model="chosenDate"
              class="u-width-1 download-transactions-action__datepicker-input"
              is-has-multiple-calendar
              is-date-range-enabled
              :begin-date="chosenDate.beginDate || $options.today"
              :end-date="chosenDate.endDate"
              multiple-calendar
            />
          </bl-field-row-mv>
        </bl-field-mv>

        <bl-field-mv class="o-layout__item u-4of12">
          <bl-dropdown-av
            v-model="state"
            placeholder="State"
            :options="$options.stateOptions"
            :disabled="isDisabled"
            class="download-transactions-action__state-input"
          />
        </bl-field-mv>

        <bl-field-mv class="o-layout__item u-4of12">
          <bl-dropdown-av
            v-model="type"
            placeholder="Type"
            :options="$options.typeOptions"
            :disabled="isDisabled"
            class="download-transactions-action__type-input"
          />
        </bl-field-mv>
      </div>
      <bl-button-group-mv is-equal class="u-mrgn-top--4">
        <bl-button-av
          style="width: 50%; display: inline-block;"
          class="download-transactions-action__cancel-button"
          @click.native="cancelAction"
        >
          Batal
        </bl-button-av>
        <bl-button-av
          style="width: 50%; display: inline-block;"
          color="red"
          class="download-transactions-action__download-button"
          @click.native="downloadInvestor"
        >
          Download {{ actionText }}
        </bl-button-av>
      </bl-button-group-mv>
    </bl-dialog-mv>
    <file-downloader ref="FileDownloader" />
    <bl-button-av
      v-if="isAllowed('transaction', 'download')"
      block
      class="download-transactions-action__download-reconcile-button u-mrgn-bottom--1"
      @click.native="openDialog('recon')"
    >
      Download Reconcile
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'download_sinvest')"
      block
      class="download-transactions-action__download-subscription-button u-mrgn-bottom--1"
      @click.native="openDialog('subscription')"
    >
      Download Subs
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'download_sinvest')"
      block
      class="download-transactions-action__download-redemption-button u-mrgn-bottom--1"
      @click.native="openDialog('redemption')"
    >
      Download Redeem
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'download_sinvest')"
      block
      class="download-transactions-action__download-switching-button u-mrgn-bottom--1"
      @click.native="openDialog('switching')"
    >
      Download Switching
    </bl-button-av>
    <bl-button-av
      v-if="isAllowed('transaction', 'download_report_fund_manager')"
      block
      class="download-transactions-action__download-settlement-button u-mrgn-bottom--1"
      @click.native="downloadSettlement"
    >
      Download Settlement
    </bl-button-av>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'partner-admin/lib/vuex';
import BlFieldMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv';
import BlFieldRowMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlFieldMv/components/BlFieldRowMv';
import BlButtonAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlButtonAv';
import BlDropdownAv from 'partner-admin/lib/bazaar-dweb/components/atoms/BlDropdownAv';
import BlButtonGroupMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlButtonGroupMv';
import BlDatePickerMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDatepickerMv';
import BlDialogMv from 'partner-admin/lib/bazaar-dweb/components/molecules/BlDialogMv';
import dayjs from 'dayjs';
import queryString from 'query-string';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import FileDownloader from 'partner-admin/components/FileDownloader';

export default {
  name: 'DownloadTransactionsAction',
  stateOptions: ['all', 'created', 'waited'],
  typeOptions: ['all', 'subscription', 'redemption', 'switching'],
  actionOptions: {
    recon: 'Reconcile',
    subscription: 'Subscription',
    redemption: 'Redemption',
    switching: 'Switching',
  },
  today: dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .valueOf(),
  components: {
    BlFieldMv,
    BlFieldRowMv,
    BlDropdownAv,
    BlButtonAv,
    BlButtonGroupMv,
    BlDatePickerMv,
    BlDialogMv,
    FileDownloader,
  },
  mixins: [PolicyMixin],
  data() {
    return {
      confirmationDialog: false,
      actionType: '',
      downloadType: '',
      chosenDate: {},
      product: '',
      state: '',
      type: '',
      holidays: [],
    };
  },
  computed: {
    ...mapState('session', {
      tokenObj: 'token',
    }),
    ...mapGetters({
      products: 'getProducts',
    }),
    actionText() {
      return this.$options.actionOptions[this.downloadType];
    },
    isRecon() {
      return this.downloadType === 'recon';
    },
    isDisabled() {
      return !this.isRecon;
    },
  },
  created() {
    this.getHolidayDate();
  },
  methods: {
    getHolidayDate() {
      this.$api.getConfigs().then(({ data }) => {
        this.holidays = data.holidays;
      });
    },
    cancelAction() {
      this.confirmationDialog = false;
    },
    downloadInvestor() {
      const payload = {
        redownload: true,
        start_time: this.chosenDate.beginDate ? dayjs(this.chosenDate.beginDate).format('YYYY-MM-DD') : undefined,
        end_time: this.chosenDate.endDate ? dayjs(this.chosenDate.endDate).format('YYYY-MM-DD') : undefined,
        product_id: this.product.length ? this.product[0].id : undefined,
        state: (this.state === 'all' ? '' : this.state) || undefined,
        type: (this.type === 'all' ? '' : this.type) || undefined,
        action: this.actionType,
      };

      this.requestDownloadFile(payload);
    },
    downloadSettlement() {
      this.requestDownloadFile({ action: 'report_fund_manager' });
    },
    requestDownloadFile(payload) {
      const token = this.tokenObj.token;
      payload.access_token = token;

      const url = new URL(
        `${process.env.PARTNER_API_URL}/_exclusive/transactions/download?${queryString.stringify(payload)}`,
      );

      this.$refs.FileDownloader.download(`${url}`);
    },
    openDialog(type) {
      this.resetFilter();
      this.actionType = 'recon';
      this.downloadType = type;

      if (type !== 'recon') {
        this.actionType = 'sinvest';
        this.state = 'reconciled';
        this.type = type;
      }

      this.confirmationDialog = true;
    },
    resetFilter() {
      this.state = '';
      this.type = '';
      this.chosenDate = {};
      this.product = '';
    },
  },
};
</script>

<style scoped>
.custom-dialog /deep/ .c-dialog__content {
  max-width: 680px !important;
}
.custom-dialog /deep/ .c-dialog__panel__body {
  max-height: 560px !important;
}
.custom-dialog /deep/ .c-popover__outer {
  position: relative;
}
</style>
