<template>
  <div style="background-color: white">
    <main-header />
    <notification />
    <div
      v-if="loadState === 'success'"
      class="u-pad--4"
    >
      <template v-if="policiesLoadState === 'no access'">
        Anda Tidak Bisa Mengakses Halaman ini
      </template>
      <template v-else-if="policiesLoadState === 'error'">
        Terjadi kesalahan. Silakan <a @click.prevent="retrievePolicies">Muat Ulang</a>
      </template>
      <template v-else-if="!isLoggedIn || (isLoggedIn && policiesLoadState === 'success')">
        <tab-nav v-if="showTabNav" />
        <router-view />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'partner-admin/lib/vuex';
import notifMixin from 'partner-admin/mixins/notifMixin';
import PolicyMixin from 'partner-admin/mixins/policyMixin';
import MainHeader from 'partner-admin/components/MainHeader';
import Notification from 'partner-admin/components/Notification';
import TabNav from 'partner-admin/components/TabNav';

export default {
  name: 'App',
  components: { Notification, MainHeader, TabNav },
  mixins: [notifMixin, PolicyMixin],
  computed: {
    ...mapState('session', {
      isLoggedIn: 'isLoggedIn',
      loadState: 'loadState',
    }),
    ...mapGetters({
      policiesLoadState: 'getPoliciesLoadState',
    }),
    showTabNav() {
      return !['changePasswordPage', 'LoginPage'].includes(this.$route.name);
    },
  },
  watch: {
    isLoggedIn: {
      handler(val) {
        if (val) {
          this.getUser()
            .then(this.retrievePolicies)
            .catch(this.showErrorNotif);
        } else {
          this.resetUser().catch(this.showErrorNotif);
          this.getSession();
        }
      },
    },
  },
  created() {
    this.getSession();
  },
  methods: {
    ...mapActions('session', ['getLoginState']),
    ...mapActions('user', ['getUser', 'resetUser']),
    ...mapActions(['retrievePolicies']),
    getSession() {
      this.getLoginState()
        .then(() => {
          if (!this.isLoggedIn && this.$route.name !== 'LoginPage') {
            this.$router.replace({ name: 'LoginPage' });
          }
        })
        .catch(this.showErrorNotif);
    },
  },
};
</script>
<style src="viewerjs/dist/viewer.css" />
<style>
.viewer-toolbar li {
  padding: 0;
}
</style>
